import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import MemberSkillDataTable from "../../components/adminSkillRecord/MemberSkillDataTable";
import {
  getlocalStorageUserObject,
} from "../../helper/LocalStorageHelper";


// ----------------------------------------------------------------------

export default function AdminDataQueryPage() {
  // const theme = useTheme();
  const user = getlocalStorageUserObject();

  return (
    <>
      <title> Event Statistics </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            技能數據查詢
          </Typography>
          <MemberSkillDataTable user={user} mode="user" />
        </Stack>

      </Container>
    </>
  );
}
