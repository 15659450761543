import React, { useEffect } from "react";
import { Card, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";



// function buildSelection(key, label, dataList, includeAll,
//     selectionValue,
//     getCaptionFunction,
//     onChangeFunction) {

//     let selectItems = dataList.map((data) => {
//         let caption = getCaptionFunction(data);
//         return <MenuItem value={data.id}>{caption}</MenuItem>;
//     });

//     return (<FormControl fullWidth>
//         <InputLabel id={key + "-label"}>{label}</InputLabel>
//         <Select
//             labelId={key + "-label"}
//             id={key + "_id"}
//             value={selectionValue}
//             label={label}
//             onChange={onChangeFunction}
//         >
//             {selectItems}
//         </Select>
//     </FormControl>);
// }
const CommonDataSelection = ({
    key, label,
    value, dataList, captionFn, onChange, 
    allOption = false
}) => {
    let selectItems = dataList.map((data) => {
        let caption = captionFn(data);
        return <MenuItem value={data.id}>{caption}</MenuItem>;
    });

    if(allOption) {
        selectItems.unshift(<MenuItem value={0}>全部{label}</MenuItem>);
    }

    return (<FormControl fullWidth>
        <InputLabel id={key + "-label"}>{label}</InputLabel>
        <Select
            labelId={key + "-label"}
            id={key + "_id"}
            value={value}
            label={label}
            onChange={onChange}
        >
            {selectItems}
        </Select>
    </FormControl>);

}

export default CommonDataSelection;