import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React, { useEffect } from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import CoSportAnalyticsChart from "../../components/leaderBoard/CoSportAnalyticsChart";
import MockData from "../../helper/MockData";

export default function TestCoSportAnalyticsChart() {
    const { t } = useTranslation();
    const performData = MockData.getSkillPerformStatistics();

    let lang = getLang();

    const cardStyle = {
        cardColor: "rgba(255, 255, 255, 0.8)",
        iconBgColor: "#ffc",
        iconColor: "#f0f",
        maxWidth: 300,
    }

    const unitStr = "cm"




    return (
        <Container maxWidth="lg" >
            <Stack direction="column" sx={{ background: '#595', padding: 3 }} >
                <Typography variant="h3">Test Chart</Typography>

                <CoSportAnalyticsChart performData={performData} unit={unitStr} />
                <Box sx={{height: 20}} />
                <CoSportAnalyticsChart performData={performData} unit={unitStr} theme="kid"/>

            </Stack>
        </Container>
    )
}
