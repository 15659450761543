import React, { useState, useEffect, createContext } from "react";
/** Helper */
// import { Connector } from "mqtt-react-hooks";
import mqtt from "precompiled-mqtt";
/** Component */
import { Box, Grid, Typography, Container } from "@mui/material";
/** Content */

const MqttContext = createContext({
  mqttClient: null,
  topicResponse: null,
  currentSubTopic: "",
  subscribeTopic: () => {},
  unSubscribeTopic: () => {},
  publishMqtt: () => {},
  disconnect: () => {},
});

export function MqttContextProvider({ children }) {
  const [client, setClient] = useState(null);
  const [isSub, setIsSub] = useState(false);
  const [subTopic, setSubTopic] = useState("");
  const [topicResponse, setTopicResponse] = useState({ topic: "", data: null });

  useEffect(() => {
    if (client === null) {
      setClient(() => mqtt.connect(process.env.REACT_APP_MQTT_BROKER_SERVER));
    }
  }, []);

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        console.log("Connected MQTT");
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        console.log("Reconnecting...");
      });
      client.on("message", (topic, message) => {
        console.log("Message received!");
        // console.log({ topic, message: data.toString() });
        setTopicResponse(() => ({
          topic,
          data: JSON.parse(message.toString()),
        }));
      });
    }
  }, [client]);

  function subscribeTopic({ topic, qos = 0 }) {
    if (client) {
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }

        setSubTopic(topic);
        setIsSub(true);
        console.debug(`Subscribed to topics: ${topic}`);
      });
    }
  }

  function unSubscribeTopic(subscription) {
    if (isSub) {
      console.warn(`Have existing topic - [${subTopic}]`);
    }
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
          return;
        }
        setIsSub(false);
        console.debug(`Unsubscribed to topics: ${topic}`);
      });
    }
  }

  function publishMqtt(context) {
    if (client) {
      const { topic, qos, payload } = context;
      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    }
  }

  function disconnect() {
    if (client) {
      client.end(() => {
        console.log("Disconnected MQTT");
      });
    }
  }

  return (
    <MqttContext.Provider
      value={{
        mqttClient: client,
        topicResponse,
        currentSubTopic: subTopic,
        subscribeTopic,
        unSubscribeTopic,
        publishMqtt,
        disconnect,
      }}
    >
      {children}
    </MqttContext.Provider>
  );
}

export default MqttContext;
