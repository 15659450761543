import {
    Container,
    Typography,
    Box,
    Grid,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";
import EventList from "../../components/app/EventList";

export default function TestEventList() {
    const { t } = useTranslation();

    let lang = getLang();

    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test EventList</Typography>


            <Grid>
                <EventList eventList={MockData.getEventList()} />

            </Grid>
        </Container>
    )
}
