import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

import MockData from "../../helper/MockData";
import { getCurrentDateStr } from "../../helper/StringHelper";
import OverallSportDisplay from "../../components/leaderBoard/OverallSportDisplay";
import LeaderDisplay from "../../components/leaderBoard/LeaderDisplay";
import LeaderboardRankItem from "../../components/leaderBoard/LeaderboardRankItem";
import LeaderboardRankItemForKid from "../../components/leaderBoard/LeaderboardRankItemForKid";
import LeaderboardDisplayForKid from "../../components/leaderBoard/LeaderboardDisplayForKid";

const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];





export default function Test() {
    const { t } = useTranslation();

    let lang = getLang();

    const oneTopSkillOrg = MockData.getOneSkillTopOrganization();

    // let leaderBoardResult = MockData.getOneTimeslotLeaderboard();
    // MockData.getOneTimeslotLeaderboard();

    let eventId = 22;
    let leaderboardData = MockData.getOneOverallLeaderboardData();
    let leaderboardDataItem = leaderboardData.result[0];

    const barData = {
        rank: 1,
        schoolName: "Test School",
        score: 100,
    }

    const skillInfo = {

        "id": 8,
        "name_zh": "單腳平衡測試",
        "name_en": "Single-leg Balance",
        "code": "balance",
        "fitness_type": "balance",
        "fitness_type_en": "Single-leg Balance",
        "fitness_type_zh": "單腳平衡測試",
        "unit": "pt",
        "decimal_place": 0,
        "rank_direction": "desc",
    }


    const themeData = {
        bg: "orange",
        barColor: "#afa"
    }

    // const eventId = 22;



    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Leaderboard </Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <pre>
                        {/* {JSON.stringify(leaderBoardResult, null, 2)} */}
                    </pre>
                </Box>

                {/* 
                SportLeader={_sportLeader}
                  eventID={eventId}
                  date={TODAY}
                  key={`carousel-${_sportLeader.skill_code}-${index}`} */}
                <pre>
                    {JSON.stringify(leaderboardDataItem, null, 2)}
                </pre>
                <Box display="flex" sx={{ background: '#555', border: 0, padding: 1 }}>
                    <LeaderboardRankItem content={leaderboardDataItem} overall />
                </Box>
                <Box display="flex" sx={{ background: '#555', border: 0, padding: 1 }}>
                    <LeaderboardRankItemForKid content={leaderboardDataItem} />
                </Box>

                <LeaderboardDisplayForKid eventID={eventId} leaderboardData={leaderboardData} />

                <OverallSportDisplay
                    leaderboardData={leaderboardData}
                    eventID={eventId}
                    date={TODAY}
                />
                <Box height="50px"></Box>

            </Stack>
        </Container>
    )
}
