import { Box, Grid, Typography, Container, Stack } from "@mui/material";

import MockData from "../../../helper/MockData.js";

const MAIN_FONT = "Noto Sans TC";

const cardStyle = {
  flexGrow: 1,
  border: "4px solid white",
  background: "rgba(0, 0, 0, 0.15)",
  borderRadius: "10px",
  width: '100%',
}

const kidCardStyle = {
  background: "#FFE0C1",
  iconBgColor: "#ffc",
  iconColor: "#f0f",
  // : "#931A00",
  labelTextColor: "#552E00",
  flexGrow: 1,
  border: "8px solid #B96400",
  // maxWidth: '100%',
  width: '100%',

  // minWidth: 290,
}


const getCardStyle = (isKidTheme) => {
  if (isKidTheme === false) {
    return cardStyle;
  }

  let style = { ...kidCardStyle }
  let radius1 = "20px"
  // let radius2 = "5px"
  style.borderRadius = `${radius1}`;

  return style;

}


export default function Component({ schoolList, orgType }) {
  const isKidTheme = orgType === "kid" || orgType === "primary";
  const fontColor = isKidTheme ? "#552E00" : "white";
  const cardStyle = getCardStyle(isKidTheme);

  return (
    <Box
      align="center"
      sx={
        cardStyle
      }
      position="relative"
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        sx={{
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Typography
            variant={"h6"}
            sx={{
              fontFamily: MAIN_FONT,
              color: fontColor,
            }}
          >
            共創運動參與學校
          </Typography>
        </Grid>
        <Box display="flex" sx={{ padding: 0, flexWrap: "wrap" }}>
          {
            schoolList ?
              schoolList.map((school, index) => {
                return (
                  <Typography key={index}
                    color={fontColor}
                    sx={{
                      color: fontColor,                    
                      fontFamily: MAIN_FONT,
                      padding: 1,
                    }}
                  >
                    {school.name}
                  </Typography>
                )
              }) :
              <Typography
                sx={{
                  fontFamily: MAIN_FONT,
                  color: fontColor,
                }}>
                暫未有參與學校
              </Typography>
          }
        </Box>
      </Grid>
    </Box >
  );
}
