import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import MemberSkillDataTable from "../components/adminSkillRecord/MemberSkillDataTable";


// ----------------------------------------------------------------------

export default function AdminDataQueryPage() {
  const theme = useTheme();

  return (
    <>
      <title> Event Statistics </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            技能數據查詢
          </Typography>
          <MemberSkillDataTable />
        </Stack>

      </Container>
    </>
  );
}
