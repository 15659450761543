import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { useState } from "react";
import { getLang } from "../../helper/LangHelper";
import { BarChart, Bar, Cell, LabelList, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, AreaProps } from 'recharts';

const barData = [
    {
        "school_cls": "P1",
        "sum_score": "1439.5",
        "min_score": "0.5",
        "max_score": "68",
        "avg_score": "36.91025641025641"
    },
    {
        "school_cls": "P2",
        "sum_score": "4262.5",
        "min_score": "1",
        "max_score": "90",
        "avg_score": "41.78921568627451"
    },
    {
        "school_cls": "P3",
        "sum_score": "2656.5",
        "min_score": "6.5",
        "max_score": "80.5",
        "avg_score": "38.5"
    },
    {
        "school_cls": "P4",
        "sum_score": "1803.5",
        "min_score": "1",
        "max_score": "83",
        "avg_score": "41.94186046511628"
    },
    {
        "school_cls": "P5",
        "sum_score": "1987.5",
        "min_score": "19.5",
        "max_score": "84.5",
        "avg_score": "55.208333333333336"
    },
    {
        "school_cls": "P6",
        "sum_score": "2512",
        "min_score": "1",
        "max_score": "86",
        "avg_score": "58.41860465116279"
    }
];


const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const renderCustomizedLabel = (props) => {
    const {
        x,
        y,
        width,
        value
    } = props;
    // const radius = 10;
    return <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text x={x + width} y={y} fill="#f00"
            textAnchor="right" dominantBaseline="bottom">
            {value}
        </text>
    </g>;
};

const VerticalBarChart = () => {
    return (
        <BarChart
            layout="vertical"
            width={500}
            height={500}
            data={barData}
            margin={{
                top: 30,
                right: 20,
                bottom: 10,
                left: 10,
            }}
        >
            {/* <CartesianGrid stroke="#999" /> */}
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" />
            {/* <Tooltip /> */}
            {/* <Legend /> */}
            <Bar dataKey="pv" fill="#8884d8" >
                <LabelList dataKey="name" content={renderCustomizedLabel} />
            </Bar>
            {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
        </BarChart>);
}


const HorizontalBarChart = () => {
    return (
        <BarChart
            width={800}
            height={300}
            data={barData}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="school_cls" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="min_score" fill="#8884d8" barSize={30} />
            <Bar dataKey="avg_score" fill="#4d4" barSize={30} />
            <Bar dataKey="max_score" fill="#f4d" barSize={30} />

            {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" barSize={30} /> */}
        </BarChart>
    );
}

const TestAreaChart = () => {
    return (
        <AreaChart
            width={800}
            height={400}
            data={barData}
            innerRadius={0}
            outerRadius={0}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="school_cls" />
            <YAxis />
            <Tooltip />
            <Area fillOpacity={1} dataKey="max_score" stroke="#black" fill="#FFB506" />
            <Area fillOpacity={1} dataKey="avg_score" stroke="#black" fill="#D0F727" />
            <Area fillOpacity={1} dataKey="min_score" stroke="#black" fill="#55EAF3" />
        </AreaChart>
    )
    // return (
    //     <AreaChart
    //         width={800}
    //         height={300}
    //         data={barData}
    //         margin={{
    //             top: 20,
    //             right: 30,
    //             left: 20,
    //             bottom: 5,
    //         }}
    //     >
    //         <CartesianGrid strokeDasharray="3 3" />
    //         <XAxis dataKey="school_cls" />
    //         <YAxis />
    //         <Tooltip />
    //         {/* <Legend /> */}
    //         <Bar dataKey="min_score" fill="#8884d8" stackId="1"  />
    //         <Bar dataKey="avg_score" fill="#4d4" stackId="1"  />
    //         <Bar dataKey="max_score" fill="#f4d" stackId="1"  />

    //         {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" barSize={30} /> */}
    //     </AreaChart>
    // );
}

export default function TestChart() {
    const { t } = useTranslation();




    let lang = getLang();

    // // Chart data and options
    // const chartData = {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [
    //         {
    //             label: 'Sales',
    //             data: [50, 100, 150, 200, 250, 300, 350],
    //             fill: false,
    //             backgroundColor: 'rgba(75,192,192,0.4)',
    //             borderColor: 'rgba(75,192,192,1)',
    //         },
    //     ],
    // };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
    };




    return (

        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <Typography variant="h3">Test Chart</Typography>
                <Box sx={{ width: '100%', height: 500 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        {/* <VerticalBarChart /> */}
                        {/* <HorizontalBarChart /> */}
                        <TestAreaChart />
                    </ResponsiveContainer>
                </Box>
                {/* <Line data={chartData} options={chartOptions} /> */}
            </Stack>
        </Container>
    )
}
