import React, { useState, useEffect } from "react";
/** Helper */
import { useNavigate, useParams } from "react-router-dom";
// import { getDataRequest } from "../../helper/AxiosHelper";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// const mockSettingList = import("../../../_mock/orgMissionSettingsList.json");
import mockSettingList from "../../../_mock/mission_setting";
import { use } from "i18next";
import { AdminApi } from "../../../api/AdminApi";
import { toast } from "react-toastify";

// import CommonTable from "../../components/common/CommonTable";
// import CommonFilter from "../../components/common/CommonFilter";
// import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";

// import AddIcon from "@mui/icons-material/Add";
// import ImportContactsIcon from "@mui/icons-material/ImportContacts";
// import QrCodeIcon from "@mui/icons-material/QrCode";

// /** Content */
// import TableConfig from "./orgMissionSettingsListTableContent.jsx";

export default function Component({ orgID, onMissionSelected, inputList = null }) {
  const [settingList, setSettingList] = useState([]);

  useEffect(() => {
    // setMockData();
  });

  useEffect(() => {
    if (inputList === null) {
      loadSettingList();
    }
  }, [orgID]);

  useEffect(() => {
    if (inputList !== null) {
      setSettingList(inputList);
    }
  }, [inputList]);

  const setMockData = () => {
    console.log("settingList: ", mockSettingList);
    setSettingList(mockSettingList);
  }

  const loadSettingList = async () => {
    if (orgID === 0) {
      setSettingList([]);
      return;
    }

    AdminApi.loadOrgMissionSettingListCallback(orgID, (isSuccess, msg, data) => {
      if (isSuccess === false) {
        toast.error(msg);
        setSettingList([]);
        return;
      }
      // console.log("data: ", data);
      setSettingList(data);
    });

    // setSettingList(data.data);            
  };

  // const rows = [
  //   {
  //     rank: 'A',
  //     range: '90 ~ 100'
  //   },
  //   {
  //     rank: 'B',
  //     range: '80 ~ 89'
  //   },
  //   {
  //     rank: 'C',
  //     range: '70 ~ 79'
  //   },
  //   {
  //     rank: 'D',
  //     range: '60 ~ 69'
  //   },
  //   {
  //     rank: 'E',
  //     range: '0 ~ 59'
  //   }
  // ];

  const getMissionCycleStr = (missionCycle) => {
    if (missionCycle == 'month') {
      return '每月';
    } else if (missionCycle == 'week') {
      return '每週';
    } else {
      return missionCycle;
    }
  }

  const getCalcTypeStr = (calcType) => {
    if (calcType === 'play_count') {
      return '次數';
    } else if (calcType === 'play_time') {
      return '時間';
    } else if (calcType === 'play_score') {
      return '分數';
    } else {
      return calcType;
    }
  }

  const getRequireCountStr = (calcType, requireValue) => {
    if (calcType === 'play_count') {
      return `${requireValue} 次`;
    } else if (calcType === 'play_time') {
      return `${requireValue} 分鐘`;
    } else if (calcType === 'play_score') {
      return `${requireValue} 分`;
    } else {
      return requireValue;
    }
  }
  // onMissionSelected
  const handleMissionSelected = (mission) => {
    console.log("missionList: mission: ", mission);
    onMissionSelected(mission);
  }

  const getTableRow = (setting) => {
    const cycleStr = getMissionCycleStr(setting.mission_cycle);
    const calcTypeStr = getCalcTypeStr(setting.calc_type);
    const scoreStr = getRequireCountStr(setting.calc_type, setting.require_value);

    return (
      <TableRow key={setting.id}>
        <TableCell align="center">{setting.id}</TableCell>
        <TableCell align="center">{setting.description}</TableCell>
        <TableCell align="center">{cycleStr}</TableCell>
        <TableCell align="center">{setting.skill}</TableCell>
        <TableCell align="center">{calcTypeStr}</TableCell>
        <TableCell align="center">{scoreStr}</TableCell>
        <TableCell align="center">{setting.reward_point}</TableCell>
        <TableCell align="center">
          <Button variant="contained" color="primary"
            onClick={() => handleMissionSelected(setting)}>
            編輯
          </Button>
        </TableCell>
      </TableRow>
    );
  }
  const tableContent = settingList.map((setting) => {
    return getTableRow(setting);
  });

  return (
    <Container sx={{ m: 0 }}>
      {/* <CommonBreadcrumb dbData={orgData} /> */}

      <Paper sx={{ mt: 0 }} elevation={3}>
        {/* <pre>orgID: {orgID}</pre> */}
        <Grid>
          <TableContainer component={Paper}>
            <Table sx={{}} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">編號</TableCell>
                  <TableCell align="center">任務描述</TableCell>
                  <TableCell align="center">任務週期</TableCell>
                  <TableCell align="center">技能 / 運動</TableCell>
                  <TableCell align="center">結算方法</TableCell>
                  <TableCell align="center">需求數值</TableCell>
                  <TableCell align="center">獎勵點數</TableCell>
                  <TableCell align="center">動作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (settingList.length === 0) ?
                    (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>無資料</TableCell>
                      </TableRow>
                    ) :
                    (
                      tableContent
                    )
                }
              </TableBody>

            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </Container >
  );
}
