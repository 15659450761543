import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React, { useEffect } from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import ColorDashCard from "../../components/common/ColorDashCard";
// import RoundIcon from "../../components/common/RoundIcon";
import RoundIcon from "../../components/common/RoundIcon/RoundIcon";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AdbIcon from '@mui/icons-material/Adb';
import CoSportAnalyticsDashCards from "../../components/leaderBoard/CoSportAnalyticsDashCards";
import MockData from "../../helper/MockData";

export default function TestDashCard() {
    const { t } = useTranslation();
    const [usageStat, setUsageStat] = React.useState(null);

    useEffect(() => {
        const _usageStat = MockData.getSkillUsageStatistics();
        setUsageStat(_usageStat);
        
    }, []);    

    let lang = getLang();

    const cardStyle = {
        cardColor: "rgba(255, 255, 255, 0.8)",
        iconBgColor: "#ffc",
        iconColor: "#f0f",
        maxWidth: 300,
    }

    const unitStr = "cm"

   

    return (
        <Container maxWidth="lg" >
            <Stack direction="column" sx={{ background: '#595', padding: 3 }} >
                <Typography variant="h3">Test CoSportAnalyticsDashCards</Typography>

                <CoSportAnalyticsDashCards analyticData={usageStat} unit={unitStr} theme="secondary" />
                <Box sx={{height: 20}} />
                <CoSportAnalyticsDashCards analyticData={usageStat} unit={unitStr} theme="primary" />

                

            </Stack>
        </Container>
    )
}
