import React, { useState, useEffect } from "react";
/** Helper */
import { useNavigate, useParams } from "react-router-dom";
import { getDataRequest } from "../../helper/AxiosHelper";
/** Component */
import { Box, Grid, Container, Stack, Paper, Button } from "@mui/material";

import CommonTable from "../../components/common/CommonTable";
import CommonFilter from "../../components/common/CommonFilter";

import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
import AddIcon from "@mui/icons-material/Add";

/** Content */
import TableConfig from "../../components/equipment/data/equipmentDataListContent.jsx";

export default function Component() {
  // URL
  let { cateId } = useParams();
  const [filterQuery, setFilterQuery] = useState([]);
  const navigate = useNavigate();

  const [cateData, setCateData] = useState({});

  useEffect(() => {
    getDataRequest(`equipmentCategory/show/${cateId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + cateId);
      }
      let { record } = data;
      setCateData(record);
    });
  }, []);

  return (
    <Container maxWidth={"lg"}>
      <CommonBreadcrumb
        dbData={{ ...cateData, cate_name: cateData?.name_zh }}
      />

      <Paper sx={{ px: 3, pb: 3, mt: 6 }} elevation={3}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {/* Filter */}
              <Box>
                <CommonFilter
                  filterConfig={TableConfig.filterConfig}
                  emitQueryFn={setFilterQuery}
                />
              </Box>
              {/* Page Action */}
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<AddIcon />}
                  onClick={() =>
                    navigate(`/equipment-category/${cateId}/equipment/create`)
                  }
                >
                  新增設備
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CommonTable
              filterQuery={filterQuery}
              tableConfig={TableConfig}
              urlParma={{ categories: cateId }}
              // debug
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
