import React from "react";
import { Card, Box, Stack, Button, Chip, Typography } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
import { inf } from "numeric";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";

function memberCardInfo(memberData) {
  // let info = "";
  let infoArray = [];
  if(memberData.phone_num !== '') {
    infoArray.push(memberData.phone_num);
  }

  if(memberData.member !== null) {
    if(memberData.member.group !== null) {
      infoArray.push(memberData.member.group);
    }
    if(memberData.member.group_num !== null) {
      infoArray.push(memberData.member.group_num);
    }
    // infoArray.push(memberData.phone_num);
  }

  const info = infoArray.join(" / ");
  return `${memberData.name} - ${info}`;
}

export default function AdminLeaderboardCard({ leaderboardItem }) {
  const leaderResult = leaderboardItem.result || [];
  const rankList = leaderResult.map((item, index) => (
    <Stack sx={{ p: 4, backgroundColor: "primary.lighter" }} direction="column">
      <Typography variant="h5">
        Rank {item.rank} - {item.score}
        {item.unit}{" "}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={2}>
      {item.members.map((member, index) => (
        <Chip sx={{fontSize:"1.0rem"}} label={memberCardInfo(member)} />
        // <Box>
        //   <Typography variant="body1">            
        // {memberCardInfo(member)}
        //   </Typography>
        // </Box>
      ))}
      </Box>
    </Stack>
  ));

  return (
    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Stack direction="column">
        <Typography variant="h4">{leaderboardItem?.skill_name}</Typography>
        <Stack direction="column" gap={2}>
          {rankList}
        </Stack>
      </Stack>
      {/* <Box>
        <pre>{JSON.stringify(leaderboardItem, null, 2)}</pre>
      </Box> */}
    </Card>
  );
}
