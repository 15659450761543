import React, { useState, useEffect, memo } from "react";
import {
  TextField,
  IconButton,
  Tooltip,
  Box,
  Grid,
  Button,
  Paper,
  Card,
  Typography,
  CardContent,
  CardActionArea,
  Alert,
  Dialog,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  AddCircleOutline,
  CloseOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Stack } from "@mui/system";
import YoutubePreview from "../../common/YoutubePreview";

const EquipmentYtVideoSelector = memo(
  ({ existingList = [], videoListSetter = () => { } }) => {
    const [videoList, setVideoList] = useState(existingList);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newVideoNode, setNewVideoNode] = useState({
      url: "",
      title_en: "",
      title_zh: "",
    });
    const [editVideoNode, setEditVideoNode] = useState({});

    const handleInputChange = (event) => {
      setNewVideoNode({
        ...newVideoNode,
        [event.target.name]: event.target.value,
      });
    };

    const handleAddNode = () => {
      setVideoList([...videoList, newVideoNode]);
      setNewVideoNode({ url: "", title_en: "", title_zh: "" });
    };

    const handleDeleteNode = (index) => {
      const newList = [...videoList];
      newList.splice(index, 1);
      setVideoList(newList);
    };

    const isFormValid = (node) => {
      return Object.values(node).every((field) => field.trim() !== "");
    };

    // Dialog control
    const handleEditDialogClose = () => {
      setIsDialogOpen(false);
    };
    const handleEditDialogOpen = () => {
      setIsDialogOpen(true);
    };

    // update dialog
    const handleEditInputChange = (event) => {
      setEditVideoNode({
        ...editVideoNode,
        [event.target.name]: event.target.value,
      });
    };

    const handleEditVideoNode = (index = null) => {
      if (index === null) return;
      let existingNode = videoList[index];
      setEditVideoNode(() => ({
        ...existingNode,
        index,
      }));
    };

    const handleConfirmEdit = () => {
      let updateNode = JSON.parse(JSON.stringify(editVideoNode));
      let { index } = updateNode;

      if (index === null) return console.warn("Edit Video Node notfound");
      delete updateNode.index;

      setVideoList((pre) =>
        pre.map((item, _index) => (_index !== index ? item : updateNode))
      );
      handleEditDialogClose();
    };

    useEffect(() => {
      videoListSetter(() => videoList);
    }, [videoList]);

    useEffect(() => {
      if (existingList.length === 0) return;
      setVideoList(existingList);
    }, [existingList]);

    return (
      <div>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <TextField
              name="url"
              label="YouTube Link"
              value={newVideoNode.url}
              onChange={handleInputChange}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="title_zh"
              label="中文標題"
              value={newVideoNode.title_zh}
              onChange={handleInputChange}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="title_en"
              label="Title (English)"
              value={newVideoNode.title_en}
              onChange={handleInputChange}
              margin="normal"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Box align={"right"} mt={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddNode}
                startIcon={<AddCircleOutline />}
                disabled={!isFormValid(newVideoNode)}
              >
                新增影片
              </Button>
            </Box>
          </Grid>
        </Grid>
        {videoList.length === 0 ? (
          <Alert severity="info" sx={{ my: 2 }}>
            未有相關影片
          </Alert>
        ) : (
          <Paper
            elevation={2}
            sx={{
              p: 3,
              my: 3,
              bgcolor: "grey.200",
              overflow: "overlay",
              maxHeight: "500px",
            }}
          >
            <Grid container direction={"row"} gap={2} alignItems="center">
              {videoList.map((node, index) => (
                <Card key={index} sx={{ maxWidth: "296px" }}>
                  <YoutubePreview key={index} youtubeUrl={node.url} title={node.title_en}/>

                  {/* <Box key={index}>
                    <iframe
                      width="296"
                      height="166"
                      src={`https://www.youtube.com/embed/${node.url.split("=")[1]
                        }?si=dsYK7Zc3GeTL69uS`}
                      // src={`https://www.youtube.com/embed/${node.url.split("=")[1]
                      //   }?si=dsYK7Zc3GeTL69uS`}
                      
                      title={node.title_en}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <Typography align="center" variant="body2">
                          {node.url}
                      
                    </Typography>
                  </Box> */}
                  <CardContent>
                    <Stack direction={"row"} mb={2}>
                      <Typography>{`中文標題： [ ${node.title_zh} ]`}</Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <Typography>{`English Title： [ ${node.title_en} ]`}</Typography>
                    </Stack>
                  </CardContent>
                  <CardActionArea
                    disableRipple
                    sx={{ ":hover": { bgcolor: "gray.100" } }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,
                      }}
                    >
                      <Tooltip title="Delete">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteNode(index)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            handleEditDialogOpen();
                            handleEditVideoNode(index);
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </CardActionArea>
                </Card>
              ))}
            </Grid>
          </Paper>
        )}
        {/* Dialog */}
        <Dialog open={isDialogOpen}>
          <Card>
            <CardContent>
              <TextField
                name="url"
                label="Youtube Link"
                value={editVideoNode.url}
                onChange={handleEditInputChange}
                margin="normal"
                fullWidth
              />
              <TextField
                name="title_zh"
                label="中文標題"
                value={editVideoNode.title_zh}
                onChange={handleEditInputChange}
                margin="normal"
                fullWidth
              />
              <TextField
                name="title_en"
                label="Title (English)"
                value={editVideoNode.title_en}
                onChange={handleEditInputChange}
                margin="normal"
                fullWidth
              />
            </CardContent>
            <CardActionArea
              disableRipple
              sx={{ ":hover": { bgcolor: "gray.100" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  variant={"outlined"}
                  color="error"
                  onClick={handleEditDialogClose}
                >
                  取消
                </Button>
                <Button variant={"contained"} onClick={handleConfirmEdit}>
                  確認
                </Button>
              </Box>
            </CardActionArea>
          </Card>
        </Dialog>
      </div>
    );
  }
);

export default EquipmentYtVideoSelector;
