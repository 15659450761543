import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Card, Box, Button, TextField, Typography, CardContent } from "@mui/material";
import { toast } from "react-toastify";
import { AdminApi } from "../../api/AdminApi";
import { useFormik } from "formik";

export default function ChangeMemberPasswordForm() {
    const [errorMsg, setErrorMsg] = useState("");
    const formik = useFormik({
        initialValues: {
            phoneNum: "",
            newPassword: "",
            // skillValue: 0,
            // skillData: {},  // { left: 3, right: 3}
        },
    });

    const handleFormSubmit = async () => {
        console.debug("handleFormSubmit: ", formik.values);

        let phoneNum = formik.values.phoneNum;
        let newPassword = formik.values.newPassword;

        if (phoneNum === "") {
            setErrorMsg("請輸入會員電話");
            return;
        }

        if (newPassword === "") {
            setErrorMsg("請輸入新密碼");
            return;
        }

        setErrorMsg("");

        let result = await AdminApi.changePasswordByPhoneNum(phoneNum, newPassword);
        if (result.success === false) {
            setErrorMsg(result.message || "Unknown error");
            return;
        }

        let member = result.member;

        let msg = `更改密碼成功! (電話號碼：${phoneNum}  用戶名字：${member.display_name})`;
        toast.success(msg);

        clearForm();
    }

    const clearForm = () => {
        formik.resetForm();
    }

    const fieldStyle = {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: {
            xs: "flex-start",
            md: "flex-end",
        }
    };
    const inputStyle = {
        width: {
            xs: "100%",
            md: "80%",
        }
    };



    return (
        <Card sx={{}} variant="outlined">
            <CardContent sx={{ padding: 1 }}>

                <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography variant="h6" sx={{ padding: 3 }}>
                        更改會員登錄密碼
                    </Typography>

                    <Box sx={{ width: "100%" }} textAlign="center">
                        <Typography variant="body" sx={{ color: "red" }}>
                            {errorMsg}
                        </Typography>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box sx={fieldStyle} >
                                <Typography variant="h6" sx={{
                                }}>
                                    會員電話：
                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={9}>
                            <TextField
                                name="phoneNum"
                                id="phoneNum"
                                label="用戶電話"
                                size="small"
                                value={formik.values.phoneNum}
                                onChange={formik.handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box sx={fieldStyle} >
                                <Typography variant="h6" sx={{
                                }}>
                                    新密碼：
                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={9}>
                            <TextField
                                name="newPassword"
                                id="newPassword"
                                label="新密碼"
                                size="small"
                                type="password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                sx={inputStyle}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ width: "50%" }}>
                        <Button variant="contained" size="medium"
                            onClick={handleFormSubmit}
                            sx={{ width: "100%" }}
                        >
                            提交
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}