import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const mainField = "id";

const dataFieldConfig = [
  {
    id: "display_name",
    field: "display_name",
    align: "center",
    disablePadding: false,
    label: "名稱",
  },
  {
    id: "gender",
    field: "gender",
    align: "center",
    disablePadding: false,
    label: "性別",
  },
  {
    id: "email",
    field: "email",
    align: "center",
    disablePadding: false,
    label: "電郵",
  },
  {
    id: "qrcode_id",
    field: "qrcode_id",
    align: "center",
    disablePadding: false,
    label: "QRCode",
  },
  {
    id: "phone_num",
    field: "phone_num",
    align: "center",
    disablePadding: false,
    label: "電話號碼",
  },
  {
    id: "group",
    field: "group",
    align: "center",
    disablePadding: false,
    label: "組別",
  },
  {
    id: "group_num",
    field: "group_num",
    align: "center",
    disablePadding: false,
    label: "組別編號",
  },
];

const fetchDataConfig = {
  url: "/adminMember/queryWithOrg",
  extraQuery: [{ key: "org", field: "orgId" }],
  // fixedFilter: [],
};

const tableActionConfig = [
  {
    type: "link",
    label: "Detail",
    icon: "detail",
    // icon: EmojiEventsIcon,
    // url: "/organization/$organization_id/member/detail/$id",
    url: "/user/member/$id",
  },
  // {
  //   type: "confirmDialog",
  //   label: "Delete",
  //   icon: "delete",
  //   url: "/adminMember/delete/$id",
  //   dialogConfig: {
  //     /** https://sweetalert2.github.io/#usage */
  //     title: "確定要刪除嗎？",
  //     icon: "warning",
  //     showConfirmButton: true,
  //     showCancelButton: true,
  //     confirmButtonAriaLabel: "確定",
  //     cancelButtonAriaLabel: "取消",
  //     cancelButtonColor: "#a7a7a7",
  //     successMsg: "刪除成功",
  //     failMsg: "刪除失敗",
  //   },
  //   // icon: EmojiEventsIcon,
  // },
];

const filterConfig = [
  {
    type: "text",
    label: "按 名稱、電郵 搜索",
    field: "display_name,email",
  },
];

const TableConfig = {
  mainField,
  dataFieldConfig,
  fetchDataConfig,
  tableActionConfig,
  filterConfig,
};

export default TableConfig;
