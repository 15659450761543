import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
/** Helper */
import { AdminApi } from "../../../api/AdminApi";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Chip,
  Autocomplete,
  TextField,
  Stack,
} from "@mui/material";
/** Content */

const EquipmentCateSelector = memo(
  ({ clientOptions = [], optionSetter = () => {} }) => {
    const [cateData, setCateData] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isAppliedExistingOption, setIsAppliedExistingOption] =
      useState(false);

    const handleSelectedOptionChange = useCallback((e, values) => {
      setSelectedOptions(values);
    }, []);

    const handleClientOptions = useCallback(
      (_cOptions, cateList) => {
        if (!cateList) return;
        if (!isAppliedExistingOption) {
          _cOptions = _cOptions.map((option) =>
            typeof option === "string" ? parseInt(option) : option
          );

          let output = cateList.filter((_v) => {
            return _cOptions.includes(_v.id);
          });

          handleSelectedOptionChange(null, output);
          setIsAppliedExistingOption(true);
          return;
        }
      },
      [clientOptions]
    );

    const formattedOption = useMemo(
      () => selectedOptions.map((_option) => _option.id),
      [selectedOptions]
    );

    useEffect(() => {
      optionSetter(() => formattedOption);
    }, [formattedOption]);

    useEffect(() => {
      AdminApi.getEquCateListCallback((isSuccess, msg, data) => {
        if (isSuccess) {
          setCateData(() => data);
          handleClientOptions(clientOptions, data);
          return;
        }
        console.warn("Cate List Error -- ", msg);
      });
    }, []);

    useEffect(() => {
      if (cateData.length === 0 || clientOptions.length === 0) return;
      handleClientOptions(clientOptions, cateData);
    }, [clientOptions, cateData, handleClientOptions]);

    return (
      <Stack spacing={3}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={cateData}
          getOptionLabel={(option) => `${option.name_zh}  ${option.name_en}`}
          filterSelectedOptions
          value={selectedOptions}
          onChange={handleSelectedOptionChange}
          renderInput={(params) => (
            <TextField {...params} label="選擇類別*" placeholder="輸入類別" />
          )}
        />
      </Stack>
    );
  }
);
export default EquipmentCateSelector;
