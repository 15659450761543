import { Navigate, useRoutes } from "react-router-dom";
/** Layout */
import DashboardLayout from "../layouts/dashboard";

import UserMemberQRPage from "../views/user/UserMemberQRPage";
import UserLeaderboardPage from "../views/user/UserLeaderboardPage";
import UserDashboardPage from "../views/user/UserDashboardPage";
import UserStatisticPage from "../views/user/UserStatisticPage";
import UserDataQueryPage from "../views/user/UserDataQueryPage";
import UserOrganizationMemberPage from "../views/user/UserOrganizationMemberPage";
import UserOrganizationMemberDetailPage from "../views/user/UserOrganizationMemberDetailPage";

import {auth} from "./routerHelper";

const route = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "user",
      children: [
        { path: "qrcode", element: auth(<UserMemberQRPage />) },
        { path: "leaderboard", element: auth(<UserLeaderboardPage />) },
        { path: "statistic", element: auth(<UserStatisticPage />) },
        { path: "dataQuery", element: auth(<UserDataQueryPage />) },
        { path: "members", element: auth(<UserOrganizationMemberPage />) },
        // { path: ":orgId/member/detail/:memberId"}, 
        { path: "member/:memberId", element: auth(<UserOrganizationMemberDetailPage />) },
        { path: "dashboard", element: auth(<UserDashboardPage />) },
        // { path: "detail/:orgId", element: <OrganizationDetailPage /> },

        // { path: ":orgId/members", element: <OrganizationMemberPage /> },
        // {
        //   path: ":orgId/member/create",
        //   element: <OrganizationMemberCreatePage />,
        // },
        // {
        //   path: ":orgId/member/detail/:memberId",
        //   element: <OrganizationMemberDetailPage />,
        // },
        // {
        //   path: ":orgId/member/import",
        //   element: <OrganizationMemberImportPage />,
        // },
        // {
        //   path: ":orgId/events",
        //   element: <OrganizationEventPage />,
        // },
        // {
        //   path: ":orgId/event/create",
        //   element: <OrganizationEventCreatePage />,
        // },
        // {
        //   path: ":orgId/event/detail/:eventId",
        //   element: <OrganizationEventDetailPage />,
        // },

        // { path: "create", element: <OrganizationCreatePage /> },
        // { path: "import", element: <></> },
      ],
    },
  ],
};

export default route;
