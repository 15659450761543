import { Box, Typography, Grid, Divider, Stack } from "@mui/material";

const MAIN_FONT = "Noto Sans TC";

function memberName(memberData) {
  const {name, member} = memberData;

  let info = name;
  const {group} = member;

  if(group !== undefined && group !== '') {
    return `${group} ${name}`
  } else {
    return name
  }

  // return name;
}


export default function LeaderDisplay({ content, overall = false }) {
  const StringMap = {
    // 1: "金獎",
    // 2: "銀獎",
    // 3: "銅獎",
  };
  const MedalImgMap = {
    1: "/medal/gold.png",
    2: "/medal/silver.png",
    3: "/medal/bronze.png",
  };

  const OverallLeaderHeader = () => {
    let { rank, score, unit, members } = content;

    console.debug("members: ", members);
    let memberListStr = members == null || members == undefined ? "" : members.map((m) => m.name).join(", ");

    return (
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems={"center"}
        gap={3}
      >
        {/* {MedalImgMap[rank] ? (
          <Box
            component="img"
            src={MedalImgMap[rank]}
            maxWidth={64}
            maxHeight={64}
          />
        ) : null} */}

        <Typography
          variant="h3"
          color="white"
          sx={{
            fontFamily: MAIN_FONT,
            fontSize: "10px",
            fontWeight: "900",
            // lineHeight: "80px",
            letterSpacing: "0em",
          }}
        >
          {StringMap[rank] ? (
            <Typography
              sx={{
                minWidth: "230px",
                fontFamily: MAIN_FONT,
                fontSize: "25px",
                fontWeight: "900",
                // lineHeight: "80px",
                letterSpacing: "0em",
              }}
            >
              {StringMap[rank]}
            </Typography>
          ) : (
            <Box whiteSpace="nowrap">
              <Typography variant="h4">{"第" + rank + "名"}</Typography>
            </Box>
          )}
          <Box whiteSpace="nowrap">
            <Typography variant="h3">
              {score} {unit}
            </Typography>
          </Box>
          <Box whiteSpace="warp">
            <Typography variant="h5">{memberListStr}</Typography>
          </Box>
        </Typography>
      </Stack >
    );
  };
  const LeaderHeader = ({ content }) => {
    let { rank, score, unit } = content;

    return (
      <>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Box
            component="img"
            src={MedalImgMap[rank]}
            maxWidth={24}
            maxHeight={24}
          />
          <Typography
            variant="h3"
            color="white"
            sx={{
              fontFamily: MAIN_FONT,
              fontSize: "30px",
              fontWeight: "900",
              // lineHeight: "80px",
              letterSpacing: "0em",
            }}
          >
             {StringMap[rank]} #{rank} ({score}
            {unit})
          </Typography>
        </Grid>

        <Box my={1}>
          <Divider sx={{ background: "white" }} />
        </Box>
      </>
    );
  };

  const LeaderBody = ({ content }) => {
    const { members } = content;
    if(members === undefined) {
      return <Box></Box>
    }
    return (
      <Grid
        container
        direction="row"
        justifyContent={"center"}
        alignItems={"center"}
        columnGap={2}
      >
        {members.map((_m, index) => {
          if (index > 3) return null;
          return (
            <Stack direction="row" spacing={1} key={index}>
              <Typography
                key={`memberInfoName-${index}`}
                color={"white"}
                sx={{
                  fontFamily: MAIN_FONT,
                  fontSize: "18px!important",
                  fontWeight: "900",
                  // lineHeight: "80px",
                  letterSpacing: "0em",
                }}
              >
                {/* {_m.name} */}
                {memberName(_m)}
              </Typography>
              <Typography
                key={`memberInfoPhone-${index}`}
                color={"white"}
                sx={{
                  fontFamily: MAIN_FONT,
                  fontSize: "24px!important",
                  fontWeight: "500",
                  // lineHeight: "80px",
                  letterSpacing: "0em",
                }}
              >
                {_m.phone_num}
              </Typography>
            </Stack>
          );
        })}
      </Grid>
    );
  };
  return (
    <Box
      sx={{
        border: "3px solid #eee",
        borderRadius: "10px",
        boxShadow: " 0 3px 10px  #9ecaed;",
      }}
    >
      {overall ? (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={1}
          px={2}
          width={"30vw"}
        >
          <OverallLeaderHeader content={content} />
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={2}
          px={4}
        >
          <Grid item xs={12}>
            <LeaderHeader content={content} />
          </Grid>
          <Grid item xs={12}>
            <LeaderBody content={content} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
