import { TextField, Grid } from "@mui/material";

var FORMIK = {};

export function setFormik(_formik) {
  FORMIK = _formik;
}

const getID = (index) => {
  return `Form-Component-${new Date().getTime()}-${index}`;
};
// -------

export function isError(formik, key) {
  // console.log(formik.errors, formik.touched);
  return formik.errors[key] && formik.touched[key];
}
export function getErrorMsg(formik, key) {
  return isError(formik, key) ? formik.errors[key] : null;
}
export async function validateForm(formik) {
  await formik.validateForm();

  return !formik.isValid;
}
export const FormType = {
  INPUT: 1,
  TEXTAREA: 2,
  SINGLE_SELECTOR: 3,
  MULTI_SELECTOR: 4,
  AUTO_COMPLETE_SELECTOR: 5,
  AVATAR_UPLOAD: 6,
  IMAGE_UPLOAD: 7,
};
