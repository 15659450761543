import React, { useState, useEffect } from "react";
/** Helper */
import { useNavigate, useParams } from "react-router-dom";
import { getDataRequest } from "../../helper/AxiosHelper";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import CommonTable from "../../components/common/CommonTable";
import CommonFilter from "../../components/common/CommonFilter";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";

import AddIcon from "@mui/icons-material/Add";

/** Content */
import TableConfig from "../../components/organization/organizationEvents/orgEventListTableContent.jsx";
export default function Component() {
  // URL
  let { orgId } = useParams();

  const [filterQuery, setFilterQuery] = useState([]);
  const navigate = useNavigate();
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + orgId);
      }
      let { record } = data;

      setOrgData(record);
    });
  }, []);

  return (
    <Container maxWidth={"lg"}>
      <CommonBreadcrumb dbData={orgData} />

      <Paper sx={{ px: 3, pb: 3, mt: 6 }} elevation={3}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {/* Filter */}
              <Box>
                <CommonFilter
                  filterConfig={TableConfig.filterConfig}
                  emitQueryFn={setFilterQuery}
                />
              </Box>
              {/* Page Action */}
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  endIcon={<AddIcon />}
                  onClick={() =>
                    navigate(`/organization/${orgId}/event/create`)
                  }
                >
                  新增活動
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CommonTable
              filterQuery={filterQuery}
              tableConfig={TableConfig}
              urlParma={{ organization_id: orgId }}
              // debug
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
