import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Card, Box, Button, Typography, CardContent } from "@mui/material";
import { getLang, localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import EventCard from "./EventCard";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import QRCode from "react-qr-code";
import { Margin } from "@mui/icons-material";
import { margin } from "@mui/system";

function shortName(fullName) {
    if(fullName.length <= 20) {
        return fullName;
    }
    let nameString = fullName;
    let token = nameString.split(" ");
    nameString = token[0];

    token = nameString.split(" ");
    nameString = token[0];

    return nameString;
}

export default function MemberQRCard({
    member
}) {

    let studentName = shortName(member.display_name);
    // let studentInfo = `${member.group} ${member.group_num}`
    let studentInfo = `${member.group} ${member.group_num}`
    let qrCodeID = member.qrcode_id;
    let memberID = member.member_id;

    // const A4Paper = styled("div")({
    //     // width: "297mm",
    //     '@media print': {
    //         // width: "210mm",
    //         // height: "790px",
    //     },
    //     // height: "790px", // 793.688px 210mm
    //     // background: "red",
    //     display: "flex",
    //     flexDirection: "column",
    //     breakAfter: "page",

    // });

    // 4.13 cm x 10.5 cm
    // A4: 210mm x 297mm        cell width: 105mm, height: 42.2mm
    return (
        <Card sx={{
            width: '105mm', height: '42.42mm',
            // backgroundColor: 'red',
            overflow: 'hidden',
            boxShadow: 'none', borderRadius: 0,
            // // pageBreakAfter: 'always',
        }}
            raised={false} variant="outlined">
            <CardContent sx={{ padding: 1 }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box width="120" height="120" sx={{marginTop:2, marginLeft:2}}>
                    <QRCode value={qrCodeID} size={100}/>
                    </Box>
                    
                    {/* <Box styl
                        component={QRCode}
                        
                        value={qrCodeID}
                    /> */}
                    <Box sx={{pl:3}}>
                        <Stack direction="column">
                            <Typography style={{fontSize:'20px'}} >{studentName}</Typography>
                            <Typography style={{fontSize:'16px'}} >{studentInfo}</Typography>
                            <Typography style={{fontSize:'16px'}} >{memberID}</Typography>
                            <Typography style={{fontSize:'16px'}} >{qrCodeID}</Typography>
                        </Stack>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}