import React, { useState, useEffect } from "react";
/** Helper */
import { useNavigate } from "react-router-dom";
import ReactSwal from "../../../helper/AlertHelper";
/** Component */
import {
  Box,
  Grid,
  Stack,
  Paper,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
/** Content */

/** API */
import TableApi from "./CommonTableApi.js";
import { postDataRequest } from "../../../helper/AxiosHelper";

/** Function */
const MyTableHeader = ({ headerContent = null }) => {
  if (headerContent === null) return <></>;
  return (
    <TableHead>
      <TableRow>
        {headerContent.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
          // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align={"center"}>動作</TableCell>
      </TableRow>
    </TableHead>
  );
};

const MyTableCellSet = ({
  dataFieldConfig,
  rowContent = null,
  customCellFn = () => { },
}) => {
  if (rowContent === null) return [<></>];
  return dataFieldConfig.map((cellContent, index) => {
    return (
      <TableCell
        key={`table-cell-${index}`}
        align="center"
        onClick={() => {
          customCellFn();
          if (!("fieldAction" in cellContent)) return;
          cellContent.fieldAction(rowContent[cellContent.field]);
        }}
      >
        {rowContent[cellContent.field] || "-"}
      </TableCell>
    );
  });
};

const MyTableActionSet = ({
  mainField = "id",
  tableActionConfig = [],
  rowContent = {},
}) => {
  if (tableActionConfig.length <= 0)
    return <TableCell align="center">-</TableCell>;

  return (
    <TableCell align="center">
      <Stack direction="row" spacing={0} justifyContent={"center"}>
        {tableActionConfig.map((action, index) => {
          let MyIcon = getActionIcon(action);

          return (
            <IconButton
              key={`table-action-${rowContent[mainField]}-${index}`}
              onClick={getActionFn(mainField, action, rowContent)}
            >
              <MyIcon />
            </IconButton>
          );
        })}
      </Stack>
    </TableCell>
  );
};

function getActionIcon(action) {
  if ("icon" in action && typeof action.icon !== "string") {
    return action.icon;
  }

  switch (action.icon) {
    case "detail":
      return EditIcon;
    case "view":
      return VisibilityIcon;
    case "delete":
      return DeleteIcon;
    default:
      return <>{action?.label || "Action"}</>;
  }
}

function getActionFn(mainField, action, rowContent) {
  let formattedLink = formatLink(action.url, rowContent);

  if (action.type === "link") {
    return () => (window.location = formattedLink);
  }
  if (action.type === "confirmDialog") {
    return () => {
      ReactSwal.fire({
        preConfirm: () => {
          postDataRequest(formattedLink, {}, (isSuccess, msg) => {
            if (!isSuccess)
              return ReactSwal.fire({
                icon: "error",
                title: action.dialogConfig?.failMsg || "失敗",
                text: msg,
              });
            return ReactSwal.fire({
              icon: "success",
              title: action.dialogConfig?.successMsg || "成功",
              timer: 2000,
              timerProgressBar: true,
              willClose: () => {
                window.location.reload();
              },
            });
          });
        },
        ...action.dialogConfig,
      });
    };
  }
}

function formatLink(url, rowContent) {
  let urlTemplate = url;
  let parmList = urlTemplate.split("/");
  parmList = parmList.map((parm) => {
    if (parm.startsWith("$")) {
      let field = parm.replace("$", "");
      return rowContent[field];
    }
    return parm;
  });
  return parmList.join("/");
}

/** Main */
export default function Component({
  customCellFn,
  filterQuery = [],
  tableConfig = null,
  urlParma = {},
  options = {},
  debug = false,
}) {
  const { mainField, dataFieldConfig, fetchDataConfig, tableActionConfig } =
    tableConfig;

  const [fetchData, setFetchData] = useState({
    data: [],
    pagination: {
      total: 1,
      page: 1,
      page_size: options?.pageSize || 10,
    },
  });
  const [fetchQuery, setFetchQuery] = useState({
    filters: [
      // {'field':'name_en,name_zh', 'value':'民生', 'op':'like'}
    ],
    sort: "created_at.desc",
    page: 1,
    page_size: options?.pageSize || 10,
  });
  const [isLoading, setIsLoading] = useState(false); // isDataPending

  // useEffect(() => {
  //   TableApi.fetchDataQuery(
  //     fetchDataConfig,
  //     fetchQuery,
  //     urlParma,
  //     setFetchData,
  //     setDataPadding
  //   );
  // }, []);

  useEffect(() => {
    if (filterQuery === false) {
      return;
    }

    setIsLoading(true);         // ??? 


    TableApi.fetchDataQuery(
      fetchDataConfig,
      fetchQuery,
      urlParma,
      setFetchData,
      setIsLoading
    );
  }, [fetchQuery]);

  useEffect(() => {
    if (!filterQuery) {
      return;
    }

    let _filterQuery = filterQuery;
    if ("fixedFilter" in fetchDataConfig) {
      let _fixedFilter = fetchDataConfig.fixedFilter
        .map((filter) => {
          if (!urlParma[filter.field]) return null;
          filter.value = urlParma[filter.field];
          return filter;
        })
        .filter((filter) => filter !== null);

      _filterQuery = [...filterQuery, ..._fixedFilter];
    }



    setFetchQuery((preQuery) => ({
      ...preQuery,
      page: 1,
      filters: _filterQuery,
    }));
  }, [filterQuery]);

  function handleChangePage(event, newPage) {
    console.log("handleChangePage", newPage);
    setFetchQuery((preQuery) => ({
      ...preQuery,
      page: newPage + 1,
    }));
  }

  function handleChangeRowsPerPage(event) {
    setFetchQuery((preQuery) => ({
      ...preQuery,
      page_size: parseInt(event.target.value, 10),
      page: 1,
    }));
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Table */}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography
          my={4}
          align="center"
          color={"info"}
          fontWeight="bold"
          sx={{ display: fetchData.data.length === 0 ? "block" : "none" }}
        >
          -- 未找到資料，請重新搜尋/新增資料 --
        </Typography>
        <TableContainer
          sx={{ display: fetchData.data.length === 0 ? "none" : "block" }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          // size={dense ? "small" : "medium"}
          >
            <MyTableHeader headerContent={dataFieldConfig} />
            <TableBody>
              {fetchData.data.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`table-row-${index}`}
                  >
                    <MyTableCellSet
                      dataFieldConfig={dataFieldConfig}
                      rowContent={row}
                      customCellFn={customCellFn}
                    />
                    <MyTableActionSet
                      mainField={mainField}
                      tableActionConfig={tableActionConfig}
                      rowContent={row}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          sx={{ display: fetchData.data.length === 0 ? "none" : "block" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={fetchData.pagination?.total}
          rowsPerPage={fetchQuery.page_size}
          page={fetchQuery.page - 1}
          // count={fetchData.pagination?.total}
          // rowsPerPage={fetchData.pagination?.page_size || 1}
          // page={fetchData.pagination?.page - 1 || 1}
          labelDisplayedRows={
            ({ from, to, count }) =>
              from === to
                ? `第 ${from} 項 共 ${count} 項`
                : `第 ${from} 至 ${to} 項  共 ${count} 項 `
            // count !== -1 ? count : `more than ${to}`
          }
          labelRowsPerPage={"每頁顯示項數"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Print out the fetchData : */}
      {debug ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h6">Table Data: </Typography>
            <Box component="pre" sx={{ overflow: "auto", maxHeight: "20rem" }}>
              {JSON.stringify(fetchData, null, "  ")}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Query Data: </Typography>
            <Box component="pre" sx={{ overflow: "auto", maxHeight: "20rem" }}>
              {JSON.stringify(fetchQuery, null, "  ")}
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
}
