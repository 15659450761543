import React, { useEffect } from "react";
import {
    Grid, TextField, Card, Typography, Box, Button, FormControl,
    Alert,
    Stack, MenuItem, InputLabel, Select
} from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { AdminApi } from "../../api/AdminApi";
import { useFormik } from "formik";
import { use } from "i18next";
import { ToastContainer, toast } from "react-toastify";


const SelectionControl = ({ formik, field, labelName, menuItems }) => {
    return (
        <FormControl fullWidth>
            <InputLabel>{labelName}</InputLabel>
            <Select
                fullWidth
                value={formik.values[field]}
                onChange={(event) => {
                    formik.setFieldValue(field, event.target.value);
                }}
                onBlur={(event) => {
                    formik.touched[field] = true;
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={`selection-${index}`} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>

        </FormControl>
    );
};

const MissionCycleList = [
    { value: "week", label: "每週" },
    { value: "month", label: "每月" },
]

const CalcTypeList = [
    {
        value: "play_count",
        label: "累積次數"
    },
    {
        value: "play_time",
        label: "累積時間"
    },
    {
        value: "play_score",
        label: "累積分數"
    },
]

export default function Form({ orgID, inputMission }) {
    const [mission, setMission] = React.useState(inputMission);

    useEffect(() => {
        // setMission(inputMission);
        console.debug("Form useEffect: mission=", mission);
    }, []);

    const isCreate = () => {
        return mission.id === 0;
    }

    const getButtonLabel = () => {
        return isCreate() ? "新增" : "更新";
    }

    const formik = useFormik({
        initialValues: {
            id: 0,
            skill: mission?.skill,
            missionCycle: mission?.missionCycle || "month",
            calcType: mission?.calcType || "play_count",
            requireValue: mission?.requireValue || 10,
            rewardPoint: mission?.rewardPoint || 10,

            // : "",
            // skillValue: 0,
            // skillData: {},  // { left: 3, right: 3}
        },
    });

    const checkValueError = (values) => {
        // let msg = "";
        if (values.skill === "") {
            return "請輸入技能";
        }

        if (values.missionCycle === "") {
            return "請輸入任務週期";
        }
        if (values.calcType === "") {
            return "請輸入結算方法";
        }

        if (values.requireValue <= 0) {
            return "請輸入需求值";
        }

        if (values.rewardPoint <= 0) {
            return "請輸入獎勵點數";
        }

        return "";
    }

    const validateValues = (values) => {
        let msg = checkValueError(values);
        if (msg !== "") {
            toast.error(msg);
            return false;
        }
        return true;
    }


    const onSubmit = () => {
        console.debug("onSubmit: ", formik.values);
        if (validateValues(formik.values) === false) {
            return;
        }

        let formData = recordToFormData(formik.values);
        console.debug("onSubmit: formData=", formData);
        console.debug("onSubmit: isCreate=", isCreate());

        if (isCreate()) {
            handleCreateRecord(formData);
        } else {
            handleUpdateRecord(formData);
        }


    }

    const formDataToRecord = (formData) => {
        let record = {
            skill: formData.skill,
            missionCycle: formData.mission_cycle,
            calcType: formData.calc_type,
            requireValue: formData.require_value,
            rewardPoint: formData.reward_point,
            id: formData.id,
        }

        return record;
    }

    const recordToFormData = (record) => {
        let formData = {
            skill: record.skill,
            mission_cycle: record.missionCycle,
            calc_type: record.calcType,
            require_value: parseInt(record.requireValue),
            reward_point: parseInt(record.rewardPoint),
            id: record.id,
            org_id: orgID,
        }

        return formData;
    }

    const handleCreateRecord = (formData) => {
        AdminApi.createMissionSettingCallback(formData, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
            if (isSuccess === false) {
                toast.error(msg);
                return;
            }

            let newMission = formDataToRecord(data);

            setMission(newMission);

            formik.setFieldValue("id", newMission.id);
            toast.success("新增成功");

        });
    }

    const handleUpdateRecord = (formData) => {
        AdminApi.updateMissionSettingCallback(formData, (isSuccess, msg, data) => {
            console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
            if (isSuccess === false) {
                toast.error(msg);
                return;
            }

            toast.success("更新成功");

        });
    }



    // const missionCycleSelection = () => {
    //     return (
    //         <FormControl fullWidth>
    //             <InputLabel id="missionCycle">任務週期</InputLabel>
    //             <Select
    //                 labelId="missionCycle"
    //                 id="missionCycle"
    //             // value={missionCycle}
    //             // label="任務週期"
    //             // onChange={onMissionCycleChange}
    //             >
    //                 <MenuItem value={1}>每日</MenuItem>
    //                 <MenuItem value={2}>每週</MenuItem>
    //                 <MenuItem value={3}>每月</MenuItem>
    //             </Select>
    //         </FormControl>
    //     );
    // };


    return (<Card>
        <Box sx={{ padding: 2 }}>
            {/* {eventSelection} */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="error">This is an error Alert.</Alert>

                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="skill"
                        id="skill"
                        label="技能"
                        fullWidth
                        value={formik.values.skill}
                        onChange={formik.handleChange}
                    />
                </Grid>

                {/* //const SelectionControl = ({ formik, field, labelName, selectList }) => { */}
                <Grid item xs={6}>
                    <SelectionControl formik={formik} field="missionCycle" labelName="任務週期" menuItems={MissionCycleList} />
                </Grid>

                <Grid item xs={6}>
                    <SelectionControl formik={formik} field="calcType" labelName="結算方法" menuItems={CalcTypeList} />
                </Grid>

                {/* <Grid item xs={6}>
                    <TextField
                        name="missionCycle"
                        id="missionCycle"
                        label="任務週期"
                        fullWidth
                        value={formik.values.missionCycle}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        name="calcType"
                        id="calcType"
                        label="結算方法"
                        fullWidth
                        value={formik.values.calcType}
                        onChange={formik.handleChange}
                    />
                </Grid> */}

                <Grid item xs={6}>
                    <TextField
                        name="requireValue"
                        id="requireValue"
                        label="需求值"
                        fullWidth
                        value={formik.values.requireValue}
                        onChange={formik.handleChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        name="rewardPoint"
                        id="rewardPoint"
                        label="獎勵點數"
                        fullWidth
                        value={formik.values.rewardPoint}
                        onChange={formik.handleChange}
                    />
                </Grid>

            </Grid>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={onSubmit}
                    >{getButtonLabel()}</Button>
                    {/* <Button variant="contained">查詢</Button> */}
                </Grid>
            </Grid>
            {/* <ToastContainer /> */}
        </Box>

    </Card>);
}