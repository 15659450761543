export const max = (valueArray) => {
    if (valueArray === undefined) {
        return 0;
    }
    let count = valueArray.length;
    if (count === 0) {
        return 0;
    }

    let maxValue = 0;
    for (let value of valueArray) {
        let floatVal = parseFloat(value);
        if(isNaN(floatVal)) {
            continue;
        }
        if(floatVal > maxValue) {   
            maxValue = floatVal;
        }
    }

    return maxValue;
}


export const avg = (valueArray) => {
    if (valueArray === undefined) {
        return 0;
    }
    let count = valueArray.length;
    if (count === 0) {
        return 0;
    }

    let sum = 0;
    for (let value of valueArray) {
        let floatVal = parseFloat(value);
        if(isNaN(floatVal)) {
            floatVal = 0;
        }
        sum += floatVal;
        console.debug("sum=", sum);
    }
    console.debug("sum=", sum, " count=", count);

    return (sum / count).toFixed(2);
}

export const ratio = (value, min, max, positiveOnly = false) => {
    let ratio = (value - min) / (max - min);

    if (positiveOnly) {
        return Math.max(0, ratio);
    }

    return ratio;
}

export const clamp = (value, min, max) => {

    if (value < min) {
        return min;
    }
    else if (value > max) {
        return max;
    }

    return value;
};

// Get the linear interpolation between two value
export const lerp = (value1, value2, amount) => {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
}