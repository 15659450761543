import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React, { useEffect } from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import CommonDateRangePicker from "../../components/common/CommonDateRangePicker";
import { dateValueToYYYYMMDD } from "../../helper/TimeHelper";
import { set } from "date-fns";


export default function TestSimple() {
    const { t } = useTranslation();

    const [dateRange, setDateRange] = React.useState('');

    useEffect(() => {
        // console.debug("selectedDate: ", selectedDate);
        // setSelectedDate(new Date());
        // setSelectedDate(null);
        // console.debug()
    }, []);


    let lang = getLang();
    // let dateValue = new Date();
    const minDate = new Date("2023-05-05");
    const maxDate = new Date("2023-05-20");



    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestCommonDateRangePicker</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <Box><pre>dateRange: {dateRange} </pre></Box>

                <CommonDateRangePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={([startDate, endDate]) => {
                        console.debug("startDate: ", startDate, " typeof=", typeof startDate);
                        console.debug("endDate: ", endDate);
                        var dateStr = `${dateValueToYYYYMMDD(startDate)} ~ ${dateValueToYYYYMMDD(endDate)}`;
                        setDateRange(dateStr);
                    }}
                ></CommonDateRangePicker>
            </Stack>
        </Container>
    )
}
