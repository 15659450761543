
export const downloadWithURL = (fileUrl, fileName) => {

    // Create a new anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;

    // Dispatch a click event on the anchor
    link.dispatchEvent(new MouseEvent('click'));

    // Clean up
    URL.revokeObjectURL(link.href);
};
