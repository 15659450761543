import { Typography, Box, Paper } from "@mui/material";

import React from "react";

import HealthInputForm from "../../components/app/HealthInputForm/HealthInputForm";

export default function SkillInputSection({ skill, event }) {
  // const skill = MockData.getSkillInfoList()[0];
  // const [skillList, setSkillList] = useState(null);
  // const { t } = useTranslation();

  const eventID = event ? event.id : 0;

  return (
    <Box>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Box display="flex-column">
          <Typography variant="h3" sx={{ width: "100%" }}>
            活動健康數據輸入
          </Typography>
        </Box>
      </Paper>
      <Box mt={2} />

      <HealthInputForm eventID={eventID} />
    </Box>
  );
}
