
export const trimDate = (dateTimeStr) => {
    if (dateTimeStr === null || dateTimeStr === undefined) {
        return "";
    }
    if (dateTimeStr.indexOf(" ") === -1) {
        return dateTimeStr;
    }

    return dateTimeStr.substring(0, dateTimeStr.indexOf(" "));
};


// Moment DataValue 
export const dateValueToTimeStr = (dateValue) => {
    if (dateValue === null || dateValue === undefined) {
        return "";
    }
    // console.debug("dateValueToTimeStr: ", dateValue);
    const result = dateValue.format('YYYY-MM-DD HH:mm:ss');
    return result;
}

export const dateValueToYYYYMMDD = (dateValue) => {
    if (dateValue === null || dateValue === undefined) {
        return "";
    }
    const dateTimeStr = dateValueToTimeStr(dateValue);
    if(dateTimeStr.length < 10) {
        return "";
    }
    return dateTimeStr.substring(0, 10);
}

