import {
    Container,
    Typography,
    Box,
    TextField,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import { trimDate } from "../../helper/TimeHelper";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export default function TestTimeHelper() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(new Date("2023-05-05"));

    let lang = getLang();

    let testCases = ['', '2023-05-10 12:33:33', '2023-05-10'];
    let result = [];
    for (let i = 0; i < testCases.length; i++) {
        let input = testCases[i];
        let output = trimDate(input);
        let testResult = { input, output };
        result.push(testResult);
    }

    const testResult = result.map((item, index) => {
        return (
            <p key={index}>input: {item.input}, output: {item.output}</p>
        );
    });

    const minDate = new Date("2023-05-05");
    const maxDate = new Date("2023-05-20");


    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TimeHelper </Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <Box>
                    {testResult}
                </Box>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            inputFormat="YYYY-MM-DD"
                            value={value}
                            minDate={minDate}
                            maxDate={maxDate}                            
                            onChange={(newValue) => {
                                console.log(newValue);
                                setValue(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    {...params}
                                    label="Custom date"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            </Stack>
        </Container >
    )
}
