import React from "react";
import { Card, Grid, Box, Button, Typography } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { AdminApi } from "../../api/AdminApi";
import { downloadWithURL } from "../../helper/ExportHelper";

export default function EventCard({ event, hideActions = false }) {
  // const lang = getLang();
  const navigate = useNavigate();

  const eventName = localizedName(event, "name");

  const dateRange =
    timeToDateStr(event.start_at) + " - " + timeToDateStr(event.end_at);

  const hasEnroll = event.member_info !== null;

  let actionButton;

  const onDashboard = (e) => {
    const getCurrentSlot = () => {
      let { timeslotList } = event;

      if (timeslotList.length === 0) return 7;
      let currentTime = new Date().getHours();
      //   console.log(timeslotList);
      let currentSlot = timeslotList.find((_t) => {
        if ("break_time" in _t && _t.break_time === true) return false;
        const [startHour, startMin] = _t.start_at.split(":");
        const [endHour, endMin] = _t.end_at.split(":");

        return (
          parseInt(startHour) <= currentTime && parseInt(endHour) >= currentTime
        );
      });
      console.log(currentSlot);
      if (currentSlot !== undefined) return currentSlot.id;

      return 7; // the last slot
      //console.log(currentTime);
    };

    const currentSlot = getCurrentSlot(); // calculate from event data
    navigate(`/dashboard/${event.id}?timeslot=${currentSlot}`);
  };

  const onLeaderBoard = (e) => {
    console.debug("onLeaderboard");
    navigate(`/overall-dashboard/${event.id}`);
  };

  const onDataInput = (e) => {
    // console.debug("onDataInput");
    const options = {
      state: { event },
    };
    navigate(`/skill-list/${event.id}`, options);
  };
  const onHealthDataInput = (e) => {
    // console.debug("onDataInput");
    const options = {
      state: { event },
    };
    navigate(`/health-input/${event.id}`, options);
  };

  const onExportHealthSkillReport = (e) => {
    // console.debug("onDataInput");
    // const options = {
    //   state: { event },
    // };
    // navigate(`/health-input/${event.id}`, options);

    AdminApi.exportHealthAndSkillSummary(
      event.id, (isSuccess, msg, result) => {
        if (isSuccess === false) {
          alert(msg);
        }
        // console.debug(`isSuccess=${isSuccess}, msg=${msg}`);
        // console.debug(`eventID=${event.id} result: `, result);
        downloadWithURL(result.export_url, result.export_filename);
      }
    );
  };

  const onImportTanita = (e) => {
    console.debug("onImportTanita");
  };

  //   actionButton = <></>;

  return (
    <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Box>
        <Typography
          level="h2"
          sx={{ color: "white.main", fontSize: "1.3rem", mb: 0.5 }}
        >
          {eventName}
        </Typography>
      </Box>
      <Box>活動日期：{dateRange}</Box>

      {hideActions === false && (
        <>
          <Box mt={2} />
          <Grid container spacing={2}>
            {/* <Grid item xs={6} md={2}>
              <Button variant="contained" onClick={onDashboard}>
                今天活動排行榜
              </Button>
            </Grid> */}
            <Grid item xs={6} md={2}>
              <Button variant="contained" onClick={onLeaderBoard}>
                活動總排行榜
              </Button>
            </Grid>
            {/* <Button variant="contained" onClick={onLeaderboard}>
              Leaderboard
            </Button> */}
            <Grid item xs={6} md={2}>
              <Button variant="contained" color="secondary" onClick={onDataInput}>
                活動數據輸入
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onHealthDataInput}
              >
                活動健康數據
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onExportHealthSkillReport}
              >
                活動數據報表
              </Button>
            </Grid>
            {/* <Button variant="contained" onClick={onImportTanita}>
              Import Tanita Data
            </Button> */}
          </Grid>
        </>
      )}
    </Card>
  );
}
