import React, { } from "react";
import { Card, Stack, MenuItem, Divider, Box, InputLabel, FormControl, Select, Button, Typography, Menu } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { useState, useRef } from 'react';

import HealthDataConfirmCard from "./HealthDataConfirmCard";
import { AdminApi } from "../../api/AdminApi";
import { async } from "q";


export default function HealthDataImportCard({
    eventList = null,
}) {
    const inputRef = useRef(null);

    const [file, setFile] = useState(null);
    const [healthData, setHealthData] = useState(null);
    const [lastAccessTime, setLastAccessTime] = useState(null);
    const [hasPreview, setHasPreview] = useState(false);
    const [eventID, setEventID] = useState(1);
    const [group, setGroup] = useState('?');

    const handleFileChange = (e) => {
        const target = e.target;
        const files = target.files;
        console.debug("files=", files);
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    }

    const fileBox = file ? (
        <Box>{file.name}</Box>
    ) : (
        <Box>no file selected</Box>
    );

    // const handleImport = () => {
    //     if(onConfirm){
    //         onConfirm();
    //     }
    // }

    const handleEventChange = (e) => {
        const target = e.target;
        const value = target.value;
        console.debug("value=", value);
        setEventID(value);
    }

    const checkInput = () => {
        if (eventID == null || eventID <= 0) {
            alert("Please select an event");
            return false;
        }

        if (file === null) {
            alert("Please select a file");
            return false;
        }

        return true;
    }

    const handlePreviewData = async () => {
        // const date = '2022-12-02';
        // const timeslot = 6;
        if (checkInput() == false) {
            return;
        }

        try {
            const result = await AdminApi.previewHealthData(eventID, file);
            console.debug("result: ", result);
            if (result) {
                //  alert("Success");
                setHealthData(result.data);
                setLastAccessTime(result.lastAccessTime);
                setHasPreview(true);
                setGroup(result.group);
            } else {
                alert("Failed to find result");
            }
        } catch (error) {
            alert(
                error.message
            )
        }
    }

    const resetForm = () => {
        setFile(null);
        inputRef.current.value = null;
        setHealthData(null);
        setLastAccessTime(null);
        setHasPreview(false);
    }

    const handleConfirm = async () => {
        if (checkInput() === false) {
            return;
        }

        try {
            const result = await AdminApi.importHealthData(eventID, file);
            console.debug("result: ", result);
            if (result) {
                alert("成功 IMPORT TANITA 數據");
                resetForm();
            } else {
                alert("Failed to import result");
            }
        } catch (error) {
            alert(
                error.message
            )
        }
    }

    const eventSelectItem = eventList ?
        eventList.map((event) => <MenuItem value={event.id}>{event.name_zh}</MenuItem>
        ) : null;



    return (
        <Stack direction="column" gap={2}>
            <Card sx={{ p: 2 }}>
                <Stack direction="column" gap={2}>
                    <Typography level="h2" sx={{ color: 'white.main', fontSize: '1.3rem', mb: 0.5 }}>
                        IMPORT TANITA 數據資料
                    </Typography>

                    {/* <Box>{JSON.stringify(eventList)}</Box> */}
                    { /* Event Input */}
                    <FormControl fullWidth>
                        <InputLabel id="eventID-label">Event</InputLabel>
                        <Select
                            labelId="eventID-label"
                            id="eventID"
                            value={eventID}
                            label="Event"
                            onChange={handleEventChange}
                        >
                            {eventSelectItem}
                        </Select>
                    </FormControl>

                    { /* File Input */}
                    <Stack direction="row" gap={3} alignItems="center">
                        <Typography variant="body1">CSV檔案</Typography>

                        {fileBox}
                        <Button
                            variant="outlined"
                            component="label"
                        >
                            Upload File
                            <input
                                type="file"
                                hidden
                                ref={inputRef}
                                
                                onChange={handleFileChange}
                            />
                        </Button>


                    </Stack>
                    <Stack direction="column" >
                        <Typography variant="body2">備註：</Typography>
                        {/* <Typography sx={{ ml: 3 }} variant="body2">如未能選擇檔案，請Reload頁面</Typography> */}
                        <Typography sx={{ ml: 3 }} variant="body2">機器ID是根據檔案頭一個英文字定義： E.g aMC22123.csv -&gt; a</Typography>
                    </Stack>
                    <Divider my={2} />
                    <Box >
                        <Button variant="contained" onClick={handlePreviewData}>
                            預覽IMPORT數據
                        </Button>
                    </Box>
                </Stack>

            </Card >

            {/* <Box component="pre">
                healthData: {JSON.stringify(healthData)}
            </Box> */}

            {hasPreview &&
                <HealthDataConfirmCard healthData={healthData}
                    lastAccessTime={lastAccessTime}
                    onConfirm={handleConfirm}
                    group={group}
                />
            }
            {/* <Button variant="contained" onClick={resetForm}>
                Reset Form
            </Button> */}
        </Stack>
    );

}