import React, { useState, useEffect } from "react";
/** Helper */
import FormContent from "../../components/organization/orgEventFormSchema.jsx";

import {
  isError,
  getErrorMsg,
  validateForm,
} from "../../helper/FormHelper.jsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { getDataRequest, postDataRequest } from "../../helper/AxiosHelper";
import { useNavigate, useParams } from "react-router-dom";

/** Component */
import {
  Box,
  Grid,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
/** Content */

/** API */

const getNavigateOption = (formData = null) => {
  return {
    state: {
      event: formData,
    },
  };
};
export default function Component() {
  // URL
  let { orgId, eventId } = useParams();
  // navigate
  const navigate = useNavigate();
  // Formik
  const formik = useFormik(FormContent.FormikInstance);
  //update function
  const handleUpdateOrgEvent = async (e) => {
    if (await validateForm(formik)) return;
    let formData = JSON.parse(JSON.stringify(formik.values));

    postDataRequest(`event/update/${eventId}`, formData, (isSuccess, data) => {
      if (!isSuccess) {
        return toast.error("更新失敗");
      }

      toast.success("更新成功");

      return;
    });
  };

  const [isDataPadding, setDataPadding] = useState(true);
  const [currentRecordData, setCurrentRecordData] = useState(null);
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    getDataRequest(`event/show/${eventId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + eventId);
      }
      let { record } = data;
      // turn of the backdrop
      setDataPadding(false);
      // pass the current record data to breadcrumb to set the name
      setCurrentRecordData(record);
      let formFieldList = Object.keys(FormContent.FormikInstance.initialValues);
      formFieldList.forEach((field) => {
        formik.setFieldValue(field, record[field]);
      });
    });
  }, []);

  useEffect(() => {
    getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + orgId);
      }
      let { record } = data;

      setOrgData(record);
    });
  }, []);

  return (
    <Container maxWidth={"lg"}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataPadding}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CommonBreadcrumb
        dbData={{ ...currentRecordData, organization_name: orgData?.name_zh }}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={9}>
          <Box component="form" sx={{ mt: 3 }}>
            <Paper sx={{ px: 3, py: 5 }} elevation={3}>
              <Grid container rowSpacing={3} columnSpacing={2}>
                {FormContent.FormControl.map((item, index) => {
                  let gridStyle = "size" in item ? item.size : { xs: 12 };
                  let formControlProps = item.props;
                  if (item.variant === "selector") {
                    return (
                      <Grid item key={`form-section-${index}`} {...gridStyle}>
                        <FormControl
                          fullWidth
                          error={isError(formik, formControlProps.id)}
                        >
                          <InputLabel>{formControlProps.label}</InputLabel>
                          <Select
                            {...formControlProps}
                            fullWidth
                            value={formik.values[formControlProps.id]}
                            onChange={(event) => {
                              formik.setFieldValue(
                                formControlProps.id,
                                event.target.value
                              );
                            }}
                            onBlur={(event) => {
                              formik.touched[formControlProps.id] = true;
                            }}
                          >
                            <MenuItem value={""} disabled sx={{ ml: 2 }}>
                              {item.placeHolder}
                            </MenuItem>
                            {item.options.map((_area, index) => (
                              <MenuItem
                                key={`area-${index}`}
                                value={_area.value}
                              >
                                {_area.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {getErrorMsg(formik, formControlProps.id)}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  }
                  if (item.variant === "dateTime") {
                    const dateError = isError(formik, formControlProps.id);
                    return (
                      <Grid item key={`form-section-${index}`} {...gridStyle}>
                        <FormControl
                          error={isError(formik, formControlProps.id)}
                          fullWidth
                        >
                          <DateTimePicker
                            {...formControlProps}
                            onClose={() =>
                              (formik.touched[formControlProps.id] = true)
                            }
                            onChange={(newValue) =>
                              formik.setFieldValue(
                                formControlProps.id,
                                newValue
                              )
                            }
                            onError={(reason, value) => {
                              alert("error");
                            }}
                            value={formik.values[formControlProps.id]}
                            renderInput={(formControlProps) => (
                              <TextField
                                {...formControlProps}
                                error={dateError}
                              />
                            )}
                          />
                          <FormHelperText>
                            {getErrorMsg(formik, formControlProps.id)}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  }

                  return (
                    <Grid item key={`form-section-${index}`} {...gridStyle}>
                      <TextField
                        {...formControlProps}
                        value={formik.values[formControlProps.id]}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={isError(formik, formControlProps.id)}
                        helperText={getErrorMsg(formik, formControlProps.id)}
                        fullWidth
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{ maxWidth: { xs: "auto", md: "150px" } }}
                    onClick={handleUpdateOrgEvent}
                    disabled={!formik.isValid}
                  >
                    更新
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ px: 3, py: 5, mt: 3 }} elevation={3}>
            <Stack direction={"column"} spacing={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ maxWidth: { xs: "auto" } }}
                onClick={() => {
                  navigate(
                    `/dashboard/${eventId}?timeslot=1`,
                    getNavigateOption(currentRecordData)
                  );
                }}
              >
                今天排行
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ maxWidth: { xs: "auto" } }}
                onClick={() =>
                  navigate(
                    `/overall-dashboard/${eventId}`,
                    getNavigateOption(currentRecordData)
                  )
                }
              >
                整個活動排行
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ maxWidth: { xs: "auto" } }}
                onClick={() =>
                  navigate(
                    `/skill-list/${eventId}`,
                    getNavigateOption(currentRecordData)
                  )
                }
              >
                活動數據輸入
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ maxWidth: { xs: "auto" } }}
                onClick={() =>
                  navigate(
                    `/adminLeaderboard/${eventId}`,
                    getNavigateOption(currentRecordData)
                  )
                }
              >
                活動數據查詢
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
