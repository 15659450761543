import { Box, Grid, Typography, Container } from "@mui/material";
import LeaderDisplay from "../LeaderDisplay";

import leaderBoardBg_1 from "../../../public/leaderBoardBg_1.png";
import leaderBoardBg_2 from "../../../public/leaderBoardBg_2.png";
import leaderBoardBg_3 from "../../../public/leaderBoardBg_3.png";
import leaderBoardBg_4 from "../../../public/leaderBoardBg_4.png";
import LeaderboardRankItem from "../LeaderboardRankItem";
import { useEffect } from "react";
import { AdminApi } from "../../../api/AdminApi";
import { useState } from "react";
import CoSportSchoolInfoCard from "../CoSportSchoolInfoCard";

// import CoSportSchoolInfoCard from "../components/leaderBoard/CoSportSchoolInfoCard/index.jsx";
const MAIN_FONT = "Noto Sans TC";

const BackgroundMap = {
  blue: leaderBoardBg_1,
  orange: leaderBoardBg_2,
  purple: leaderBoardBg_3,
  green: leaderBoardBg_4,
};
export default function SportDisplay({ leaderboardData, date, eventID, overall }) {
  const [localSportLeader, setLocalSportLeader] = useState(leaderboardData);

  const NothingDisplay = () => {
    return (
      <Box
        sx={{
          border: "3px solid #eee",
          borderRadius: "10px",
          boxShadow: " 0 3px 10px  #9ecaed;",
        }}
        my={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={4}
          px={4}
        >
          <Grid item xs={12}>
            <Typography variant="h2" color={"white"}>
              未有資料
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    setLocalSportLeader((pre) => leaderboardData);

    // setnIntervId(
    const interval = setInterval(() => {
      SportLeaderUpdate();
    }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

    return () => clearInterval(interval);

    // );
  }, [leaderboardData]);

  const SportLeaderUpdate = async () => {
    console.log("SportLeaderUpdate: ", new Date().toLocaleTimeString());
    if (!("timeslot_id" in leaderboardData)) return;
    let result = await AdminApi.loadSingleEventOverallLeaderboardCallback(
      eventID,
      date,
      leaderboardData.query_code
    );
    setLocalSportLeader(result);
  };

  const skillCode = localSportLeader?.skill_code || "";

  return (
    <Box
      align="center"
      pt={6}
      minHeight={"100vh"}
      sx={{
        backgroundImage: `url("${BackgroundMap[localSportLeader?.color || "blue"]
          }")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      position="relative"
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        rowSpacing={2}
        pb={4}
        mb={4}
        sx={{
          // transform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg) ",
          // WebkitTransform: "rotateZ(-5deg) skewX(-5deg)" /* Safari & Chrome */,
          // MozTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Firefox */,
          // MsTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Internet Explorer */,
          // OTransform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Opera */,
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Box
            sx={{
              borderBottom: "3px solid #eee",
              boxShadow: " 0 3px 2px -2px  #9ecaed;",
              width: "fit-content",
            }}
          >
            <Typography
              variant={"h1"}
              color={"white"}
              sx={{
                fontFamily: MAIN_FONT,
                fontSize: "68px",
                fontWeight: "900",
                lineHeight: "80px",
                letterSpacing: "0em",
              }}
            >
              {localSportLeader.skill_name || "N/A"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={"h3"}
            color={"white"}
            sx={{
              fontFamily: MAIN_FONT,
              fontSize: "40px",
              fontWeight: "900",
              // lineHeight: "80px",
              letterSpacing: "0em",
            }}
          >
            {localSportLeader.timeslot_caption || "N/A"}
          </Typography>
        </Grid>


        {/* No any result */}
        {localSportLeader?.result?.length === 0 &&
          !("break_time" in localSportLeader) ? (
          <Grid item sx={{ width: "90%" }}>
            <NothingDisplay />
          </Grid>
        ) : null}
        {/* // Leader Display */}
        <Box sx={{}}>
          {/* <pre>{eventID} skillCode: [{skillCode}]</pre>
          <pre>
            {JSON.stringify(localSportLeader, null, 2)}
          </pre> */}
          {/* {
            skillCode !== "" && (
              <CoSportSchoolInfoCard eventID={eventID} skillCode={skillCode} />
            )
          } */}

        </Box>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          mt={3}
        >
          {localSportLeader.result.map((_r, index) => (
            <Grid item key={index} sx={{ width: "auto" }}>
              <LeaderboardRankItem key={`rand-${index}`} content={_r} overall />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
