import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData  from "../../helper/MockData";
import MemberQueryCardSection from "../../sections/_dashboard/MemberQueryCardSection";

export default function TestMemberQueryCardSection() {
    const { t } = useTranslation();

    let lang = getLang();
    let memberQueryResult = MockData.getMemberQueryResult();

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <MemberQueryCardSection />
            </Stack>
        </Container>
    )
}
