import React, { } from "react";
import { useState } from "react";
import { useTranslation, Translation } from "react-i18next";
import { useFormik } from "formik";
import {
    Stack, TextField, InputAdornment,
    Grid, Container, Box, Button,
    CardContent, Card, Typography,
    OutlinedInput, Paper,
    Divider, FormControl,
} from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
// import Typography from "../../theme/overrides/Typography";

export default function SkillLabel({
    skill
}) {
    const { t } = useTranslation();
    const skillName = localizedName(skill, 'name');
    const fitnessName = localizedName(skill, 'fitness_type');


    return (
        <Paper variant="outlined" sx={{p:2}}>
            <Box display="flex-column" >
                <Typography variant="h3" sx={{ width: '100%' }}>
                    {fitnessName}
                </Typography>
                <Typography variant="h4" sx={{ width: '100%' }}>
                    測試項目：{skillName}
                </Typography>
            </Box>
        </Paper>
    );
}