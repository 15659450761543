import React, { useEffect } from "react";
import { Card, Grid, TextField, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";


import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { WidthFull } from "@mui/icons-material";
import { createStyles, makeStyles } from '@mui/styles';
import Moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        // display: 'flex',
        alignItems: "center",
        width: "90%",
        margin: "auto"
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    formControl: {
        width: "90%"
    }
}));

const ranges = [
    { value: "custom", label: "Custom" },
    { value: "1week", label: "1 Week" },
    { value: "2week", label: "2 Weeks" },
    { value: "30d", label: "30 Days" },
    { value: "60d", label: "60 Days" },
    { value: "90d", label: "90 Days" },
];

const Component = ({
    onChange,
    startDateValue,
    endDateValue,
}) => {
    const [startDate, setStartDate] = React.useState(startDateValue);
    const [endDate, setEndDate] = React.useState(endDateValue);
    const [rangeOption, setRangeOption] = React.useState("custom");

    const dateTextFormat = "YYYY-MM-DD";

    useEffect(() => {
        changeRange("1week");
    }, []);

    // const customOrAll = 
    const onChangeStartDate = (newValue) => {
        setStartDate(newValue);
        onChange([startDate, endDate]);
        // setSelectedDate(newValue);
        // onChange(newValue);
    };

    const onChangeEndDate = (newValue) => {
        setEndDate(newValue);
        onChange([startDate, endDate]);
        // setSelectedDate(newValue);
        // onChange(newValue);
    };


    const menuItems = ranges.map((range) => (
        <MenuItem key={range.value} value={range.value}>
            {range.label}
        </MenuItem>
    ));

    const modifyDateByRange = (days) => {
        const end = new Moment();
        const start = (new Moment()).subtract(days, 'days'); 
        // start.setDate(end.getDate() - days);
        console.log("start", start, " end", end);
        setStartDate(start);
        setEndDate(end);

        onChange([start, end])
    }
    const handleOptionChange = (event) => {
        changeRange(event.target.value);
    }

    const changeRange = (value) => {
        setRangeOption(value);

        switch (value) {
            case '1week':
                modifyDateByRange(7);
                break;
            case '2week':
                modifyDateByRange(14);
                break;
            case '30d':
                modifyDateByRange(30);
                break;
            case '60d':
                modifyDateByRange(60);
                break;
            case '90d':
                modifyDateByRange(90);
                break;
            default:
                break;
        }

        // setSelectedDate(newValue);
        // onChange(newValue);
    }

    const classes = useStyles();
    return (
        <Box>
            {/* <pre>customDate: {customDate}</pre> */}
            < LocalizationProvider dateAdapter={AdapterMoment} >
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                        <DatePicker
                            inputFormat={dateTextFormat}
                            value={startDate}
                            onChange={(newValue) => {
                                onChangeStartDate(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth

                                    {...params}
                                    label="Start Date"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            inputFormat={dateTextFormat}
                            value={endDate}
                            onChange={(newValue) => {
                                onChangeEndDate(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth

                                    {...params}
                                    label="End Date"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>

                        <FormControl className={classes.formControl}>
                            <InputLabel id="rangeOption-label">Date Range</InputLabel>
                            <Select
                                labelId="select-label"
                                id="rangeOption-select"
                                value={rangeOption}
                                // value={selectedOption}
                                onChange={handleOptionChange}
                            >
                                {menuItems}
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
            </LocalizationProvider >

        </Box>

    );

}

export default Component;