import React, { useState, useEffect } from "react";
/** Helper */
import { useNavigate } from "react-router-dom";

/** Component */
import { Box, Grid, Container, Stack, Paper } from "@mui/material";

import NettedTable from "../../components/common/NettedTable";

import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";

/** Content */
import TableConfig from "../../components/equipment/data/equipmentDataTableContent.jsx";

export default function Component() {
  const [filterQuery, setFilterQuery] = useState([]);
  const navigate = useNavigate();

  return (
    <Container maxWidth={"lg"}>
      <CommonBreadcrumb />

      <Paper sx={{ px: 3, pb: 3, mt: 6 }} elevation={3}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {/* Filter */}
              <Box>
                {/* <CommonFilter
                  filterConfig={TableConfig.filterConfig}
                  emitQueryFn={setFilterQuery}
                /> */}
              </Box>
              {/* Page Action */}
              <Box>
                {/* <Button
                  size="small"
                  variant="contained"
                  endIcon={<AddIcon />}
                  onClick={() => navigate("/organization/create")}
                >
                  新增機構
                </Button> */}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {/* <CommonTable
              filterQuery={filterQuery}
              tableConfig={TableConfig}

              // debug
            /> */}
            <NettedTable filterQuery={filterQuery} tableConfig={TableConfig} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
