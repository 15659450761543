import { useTheme } from '@mui/material/styles';
/** Component */
import {
  Box,
  Grid,
  Container,
  TextField,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, } from 'react';
import { AdminApi } from "../../api/AdminApi";
import { isCancel } from 'axios';

// Testing URL 
// http://localhost:3000/organization/2/member/import
// ----------------------------------------------------------------------

export default function Component() {
  const theme = useTheme();

  let { orgId } = useParams();

  const [orgData, setOrgData] = useState({});

  // let organizationName = "??";
  
  useEffect(() => {

    AdminApi.getOrgnizationDetailCallback(orgId, (isSuccess, msg, data) => {
      console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
      if (isSuccess) {
        setOrgData(data.record);
      } else {
        console.log("Data not found with id - " + orgId);
      }
    });
    // getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
    //   if (!isSuccess) {
    //     console.log("Data not found with id - " + orgId);
    //   }
    //   let { record } = data;

    //   setOrgData(record);
    // });
  }, []);


  // url: http://localhost:3000/organization/2/member/import

  return (
    <Container maxWidth={"lg"}>
      {/* Backdrop */}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataPadding}
      > */}
      {/* <CircularProgress color="inherit" /> */}
      {/* </Backdrop> */}
      {/* <CommonBreadcrumb
        dbData={{ ...currentRecordData, organization_name: orgData?.name_zh }}
      /> */}
      <CommonBreadcrumb dbData={{ organization_id: orgId, organization_name: orgData.name_zh }} />
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Box component="form" sx={{ mt: 3 }}>
            <Paper sx={{ px: 3, py: 5 }} elevation={3}>
              <Typography variant="h4" sx={{ mb: 5 }}>
                TODO: Member Import form
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
