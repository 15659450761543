import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSwal from "../../../helper/AlertHelper";

// @mui
import { Link, Box, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { AdminApi } from '../../../api/AdminApi';
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../../../reducers/userSlice";
import { setLocalStorageUser } from "../../../helper/LocalStorageHelper";

import { useFormik } from "formik";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      account: "",
      password: "",
    },
    // validateOnMount: true,
    // validationSchema: LoginSchema,
  });

  const handleLogin = async (account, password) => {
    // console.debug("onSubmit: ", values);

    const result = await AdminApi.login(account, password);

    // result.jwt = null;
    let jwt = result.jwt;
    let userInfo = result.user;
    let organizatoinInfo = result.organization;
    // console.debug("organizatoinInfo: ", organizatoinInfo);

    userInfo.orgType = organizatoinInfo?.type || "";
    userInfo.orgNameZh = organizatoinInfo?.name_zh || "";
    userInfo.orgNameEn = organizatoinInfo?.name_en || "";


    if (jwt === undefined || jwt == null) {
      // alert("Login failed");

      // ken: simplify the logic 
      ReactSwal.fire({
        title: "登入錯誤",
        html: "系統錯誤",
      });
      return;
    }

    if (userInfo === undefined || userInfo == null) {
      ReactSwal.fire({
        title: "登入錯誤",
        html: "系統錯誤",
      });
      return;
    }




    dispatch(updateUserInfo(userInfo, jwt));
    setLocalStorageUser(userInfo);
    console.debug("jwt: ", jwt);
    console.debug("userInfo: ", userInfo);

    // focus update the react app
    window.location.href = "/";
  }

  const handleClick = () => {
    // navigate('/home', { replace: true });
    console.debug("formik.values: ", formik.values);
    let account = formik.values.account.trim();
    let password = formik.values.password.trim();
    if (account === "") {
      ReactSwal.fire({
        title: "登入錯誤",
        html: "請輸入用戶名字或電郵",
      });
      return;
    }

    if (password === "") {
      ReactSwal.fire({
        title: "登入錯誤",
        html: "請輸入帳號密碼",
      });
      return;
    }
    handleLogin(formik.values.account, formik.values.password);
  };



  return (
    <>
      <Stack spacing={3}>
        <TextField
          id="account"
          name="account" label="Username or Email"
          value={formik.values.account}
          onChange={formik.handleChange}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Box sx={{ my: 2 }}>

      </Box>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
