import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";
import CommonDataSelection from "../../components/common/CommonDataSelection/CommonDataSelection";
import CommonButton from "../../components/common/CommonButton/CommonButton";

export default function TestSimple() {
    const { t } = useTranslation();
    const [eventID, setEventID] = React.useState(0);

    let lang = getLang();

    const dataList = MockData.getLeaderboardEventList();

    // const CommonDataSelection = ({ key, label, value, dataList, captionFn, onChange }) => {
    const onEventChange = (event) => {
        setEventID(event.target.value);
    }

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Common Data Selection</Typography>

                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <CommonDataSelection
                    key="event"
                    label="活動"
                    value={eventID}
                    allOption={true}    
                    dataList={dataList}
                    captionFn={(item) => item.name_zh}
                    onChange={onEventChange}
                />
                <CommonButton></CommonButton>

            </Stack>
        </Container>
    )
}
