import React, { useState, useEffect, useContext } from "react";
/** Helper */

/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Stack,
  CardActions,
  Button,
  Paper,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
/** Content */

/** Icon */

export default function ImportConfirmDialog({
  open,
  onClose = () => {},
  scoreData = null,
  onSubmit = () => {},
}) {
  function onCancel() {
    onClose();
    console.log("onCancel");
  }

  return (
    <Dialog open={open} fullWidth scroll="body">
      <Card>
        <CardHeader title="確認匯入紀錄" />

        <CardContent>
          <Grid container spacing={2} mb={4}>
            {scoreData !== null
              ? Object.keys(scoreData).map((key) => {
                  const score = scoreData[key]?.skill_score || 0;
                  return (
                    <Grid item xs={12} p={2} key={"csv-import-cell-" + key}>
                      <Paper
                        elevation={3}
                        sx={{ p: 2 }}
                        key={`confirm-record-${key}`}
                      >
                        <Typography variant="h6">ID： {key}</Typography>
                        <Divider sx={{ my: 1 }} />
                        <Stack direction="row" spacing={1}>
                          <Typography variant="body1" fontStyle={"bold"}>
                            分數:
                          </Typography>
                          <Typography variant="body1">{score}</Typography>
                        </Stack>
                      </Paper>
                    </Grid>
                  );
                })
              : null}
          </Grid>

          <CardActions sx={{ justifyContent: "space-between", p: 0 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onCancel()}
            >
              取消
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            >
              提交紀錄
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Dialog>
  );
}
