import axios from "../helper/AxiosHelper";
import ApiHelper from "./ApiHelper";


export const getEventStat = async (eventID, date) => {
  if(date === undefined || date == null){
    date = "";
  }

  // eventStat/getStat?event_id=14&date
  const url = `/eventStat/getStat?event_id=${eventID}&&date=${date}`;

  const response = await axios.get(url, {});

  return ApiHelper.processResponse(response);
}



export const StatApi = {
  getEventStat,
};
