import React, { useState, useEffect, useContext } from "react";
/** Helper */

/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Stack,
  CardActions,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
/** Content */
import MqttContext from "../../../mqtt/MqttProvider";
/** Icon */
import SvgMicroBit from "../../../icon/SvgMicroBit.jsx";
import CircularProgress from "@mui/material/CircularProgress";

export default function MqttInputDialog({
  microbit = null,
  qrCode = "",
  inputConfig = null,
  unitName = "",
  callCheckInput = () => {},
}) {
  // Mqtt
  const mqttContext = useContext(MqttContext);
  // State
  const [isInputDialogOpen, setIsInputDialogOpen] = useState(false);
  const [skillValueList, setSkillValueList] = useState(getInitSkillValueList());
  const [receivedCount, setReceivedCount] = useState(0);
  // hook
  useEffect(() => {
    if (isInputDialogOpen === false) return;

    let { topic = "", data = null } = mqttContext.topicResponse;
    if (topic !== microbit.topic) return;
    if (data && receivedCount < skillValueList.length) {
      setSkillValueList((prev) => {
        let _prev = [...prev];
        _prev[receivedCount].score = data.value;
        // _prev[receivedCount].data = data;
        return _prev;
      });
      setReceivedCount((prev) => prev + 1);
    }
  }, [mqttContext.topicResponse]);

  function getInitSkillValueList() {
    let initSkillValueList = [];
    if (inputConfig === null) return 0;
    // let labelLength =
    //   "labelList" in inputConfig ? inputConfig.labelList.length : 1;

    for (let i = 1; i <= inputConfig.inputCount; i++) {
      let _inputLabel =
        "labelList" in inputConfig ? inputConfig.labelList[i - 1] : "";
      let initSkillValue = {
        label: `${_inputLabel} 第${i}次 :`,
        score: 0,
        //   data: null,
        unit: unitName,
      };
      initSkillValueList.push(initSkillValue);
      //   for (let j = 0; j < labelLength; j++) {
      //     let _inputLabel =
      //       "labelList" in inputConfig ? inputConfig.labelList[j] : "";
      //     let initSkillValue = {
      //       label: `${_inputLabel} 第${i}次 :`,
      //       score: 0,
      //       //   data: null,
      //       unit: unitName,
      //     };
      //     initSkillValueList.push(initSkillValue);
      //   }
    }
    return initSkillValueList;
  }

  function handleInputDialogOpen() {
    setIsInputDialogOpen(true);
  }
  function handleInputDialogClose() {
    setIsInputDialogOpen(false);
  }

  function onSubmit() {
    console.log("onSubmit");
    let score = getSportScore(skillValueList, inputConfig?.formula);

    callCheckInput(score);
    init();
  }

  function onCancel() {
    console.log("onCancel");
    init();
  }

  function getSportScore(skillValueList, formula) {
    if (!formula) return toast.error("運動公式未設定，請聯絡管理員");

    let valueList = skillValueList.map((input) => input.score).filter((v) => v);
    if (valueList.length === 0) return toast.error("請至少輸入一筆資料");
    if (formula === "max") {
      return Math.max(...valueList);
    }
    if (formula === "min") {
      return Math.min(...valueList);
    }
    if (formula === "avg") {
      return (valueList.reduce((a, b) => a + b) / valueList.length).toFixed(2);
    }
    return 0;
  }

  function init() {
    handleInputDialogClose();
    setReceivedCount(0);
    setSkillValueList(getInitSkillValueList());
    mqttContext.unSubscribeTopic({ topic: microbit.topic });
  }
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        sx={{ minWidth: "130px", minHeight: "120px" }}
        disabled={!qrCode}
        onClick={() => {
          handleInputDialogOpen();
          mqttContext.subscribeTopic({ topic: microbit.topic });
        }}
      >
        <Stack direction={"column"} alignItems="center" gap={1}>
          <SvgMicroBit />
          <Typography variant="caption" fontWeight={"bold"}>
            {microbit.name}
          </Typography>
        </Stack>
      </Button>
      <Dialog open={isInputDialogOpen} fullWidth scroll="body">
        <Card>
          <CardHeader
            title="Micro:bit 資料接收中..."
            subheader={
              <Typography
                variant="caption"
                fontWeight={"bold"}
                color={"grey"}
                my={2}
              >
                {`Listening topic: ${mqttContext.currentSubTopic}`}
              </Typography>
            }
          />

          <CardContent>
            <Grid container spacing={2} mb={4}>
              {skillValueList.map((input, index) => {
                return (
                  <Grid item xs={12} key={`input-cell-${index}`}>
                    <Typography variant="body1" fontWeight={"bold"} mb={2}>
                      {input.label}
                    </Typography>
                    {input.score ? (
                      <Typography variant="body1" fontWeight={"bold"}>
                        {input.score} {input.unit}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                );
              })}
            </Grid>

            <CardActions sx={{ justifyContent: "space-between", p: 0 }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => onCancel()}
              >
                取消
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmit()}
                disabled={receivedCount < skillValueList.length}
              >
                提交紀錄
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}
