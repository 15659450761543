import { Box, Grid, Typography, Container, Stack } from "@mui/material";

import leaderBoardBg_1 from "../../../public/leaderBoardBg_1.png";
import leaderBoardBg_2 from "../../../public/leaderBoardBg_2.png";
import leaderBoardBg_3 from "../../../public/leaderBoardBg_3.png";
import leaderBoardBg_4 from "../../../public/leaderBoardBg_4.png";
import { useState } from "react";

import CoSportAnalyticsDashCards from "../CoSportAnalyticsDashCards";
import CoSportAnalyticsChart from "../CoSportAnalyticsChart";
import MockData from "../../../helper/MockData.js";

const MAIN_FONT = "Noto Sans TC";

const BackgroundMap = {
  blue: leaderBoardBg_1,
  orange: leaderBoardBg_2,
  purple: leaderBoardBg_3,
  green: leaderBoardBg_4,
};


export default function CoSportAnalyticsDisplay({ analyticData, skillInfo, themeData }) {
  // const [localAnalyticData, setLocalAnalyticData] = useState(analyticData);


  const bgColor = themeData.bg || "blue"
  const barColor = themeData.barColor || "red"
  const skillName = skillInfo?.name_zh || "N/A"
  const unitStr = skillInfo?.unit || ""


  // const dataBarList = getBarDataList().map((data, index) => {
  //   return (
  //     <CoSportRankingBarItem key={`rand-${index}`}
  //       barColor={barColor}
  //       barData={data} bestScore={bestScore} unit={unitStr} />
  //   )
  // }).slice(0, 5)


  // console
  console.debug("analyticData", analyticData)

  const usageData = analyticData?.usageData || null;
  const performData = analyticData?.performData || null;
  // const performData = MockData.getSkillPerformStatistics();

  console.debug("analyticData", analyticData)
  console.debug("usageStat", usageData)

  return (
    <Box
      align="center"
      pt={2}
      minHeight={"100vh"}
      sx={{
        backgroundImage: `url("${BackgroundMap[bgColor]}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      position="relative"
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        rowSpacing={2}
        pb={4}
        mb={4}
        sx={{
          // ken: Skew Screen Style
          // transform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg) ",
          // WebkitTransform: "rotateZ(-5deg) skewX(-5deg)" /* Safari & Chrome */,
          // MozTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Firefox */,
          // MsTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Internet Explorer */,
          // OTransform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Opera */,
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Box
            sx={{
              borderBottom: "2px solid #eee",
              boxShadow: " 0 3px 2px -2px  #9ecaed;",
              width: "fit-content",
            }}
          >
            <Typography
              variant={"h2"}
              color={"WHITE"}
              sx={{
                fontFamily: MAIN_FONT,
                fontSize: "68px",
                fontWeight: "900",
                lineHeight: "60px",
                letterSpacing: "0em",
              }}
            >
            共創運動紀錄 (綜覽)
            </Typography>
            
          </Box>

          <Typography
              variant={"h3"}
              color={"white"}
              sx={{
                fontFamily: MAIN_FONT,
                fontSize: "68px",
                fontWeight: "900",
                lineHeight: "60px",
                letterSpacing: "0em",
              }}
            >
              {skillName || "N/A"}
            </Typography>
        </Grid>
        <Stack rowGap={2}>
            {/* {dataBarList} */}
            <CoSportAnalyticsDashCards analyticData={usageData} unit={unitStr} />
          <CoSportAnalyticsChart performData={performData} unit={unitStr} />

        </Stack>
      </Grid>
    </Box>
  );
}
