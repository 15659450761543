import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MemberQueryQRCardList from "../../components/app/MemberQueryQRCardList";
import MockData from "../../helper/MockData";
import MemberQRCard from "../../components/app/MemberQRCard";

export default function TestMemberQueryQRCardList() {
    const { t } = useTranslation();

    let lang = getLang();
    let memberQueryResult = MockData.getMemberQueryResult();
    let memberList = memberQueryResult.data;
    let oneMember = memberQueryResult.data[0];

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TestMemberQueryQRCardList</Typography>



                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <MemberQRCard member={oneMember} />
                    <pre>
                        {JSON.stringify(oneMember, null, 2)}
                    </pre>
                    <MemberQueryQRCardList memberQueryResult={memberList} />
                </Box>
            </Stack>
        </Container>
    )
}
