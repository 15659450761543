import React, { useState, useEffect } from "react";


/** Component */
import { Grid, Paper } from "@mui/material";

import CommonTable from "../../components/common/CommonTable";
import MemberSkillDataQueryForm from "./MemberSkillDataQueryForm";
import MockData from "../../helper/MockData";
import { downloadWithURL } from "../../helper/ExportHelper";

import { InfoApi } from "../../api/InfoApi";
/** Content */
import TableConfig from "./MemberSkillDataTableConfig";
import { type } from "@testing-library/user-event/dist/type";
import { isAdminUser } from "../../helper/PermissionHelper";

const buildFilterItem = (field, value) => {
    value = value || '';
    if (typeof value === 'string') {
        value = value.trim();
        if (value == '') {
            return null;
        }
    } else {
        if (value == null) {
            return null;
        }
    }



    console.debug("buildFilterItem: ", field, value);
    // value = valuvalue.trim();
    // if (value === '') {
    //     return null;
    // }

    // All Fields:
    // eventID, memberID, memberName, startDate, endDate, group, groupNum    
    if (field === 'eventID') {
        if (value === -1) {   // -1 mean all
            return null;
        }

        return {
            field: 'event_id',
            op: 'eq',
            value: value
        }
    } else if (field === 'memberID') {
        return {
            field: 'member_id',
            op: 'eq',
            value: value
        }
    } else if (field === 'memberName') {
        return {
            field: 'display_name',
            op: 'like',
            value: value
        }
    } else if (field === 'startDate') {
        return {
            field: 'created_at',
            op: 'gte',
            value: `${value} 00:00:00`
        }
    } else if (field === 'endDate') {
        return {
            field: 'created_at',
            op: 'lte',
            value: `${value} 23:59:59`
        }
    } else if (field === 'group') {
        return {
            field: 'group',
            op: 'eq',
            value: value
        }
    } else if (field === 'groupNum') {
        return {
            field: 'group_num',
            op: 'eq',
            value: value
        }
    }

    return null;
}

const buildQueryFilter = (inputData, organizationID) => {
    let result = [];
    for (let key in inputData) {
        var filterItem = buildFilterItem(key, inputData[key]);
        if (filterItem === null) {
            continue;
        }
        result.push(filterItem);
    }

    //
    if (organizationID > 0) {
        result.push({
            field: 'organization_id',
            op: 'eq',
            value: organizationID
        });
    }

    return result;
}


export default function MemberSkillDataTable({ mode = "admin", user }) {
    const [eventList, setEventList] = useState([]);
    // const [event, setEvent] = useState(null);

    const [filterQuery, setFilterQuery] = useState(false);

    const isAdminUser = mode === "admin";
    const userID = user ? user.id || 0 : 0;
    let organizationID = 0;
    if (isAdminUser === false) {
        organizationID = user ? user.organization_id || 0 : 0;
    }


    useEffect(() => {
        if (isAdminUser) {
            loadAllEventList();
        } else {
            loadUserEventList();
        }
    }, []);


    // Loading Event List Logic 
    const updateEventList = (newEventList) => {
        setEventList(newEventList);
    }

    const loadUserEventList = async () => {
        // console.debug("currentMemberID: ", currentMemberID);
        const result = await InfoApi.getUserEventList(userID);
        console.debug("result: ", result);
        updateEventList(result);

    };

    const loadAllEventList = async () => {
        // console.debug("currentMemberID: ", currentMemberID);
        const result = await InfoApi.getEventList();
        console.debug("result: ", result);
        updateEventList(result);
        // setEventList(result);
    };



    // ----
    // let lang = getLang();
    // let eventList = MockData.getLeaderboardEventList();

    const tableOptions = {
        pageSize: 25
    };

    const onQuery = (inputData) => {
        console.debug("onQuery: ", inputData);
        console.debug(" organizationID: ", organizationID);
        let filters = buildQueryFilter(inputData, organizationID);
        console.debug("filters: ", filters);
        setFilterQuery(filters);
        // console.debug("dateValueToStr: ", dateValueToTimeStr(inputData.selectedDate));
    };


    const onExport = async (inputData) => {
        // downloadWithURL
        let filters = buildQueryFilter(inputData, organizationID);

        const result = await InfoApi.exportMemberSkillData(
            filters);
        // console.debug("result: ", result);
        if (!result) {
            return;
        }
        downloadWithURL(result.export_url, result.export_filename);
    }


    // downloadURL

    return (


        <Grid >
            <Grid item xs={12}>
                <MemberSkillDataQueryForm eventList={eventList}
                    onQuery={onQuery}
                    onExport={onExport}>
                </MemberSkillDataQueryForm>
            </Grid>
            <Grid item xs={12}>
                <CommonTable
                    filterQuery={filterQuery}
                    tableConfig={TableConfig}
                    debug={false}
                    options={tableOptions}
                // debug
                />
            </Grid>
        </Grid>

    );
}
