import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

export default function TestSimple() {
    const { t } = useTranslation();

    let lang = getLang();

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestAccordion</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <Box>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowForwardIosSharpIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Accordion 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowForwardIosSharpIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Accordion 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Container>
    )
}
