import React, { useState, useEffect } from "react";
/** Helper */

/** Component */
import { Box, Grid, Typography, Container } from "@mui/material";
import CommonTable from "../../components/common/CommonTable";
import CommonFilter from "../../components/common/CommonFilter";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
/** Content */
import TableConfig from "../../components/test/tableConfig.jsx";
export default function Component() {
  const [filterQuery, setFilterQuery] = useState([]);
  return (
    <Container maxWidth="md">
      <Grid container direction={"column"} gap={2}>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Edit 'siteMap.json' to configure the breadcrumb behavior
          this breadcrumb setting based on the current route url.
        </Typography>
        {/* the component will read the current route */}
        <CommonBreadcrumb dbData={{ var_name: "數據A", var_id: 1 }} />
      </Grid>
    </Container>
  );
}
