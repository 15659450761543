import {
    Container,
    Typography,
    Box,
    Stack,
    Button,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import CsvImportHelper from "../../helper/CsvImportHelper";
import { getLang } from "../../helper/LangHelper";

export default function TestCsvParser() {
    const { t } = useTranslation();

    let lang = getLang();

    const testHandGrip = async () => {
        console.debug("testHandGrip Parser");
        CsvImportHelper.testHandGripParser();
    }

    const testList = [
        testHandGrip,
    ];


    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Csv Parser</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>

                <Box display="flex" direction="column" gap={3} sx={{ flexWrap: 'wrap' }}>
                {
                    testList.map((testFunc, index) => {
                        return (
                            <Button key={index} variant="outlined" color="primary"
                                onClick={
                                    testFunc
                                }>
                                {testFunc.name}
                            </Button>
                        );
                    })
                }
            </Box>
            </Stack>
        </Container>
    )
}
