import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Card, Box, Button, TextField, Typography, CardContent } from "@mui/material";
import { toast } from "react-toastify";

const kStatusNotReady = "not_ready";
const kStatusReady = "ready";
const kStatusRedeemed = "redeemed";


export default function RedemptionInfoCard({ member,
    redemptionInfo,
    skillDetails,
    onRedeemClicked
}) {

    const getStatusBlock = (status) => {
        let str = status;
        let textColor = "#000000"
        if (status === kStatusNotReady) {
            str = "未可換領";
            textColor = "#ff0000";
        } else if (status === kStatusReady) {
            str = "可換領";
            textColor = "#007700";
        } else if (status === kStatusRedeemed) {
            str = "已換領";
            textColor = "#555";
        }

        return (
            <Typography variant="body" style={{ fontWeight: "bold", color: textColor }}>
                {str}
            </Typography>
        )
    }

    const handleRedeemClick = () => {
        if (onRedeemClicked !== undefined) {
            onRedeemClicked();
        }
    }

    const getSkillDetails = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={4} md={2}>
                    <Typography variant="h6">
                        活動進度：
                    </Typography>

                </Grid>
                <Grid item xs={8} md={10}>
                    <Grid container spacing={1}>
                        {
                            skillDetails.map((skill, index) => {
                                let completeStr = skill.is_complete ? "已完成" : "未完成";
                                let completeColor = skill.is_complete ? "#007700" : "#ff0000";

                                return (
                                    <Grid item xs={12} key={index}>
                                        {skill.name_zh} :
                                        <Typography variant="body" sx={{ color: completeColor }}>

                                            {completeStr}
                                        </Typography>
                                        {/* <pre>{JSON.stringify(skill)}</pre> */}
                                    </Grid>
                                );
                            })
                        }

                    </Grid>

                    {/* <Typography variant="body">
                        {skillDetails}
                    </Typography> */}
                    {/* <pre>
                        skillDetails:
                        {JSON.stringify(skillDetails, null, 2)}
                    </pre> */}
                </Grid>
            </Grid>
        )
    }


    return (
        <Card sx={{ p: 2 }} variant="outlined">
            <CardContent sx={{ padding: 1 }}>
                <Typography variant="h5" textAlign="center" sx={{ mb: 2 }}>
                    換領資訊
                </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={4} md={2}>
                        <Typography variant="h6">
                            會員電話：
                        </Typography>

                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Typography variant="body">
                            {member.phone_num}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={4} md={2}>
                        <Typography variant="h6">
                            會員名稱：
                        </Typography>

                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Typography variant="body">
                            {member.display_name}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={4} md={2}>
                        <Typography variant="h6">
                            換領狀態：
                        </Typography>

                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Typography variant="body">
                            {getStatusBlock(redemptionInfo.status)}
                        </Typography>
                    </Grid>
                </Grid>

                {
                    redemptionInfo.status === kStatusReady &&
                    <Grid container spacing={1}>
                        <Grid item xs={4} md={2}>
                            <Typography variant="h6">
                                可換領次數
                            </Typography>

                        </Grid>
                        <Grid item xs={2} md={1}>
                            <Typography variant="body" style={{ fontWeight: "bold", color: "#44f" }}>
                                {redemptionInfo.reward_chance} 次
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Button variant="contained" size="medium" style={{ width: "100%" }}
                                onClick={handleRedeemClick}
                            >
                                確認領獎
                            </Button>

                        </Grid>
                    </Grid>
                }



                {
                    redemptionInfo.status === kStatusRedeemed &&
                    <Grid container spacing={1}>
                        <Grid item xs={4} md={2}>
                            <Typography variant="h6">
                                換領時間：
                            </Typography>

                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Typography variant="body">
                                {redemptionInfo.updated_at}
                            </Typography>
                        </Grid>
                    </Grid>
                }

                {/* <pre>
                    {JSON.stringify(member, null, 2)}
                </pre>
                <pre>
                    {JSON.stringify(redemptionInfo, null, 2)}
                </pre> */}
                {getSkillDetails()}
            </CardContent>
        </Card>
    );
}