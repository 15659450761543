import React, { useState, useEffect } from "react";
/** Helper */
import FormContent from "./UserOrganizationMemberFormSchema.jsx";
import { postDataRequest, getDataRequest } from "../../helper/AxiosHelper.js";
import {
  isError,
  getErrorMsg,
  validateForm,
} from "../../helper/FormHelper.jsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
/** Component */
import {
  Box,
  Grid,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb/index.jsx";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
/** Content */

/** API */

export default function Component() {
  // URL
  let { memberId } = useParams();
  let orgId = 19;


  // navigate
  const navigate = useNavigate();
  // Formik
  const formik = useFormik(FormContent.FormikInstance);

  //create function
  const handleUpdateOrgMember = async (e) => {
    if (await validateForm(formik)) {
      console.debug("validation error: ", formik.errors);
      return;
    }
    let formData = JSON.parse(JSON.stringify(formik.values));
    formData["organization_id"] = orgId;
    delete formData["repeatPassword"];
    postDataRequest(
      `adminMember/update/${memberId}`,
      formData,
      (isSuccess, data) => {
        if (!isSuccess) {
          return toast.error("更新失敗");
        }

        toast.success("更新成功");
        return;
      }
    );
  };
  const [isDataPadding, setDataPadding] = useState(true);
  const [currentRecordData, setCurrentRecordData] = useState(null);
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    getDataRequest(`adminMember/show/${memberId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + memberId);
      }
      let { record } = data;
      // turn of the backdrop
      setDataPadding(false);
      // pass the current record data to breadcrumb to set the name
      setCurrentRecordData(record);
      let formFieldList = Object.keys(FormContent.FormikInstance.initialValues);
      formFieldList.forEach((field) => {
        if (record[field] === undefined) return;
        if (field === "dob") {
          let dob = moment(record[field].split("T").shift()).format(
            "YYYY-MM-DD"
          );
          return formik.setFieldValue(field, dob);
        }
        formik.setFieldValue(field, record[field]);
      });
    });
  }, []);

  useEffect(() => {
    getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + orgId);
      }
      let { record } = data;

      setOrgData(record);
    });
  }, []);

  return (
    <Container maxWidth={"lg"}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataPadding}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* BreadCrumb */}
     
      {/* From */}
      <Box component="form" sx={{ mt: 3 }}>
        <Paper sx={{ px: 3, py: 5 }} elevation={3}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            {FormContent.FormControl.map((item, index) => {
              let gridStyle = "size" in item ? item.size : { xs: 12 };
              let formControlProps = item.props;
              if (item.variant === "selector") {
                return (
                  <Grid item key={`form-section-${index}`} {...gridStyle}>
                    <FormControl
                      fullWidth
                      error={isError(formik, formControlProps.id)}
                    >
                      <InputLabel>{formControlProps.label}</InputLabel>
                      <Select
                        {...formControlProps}
                        fullWidth
                        value={formik.values[formControlProps.id]}
                        onChange={(event) => {
                          formik.setFieldValue(
                            formControlProps.id,
                            event.target.value
                          );
                        }}
                        onBlur={(event) => {
                          formik.touched[formControlProps.id] = true;
                        }}
                      >
                        <MenuItem value={""} disabled sx={{ ml: 2 }}>
                          {item.placeHolder}
                        </MenuItem>
                        {item.options.map((_area, index) => (
                          <MenuItem key={`area-${index}`} value={_area.value}>
                            {_area.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {getErrorMsg(formik, formControlProps.id)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                );
              }
              if (item.variant === "date") {
                const dateError = isError(formik, formControlProps.id);
                return (
                  <Grid item key={`form-section-${index}`} {...gridStyle}>
                    <FormControl
                      error={isError(formik, formControlProps.id)}
                      fullWidth
                    >
                      <DatePicker
                        {...formControlProps}
                        onClose={() =>
                          (formik.touched[formControlProps.id] = true)
                        }
                        onChange={(newValue) =>
                          formik.setFieldValue(formControlProps.id, newValue)
                        }
                        // value={"1998-10-11"}
                        value={formik.values[formControlProps.id]}
                        renderInput={(formControlProps) => (
                          <TextField {...formControlProps} error={dateError} />
                        )}
                      />
                      <FormHelperText>
                        {getErrorMsg(formik, formControlProps.id)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                );
              }

              return (
                <Grid item key={`form-section-${index}`} {...gridStyle}>
                  <TextField
                    {...formControlProps}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={isError(formik, formControlProps.id)}
                    helperText={getErrorMsg(formik, formControlProps.id)}
                    value={formik.values[formControlProps.id]}
                    fullWidth
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ maxWidth: { xs: "auto", md: "150px" } }}
                onClick={handleUpdateOrgMember}
                disabled={!formik.isValid}
              >
                更新
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
