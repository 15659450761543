import * as Yup from "yup";
import { FormType } from "../../../helper/FormHelper.jsx";
const Schema = Yup.object().shape({
  age: Yup.number()
    // .min(1, "年齡不能小於1歲")
    .max(80, "年齡不能大於80歲"),
  // .required("請填寫年齡")
  height: Yup.number()
    // .min(80, "身高不能小於80cm")
    .max(300, "身高不能大於300cm"),
  // .required("請填寫身高")
  weight_kg: Yup.number()
    // .min(30, "體重不能小於30kg")
    .max(200, "體重不能大於300kg"),
  // .required("請填寫體重")
  weight_ibs: Yup.number()
    // .min(60, "體重不能小於60磅")
    .max(500, "體重不能大於500磅"),
  // .required("請填寫體重")
  // eye_sight_r: Yup.number().required("請填寫右眼視力"),
  // eye_sight_l: Yup.number().required("請填寫左眼視力"),
  // eye_sight_color: Yup.number().required("請填寫色盲測試結果"),
});

const FormControl = [
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.INPUT,
    props: { id: "age", label: "Age", type: "number" },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.INPUT,
    props: { id: "height", label: "Height", type: "number" },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.INPUT,
    props: { id: "weight", label: "Weight (kg)", type: "number" },
  },
  // {
  //   size: { xs: 12, sm: 6 },
  //   variant: FormType.INPUT,
  //   props: { id: "weight_ibs", label: "Weight (ibs)", type: "number" },
  // },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.INPUT,
    props: {
      id: "eye_sight_r",
      label: "Eye Sight (R Results)",
      type: "number",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.INPUT,
    props: {
      id: "eye_sight_l",
      label: "Eye Sight (L Results)",
      type: "number",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.INPUT,
    props: {
      id: "eye_sight_color",
      label: "Eye Sight (Color)",
      type: "string",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.IMAGE_UPLOAD,
    props: {
      id: "tanita_screen_capture_1",
      label: "Tanita Screen Capture 01",
      type: "string",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.IMAGE_UPLOAD,
    props: {
      id: "tanita_screen_capture_2",
      label: "Tanita Screen Capture 02",
      type: "string",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.IMAGE_UPLOAD,
    props: {
      id: "balance_screen_capture_1",
      label: "Balance Screen Capture 01",
      type: "string",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: FormType.IMAGE_UPLOAD,
    props: {
      id: "balance_screen_capture_2",
      label: "Balance Screen Capture 02",
      type: "string",
    },
  },
];

const FormikInstance = {
  initialValues: {
    qrCode: undefined,
    age: undefined,
    height: undefined,
    weight: undefined,
    //weight_ibs: undefined,
    eye_sight_r: undefined,
    eye_sight_l: undefined,
    eye_sight_color: undefined,
    tanita_screen_capture_1: null,
    tanita_screen_capture_2: null,
    balance_screen_capture_1: null,
    balance_screen_capture_2: null,
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
