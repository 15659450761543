import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React, { useEffect } from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import CommonDatePicker from "../../components/common/CommonDatePicker/CommonDatePicker";
import { dateValueToTimeStr } from "../../helper/TimeHelper";
import { set } from "date-fns";


export default function TestSimple() {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = React.useState(null);

    useEffect(() => {
        // console.debug("selectedDate: ", selectedDate);
        // setSelectedDate(new Date());
        setSelectedDate(null);
    }, []);


    let lang = getLang();
    // let dateValue = new Date();
    const minDate = new Date("2023-05-05");
    const maxDate = new Date("2023-07-20");



    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestCommonDatePicker</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <Box><pre>selectedDate: {dateValueToTimeStr(selectedDate)} </pre></Box>
                <CommonDatePicker dateValue={selectedDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => {
                        setSelectedDate(date);
                    }}
                ></CommonDatePicker>
            </Stack>
        </Container>
    )
}
