import {
  Box, Grid, Typography,
  Avatar,
  Container, Stack
} from "@mui/material";

import RoundIcon from "../../common/RoundIcon/RoundIcon";
import { useState } from "react";
import { formatNumber } from "../../../helper/StringHelper";
import badgeImageGold from "../../../public/rankingIcons/crown_gold.svg";
import badgeImageSilver from "../../../public/rankingIcons/crown_silver.svg";
import badgeImageBronze from "../../../public/rankingIcons/crown_bronze.svg";
import rankImage1 from "../../../public/rankingIcons/icon_rocket.svg";
import rankImage2 from "../../../public/rankingIcons/icon_plane.svg";
import rankImage3 from "../../../public/rankingIcons/icon_chopper.svg";

const MAIN_FONT = "Noto Sans TC";

const RankBadge = ({ rank }) => {
  // size, bgColor, color, padding, component
  // const bgColor = '#552E00';
  const bgColor = "rgba(0, 0, 0, 0)";
  const rankStr = `${rank}`;
  const valueColor = '#552E00'

  const getBadgeImage = (rank) => {
    if (rank === 1) {
      return badgeImageGold;
    }

    if (rank === 2) {
      return badgeImageSilver;
    }

    if (rank === 3) {
      return badgeImageBronze;
    }
    return null;
  }

  return (
    <Box display="flex" sx={{
      width: 40, height: 40,
      background: bgColor, justifyContent: "center", alignItems: "center"
    }}>
      {
        (rank >= 1 && rank <= 3) ?
          <Box component="img" src={getBadgeImage(rank)} />
          :
          <Typography sx={
            {
              fontSize: '20px',
              fontWeight: 'bold', color: valueColor
            }
          }>
            {rankStr}
          </Typography>
      }



    </Box>
  );
}


const RankIcon = ({ rank, position }) => {
  // size, bgColor, color, padding, component
  // const bgColor = '#552E00';
  const bgColor = "rgba(0, 0, 0, 0)";

  const posPercent = `${(position + 1)}%`;

  const getImage = (rank) => {
    if (rank === 1) {
      return rankImage1;
    }

    if (rank === 2) {
      return rankImage2;
    }

    if (rank === 3) {
      return rankImage3;
    }
    return null;
  }

  return (
    <Box display="flex" sx={{
      width: 60, height: 40,
      background: bgColor, justifyContent: "center", alignItems: "center",
      position: "absolute", left: posPercent, top: 10
    }}>
      {
        (rank >= 1 && rank <= 3) ?
          <Box component="img" src={getImage(rank)} />
          : <> </>
      }
    </Box>
  );
}



export default function Component({ barData, barColor, unit, bestScore }) {


  const schoolName = barData.schoolName || '???';

  const score = barData.score || 0;
  bestScore = bestScore || 10;
  if (bestScore === 0) {
    bestScore = 10;
  }

  const valueColor = '#931A00'
  const nameColor = '#552E00'

  barColor = barColor || '#854D25';

  const scoreStr = `${formatNumber(score)} ${unit}`

  const barPercent = Math.floor(score * 100 / bestScore)
  const barPercentStr = `${barPercent}%`;

  return (
    <Box
      align="center"
      padding={0}
      // paddingTop={1}
      width="96%"
      minWidth={200}
      sx={{
        // background: 'rgba(0, 0, 0, 0.5)',
      }}
      position="relative"
    >
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"90%"}>
        <Box sx={{ paddingRight: '15px', textAlign: 'right' }} >
          <RankBadge rank={barData.rank} />
        </Box>

        <Box display="flex" sx={{
          flexGrow: 1, padding: 0, gap: 1, justifyContent:
            "start", alignItems: "center", position: "relative"
        }
        } >
          <RankIcon rank={barData.rank} position={barPercent} />
          <Stack sx={{ width: "100%" }}>
            { /* score */}
            <Box height={20}
              width={barPercentStr}
              minWidth={100}
              display={"flex"} justifyContent={"end"} paddingBottom={2}>
              <Typography sx={{ textWrap: 'nowrap', fontSize: '15px', fontWeight: 'bold', color: valueColor }}>
                {scoreStr}
              </Typography>
            </Box>
            <Stack height={30} sx={{ display: 'relative' }} >
              <Box height={30} width={barPercentStr} sx={
                {
                  background: barColor,
                  // borderTop: '1px solid black',
                  // borderBottom: '1px solid white'
                }
              }></Box>

            </Stack>
            <Box height={20} display={"flex"} justifyContent={"start"}>
              <Typography sx={{ textWrap: 'nowrap', fontSize: '15px', fontWeight: 'bold', color: nameColor }}>
                {schoolName}
              </Typography>
            </Box>
          </Stack>

        </Box>

        
      </Box>
      
    </Box >
  );
}
