import React, { useEffect } from "react";
import { Grid, Card, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { dateValueToTimeStr } from "../../helper/StringHelper";
import CommonDataSelection from "../common/CommonDataSelection/CommonDataSelection";
import CommonDatePicker from "../common/CommonDatePicker/CommonDatePicker";
import CommonDashboardCard from "../common/CommonDashboardCard/CommonDashboardCard";
import MainCard from "../common/MainCard/MainCard";

function getDefaultEventID(eventList) {
    if (eventList.length > 0) {
        return eventList[0].id;
    }

    return 0;
}

function getEventFromList(eventList, eventID) {
    for (let event of eventList) {
        if (event.id === eventID) {
            return event;
        }
    }

    return null;
}

export default function EventStatCard({ stat }) {


    return (
        <Stack spacing={0}>
            <Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    活動統計
                </Typography>
            </Box>
            <Grid container alignItems="center">
                <Grid item xs={4} sx={{ p: 2 }}>
                    <CommonDashboardCard title="參與次數" value={stat.total} />
                </Grid>

                <Grid item xs={4} sx={{ p: 2 }}>
                    <CommonDashboardCard title="參與人數" value={stat.member_count} />
                </Grid>
                <Grid item xs={4} sx={{ p: 2 }}>
                    <CommonDashboardCard title="參與機構數" value={stat.org_count} />
                </Grid>
            </Grid>

            <pre>
                {/* DEBUG: eventID={eventID} timeslot: {timeslotID} skill: {skillID} selectedDate: {dateValueToTimeStr(selectedDate)} 
            <br/>
            {JSON.stringify(eventList, null, 2)} */}
            </pre>
        </Stack>
    );
}