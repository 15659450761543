import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const mainField = "id";
const nettedField = "";
const recordNotFoundMsg = "-- 未找到資料 --";

const dataFieldConfig = [
  {
    id: "name_zh",
    field: "name_zh",
    align: "center",
    disablePadding: false,
    label: "設備名稱",
    fieldAction: () => {},
  },
  {
    id: "name_en",
    field: "name_en",
    align: "center",
    disablePadding: false,
    label: "Equipment Name",
  },
];

const fetchDataConfig = {
  url: "equipment/query",
  // extraQuery: [{ key: "cateId", field: "categories" }],
  fixedFilter: [{ field: "categories", value: null, op: "=" }],
};

const tableActionConfig = [
  {
    type: "link",
    label: "View Contents",
    icon: "detail",
    // icon: EmojiEventsIcon,
    url: "/equipment/detail/$id",
  },
];

const filterConfig = [
  { type: "text", label: "按 名稱 搜索", field: "name_zh,name_en" },
];

const TableConfig = {
  mainField,
  nettedField,
  dataFieldConfig,
  fetchDataConfig,
  tableActionConfig,
  filterConfig,
  recordNotFoundMsg,
};

export default TableConfig;
