import React, { useState, useEffect, useContext } from "react";
/** Helper */

/** Component */
import { Box, Grid, Typography, Container, Divider } from "@mui/material";
import MqttContext from "../../components/mqtt/MqttProvider";
/** Content */

export default function TestMqttSubTopic() {
  const mqttContext = useContext(MqttContext);
  useEffect(() => {
    if (mqttContext.mqttClient) {
      mqttContext.subscribeTopic({ topic: "testtopic/dev1" });
      mqttContext.subscribeTopic({ topic: "testtopic/dev2" });
    }
  });
  return (
    <Container maxWidth="md" disableGutters>
      <Typography variant="h1"> This is MQTT Sub Topic Test</Typography>
      <Divider />
      <Box>
        <pre>{JSON.stringify(mqttContext.topicResponse, null, " ")}</pre>
      </Box>
    </Container>
  );
}
