import React, { useState, useEffect, useCallback } from "react";
/** Helper */
import { useParams, useNavigate } from "react-router-dom";
import { postDataRequest, getDataRequest } from "../../helper/AxiosHelper";
/** Component */
import {
  Box,
  Grid,
  Container,
  Stack,
  Paper,
  Typography,
  Divider,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
import EquipmentCateSelector from "../../components/equipment/EquipmentCateSelector";
import EquipmentYtVideoSelector from "../../components/equipment/EquipmentYtVideoSelector";
import EquipmentCoverImageUploader from "../../components/equipment/EquipmentCoverImageUploader";
import EquipmentBaseInfoForm from "../../components/equipment/EquipmentBaseInfoForm";

import { AddCircleOutline } from "@mui/icons-material";
import { toast } from "react-toastify";
/** Api */
import { AdminApi } from "../../api/AdminApi";

export default function Component() {
  // URL
  let { cateId } = useParams();

  // router
  const navigate = useNavigate();

  const [isDataPadding, setDataPadding] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [coverImage, setCoverImage] = useState("");
  const [baseEquInfo, setBaseEquInfo] = useState({});

  const [cateData, setCateData] = useState({});

  useEffect(() => {
    getDataRequest(`equipmentCategory/show/${cateId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + cateId);
      }
      let { record } = data;
      setCateData(record);
    });
  }, []);

  const handleOnSubmit = useCallback(() => {
    setDataPadding(() => true);
    const formData = {
      ...baseEquInfo,
      images: [coverImage],
      videos: videoList,
      display_position: 1,
      categories: categoryOptions,
    };

    AdminApi.insertEquipmentData(formData, (isSuccess) => {
      setDataPadding(() => false);
      if (isSuccess) {
        toast.success("新增成功");
        setTimeout(
          () => navigate(`/equipment-category/${cateId}/equipments`),
          1500
        );

        return;
      }

      return toast.error("新增失敗");
    });
  }, [baseEquInfo, categoryOptions, coverImage, videoList]);
  const validateSubmit = useCallback(() => {
    if (categoryOptions.length === 0) return false;
    if (
      Object.values(baseEquInfo).length === 0 ||
      Object.values(baseEquInfo).some((item) => item === "")
    )
      return false;
    return true;
  }, [categoryOptions, baseEquInfo]);

  return (
    <Container maxWidth={"lg"}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataPadding}
      >
        {" "}
        <CircularProgress color="inherit" />
      </Backdrop>

      <CommonBreadcrumb
        dbData={{ cate_name: cateData.name_zh, cate_id: cateData.id }}
      />

      <Paper sx={{ px: 3, pb: 3, mt: 6 }} elevation={3}>
        <Box>
          <Grid container rowSpacing={2} columnSpacing={2} sx={{ pt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="body" color={"grey.600"}>
                設備資料
              </Typography>
              <Divider sx={{ mt: 2, mb: 3, maxWidth: "200px" }} />
              <EquipmentBaseInfoForm onUpdateData={setBaseEquInfo} />
            </Grid>

            <Grid item xs={12}>
              <EquipmentCateSelector
                clientOptions={[cateId]}
                optionSetter={setCategoryOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body" color={"grey.600"}>
                封面
              </Typography>
              <Divider sx={{ my: 2, maxWidth: "200px" }} />

              <EquipmentCoverImageUploader onImageUpload={setCoverImage} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body" color={"grey.600"}>
                影片
              </Typography>
              <Divider sx={{ my: 2, maxWidth: "200px" }} />
              <EquipmentYtVideoSelector videoListSetter={setVideoList} />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box align={"right"} mt={3}>
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            disabled={!validateSubmit()}
            onClick={() => handleOnSubmit()}
          >
            新增設備
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
