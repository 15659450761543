// import 
import mockData from '../_mock/parserData';


const csvParserMap = {
  TTestParser,
  HandGripParser,
};

function getExistingParserName() {
  return Object.keys(csvParserMap);
}

function parseCommonCsvContent(csvContent, {
  returnCols = false, separator = ";",
  returnLines = 0,
}) {
  let lines = csvContent
    .split("\n")
    .filter((line) => line !== "");

  const resultList = [];

  const headers = lines[0].split(separator);

  // Getting the Subset of the Lines 
  if (returnLines == 0) {
    lines = lines.slice(1); // remove header
  } else if (returnLines > 0) {
    lines = lines.slice(1, returnLines); // remove header
  } else { // Last N Lines
    const first = lines.length + returnLines;
    lines = lines.slice(first);
    // lines 
  }

  for (const line of lines) {
    const cols = line.split(separator);
    const object = {};
    for (let j = 0; j < headers.length; j++) {
      const colKey = headers[j];

      // Check if we need to return this column
      if (returnCols != false && returnCols.indexOf(colKey) == -1) {
        continue;
      }

      object[colKey] = cols[j];
    }
    resultList.push(object);
  }
  // Create an empty array to hold the objects
  return resultList;
}

function TTestParser(csvContent, memberId) {
  let lines = csvContent
    .split("\n")
    .slice(4)
    .filter((line) => line !== "");

  const headers = lines[0].split(";");
  // Create an empty array to hold the objects
  const payload = [];

  // Loop over the remaining rows and create objects
  for (let i = 1; i < lines.length; i++) {
    const row = lines[i].split(";");
    const object = {};
    for (let j = 0; j < headers.length; j++) {
      object[headers[j]] = row[j];
    }
    payload.push(object);
  }

  const skill_score =
    parseFloat(payload.pop()["HIT TIME"].replace(",", ".")) || 0;
  const output = {
    skill_score,
    participant_id: memberId,
    payload,
  };

  return output;
}


function getMaxValue(records, field) {
  const values = records.map((record) => parseFloat(record[field]));
  
  let result = 0;
  for (const value of values) {
    if (value > result) {
      result = value;
    }
  }
  return result;
}

function HandGripParser(csvContent, memberId) {
  // console.debug("csvContent: ", csvContent);
  // let lines = csvContent
  //   .split("\n")
  //   // .slice(4)
  //   .filter((line) => line !== "");

  const returnCols = ["Average Force", "Series Name", 
        "Dominant Hand", "Value", "Test Date", "Average Force",
        "Average Of Peaks", "Maximum Peak",
        "Time to Peak"];
  
  const recordList = parseCommonCsvContent(csvContent, { returnCols, separator: "," });
  const leftRecords = recordList.filter((record) => record["Series Name"].startsWith("Left"));
  const rightRecords = recordList.filter((record) => record["Series Name"].startsWith("Right"));

  // console.debug("recordList: ", recordList);
  console.debug("leftRecords: ", leftRecords);
  console.debug("rightRecords: ", rightRecords);

  const leftMax = getMaxValue(leftRecords, "Value");
  const rightMax = getMaxValue(rightRecords, "Value");
  const totalScore = leftMax + rightMax;


  const payload = {
    leftMax,
    rightMax,
    totalScore    
  };

  const skill_score = totalScore || 0;

  return {
    skill_score,
    participant_id: "",
    payload,
  };
}

function testHandGripParser() {
  const parser = HandGripParser;

  const content = mockData.handGrip;
  console.debug("content: ", content);
  const memberId = "test";

  const result = parser(content, memberId);

  console.debug("result: ", result);
}




// main
function csvParser(parserName, csvFileList, parentCallback) {
  if (!parserName) {
    console.warn("Parser Name is not defined");
    return false;
  }
  if (csvParserMap[parserName] === undefined) {
    console.warn("Parser is not exist");
    return false;
  }

  // Get the Parser by name 
  const parser = csvParserMap[parserName];

  // Loop for input data 
  csvFileList.forEach((csvFile) => {
    let reader = new FileReader();
    reader.addEventListener("load", function (e) {
      let csvContent = e.target.result;
      let memberId = csvFile.name.replace(".csv", "");
      parentCallback((pre) => ({
        ...pre,
        [memberId]: parser(csvContent, memberId),
      }));
      //   console.log(parsedCsvContent);
    });
    reader.readAsBinaryString(csvFile);
  });

  //   parser(csvList);
}

const CsvImportHelper = { csvParser, getExistingParserName, testHandGripParser };

export default CsvImportHelper;
