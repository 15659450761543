import {
    Card, CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    Button,
    TextField,
    Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import mockOrganizationList from "../../_mock/organization";
import { AdminApi } from "../../api/AdminApi";
import { toast } from "react-toastify";


const getOrgLabel = (org) => {
    if (org === null) {
        return null;
    }
    return `${org.name_en} - ${org.name_zh}`;
}

function removeDuplicate(inputList) {
    let appearSet = [];
    let result = [];
    for (let data of inputList) {
        let label = getOrgLabel(data);

        if (appearSet.indexOf(label) === -1) {
            appearSet.push(label);
            result.push(data);
        }
        // if (! appearSet.contains(label)) {
        //     appearSet.push(label);
        //     result.push(data);
        // }
    }
    return result;
}


const options = ['Option 1', 'Option 2'];
const OrganizationOptions = ({ organizationList, onValueChanged }) => {
    const firstOrg = organizationList[0];

    const [value, setValue] = useState(firstOrg);
    // organizationList[0]);
    const [inputValue, setInputValue] = useState(getOrgLabel(firstOrg));
    const [orgList, setOrgList] = useState(organizationList);

    // const get
    // const getFinalOrgList = () => {
    //     if (organizationList === null || organizationList === undefined) {
    //         return [];
    //     }
    //     return organizationList; // removeDuplicate(organizationList);
    // }


    return (

        <Autocomplete
            value={value || ''}
            onChange={(event, newValue) => {
                setValue(newValue);
                if (onValueChanged) {
                    onValueChanged(newValue);
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => {
                if (option === null) {
                    return null;
                }
                if (option === undefined || option === '') {
                    return "請選擇機構";
                }
                return getOrgLabel(option);
                // return option;
            }}

            id="organization"
            options={orgList}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="選擇機構" />}
        />

    );
}

export default function OrganizationDropdown({ onOrganizationSelected }) {
    const [organizationList, setOrganizationList] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const setMockData = () => {
        let filteredList = removeDuplicate(mockOrganizationList);
        // console.debug("setMockData: ", filteredList);
        setOrganizationList(filteredList);
    };

    const loadOrganizationList = () => {
        setLoading(true);
        AdminApi.getOrgnizationListCallback((isSuccess, msg, data) => {
            setLoading(false);  
            if (isSuccess === false) {
                toast.error(msg);
                setOrganizationList([]);
                return;
            }

            let filteredList = removeDuplicate(data);
            setOrganizationList(filteredList);            
        });
    }

    useEffect(() => {
        // setMockData();
        loadOrganizationList();
    }, []);

    const onValueChanged = (newValue) => {
        console.debug("onValueChanged=", newValue);
        // setOrganization(newValue);

        if (newValue === null) {
            return;
        }

        setOrganization(newValue);

        // if(onOrganizationSelected) {
        //     onOrganizationSelected(newValue);
        // }
    }

    const handleSelect = () => {
        if (organization === null) {
            return;
        }

        if (onOrganizationSelected) {
            onOrganizationSelected(organization);
        }
    }

    const orgDropdown = () => {
        if (organizationList === null || organizationList === undefined) {
            return (<pre>??</pre>);
        }

        if (organizationList.length === 0) {
            return (<pre>無資料</pre>);
        }

        return (
            <OrganizationOptions organizationList={organizationList}
                onValueChanged={onValueChanged} />
        );
    }


    return (
        <Card sx={{}} variant="outlined">
            <CardContent sx={{ padding: 1 }}>

                {
                    isLoading ? "Loading..." :
                        <Stack direction="row" spacing={2}>
                            {orgDropdown()}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSelect}
                            >
                                選擇
                            </Button>
                        </Stack>
                }

                {/* <pre>
                    {JSON.stringify(organization, null, 2)}
                </pre> */}



            </CardContent>
        </Card>
    );
}

