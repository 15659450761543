import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { auth, updateAuthState, updateUserInfo } from "../reducers/userSlice";
import {
  checkPageByUserRole,
} from "../helper/PermissionHelper";

// helper
import {
  getLocalStorageUser,
  getlocalStorageUserObject,
  removeLocalStorageUser,
} from "../helper/LocalStorageHelper";
// import { checkPagePermission } from "../helper/PermissionHelper";

const AuthProvider = ({ children, page, action }) => {
  const location = useLocation();
  // const outlet = useOutletContext();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const dispatch = useDispatch();
  
  // const authorization = useSelector(auth);
  // console.debug("AuthProvider: ", location.pathname, " action: ", action, " page: ", page);
  if (page === undefined || page === "") {
    page = location.pathname;
  }

  useEffect(() => {
    var user = getlocalStorageUserObject();

    console.debug("AuthProvider: ", location.pathname, " action: ", 
          action, " page: ", page, " user=", user);

    if (!user) {
      setIsAuthenticated(false);
      dispatch(updateAuthState(false));
      return; 
    }

    // Check
    let hasPerm = checkPageByUserRole(user, page, action);
    console.debug("AuthProvider: hasPerm=", hasPerm, " page=", page, " action: ", action);
    setHasPermission(hasPerm);


    // checkPagePermission(user, page, action, navigate);

    // try {
    //   // user = JSON.parse(user);
    //   // setIsAuthenticated(true);
    //   // dispatch(updateUserInfo(user));

    //   console.log("AuthProvider: ", user,
    //        " hasPermission: ", hasPermission);
    //   // checkPagePermission(page, action);
    //   // console.log("page", page, "action", action);
    // } catch (error) {
    //   console.log(error);
    //   removeLocalStorageUser();
    //   setIsAuthenticated(false);
    //   dispatch(updateAuthState(false));
    // }
  }, [location]);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return hasPermission ? children : <></>;
};

export default AuthProvider;
