import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Card, Box, Button, TextField, Typography, CardContent } from "@mui/material";
import QRCode from "react-qr-code";
import QrReader from "modern-react-qr-reader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { ca } from "date-fns/locale";

export default function RedemptionQRCard({ onQRCodeUpdated, caption }) {
    const formik = useFormik({
        initialValues: {
            qrCode: "",
            // qrCode: "FMVGNXLV"
            // skillValue: 0,
            // skillData: {},  // { left: 3, right: 3}
        },
    });

    caption = caption ?? "換領碼輸入";

    const handleQRError = (err) => {
        // setError(err);
    };

    const handleQRScan = (data) => {
        if (!data) return;
        if (data === formik.values.qrCode) return;

        console.debug("QR data=", data);
        if (data !== "" && data !== undefined) {
            formik.setFieldValue("qrCode", data);
            toast.success("QR code scanned");

            confirmQRCode(data);
        }
    };

    const confirmQRCode = (qrCodeValue) => {
        if (onQRCodeUpdated !== undefined) {
            // trim & upper the value 
            let qrCode = qrCodeValue.trim().toUpperCase();

            onQRCodeUpdated(qrCode);
        }
    }

    const handleQRInput = () => {
        if(formik.values.qrCode === "") {
            toast.error("請輸入QR碼");
            return;
        }

        console.debug("handleQRInput: ", formik.values.qrCode);

        confirmQRCode(formik.values.qrCode);
    };

    return (
        <Card sx={{}} variant="outlined">
            <CardContent sx={{ padding: 1 }}>
                <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography variant="h6">
                        {caption}
                    </Typography>

                    <Box display="flex" gap={2} flexDirection="row" alignItems="center">
                        <TextField
                            name="qrCode"
                            id="qrCode"
                            label="用戶QR碼"
                            size="small"
                            value={formik.values.qrCode}
                            onChange={formik.handleChange}
                        />
                        <Button variant="contained" size="medium"
                            onClick={handleQRInput}
                        >
                            提交
                        </Button>
                    </Box>
                    <Box
                        component={QrReader}
                        // onResult={(result, error) => {
                        //     // console.debug("result=", result);
                        //     // console.debug("error=", error);
                        //     if (!!result) {
                        //         setData(result?.text);
                        //     }
                        //     setError(error ?? "No result yet");
                        // }}
                        delay={300}
                        onError={handleQRError}
                        onScan={handleQRScan}
                        constraints={{
                            facingMode: "environment",
                        }}
                        sx={{ width: 300, height: "auto" }}
                    />

                </Box>
            </CardContent>
        </Card>
    );
}