import React from "react";
import { useState, useEffect } from "react";
import { useTranslation, Translation } from "react-i18next";
import { useFormik } from "formik";
import {
  Stack,
  TextField,
  InputAdornment,
  Box,
  Button,
  CardContent,
  Card,
  Typography,
  OutlinedInput,
} from "@mui/material";
import QrReader from "modern-react-qr-reader";
import ConfirmInputDialog from "../ConfirmInputDialog";
import CommonForm from "../../common/CommonForm";
/** Api */
import { AdminApi } from "../../../api/AdminApi";
import { toast } from "react-toastify";
import { convertBase64 } from "../../../helper/UnitsHelper";
/** Content */
import FormContent from "./healthInputFormSchema.js";

export default function HealthInputForm({ eventID }) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [memberID, setMemberID] = useState(0);
  const [confirmResult, setConfirmResult] = useState(null);

  const formik = useFormik(FormContent.FormikInstance);


  // Logic: 
  //    if image is empty or string, keep it return
  //    if image is file, convert to base64
  const getUploadImageData = async (imageData) => {
    if(imageData === undefined || imageData === null) return "";
    if (typeof imageData === "string") {
      return imageData;
    }

    if (imageData === "" || imageData === null) {
      return imageData
    }

    if (typeof imageData === "object") {
      return convertBase64(imageData);
    }
    // convertBase64
    return "";
  }

  const onSubmit = async () => {
    console.debug("onSubmit: ", formik.values);
    if (formik.values.qrCode === "") {
      alert("請輸入參加者QR碼");
      return;
    }

    if (formik.values.qrCode.length < 4) {
      alert("參加者QR號碼不正確");
      return;
    }

    // tanita_screen_capture_1: " ",
    // tanita_screen_capture_2: " ",
    // balance_screen_capture_1: " ",
    // balance_screen_capture_2: " ",
    console.debug("tanita_screen_capture_1: ", formik.values.tanita_screen_capture_1);
    console.debug("tanita_screen_capture_2: ", formik.values.tanita_screen_capture_2);
    console.debug("balance_screen_capture_1: ", formik.values.balance_screen_capture_1);
    console.debug("balance_screen_capture_2: ", formik.values.balance_screen_capture_2);

    let imageData = null;
    let tanitaImages = [];
    imageData = await getUploadImageData(formik.values.tanita_screen_capture_1);
    tanitaImages.push(imageData);
    imageData = await getUploadImageData(formik.values.tanita_screen_capture_2);
    tanitaImages.push(imageData);

    let balanceImages = [];
    imageData = await getUploadImageData(formik.values.balance_screen_capture_1);
    balanceImages.push(imageData);
    imageData = await getUploadImageData(formik.values.balance_screen_capture_2);
    balanceImages.push(imageData);

    console.debug("tanitaImages: ", tanitaImages);
    console.debug("balanceImages: ", balanceImages);

    let data = {
      member_id: memberID,
      event_id: eventID,
      age: formik.values.age,
      height: formik.values.height,
      weight: formik.values.weight,
      eye_sight_r: formik.values.eye_sight_r,
      eye_sight_l: formik.values.eye_sight_l,
      eye_sight_color: formik.values.eye_sight_color,
      tanita_info: tanitaImages,
      balance_info: balanceImages,
      // tanitaImages,
    };
    //
    console.debug("data: ", data);
    // return;

    // export const queryMemberSkillData = async (page, pageSize, filters, fields='default') => {
    AdminApi.updateMemberEventHealthInfo(eventID, data, (isSuccess, msg, result) => {
      if (isSuccess === false) {
        alert(msg);
        return;
      }

      console.debug("result: ", result);


      // Update the images 
      updateImageToForm(result.tanita_info, "tanita_screen_capture_1", "tanita_screen_capture_2");
      updateImageToForm(result.balance_info, "balance_screen_capture_1", "balance_screen_capture_2");

      alert("提交成功");
    });
    //await AdminApi.submitScore(memberID, eventID, skill, score);
  };

  const onQrCodeSubmit = () => {
    let qrCode = formik.values.qrCode || "";
    qrCode = qrCode.trim();
    if (qrCode === "") {
      alert("請輸入參加者QR碼");
      return;
    }

    console.debug("onQrCodeSubmit: ", qrCode);
    // console.debug("onQrCodeSubmit: values: ", formik.values);
    fetchHealthInfo(qrCode);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleQRError = (err) => {
    // setError(err);
  };

  const updateImageToForm = (imageArrayInfo, field1, field2) => {
    let imageData = imageArrayInfo || [];
    let image1 = imageData.length > 0 ? imageData[0] : "";
    let image2 = imageData.length > 1 ? imageData[1] : "";

    formik.setFieldValue(field1, image1);
    formik.setFieldValue(field2, image2);
  }

  const fetchHealthInfo = (qrCode) => {
    AdminApi.getMemberEventHealthInfoCallback(
      eventID,
      qrCode,
      (isSuccess, msg, result) => {
        console.debug(`isSuccess=${isSuccess}, msg=${msg}`);
        console.debug(`eventID=${eventID}, qrCode=${qrCode} result: `, result);

        if (isSuccess === false) {
          alert(msg);
          return;
        }

        setMemberID(result.member_id);

        // formik.setFieldValue("qrCode", qrCode);
        formik.setFieldValue("age", result.age);
        formik.setFieldValue("height", result.height);
        formik.setFieldValue("weight", result.weight);
        formik.setFieldValue("eye_sight_r", result.eye_sight_r);
        formik.setFieldValue("eye_sight_l", result.eye_sight_l);
        formik.setFieldValue("eye_sight_color", result.eye_sight_color);

        /// Tanita Images 
        updateImageToForm(result.tanita_info, "tanita_screen_capture_1", "tanita_screen_capture_2");
        updateImageToForm(result.balance_info, "balance_screen_capture_1", "balance_screen_capture_2");
        
      }
    );
  };

  const handleQRScan = (data) => {
    if (!data) return;
    if (data === formik.values.qrCode) return;

    console.debug("QR data=", data);
    if (data !== "" && data !== undefined) {
      let qrCode = data.trim();
      formik.setFieldValue("qrCode", qrCode);
      toast.success("QR code scanned");

      fetchHealthInfo(qrCode);
      /**
       Get student date
      apply to the form
    **/
    }
  };

  return (
    <>
      {confirmResult && (
        <ConfirmInputDialog
          eventID={eventID}
          confirmResult={confirmResult}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      )}
      <Box>
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <Box
                component={QrReader}
                delay={300}
                onError={handleQRError}
                onScan={handleQRScan}
                constraints={{
                  facingMode: "environment",
                }}
                sx={{ width: 300, height: "auto" }}
              />
              <TextField
                name="qrCode"
                id="qrCode"
                label="用戶QR碼"
                value={formik.values.qrCode || ""}
                onChange={formik.handleChange}
              />

              <Button
                variant="contained"
                onClick={onQrCodeSubmit}
                color="primary"
              >
                提交 QR Code
              </Button>
            </Stack>
          </CardContent>
        </Card>
        <Box sx={{ my: 2 }} />
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                健康數據：
              </Typography>
              <Box>
                <CommonForm
                  fromContent={FormContent}
                  formik={formik}
                // submitUrl={"company"}
                />
              </Box>

              <Button
                variant="contained"
                onClick={onSubmit}
                color="primary"
                sx={{ width: "100%" }}
                disabled={!formik.isValid}
              >
                提交數據
              </Button>
              {/* <pre>skillValue: {skillValue}</pre> */}
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
