import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

import MockData from "../../helper/MockData";
import { getCurrentDateStr } from "../../helper/StringHelper";

import CoSportRankingDisplay from "../../components/leaderBoard/CoSportRankingDisplay";
import CoSportRankingDisplayForKid from "../../components/leaderBoard/CoSportRankingDisplayForKid";
import CoSportRankingBarItem from "../../components/leaderBoard/CoSportRankingBarItem";


const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];



export default function TestCoSportRankingDisplay() {
    const { t } = useTranslation();

    let lang = getLang();

    const oneTopSkillOrg = MockData.getOneSkillTopOrganization();

    // let leaderBoardResult = MockData.getOneTimeslotLeaderboard();
    // MockData.getOneTimeslotLeaderboard();

    let eventId = 3;

    const barData = {
        rank: 1,
        schoolName: "Test School",
        score: 100,
    }

    const skillInfo = {

        "id": 8,
        "name_zh": "單腳平衡測試",
        "name_en": "Single-leg Balance",
        "code": "balance",
        "fitness_type": "balance",
        "fitness_type_en": "Single-leg Balance",
        "fitness_type_zh": "單腳平衡測試",
        "unit": "pt",
        "decimal_place": 0,
        "rank_direction": "desc",
    }

    // const themeData = {
    //     // bg: "blue",
    //     bg: "green",
    //     barColor: "#a00"
    // }

    // const themeData = {
    //     bg: "blue",
    //     barColor: "#f80"
    // }

    const themeData = {
        bg: "orange",
        barColor: "#afa"
    }


    // const themeData = {
    //     bg: "purple",
    //     barColor: "#afa"
    // }


    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TestSportDisplay</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <pre>
                        {/* {JSON.stringify(leaderBoardResult, null, 2)} */}
                    </pre>
                </Box>

                <Box width="80%">
                    <CoSportRankingBarItem barData={barData} unit="cm" bestScore="1000" />
                </Box>

                <CoSportRankingDisplay
                    rankingData={oneTopSkillOrg}
                    skillInfo={skillInfo}
                    themeData={themeData}
                />
                <Box height="50px"></Box>
                <CoSportRankingDisplayForKid
                    rankingData={oneTopSkillOrg}
                    skillInfo={skillInfo}
                    themeData={themeData}
                />

            </Stack>
        </Container>
    )
}
