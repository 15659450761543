import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import YoutubePreview from "../../components/common/YoutubePreview";

export default function TestSimple() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(1234);

    React.useEffect(() => {
        setValue(1234);
    }, []);

    let lang = getLang();
    let youtubeUrl = "https://www.youtube.com/watch?v=lJ4ey3YJWjQ&ab_channel=TheCodingTrain";

    // setValue(1234);

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <pre>Value: {value} </pre>
                </Box>
                <YoutubePreview youtubeUrl={youtubeUrl} />
            </Stack>
        </Container>
    )
}
