import { Box, Grid, Typography, IconButton, Button, Container, Stack } from "@mui/material";

import SvgBackBtn from "../../icon/SvgBackBtn.jsx";
import SvgForwardBtn from "../../icon/SvgForwardBtn.jsx";
import SvgToHome from "../../icon/SvgToHome.jsx";

import { useEffect } from "react";
import { AdminApi } from "../../../api/AdminApi.js";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Footer from "../Footer/Footer.jsx";

import MockData from "../../../helper/MockData.js";
import CoSportRankingDisplay from "../CoSportRankingDisplay/CoSportRankingDisplay.jsx";
import CoSportAnalyticsDisplay from "../CoSportAnalyticsDisplay/CoSportAnalyticsDisplay.jsx";
import CoSportAnalyticsDisplayForKid from "../CoSportAnalyticsDisplayForKid/CoSportAnalyticsDisplayForKid.jsx";

const MAIN_FONT = "Noto Sans TC";

const CarouselProps = {
  showThumbs: false,
  autoPlay: true,
  infiniteLoop: true,
  showIndicators: false,
  showStatus: false,
  showArrows: false,
  // interval: 60000,
  interval: 4000,
  emulateTouch: true,
};
const themeSetting = [
  { bg: "green", barColor: "#a00" },
  { bg: "blue", barColor: "#f80" },
  { bg: "orange", barColor: "#9fc" },
  { bg: "purple", barColor: "#afa" },
]

export default function CoSportAnalyticsCarousel({ onBackClicked, orgType }) {
  const [analyticData, setAnalyticData] = useState([]);
  const [skillInfo, setSkillInfo] = useState([]);
  const [updateTime, setUpdateTime] = useState(null);

  const isKidTheme = orgType === "kid" || orgType === "primary";
  // let orgType = "school";
  // let result = await AdminApi.loadCoSportAnalytics(orgType);

  const loadDataFromServer = async () => {
    let response = await AdminApi.loadCoSportAnalytics(orgType);
    handleAnalyticData(response)
  }

  useEffect(() => {
    loadDataFromServer();
  }, []);

  useEffect(() => {
    // setAnalyticData((pre) => analyticData);

    // setnIntervId(
    const interval = setInterval(() => {
      loadDataFromServer();
    }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

    return () => clearInterval(interval);

    // );
  }, [analyticData]);

  const handleAnalyticData = (response) => {
    console.log("handleRankingData: ", response)
    let analyticData = response.records;
    let skillInfo = response.skillInfo;

    setSkillInfo(skillInfo);

    let rankingDataList = [];

    // Re-organize the data with Skill Data 
    for (let skillCode in analyticData) {
      let rankingDataItem = {
        skillCode: skillCode,
        data: analyticData[skillCode]
      }
      rankingDataList.push(rankingDataItem);
    }
    setAnalyticData(rankingDataList);

    setUpdateTime(new Date().toLocaleString());
  }


  const handleBackHome = () => {
    console.debug("handleBackHome: called")
    if (onBackClicked) {
      onBackClicked();
    }
  }

  const handleScrollLeft = () => {

  }

  const handleScrollRight = () => {

  }


  const getSkillInfo = (skillCode) => {
    for (let skillInfoItem of skillInfo) {
      if (skillInfoItem.code === skillCode) {
        return skillInfoItem;
      }
    }

    return ""
  }

  const getTheme = (index) => {
    let themeIndex = index % themeSetting.length;
    return themeSetting[themeIndex];
  }


  const showLeft = false
  const showRight = false

  return (
    <Box position={"relative"} overflow="hidden">
      <Box position={"absolute"} left={10} top={10} zIndex={10}>
        <IconButton onClick={() => handleBackHome()}>
          <SvgToHome />
        </IconButton>
      </Box>

      <Box position={"absolute"} left={10} bottom={10} zIndex={10}>
        <Typography sx={{fontSize: '15px'}} color={"#555"}>
          {
            updateTime ? `(更新時間: ${updateTime})` : ""
          }
          
        </Typography>
      </Box>

      <Footer />
      <Box
        position={"absolute"}
        left={10}
        top={"50%"}
        zIndex={10}
        display={showLeft ? "block" : "none"}
      >
        <IconButton
          onClick={() => {
            handleScrollLeft();
          }}
        >
          <SvgBackBtn />
        </IconButton>
      </Box>
      <Box
        position={"absolute"}
        right={10}
        top={"50%"}
        zIndex={10}
        display={showRight ? "block" : "none"}
      >
        <IconButton
          onClick={() => {
            handleScrollRight();
          }}
        >
          <SvgForwardBtn />
        </IconButton>
      </Box>
      {/* <pre>
        <b>Debug Data</b>
        {
          rankingData.map((rankingDataItem, index) => {
            return (
              <pre>
                {getSkillName(rankingDataItem.skillCode)}
                {JSON.stringify(rankingDataItem.rankingData, null, 2)}
              </pre>
            )
          })
        }

        ---
        {getSkillName("balance")}
        ---
        {JSON.stringify(rankingData, null, 2)}
        ---
        {JSON.stringify(skillInfo, null, 2)}
      </pre> */}
      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={12}>
          <Box>
            {/* {leaderData.map((_sportLeader, index) => (
                <SportDisplay
                  SportLeader={_sportLeader}
                  eventID={eventId}
                  date={TODAY}
                  key={`carousel-${_sportLeader.skill_code}-${index}`}
                />
              ))} */}
            <Carousel {...CarouselProps}>
              {
                analyticData.map((dataItem, index) => {
                  return isKidTheme ?
                    (
                      <CoSportAnalyticsDisplayForKid
                        analyticData={dataItem.data}
                        skillInfo={getSkillInfo(dataItem.skillCode)}
                        themeData={getTheme(index)}
                        key={`carousel-${dataItem.skillCode}-${index}`}
                      />
                    )
                    : (
                      <CoSportAnalyticsDisplay
                        analyticData={dataItem.data}
                        skillInfo={getSkillInfo(dataItem.skillCode)}
                        themeData={getTheme(index)}
                        key={`carousel-${dataItem.skillCode}-${index}`}
                      />
                    )
                })
              }
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
