import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const mainField = "id";
const nettedField = "sub_catagories";
const recordNotFoundMsg = "-- 未找到資料 --";

const dataFieldConfig = [
  {
    id: "name_zh",
    field: "name_zh",
    align: "left",
    disablePadding: false,
    label: "類別名稱",
    fieldAction: () => {},
  },
  {
    id: "name_en",
    field: "name_en",
    align: "left",
    disablePadding: false,
    label: "Category Name",
  },
];

const fetchDataConfig = {
  url: "/equipmentCategory/tree",
  // extraQuery: [{ key: "org", field: "orgId" }],
  // fixedFilter: [],
};

const tableActionConfig = [
  {
    type: "link",
    label: "View Contents",
    icon: "view",
    // icon: EmojiEventsIcon,
    url: "equipment-category/$id/equipments",
  },
];

const filterConfig = [
  // { type: "text", label: "按 名稱 搜索", field: "name_zh,name_en" },
];

const TableConfig = {
  mainField,
  nettedField,
  dataFieldConfig,
  fetchDataConfig,
  tableActionConfig,
  filterConfig,
  recordNotFoundMsg,
};

export default TableConfig;
