import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, TextField } from "@mui/material";

export default function Component({
  filterConfig = [],
  emitQueryFn = () => {},
}) {
  const [filterQuery, setFilterQuery] = useState([]);

  const handleSearchQuery = useCallback(
    (field, newValue) => {
      if (!newValue) {
        return setFilterQuery([]);
      }

      const queryObj = { field, value: newValue, op: "like" };
      const filteredQuery = filterQuery.filter(
        (query) => query.field !== field
      );
      filteredQuery.push(queryObj);
      setFilterQuery(filteredQuery);
    },
    [filterQuery]
  );

  const filteredConfig = useMemo(() => {
    return filterConfig.filter((filter) => filter.type === "text");
  }, [filterConfig]);

  useEffect(() => {
    emitQueryFn(filterQuery);
  }, [filterQuery]);

  return (
    <Grid container>
      {filteredConfig.map((filter, index) => (
        <Grid item xs={12} key={`table-filter-${index}`}>
          <TextField
            size="small"
            label={filter.label}
            onChange={(e) => handleSearchQuery(filter.field, e.target.value)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
