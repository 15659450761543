import { Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import MockData from "../../helper/MockData";
import { InfoApi } from "../../api/InfoApi";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import SkillLabel from "../../components/app/SkillLabel";

import SkillSelectionList from "../../components/app/SkillSelectionList";
import SkillInputForm from "../../components/app/SkillInputForm";

export default function SkillInputSection({ skill, event }) {
  // const skill = MockData.getSkillInfoList()[0];
  // const [skillList, setSkillList] = useState(null);
  // const { t } = useTranslation();

  const eventID = event ? event.id : 0;

  return skill ? (
    <Box>
      <SkillLabel skill={skill} />
      <Box mt={2} />

      <SkillInputForm skill={skill} eventID={eventID} />
    </Box>
  ) : (
    <Box>No Skill Data</Box>
  );
}
