import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: false,
  jwt: null,
  id: null,
  username: "",
  email: "",  
  //role: [],
  role: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, userObject, jwt) => {
      state.auth = true;
      state.jwt = jwt;
      state.id = userObject.id;
      state.username = userObject.username;
      state.email = userObject.email;      
      state.role = userObject.role;
      state.organizationID = userObject.organization_id;
    },
    updateAuthState: (state, value) => {
      state.auth = value.payload;
    },
    updateJwtState: (state, value) => {
      state.jwt = value.payload;
    },  
  },
});

export const {
  updateUserInfo,
  updateAuthState,
  updateJwtState,
} = userSlice.actions;

export const auth = (state) => state.user.auth;
export const jwtToken = (state) => state.user.jwt;
export const userID = (state) => state.user.id;
export const username = (state) => state.user.username;
export const email = (state) => state.user.email;

export default userSlice.reducer;
