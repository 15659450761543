import React, { useEffect, useState } from "react";
import { Divider, Stack, Grid, Card, Box, Button, Typography } from "@mui/material";
import { getLang, localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import EventCard from "./EventCard";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from "@emotion/styled";
import MemberQRCard from "./MemberQRCard";
import MemberQueryQRCardList from "./MemberQueryQRCardList";

const splitResult = (result, pageSize) => {
    let resultArray = [];
    let resultArrayItem = [];
    let count = 0;
    result.forEach((item) => {
        resultArrayItem.push(item);
        count++;
        if (count >= pageSize) {
            resultArray.push(resultArrayItem);
            resultArrayItem = [];
            count = 0;
        }
    });
    if (resultArrayItem.length > 0) {
        resultArray.push(resultArrayItem);
    }
    return resultArray;
}

export default function MemberQueryQRCardPrintList({
    memberQueryResult,
    pageSize,
    componentToPrint
}) {
    pageSize = pageSize || 10;

    if (memberQueryResult == null) {
        return (
            <Box></Box>
        );
    }


    const total = memberQueryResult.total;
    if (total === 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">沒有相關資料</Typography>
                </Grid>
            </Grid>
        );
    }

    let memberList = memberQueryResult.data;
    console.debug("pageSize: ", pageSize);
    // const splitMemberList = splitResult(memberList, pageSize);

    // console.debug("splitMemberList", splitMemberList);


    const A4Paper = styled("div")({
        // width: "297mm",
        '@media print': {
            width: "210mm",
            // height: "790px",
        },
        // height: "790px", // 793.688px 210mm
        // background: "red",
        display: "flex",
        flexDirection: "column",
        breakAfter: "page",

    });

    // Multi-purpose Labels	U4452-100	雷射 / 噴墨 / 影印	105 x 42 mm

    return (
        <Stack sx={{ display: 'block' }} direction="column" ref={(el) => (componentToPrint.current = el)}>
            {
                <A4Paper>
                    <MemberQueryQRCardList memberList={memberList} />

                </A4Paper>
                 
                // splitMemberList.map((memberList, index) => {
                //     return (
                //         <Box key={index}>
                //             <A4Paper>
                //                 <MemberQueryQRCardList memberList={memberList} />

                //             </A4Paper>
                //             <Box sx={{ pageBreakAfter: 'always' }}></Box>
                //         </Box>
                //     );
                // })
            }

        </Stack>
    );
}