import { Box, Grid, Typography, Container } from "@mui/material";
import LeaderboardRankItemForKid from "../LeaderboardRankItemForKid";
import dashboardBackground1 from "../../../public/dashboard-kid-bg-1.png";
import CoSportDisplayHeaderForKid from "../CoSportDisplayHeaderForKid/index.jsx";
import CoSportSchoolInfoCard from "../CoSportSchoolInfoCard";
import { AdminApi } from "../../../api/AdminApi";

import { useEffect, useState } from "react";
const MAIN_FONT = "Noto Sans TC";

const BackgroundMap = {
  blue: dashboardBackground1,
  orange: dashboardBackground1,
  purple: dashboardBackground1,
  green: dashboardBackground1,
};



export default function Component({ leaderboardData, date, eventID, overall }) {
  const [localSportLeader, setLocalSportLeader] = useState(leaderboardData);

  // const cardStyle = {
  //   border: "6px solid #B96400",
  //   borderRadius: "20px",
  //   background: "#FFE0C1",
  // }
  const NothingDisplay = () => {
    return (
      <Box
        sx={{
          border: "6px solid #B96400",
          borderRadius: "20px",
          background: "#FFE0C1",
        }}
        my={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={4}
          px={4}
        >
          <Grid item xs={12}>
            <Typography variant="h2" color={"#662A00"}>
              未有資料
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    setLocalSportLeader((pre) => leaderboardData);

    // setnIntervId(
    const interval = setInterval(() => {
      SportLeaderUpdate();
    }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

    return () => clearInterval(interval);

    // );
  }, [leaderboardData]);

  const SportLeaderUpdate = async () => {
    console.log("SportLeaderUpdate(Kid): ", new Date().toLocaleTimeString());
    if (!("timeslot_id" in leaderboardData)) return;
    let result = await AdminApi.loadSingleEventOverallLeaderboardCallback(
      eventID,
      date,
      leaderboardData.query_code
    );
    setLocalSportLeader(result);
  };

  const backgroundStyle = {
    backgroundImage: `url("${BackgroundMap["blue"]
      }")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }

  const skillName = leaderboardData.skill_name || "N/A";
  const hasLeaderboard = leaderboardData.result?.length > 0;
  const subHeading = overall ? "整個活動" : "本日活動";
  const skillCode = leaderboardData?.skill_code || "";

  return (
    <Box
      align="center"
      pt={6}
      minHeight={"100vh"}
      sx={backgroundStyle}
      position="relative"
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        rowSpacing={2}
        pb={4}
        mb={4}
        sx={{
          // transform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg) ",
          // WebkitTransform: "rotateZ(-5deg) skewX(-5deg)" /* Safari & Chrome */,
          // MozTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Firefox */,
          // MsTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Internet Explorer */,
          // OTransform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Opera */,
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Grid item xs={12} align={"center"} sx={{ padding: 2 }}>
            <CoSportDisplayHeaderForKid heading={skillName} subHeading={subHeading} />
          </Grid>

        </Grid>

        {/* No any result */}
        {!hasLeaderboard && <Grid item sx={{ width: "90%" }}>
          <NothingDisplay />
        </Grid>}
        {/* // Leader Display */}
        <CoSportSchoolInfoCard eventID={eventID} skillCode={skillCode} />
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          mt={3}
        >
          {localSportLeader.result.map((_r, index) => (
            <Grid item key={index} sx={{ width: "auto" }}>
              <LeaderboardRankItemForKid key={`rand-${index}`} content={_r} overall />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
