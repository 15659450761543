import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import EventStatisticQueryForm from "../../components/adminStatistic/EventStatisticQueryForm";
import MockData from "../../helper/MockData";
import { dateValueToTimeStr } from "../../helper/TimeHelper";

export default function TestEventStatisticQueryForm() {
    const { t } = useTranslation();

    let lang = getLang();
    let eventList = MockData.getLeaderboardEventList();

    const onQuery = (inputData) => {
        console.debug("onQuery: ", inputData);
        console.debug("dateValueToStr: ", dateValueToTimeStr(inputData.selectedDate));
    };

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <EventStatisticQueryForm eventList={eventList} onQuery={onQuery}>

                </EventStatisticQueryForm>

            </Stack>
        </Container>
    )
}
