import React, { useState, useEffect } from "react";
/** Helper */
import CsvImportHelper from "../../../helper/CsvImportHelper";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  FormControl,
  MenuItem, InputLabel, Select,
  Card,
  CardContent,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import ImportConfirmDialog from "./ImportConfirmDialog";
import { toast } from "react-toastify";

// import CancelIcon from "@mui/icons-material/Cancel";
/** Api */
import { AdminApi } from "../../../api/AdminApi";
/** Content */
const fileTypes = ["CSV"];

export default function SkillCsvImport({ skill, eventID, qrCode }) {
  const [file, setFile] = useState(null);
  const [parsedCsvContent, setParsedCsvContent] = useState(null);
  const [open, setOpen] = useState(false);

  const [idType, setIdType] = useState("qrcode_id");

  // Dialog
  function handleDialogOpen() {
    setOpen(true);
  }
  function handleDialogClose() {
    setOpen(false);
  }
  // file control
  const handleChange = (file) => {
    setFile(file);
  };

  const removeUploadFile = () => {
    setFile((pre) => null);
  };

  const importCsv = () => {
    if (!file) return;
    if (!skill?.import_setting?.file_import?.parser) {
      console.warn("No parser setting");
      return;
    }
    CsvImportHelper.csvParser(
      skill.import_setting.file_import.parser,  // ParserName: TTestParser
      Object.values(file).filter((file) => "name" in file), // List of file 
      setParsedCsvContent, // Callback
    );
  };


  // ID Type Selection
  const idTypeSelection = <FormControl fullWidth>
    <InputLabel id="eventID-label">ID類型</InputLabel>
    <Select
      labelId="id_type-label"
      id="id_type"
      value={idType}
      label="Event"
      onChange={(e) => setIdType(e.target.value)}
    // onChange={}
    >
      <MenuItem value="qrcode_id">使用以當前的QR CODE ID</MenuItem>;
      <MenuItem value="member_id">使用以CSV檔案名字的Member ID</MenuItem>;
    </Select>
  </FormControl>;


  function getFinalRecordList() {
    if(idType == "qrcode_id"){
      return Object.values(parsedCsvContent).map((record) => {
        return { ...record, participant_id: qrCode };
      });
    } else {
      return  Object.values(parsedCsvContent);
    }
  }

  /// 
  function onSubmit() {
    const recordList = getFinalRecordList();
    console.log("parsedCsvContent: ", recordList);
    let insertData = {
      event_id: eventID,
      skill_code: skill.code,
      id_type: idType,
      record_list: recordList, // Object.values(parsedCsvContent),
    };
    console.log("insertData: (onSubmit) ", insertData);
    AdminApi.batchInsertSkillScore(insertData, (isSuccess) => {
      if (isSuccess) {
        toast.success("更新成功");
      }
    });

    setParsedCsvContent((pre) => null);
    removeUploadFile();
    handleDialogClose();
  }
  return (
    <Box>
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Stack spacing={3} direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6" sx={{ mt: 1 }}>
              匯入 CSV 檔案
            </Typography>
          </Stack>

          <Typography
            variant="caption"
            fontWeight="bold"
            color="text.secondary"
            sx={{ mb: 1 }}
          >
            {"* 請將 .csv 檔案拖曳至下方區域 (Max size: 5MB)"}
          </Typography>

          <Box sx={{ marginTop: "1rem" }}>
            {idTypeSelection}
            {/* DEBUG: {idType} */}
          </Box>

          <FileUploader
            multiple={true}
            handleChange={handleChange}
            fileOrFiles={"Array"}
            name="file"
            types={fileTypes}
            sx={{ width: "100%", height: "100%" }}
            maxSize={5}
            dropMessageStyle={{ backgroundColor: "red" }}
          >
            <Box
              sx={{
                my: 2,
                p: 2,
                width: "100%",
                height: "100%",
                minHeight: "15rem",
                border: "3px dashed #eee",
                cursor: "pointer",
              }}
            >
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                my={3}
                mx={0.1}
                gap={2}
              >
                {file !== null
                  ? [...Array(file.length)].map((_v, index) => (
                    <Box
                      position={"relative"}
                      backgroundColor={"background.neutral"}
                      p={1}
                      borderRadius={2}
                      key={`file-${index}`}
                    >
                      {file[index].name}
                    </Box>
                  ))
                  : null}
                {/* {[...Array(file?.length || 0)].map((index) => (
                  <Box
                    position={"relative"}
                    backgroundColor={"background.neutral"}
                    p={1}
                    borderRadius={2}
                    key={`file-${index}`}
                  >
                    {JSON.stringify(file.FileList[index])}
                  </Box>
                ))} */}
              </Grid>
            </Box>
          </FileUploader>
          {file?.length > 0 && (
            <Typography
              variant="caption"
              fontWeight="bold"
              color="text.secondary"
              sx={{ mb: 1 }}
              align={"right"}
            >
              已加入 {file.length} 個檔案
            </Typography>
          )}

          {file?.length > 0 && (
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  importCsv();
                  handleDialogOpen();
                }}
              >
                匯入
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => removeUploadFile()}
              >
                取消
              </Button>
            </Stack>
          )}
          <ImportConfirmDialog
            open={open}
            onClose={handleDialogClose}
            scoreData={parsedCsvContent}
            onSubmit={onSubmit}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
