import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
    Avatar,
} from "@mui/material";

const isYoutubeShort = (url) => {
    return url.indexOf("youtube.com/shorts") > 0;
}

const getYoutubeShortEmbedCode = (url) => {
    let index = url.indexOf("?");

    if (index > 0) {
        return url.substring(index + 1);
    }

    let tokens = url.split("/");
    return tokens[tokens.length - 1];
}



const getEmbedCode = (url) => {
    let queryStr = url.split("?");
    if (queryStr.length < 2) {
        return "";
    }
    let tokens = queryStr[1].split("&");
    for (let token of tokens) {
        let kv = token.split("=");
        if (kv[0] === "v") {
            return kv[1];
        }
    }

    return "";
}

const YoutubePreview = ({
    youtubeUrl, title
}) => {
    let embedCode = isYoutubeShort(youtubeUrl) ?
        getYoutubeShortEmbedCode(youtubeUrl) :
        getEmbedCode(youtubeUrl);
    return (
        <Box>
            <iframe
                width="296"
                height="166"
                src={`https://www.youtube.com/embed/${embedCode}`}
                // src={`https://www.youtube.com/embed/${node.url.split("=")[1]
                //   }?si=dsYK7Zc3GeTL69uS`}

                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>

            <Typography align="left" variant="body2">
                {youtubeUrl}
                {/* {JSON.stringify(node)} */}
            </Typography>
        </Box>
    )

    // return (<MainCard sx={mainStyle} border={false} boxShadow={true} contentSX={contentSX}>
    //     <Box display="flex" sx={{ alignItems: 'center',  paddingTop:1}}>
    //         <RoundIcon
    //             component={iconComponent}
    //             size={70}
    //             bgColor={iconBgColor}
    //             color={iconColor}
    //             padding={1}
    //         />

    //         <Stack sx={{ paddingLeft: 1, alignItems: "start" }} display={"flex"} >
    //         <Typography variant="h3" color="black">
    //                 {value}
    //             </Typography>
    //             <Typography variant="h6" color="textSecondary">
    //                 {label}
    //             </Typography>

    //         </Stack>
    //     </Box>

    // </MainCard>);

}

export default YoutubePreview;


