import React from "react";
import {
    Box,
} from "@mui/material";
// import iconImage from "../../../public/iconImages/white-person-icon.png";

const BaseWhiteIcon = ({
    imageSrc, size, imageSize, padding, ...otherProps
}) => {
    if (!imageSize) {
        switch (size) {
            case "small":
                imageSize = 50;
                break;
            case "medium":
                imageSize = 80;
                break;
            case "large":
                imageSize = 120;
                break;
            default:
                imageSize = 80;
                break;
        }
    }
    // console.debug("imageSize", imageSize)

    let style = {
        padding: padding || 2,
        width: imageSize,
        height: imageSize,
        ...otherProps
    };


    return (
        <Box src={imageSrc}  {...style}>
            <img alt="" src={imageSrc} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </Box>
    );

}

export default BaseWhiteIcon;


