import { Box, Grid, Typography, Container, Stack } from "@mui/material";
import LeaderDisplay from "../LeaderDisplay";

import leaderBoardBg_1 from "../../../public/leaderBoardBg_1.png";
import leaderBoardBg_2 from "../../../public/leaderBoardBg_2.png";
import leaderBoardBg_3 from "../../../public/leaderBoardBg_3.png";
import leaderBoardBg_4 from "../../../public/leaderBoardBg_4.png";
import { useEffect } from "react";
import { AdminApi } from "../../../api/AdminApi";
import { useState } from "react";

import CoSportRankingBarItem from "../CoSportRankingBarItem";
import CoSportSchoolListCard from "../CoSportSchoolListCard";

const MAIN_FONT = "Noto Sans TC";

const BackgroundMap = {
  blue: leaderBoardBg_1,
  orange: leaderBoardBg_2,
  purple: leaderBoardBg_3,
  green: leaderBoardBg_4,
};

const testRankingData = [
  {
    rank: 1,
    schoolName: "Test School",
    score: 890,
  },
  {
    rank: 2,
    schoolName: "Test School B",
    score: 390,
  },
  {
    rank: 1,
    schoolName: "Test School C",
    score: 90,
  }
]

export default function CoSportRankingDisplay({ rankingData, skillInfo, themeData }) {
  const [localRankingData, setLocalRankingData] = useState(rankingData);


  const NothingDisplay = () => {
    return (
      <Box
        sx={{
          border: "3px solid #eee",
          borderRadius: "10px",
          boxShadow: " 0 3px 10px  #9ecaed;",
        }}
        my={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={4}
          px={4}
        >
          <Grid item xs={12}>
            <Typography variant="h2" color={"white"}>
              未有資料
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };



  // useEffect(() => {
  //   // setLocalSportLeader((pre) => SportLeader); // ken: what's that??? 

  //   // setnIntervId(
  //   const interval = setInterval(() => {
  //     // SportLeaderUpdate();
  //   }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

  //   return () => clearInterval(interval);

  //   // );
  // }, [coSportRankingData]);

  // const SportLeaderUpdate = async () => {
  //   if (!("timeslot_id" in SportLeader)) return;
  //   let result = await AdminApi.loadSingleEventLeaderboardCallback(
  //     eventID,
  //     date,
  //     SportLeader.timeslot_id,
  //     SportLeader.skill_code
  //   );
  //   setLocalSportLeader(result);
  // };

  // const skillName = localCoSportRankingData?.skill_name || "N/A";

  // const LeaderboardListDisplay = () => {
  //   return (
  //     <Grid container justifyContent="center" gap={1} alignItems={"center"} py={4} px={4}>
  //       {localSportLeader.result.map((_r, index) => (
  //         <Grid item key={index} sx={{ width: "32%" }}>
  //           <LeaderDisplay key={`rand-${index}`} content={_r} />
  //         </Grid>
  //       ))
  //       }
  //     </Grid>);
  // }

  const bgColor = themeData.bg || "blue"
  const barColor = themeData.barColor || "red"

  const getBarDataList = () => {
    return localRankingData.map((data, index) => {
      console.debug("data", data)
      return {
        rank: data.rank,
        schoolName: data.organization_name_zh,
        score: Math.round(data.sum_score),
      }
    })
  }

  const calculateBestScore = (dataList) => {
    let bestScore = 1
    for (let data of dataList) {
      let newScore = Math.round(data.sum_score)
      if (newScore > bestScore) {
        bestScore = newScore
      }
    }
    return bestScore
  }

  const bestScore = calculateBestScore(localRankingData)
  const skillName = skillInfo?.name_zh || "N/A"
  const unitStr = skillInfo?.unit || ""


  const dataBarList = getBarDataList().map((data, index) => {
    return (
      <>
        {
          index > 0 ? <Box sx={{ marginTop: 2, marginBottom: 2, width: '100%', background: '#ccc', height: '1px' }}></Box> : <></>
        }
        <CoSportRankingBarItem key={`rand-${index}`}
          barColor={barColor}
          barData={data} bestScore={bestScore} unit={unitStr} />
      </>

    )
  }).slice(0, 5)

  // Ranking Bar Style 
  const barStyle = {
    width: '90%',
    marginTop: 3,
    padding: 2,
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 2,
    border: "2px solid white",
  }

  // console
  console.debug("dataBarList", dataBarList)

  const schoolList = rankingData.map((org, index) => {
    return {
      name: org.organization_name_zh
    }
  })

  return (
    <Box
      align="center"
      pt={2}
      minHeight={"100vh"}
      sx={{
        backgroundImage: `url("${BackgroundMap[bgColor]
          }")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      position="relative"
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        rowSpacing={2}
        pb={4}
        mb={4}
        sx={{
          // transform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg) ",
          // WebkitTransform: "rotateZ(-5deg) skewX(-5deg)" /* Safari & Chrome */,
          // MozTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Firefox */,
          // MsTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Internet Explorer */,
          // OTransform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Opera */,
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Box
            sx={{
              borderBottom: "2px solid #eee",
              boxShadow: " 0 3px 2px -2px  #9ecaed;",
              width: "fit-content",
            }}
          >
            <Typography
              variant={"h2"}
              color={"WHITE"}
              sx={{
                fontFamily: MAIN_FONT,
                fontSize: "68px",
                fontWeight: "900",
                lineHeight: "60px",
                letterSpacing: "0em",
              }}
            >
              共創運動紀錄 (內部查閱)
            </Typography>

          </Box>
          <Typography
            variant={"h3"}
            color={"white"}
            sx={{
              fontFamily: MAIN_FONT,
              fontSize: "68px",
              fontWeight: "900",
              lineHeight: "60px",
              letterSpacing: "0em",
            }}
          >
            {skillName || "N/A"}
          </Typography>
        </Grid>
        { /* Ranking Bar */}
        <Box sx={barStyle}>
          <Stack>
            {dataBarList}
          </Stack>
        </Box>

        <Box sx={{
          width: '90%',
          marginTop: 3,
        }}>
          <CoSportSchoolListCard orgType={"secondary"} schoolList={schoolList} />
        </Box>
      </Grid>
    </Box>
  );
}
