import { Box, Typography, Grid, Divider, Stack } from "@mui/material";
import badgeImageGold from "../../../public/rankingIcons/crown_gold.svg";
import badgeImageSilver from "../../../public/rankingIcons/crown_silver.svg";
import badgeImageBronze from "../../../public/rankingIcons/crown_bronze.svg";


const MAIN_FONT = "Noto Sans TC";

// const displayType = "name";
const displayType = "groupNum";

const getMembersInfo = (members) => {
  if(members == null || members == undefined) {
    return ""
  }

  let infoList = members.map((m) => {
    if(displayType == "groupNum") {
      return m.group + m.group_num;
    }
    return m.name;
  });
  
  return infoList.join(", ")
}
const getBadgeImage = (rank) => {
  if (rank === 1) {
    return badgeImageGold;
  }

  if (rank === 2) {
    return badgeImageSilver;
  }

  if (rank === 3) {
    return badgeImageBronze;
  }
  return null;
}


function MedalIcon({ rank }) {
  const imageSrc = getBadgeImage(rank)
  return imageSrc ? (
    <Box
      component="img"
      src={imageSrc}
      maxWidth={25}
      maxHeight={25}
    />
  ) : <></>;
}


export default function Component({ content, overall = false }) {
  const StringMap = {
    // 1: "金獎",
    // 2: "銀獎",
    // 3: "銅獎",
  };

  const OverallLeaderboardItem = () => {
    let { rank, score, unit, members } = content;

    console.debug("members: ", members);
    let memberListStr = getMembersInfo(members);

    const headingStr = `第${rank}名 (${score} ${unit})`;



    return (
      <Stack sx={{ width: '90%' }}
        direction={"column"} justifyContent="center" alignItems={"center"} gap={1}>
        <Box display="flex" sx={{ justifyContent: "center", columnGap: 1, alignItems: "center" }}>
          <MedalIcon rank={rank} />
          <Typography color="#931A00" sx={
            {
              fontFamily: MAIN_FONT, fontSize: "20px", fontWeight: "900",
              letterSpacing: "0em", whiteSpace: "nowrap"
            }
          }>
            {headingStr}
          </Typography>
        </Box>

        <Box sx={{ height: '1px', background: '#aaa', width: '100%' }}></Box>
        <Typography color="#552E00" sx={{ fontFamily: MAIN_FONT, fontSize: "18px", letterSpacing: "0em", }}>
          {memberListStr}
        </Typography>

      </Stack>
    )

  };


  // const valueColor = '#931A00'
  // const nameColor = '#552E00'


  // const barStyle = {
  //   background: "#FFE0C1",
  //   padding: "10px 50px",
  //   border: "8px solid #B96400",
  //   borderRadius: "20px",
  //   width: '90%',
  const cardStyle = {
    border: "6px solid #B96400",
    borderRadius: "20px",
    background: "#FFE0C1",
  }

  return (
    <Box
      sx={cardStyle}
    >
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        py={1}
        px={2}
        width={"30vw"}
      >
        <OverallLeaderboardItem content={content} />
      </Grid>

    </Box>
  );
}
