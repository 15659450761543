import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import MemberQueryCardSection from '../../sections/_dashboard/MemberQueryCardSection';

// ----------------------------------------------------------------------

export default function UserMemberQRPage() {

  return (
    <>
      <title> User Member QR Checking </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Member QR Checking
          </Typography>
          <MemberQueryCardSection />
        </Stack>

      </Container>
    </>
  );
}
