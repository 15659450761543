//import axios from "../helper/AxiosHelper";

const processResponse = (response) => {
  console.log("response: ", response);
  if (response === undefined || response == null) {
    return null;
  }

  

  const responseData = response.data;
  if (responseData === undefined || responseData == null) {
    return null;
  }

  return responseData.result;
}

const ApiHelper = {
  processResponse,
};
export default ApiHelper;