import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import SportDisplay from "../../components/leaderBoard/SportDisplay";
// import { SportDisplay}  from "../../components/app/SportDisplay";
import MockData from "../../helper/MockData";
import { getCurrentDateStr } from "../../helper/StringHelper"; 
const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];

export default function TestSportDisplay() {
    const { t } = useTranslation();

    let lang = getLang();

    let leaderBoardResult = MockData.getOneTimeslotLeaderboard();
    // MockData.getOneTimeslotLeaderboard();

    let eventId = 3;

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TestSportDisplay</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <pre>
                        {/* {JSON.stringify(leaderBoardResult, null, 2)} */}
                    </pre>
                </Box>
                <SportDisplay
                  SportLeader={leaderBoardResult}
                  eventID={eventId}
                  date={TODAY}                  
                />
            </Stack>
        </Container>
    )
}
