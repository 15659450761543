import * as Yup from "yup";
import orgTypeList from "./data/orgTypeList.json";

const FormControl = [
  {
    size: { xs: 12, sm: 6 },
    props: { id: "name_zh", label: "機構名稱 *", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "name_en", label: "Organization Name *", type: "text" },
  },
  {
    props: { id: "address_zh", label: "地址 *", type: "text" },
  },
  {
    props: { id: "address_en", label: "Address *", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: "selector",
    placeHolder: "請選擇您的機構類型",
    props: { id: "type", label: "機構類型 *" },
    options: orgTypeList,
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "phone", label: "機構電話號碼 *", type: "tel" },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "contact_person", label: "機構聯會人 *", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "contact_phone", label: "聯會人電話號碼 *", type: "tel" },
  },
  {
    size: { xs: 12, sm: 12 },
    props: { id: "login_access_token", label: "ACCESS TOKEN", type: "text", readOnly: true },
  },
  {
    size: { xs: 12, sm: 12 },
    props: { id: "direct_access_url", label: "Login URL", type: "text", readOnly: true},
  },
];

const Schema = Yup.object().shape({
  name_zh: Yup.string().required("別忘記輸入機構名稱"),
  name_en: Yup.string().required("Organization Name is required"),
  address_zh: Yup.string().required("別忘記輸入機構地址"),
  address_en: Yup.string().required("Organization English is required"),
  type: Yup.string().required("別忘記選擇機構類型"),
  phone: Yup.string()
    .required("別忘記輸入機構電話號碼")
    .matches(/^[0-9]+$/, "只接受數字")
    .min(8, "只接受8位電話號碼")
    .max(8, "只接受8位電話號碼"),
  contact_person: Yup.string().required("別忘記輸入機構聯會人"),
  contact_phone: Yup.string()
    .required("聯會人電話號碼")
    .matches(/^[0-9]+$/, "只接受數字")
    .min(8, "只接受8位電話號碼")
    .max(8, "只接受8位電話號碼"),
});

const FormikInstance = {
  initialValues: {
    name_zh: "",
    name_en: "",
    address_zh: "",
    address_en: "",
    type: "",
    phone: "",
    contact_person: "",
    contact_phone: "",
    login_access_token: "",
    direct_access_url: "",
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
