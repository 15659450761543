import * as Yup from "yup";
import orgTypeList from "./data/orgTypeList.json";
import genderList from "./data/genderList.json";
import moment from "moment";
const FormControl = [
  {
    size: { xs: 12 },
    props: { id: "display_name", label: "名稱 *", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: "selector",
    props: { id: "gender", label: "性別 *", type: "text" },
    options: genderList,
  },
  {
    size: { xs: 12, sm: 6 },
    variant: "date",
    props: {
      id: "dob",
      label: "生日日期 *",
      inputFormat: "YYYY/MM/DD",
      views: ["year", "month", "day"],
    },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "email", label: "電郵地址 *", type: "email" },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "member_id", label: "會員編號 / EclassID", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    props: {
      id: "password",
      label: "密碼（密碼必須包含大小寫英文字母及數字）*",
      type: "password",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "repeatPassword", label: "再次輸入密碼 *", type: "password" },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: "selector",
    placeHolder: "請選擇您的機構類型",
    props: { id: "user_type", label: "會員類型 *" },
    options: orgTypeList,
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "phone_num", label: "手提電話號碼 *", type: "tel" },
  },

  {
    size: { xs: 6 },
    props: { id: "group", label: "組別 *", type: "text" },
  },
  {
    size: { xs: 6 },
    props: { id: "group_num", label: "組別編號 / 班號 *", type: "text" },
  },
];

const Schema = Yup.object().shape({
  display_name: Yup.string().required("別忘記輸入名稱"),
  gender: Yup.string().required("別忘記選擇性別"),
  dob: Yup.date().required("別忘記選擇生日日期"),
  password: Yup.string()
    .required("別忘記輸入密碼")
    .min(8, "密碼長度至少8個字元")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "密碼必須包含大小寫英文字母及數字"
    ),
  repeatPassword: Yup.string()
    .required("別忘記輸入確認密碼")
    .oneOf([Yup.ref("password")], "密碼不一致"),
  user_type: Yup.string().required("別忘記選擇會員類型"),
  phone_num: Yup.string()
    .required("別忘記輸入電話號碼")
    .matches(/^[0-9]+$/, "只接受數字")
    .min(8, "只接受8位電話號碼")
    .max(8, "只接受8位電話號碼"),
  email: Yup.string().required("別忘記輸入電郵地址").email("請輸有效電郵地址"),
  group: Yup.string().required("別忘記輸入組別"),
  group_num: Yup.string().required("別忘記輸入組別編號"),
});

const FormikInstance = {
  initialValues: {
    display_name: "",
    gender: "M",
    dob: moment(new Date()).format("YYYY/MM/DD"),
    password: "",
    repeatPassword: "",
    user_type: "",
    member_id: "",
    phone_num: "",
    email: "",
    group: "",
    group_num: "",
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
