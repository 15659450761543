import { useEffect } from "react";

import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactSwal from "../helper/AlertHelper";
import { AdminApi } from '../api/AdminApi';
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../reducers/userSlice";
import { setLocalStorageUser } from "../helper/LocalStorageHelper";
import { useLocation } from 'react-router-dom';


// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function DirectSchoolLoginPage() {
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();
  // const queryParams = useSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  
  console.debug("searchParams: ", searchParams);



  // const handleLogin = async (account, password) => {
  //   // console.debug("onSubmit: ", values);

  //   const result = await AdminApi.login(account, password);

  //   // result.jwt = null;
  //   let jwt = result.jwt;
  //   let userInfo = result.user;

  //   if (jwt === undefined || jwt == null) {
  //     // alert("Login failed");

  //     // ken: simplify the logic 
  //     ReactSwal.fire({
  //       title: "登入錯誤",
  //       html: "系統錯誤",
  //     });
  //     return;
  //   }

  //   if (userInfo === undefined || userInfo == null) {
  //     ReactSwal.fire({
  //       title: "登入錯誤",
  //       html: "系統錯誤",
  //     });
  //     return;
  //   }




  //   dispatch(updateUserInfo(userInfo, jwt));
  //   setLocalStorageUser(userInfo);
  //   console.debug("jwt: ", jwt);
  //   console.debug("userInfo: ", userInfo);

  //   // focus update the react app
  //   window.location.href = "/";
  // }

  const processTokenLogin = async (token) => {
    const result = await AdminApi.loginWithAccessToken(token);

    let jwt = result.jwt;
    let userInfo = result.user;
    let organizatoinInfo = result.organization;
    // console.debug("organizatoinInfo: ", organizatoinInfo);

    userInfo.orgType = organizatoinInfo?.type || "";
    userInfo.orgNameZh = organizatoinInfo?.name_zh || "";
    userInfo.orgNameEn = organizatoinInfo?.name_en || "";


    if (jwt === undefined || jwt == null) {
      // alert("Login failed");

      // ken: simplify the logic 
      ReactSwal.fire({
        title: "登入錯誤",
        html: "系統錯誤 (Missing JWT)",
      });
      return;
    }

    if (userInfo === undefined || userInfo == null) {
      ReactSwal.fire({
        title: "登入錯誤",
        html: "系統錯誤 (Missing UserInfo)",
      });
      return;
    }

    dispatch(updateUserInfo(userInfo, jwt));
    setLocalStorageUser(userInfo);
    console.debug("jwt: ", jwt);
    console.debug("userInfo: ", userInfo);

    // focus update the react app
    window.location.href = "/";

  }


  useEffect(() => {
    console.debug("DirectSchoolLoginPage: initializating");
    console.debug("token=", token);
    // 
    processTokenLogin(token);
  }, []);


  return (
    <>
      <title> Login | VTC Smart Gym App </title>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h3" paragraph textAlign="center">
              Loading in Progress
            </Typography>
            {
              token
                ?
                <Typography variant="paragraph1" paragraph textAlign="center">
                  Please wait...
                </Typography>
                :
                <Typography variant="paragraph1" paragraph textAlign="center">
                  Error Token is not found
                </Typography>
            }
            {/* <pre>
              {
                JSON.stringify(token)
              }
            </pre> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
