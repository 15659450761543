import React, { useState, useEffect } from "react";
/** Helper */
import {
  isError,
  getErrorMsg,
  validateForm,
  FormType,
} from "../../../helper/FormHelper.jsx";
// import { postMultiDataRequestPromise } from "@/helper/AxiosHelper";
import palette from "../../../theme/palette";
import { toast } from "react-toastify";

/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Avatar,
  ButtonBase,
  Badge,
  IconButton,
  InputAdornment,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@mui/material";
/** Content */
/** Icon */

import ImageIcon from "@mui/icons-material/Image";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const getColumnSize = (formItem) => {
  if (!("size" in formItem)) {
    return { xs: 12, sm: 6 };
  }
  if (typeof formItem.size === "object" && formItem.size !== null)
    return formItem.size;

  return null;
};

const FormikTextField = ({ formik, elementContent }) => {
  return (
    <TextField
      {...elementContent.props}
      value={formik.values[elementContent.props.id] ?? ""}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      error={isError(formik, elementContent.props.id)}
      helperText={getErrorMsg(formik, elementContent.props.id)}
      fullWidth
    />
  );
};

const FormikSingleSelector = ({ formik, elementContent }) => {
  return (
    <FormControl fullWidth error={isError(formik, elementContent.props.id)}>
      <InputLabel>{elementContent.props.label}</InputLabel>
      <Select
        {...elementContent.props}
        fullWidth
        value={formik.values[elementContent.props.id]}
        onChange={(event) => {
          formik.setFieldValue(elementContent.props.id, event.target.value);
        }}
        onBlur={(event) => {
          formik.touched[elementContent.props.id] = true;
        }}
      >
        <MenuItem value={""} disabled>
          {"Please select"}...
        </MenuItem>
        {elementContent.additionInfo.options.map((_option, index) => (
          <MenuItem key={`selection-${index}`} value={_option.value}>
            {_option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {getErrorMsg(formik, elementContent.props.id)}
      </FormHelperText>
    </FormControl>
  );
};

const FormikImageUploader = ({ formik, elementContent }) => {
  const [selectedImage, setSelectedImage] = useState(
    "/default/default_image.png"
  );

  useEffect(() => {
    let file = formik?.values[elementContent.props.id];
    if (!file) return;
    if (typeof file === "string") setSelectedImage(file);
  }, [formik.values[elementContent.props.id]]);

  const id = `image-upload-${elementContent.props.id}`;
  const handleImageChange = (event) => {
    let file = event.target.files[0];
    console.log(file);
    if (!file) return;
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    formik.setFieldValue(elementContent.props.id, file);
  };

  const handleRemoveImage = () => {
    setSelectedImage("/default/default_image.png");
    formik.setFieldValue(elementContent.props.id, null);
  };

  return (
    <Grid container direction="column" gap={2} sx={{ borderShadow: 13 }} p={1}>
      <input
        accept="image/*"
        id={id}
        type="file"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <label htmlFor={id} style={{ cursor: "pointer", background: "#F4F6F8" }}>
        <Box width={"100%"} height={"100%"} position={"relative"}>
          <Box
            component={"img"}
            src={selectedImage}
            sx={{
              objectFit: "contain",
              objectPosition: "center",
              width: "100%",
              height: "600px",
            }}
          />
          <Box position={"absolute"} bottom={0} right={0}>
            <IconButton onClick={handleRemoveImage}>
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Box>
      </label>
      <Grid item>
        <TextField
          {...elementContent.props}
          disabled
          sx={{ bgcolor: "#FFF", color: "" }}
          value={formik?.values[elementContent.props.id]?.name || ""}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ImageIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default function CommonForm({
  fromContent,
  formik,
  submitUrl = null,
  onSuccessExtentAction = () => {},
  debug = false,
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <Grid container spacing={3}>
      <Backdrop
        open={isProcessing}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {fromContent.FormControl.map((_formItem, index) => {
        const columnSize = getColumnSize(_formItem);
        return (
          <Grid item key={`form-item-${index}`} {...columnSize}>
            {_formItem.variant === FormType.IMAGE_UPLOAD ? (
              <FormikImageUploader formik={formik} elementContent={_formItem} />
            ) : null}
            {_formItem.variant === FormType.INPUT ? (
              <FormikTextField formik={formik} elementContent={_formItem} />
            ) : null}
            {_formItem.variant === FormType.SINGLE_SELECTOR ? (
              <FormikSingleSelector
                formik={formik}
                elementContent={_formItem}
              />
            ) : null}
          </Grid>
        );
      })}

      {debug ? (
        <Grid item xs={12}>
          <pre>{JSON.stringify(formik.values, null, 2)}</pre>
        </Grid>
      ) : null}
    </Grid>
  );
}
