import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const mainField = "id";

const dataFieldConfig = [
  {
    id: "skill_code",
    field: "skill_code",
    align: "center",
    disablePadding: false,
    label: "SKILL",
    fieldAction: () => {},
  },
  {
    id: "name_en",
    field: "name_en",
    align: "center",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "address_zh",
    field: "address_zh",
    align: "center",
    disablePadding: false,
    label: "地址",
  },
  {
    id: "address_en",
    field: "address_en",
    align: "center",
    disablePadding: false,
    label: "Address",
  },
  {
    id: "phone",
    field: "phone",
    align: "center",
    disablePadding: false,
    label: "電話號碼",
  },
  {
    id: "contact_person",
    field: "contact_person",
    align: "center",
    disablePadding: false,
    label: "聯絡人",
  },
  {
    id: "contact_phone",
    field: "contact_phone",
    align: "center",
    disablePadding: false,
    label: "聯絡人電話",
  },
];

const fetchDataConfig = {
  // url: "/organization/query",
  url: "/memberSkillData/query",
  // fixedFilter: [{ field: "name_en,name_zh", value: '', op: "like" }],
  // extraQuery: [{ key: "org", field: "orgId" }],
  // fixedFilter: [],
};

const tableActionConfig = [
  {
    type: "link",
    label: "noIcon",
    icon: "view",
    // icon: EmojiEventsIcon,
    url: "/organization/detail/$id",
  },
  {
    type: "link",
    label: "Edit",
    icon: "detail",
    // icon: EmojiEventsIcon,
    url: "/organization/detail/$id",
  },
  {
    type: "link",
    label: "Edit",
    icon: EmojiEventsIcon,
    url: "/organization/$id/events",
  },
  {
    type: "link",
    label: "member",
    icon: PersonIcon,
    url: "/organization/$id/members",
  },
  {
    type: "confirmDialog",
    label: "Delete",
    icon: "delete",
    url: "/organization/delete/$id", //api url
    dialogConfig: {
      /** https://sweetalert2.github.io/#usage */
      title: "確定要刪除嗎？",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "確定",
      cancelButtonAriaLabel: "取消",
      cancelButtonColor: "#a7a7a7",
      successMsg: "刪除成功",
      failMsg: "刪除失敗",
    },
    // icon: EmojiEventsIcon,
  },
];

const filterConfig = [
  { type: "text", label: "按 名稱 搜索", field: "name_zh,name_en" },
    // { type: "text", label: "按 名稱 搜索", field: "name_zh,name_en" },
];

const TableConfig = { 
  mainField,
  dataFieldConfig,
  fetchDataConfig,
  tableActionConfig,
  filterConfig,
};

export default TableConfig;
