import React, { memo, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  Typography,
  Box,
} from "@mui/material";

const EquipmentCoverImageUploader = memo(
  ({ existingImagePath = null, onImageUpload = () => {} }) => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);

    const handleImageUpload = (event) => {
      const selectedImage = event.target.files[0];
      if (!selectedImage.type.startsWith("image/")) {
        setError("Please select an image file");
        setImage(null);
      } else {
        setImage(selectedImage);
        setError(null);
      }
    };

    const handleImageSubmit = (_image) => {
      const reader = new FileReader();
      reader.readAsDataURL(_image);
      reader.onload = () => {
        onImageUpload(reader.result);
      };
    };

    useEffect(() => {
      if (image === null) return;
      handleImageSubmit(image);
    }, [image]);

    return (
      <Paper sx={{ p: 2 }}>
        {existingImagePath && image === null && (
          <div>
            <img
              src={existingImagePath}
              alt="Existing image"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}

        <FormControl sx={{ mb: 2 }} error={!!error}>
          <Input
            type="file"
            id="equipment-cover-image-upload-input"
            accept=".png,.jpg,.jpeg"
            onChange={handleImageUpload}
            disableUnderline
          />
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>

        {image && (
          <div>
            <Box
              component={"img"}
              src={URL.createObjectURL(image)}
              alt="New image preview"
              style={{
                maxWidth: "500px",
                height: "auto",
                maxHeight: "400px",
                objectFit: "contain",
              }}
            />
          </div>
        )}
      </Paper>
    );
  }
);

export default EquipmentCoverImageUploader;
