import {
    Container,
    Typography,
    Box,

} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MemberEventSkillInfoCard from "../../components/app/MemberEventSkillInfoCard";
import MockData from "../../helper/MockData";

export default function TestEventSkillInfoCard() {
    const { t } = useTranslation();

    let lang = getLang();

    const memberSkillInfo = MockData.getMemberEventInfo().skillInfoList[0];

    return (
        <Container maxWidth="lg">
            <Typography variant="h3">TestEventSkillInfoCard</Typography>


            <div>
                <Box>
                <MemberEventSkillInfoCard memberEventInfo={memberSkillInfo} gender='F' />
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <pre>
                        {JSON.stringify(memberSkillInfo, null, 2)}
                    </pre>
                </Box>
            </div>
        </Container>
    )
}
