import {
    Container,
    Typography,
    Box,
    Divider,

} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import QrReader from 'modern-react-qr-reader'

import { useState } from 'react';

export default function TestSimple() {
    const { t } = useTranslation();
    const [data, setData] = useState('No result');

    const [error, setError] = useState(null);

    let lang = getLang();

    const handleError = (err) => {
        setError(err);
    }

    const handleScan = (data) => {
        if (data) {
            setData(data);
            // this.setState({ showCam: false });
        }
    };

    // Reference 
    //   

    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test QRCode</Typography>


            <div>
                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <Divider />
                    <Box component={QrReader}
                        // onResult={(result, error) => {
                        //     // console.debug("result=", result);
                        //     // console.debug("error=", error);
                        //     if (!!result) {
                        //         setData(result?.text);
                        //     }
                        //     setError(error ?? "No result yet");
                        // }}
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        constraints={{
                            facingMode: 'environment',
                        }}
                        sx={{ width: 300, height: 'auto' }} />
                    <pre>
                        Result:
                        {JSON.stringify(data, null, 2)}
                    </pre>

                    <pre>
                        Error:
                        {JSON.stringify(error, null, 2)}
                    </pre>
                </Box>
            </div>
        </Container>
    )
}
