import React, { useEffect } from "react";
import { Grid, Card, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { dateValueToTimeStr } from "../../helper/StringHelper";
import CommonDataSelection from "../common/CommonDataSelection/CommonDataSelection";
import CommonDatePicker from "../common/CommonDatePicker/CommonDatePicker";

function getDefaultEventID(eventList) {
    if (eventList.length > 0) {
        return eventList[0].id;
    }

    return 0;
}

function getEventFromList(eventList, eventID) {
    for (let event of eventList) {
        if (event.id === eventID) {
            return event;
        }
    }

    return null;
}

export default function EventStatisticQueryForm({ eventList, onQuery }) {
    const [eventID, setEventID] = React.useState(-1); // getDefaultEventID(eventList));
    const [event, setEvent] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);

    const [timeslotList, setTimeslotList] = React.useState([]);
    const [timeslotID, setTimeslotID] = React.useState(0);

    const [skillList, setSkillList] = React.useState([]);
    const [skillID, setSkillID] = React.useState(0);

    useEffect(() => {
        console.debug("Event Object is changed: ", event);
        if (event != null) {
            setTimeslotList(event.timeslotList);
            setSkillList(event.leaderboardSetting);
        }
    }, [event]);

    useEffect(() => {
        setTimeslotID(0);
    }, [timeslotList]);

    useEffect(() => {
        setSkillID(0);
    }, [skillList]);

    useEffect(() => {
        const initialEventID = getDefaultEventID(eventList);
        setEventID(initialEventID);
        var event = getEventFromList(eventList, initialEventID);
        setEvent(event);

    }, []);

    const onEventChanged = (evt) => {
        setEventID(evt.target.value);

        var event = getEventFromList(eventList, evt.target.value);
        setEvent(event);

    };

    const onTimeslotChanged = (evt) => {
        setTimeslotID(evt.target.value);
    };

    const onQueryClicked = () => {
        if (onQuery != null) {
            onQuery({ eventID, timeslotID, skillID, selectedDate });
        }
    }

    // skill control
    const onDateChanged = (date) => {
        setSelectedDate(date);
    }

    const onSkillChanged = (evt) => {
        setSkillID(evt.target.value);
    };

    const getMinDate = () => {
        if (event != null) {
            return event.start_at;
        }
    }

    const getMaxDate = () => {
        if (event != null) {
            return event.end_at;
        }
    }

    const minDate = new Date("2023-05-05");
    const maxDate = new Date("2023-05-20");


    return (<Card>
        <Box sx={{ padding: 2 }}>
            {/* <Typography variant="h5">Event Leaderboard</Typography> */}
            {/* {eventSelection} */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <CommonDataSelection
                        key="event"
                        label="活動"
                        value={eventID}
                        dataList={eventList}
                        captionFn={(item) => item.name_zh}
                        onChange={onEventChanged}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CommonDatePicker dateValue={selectedDate}
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        onChange={onDateChanged}
                    ></CommonDatePicker>
                </Grid>
                {/* <Grid item xs={12} sm={6}>

                    <CommonDataSelection
                        key="skill"
                        label="技能"
                        value={skillID}
                        allOption={true}
                        dataList={skillList}
                        captionFn={(item) => item.skill_name}
                        onChange={onSkillChanged}
                    />

                </Grid> */}
                <Grid item xs={12} sm={2}>
                    <Button variant="contained" onClick={onQueryClicked}>查詢</Button>
                </Grid>
            </Grid>




        </Box>
        <pre>
            {/* DEBUG: eventID={eventID} timeslot: {timeslotID} skill: {skillID} selectedDate: {dateValueToTimeStr(selectedDate)} 
            <br/>
            {JSON.stringify(eventList, null, 2)} */}
        </pre>
    </Card>);
}