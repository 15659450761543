import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import CoSportRankingCarousel from "../../components/leaderBoard/CoSportRankingCarousel";

export default function TestCoSportCarousel() {
    const { t } = useTranslation();

    let lang = getLang();

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <CoSportRankingCarousel />
            </Stack>
        </Container>
    )
}
