import React from "react";
/** Helper */
import FormContent from "../../components/organization/orgFormSchema.jsx";

import {
  isError,
  getErrorMsg,
  validateForm,
} from "../../helper/FormHelper.jsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { postDataRequest } from "../../helper/AxiosHelper";
import { useNavigate } from "react-router-dom";

/** Component */
import {
  Box,
  Grid,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
} from "@mui/material";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
/** Content */

/** API */

export default function Component() {
  // navigate
  const navigate = useNavigate();
  const formik = useFormik(FormContent.FormikInstance);
  //create function
  const handleCreateOrg = async (e) => {
    if (await validateForm(formik)) return;
    let createData = JSON.parse(JSON.stringify(formik.values));

    postDataRequest("organization/create", createData, (isSuccess, data) => {
      if (!isSuccess) {
        return toast.error("新增失敗");
      }

      toast.success("新增成功");
      setTimeout(() => navigate("/organizations"), 1500);

      return;
    });
  };
  return (
    <Container maxWidth={"lg"}>
      <CommonBreadcrumb />
      <Box component="form" sx={{ mt: 3 }}>
        <Paper sx={{ px: 3, py: 5 }} elevation={3}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            {FormContent.FormControl.map((item, index) => {
              let gridStyle = "size" in item ? item.size : { xs: 12 };
              let formControlProps = item.props;
              if (item.variant === "selector") {
                return (
                  <Grid item key={`form-section-${index}`} {...gridStyle}>
                    <FormControl
                      fullWidth
                      error={isError(formik, formControlProps.id)}
                    >
                      <InputLabel>{formControlProps.label}</InputLabel>
                      <Select
                        {...formControlProps}
                        fullWidth
                        value={formik.values[formControlProps.id]}
                        onChange={(event) => {
                          formik.setFieldValue(
                            formControlProps.id,
                            event.target.value
                          );
                        }}
                        onBlur={(event) => {
                          formik.touched[formControlProps.id] = true;
                        }}
                      >
                        <MenuItem value={""} disabled sx={{ ml: 2 }}>
                          {item.placeHolder}
                        </MenuItem>
                        {item.options.map((_area, index) => (
                          <MenuItem key={`area-${index}`} value={_area.value}>
                            {_area.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {getErrorMsg(formik, formControlProps.id)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                );
              }
              if (item.variant === "radio") {
                return <></>;
              }

              return (
                <Grid item key={`form-section-${index}`} {...gridStyle}>
                  <TextField
                    {...formControlProps}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={isError(formik, formControlProps.id)}
                    helperText={getErrorMsg(formik, formControlProps.id)}
                    fullWidth
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ maxWidth: { xs: "auto", md: "150px" } }}
                onClick={handleCreateOrg}
                disabled={!formik.isValid}
              >
                註冊
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
