import {
    Container,
    Typography,
    Box,
    Card,
    Stack,
    CardContent,
} from "@mui/material";
import { useState } from "react";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import OrganizationDropdown from "../../components/organization/OrganizationDropdown";

export default function TestOrganizationDropdown() {
    const { t } = useTranslation();
    const [ organization, setOrganization ] = useState(null);
    
    let lang = getLang();

    const onOrganizationSelected = (org) => {
        console.debug("onOrganizationSelected=", org);
        // setOrg(org);
        setOrganization(org);
    }
    // setTestValue(1234);

    // setOrg(null);
    // console.debug("org=", org, " setOrg=", setOrg);

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Simple</Typography>


                <OrganizationDropdown onOrganizationSelected={onOrganizationSelected} />

                <h2>Selected Org:</h2>
                <pre>
                    {JSON.stringify(organization, null, 2)}
                </pre>
                
            </Stack>
        </Container>
    )
}
