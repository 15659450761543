import React from 'react';
// import { Avatar } from '@material-ui/core';
import {
    Avatar,    
    Box,
} from "@mui/material";
// import { AccountCircle } from '@material-ui/icons';


const RoundIcon = ({
    size, bgColor, color, padding, component
}) => {

    console.debug("component=", component)

    size = size || 100;
    color = color || "#fff";
    // color = 'red';

    bgColor = bgColor || '#555';
    padding = padding || 1;

    const iconStyle = {
        width: '100%',
        height: '100%',
        padding: padding,
        color: color,
        // color: "#fff",
        // background: bgColor,
    }

    return (
        <Avatar sx={{width:size, height:size, background: bgColor}}>
            {/* {buildIcon(icon, color)} */}
            {
                component ? 
                 <Box component={component} sx={iconStyle} />
                 : <Box></Box>
            }
            
        </Avatar>
    );

}

export default RoundIcon;


