import React, { useState, useEffect, memo } from "react";
/** Helper */
import { useLocation } from "react-router-dom";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Breadcrumbs,
  Link,
  Paper,
} from "@mui/material";
/** Content */
import navMap from "./siteMap.json";

/** Function */

const getBreadcrumbContent = (path) => {
  let pathArr = path
    .split("/")
    .filter((path) => path !== "")
    .map((path) => path.replace("/", ""));

  var output = [];
  var parentNode = JSON.parse(JSON.stringify(navMap));
  pathArr.forEach((path) => {
    let navNode = findNode(path, parentNode);
    if (navNode) {
      // switch the parentNode to the current node
      parentNode = navNode;
      // handle the case when the path is having extra parma
      let splitPath = navNode.label.split("/");

      if (splitPath.length <= 1) {
        navNode.path =
          typeof navNode.path == "object" ? navNode.path.shift() : "";
        return output.push(navNode);
      }

      splitPath.forEach((path) => {
        let tempNode = JSON.parse(JSON.stringify(navNode));
        tempNode.label = path.replace("/", "");

        tempNode.path =
          typeof navNode.path == "object" ? navNode.path.shift() : "";
        output.push(tempNode);
      });
    }

    return;
  });
  // console.log("FOUNDED - ", output);
  return output;
};

const findNode = (key, parentNode = {}) => {
  // console.group();
  // console.log("key - ", key);
  // console.log("parentNode - ", parentNode);
  // console.log("typeof parentNode - ", typeof parentNode);
  // console.groupEnd();

  if (key === undefined) return null;
  let navNode = parentNode[key];
  if (navNode === undefined && parentNode != null && "sub" in parentNode) {
    // console.log("NOT FIND HAVE SUB - ", parentNode.sub);
    return findNode(key, parentNode.sub);
  }
  return navNode;
};

function formatLink(url, dbData = null) {
  let urlTemplate = url;
  let parmList = urlTemplate.split("/");
  parmList = parmList.map((parm) => {
    if (parm.startsWith("$")) {
      let field = parm.replace("$", "");
      return dbData && dbData[field] ? dbData[field] : null;
    }
    return parm;
  });
  return parmList.join("/");
}

const CommonBreadcrumb = memo(({ dbData = null }) => {
  const location = useLocation();
  const breadcrumbContent = getBreadcrumbContent(location.pathname);

  const BreadcrumbNode = ({ label = "", link = "", index = 0 }) => {
    if (label && label.startsWith("$") && dbData)
      label = dbData[label.replace("$", "")];
    if (label === undefined || label.startsWith("$")) label = "-";

    if (!link || link === "" || breadcrumbContent.length === index + 1) {
      return (
        <Typography fontWeight={"bold"} color="info">
          {label}
        </Typography>
      );
    }
    return (
      <Link underline="hover" color="inherit" href={formatLink(link, dbData)}>
        {label}
      </Link>
    );
  };
  return (
    <>
      {/* <pre>{JSON.stringify(breadcrumbContent, null, "")}</pre> */}
      <Paper sx={{ px: 3, pt: 3, pb: 1 }} elevation={3}>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbContent.map((node, index) => {
            return (
              <BreadcrumbNode
                key={`bc-${index}`}
                label={node.label}
                link={node.path}
                index={index}
              />
            );
          })}
        </Breadcrumbs>
        {/* <Divider /> */}
      </Paper>
    </>
  );
});
export default CommonBreadcrumb;
