
import React, { useEffect, useState } from "react";
import { Divider, Grid, Stack, Card, Box, Button, TextField, Typography, CardContent } from "@mui/material";
import { toast } from "react-toastify";
import { AdminApi } from "../../api/AdminApi";

import RedemptionQRCard from "../../components/redemption/RedemptionQRCard";
import RedemptionInfoCard from "../../components/redemption/RedemptionInfoCard";

export default function CapsuleRedemption({ }) {
    const [member, setMember] = useState(null);
    const [eventID, setEventID] = useState(41);     // 41: Capsule Event Hoshun 
    const [redemptionInfo, setRedemptionInfo] = useState(null);
    const [skillDetails, setSkillDetails] = useState(null);
    // const []

    const handleQRCodeUpdated = (qrCode) => {
        
        console.debug("handleQRCodeUpdated called: ", qrCode);

        loadRedemptionInfo(qrCode);
    }

    const loadRedemptionInfo = async (qrCode) => {
        let result = await AdminApi.getEventRedemptionDetailByQRCode(eventID, qrCode);
        // let result = await AdminApi.getRedemptionInfoByQRcode(qrCode);
        console.debug("loadRedemptionInfo: ", result);

        if(result === null) {
            toast.error("QRCode 不存在");
            return;
        }

        setMember(result.member);
        setRedemptionInfo(result.redeem_status);
        setSkillDetails(result.skill_details);
    }

    const handleRedeemClicked = async () => {
        console.debug("handleRedeemClicked called");
        if(redemptionInfo === null) {
            toast.error("請先掃描QRCode");
            return;
        }

        if(redemptionInfo.status !== "ready") {
            toast.error("未可換領");
            return;
        }
    
        let result = await AdminApi.markRedeemedByID(redemptionInfo.id);
        console.debug("handleRedeemClicked: ", result);
        // let result = AdminApi.redeemRedemptionInfo(redemptionInfo.id);
        let newRedemptionInfo = {...redemptionInfo};
        newRedemptionInfo.status = "redeemed";
        newRedemptionInfo.updated_at = new Date().toISOString();

        setRedemptionInfo(newRedemptionInfo);
    }

    return (
        <Stack direction="column" spacing={2}>
            <RedemptionQRCard onQRCodeUpdated={handleQRCodeUpdated} 
            caption={"請掃描/輸入 同學QRCode"}
            />

            {
                (member !== null && redemptionInfo !== null) &&
                <RedemptionInfoCard member={member} 
                        redemptionInfo={redemptionInfo} 
                        skillDetails={skillDetails}
                        onRedeemClicked={handleRedeemClicked}/>
            }

            {
                (member === null || redemptionInfo === null) &&
                <Card sx={{ p: 2 }} variant="outlined">
                    <CardContent sx={{ padding: 6 }}>
                        <Typography variant="h5" textAlign="center" sx={{ mb: 2 }}>
                            請掃描 用戶QRCode 獲取換領資訊
                        </Typography>
                    </CardContent>
                </Card>
            }
        </Stack>
    )
}