import { Navigate, useRoutes } from "react-router-dom";
/** Layout */
import DashboardLayout from "../layouts/dashboard";
/** Organization */
import EquipmentCategory from "../views/equipment/equipmentCategory.jsx";
import EquipmentList from "../views/equipment/equipmentList.jsx";
import EquipmentDetail from "../views/equipment/equipmentDetail.jsx";
import EquipmentCreate from "../views/equipment/equipmentCreate.jsx";
import {auth} from "./routerHelper";


const routes = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "equipment-categories",
      element: auth(<EquipmentCategory />),
    },
    {
      path: "equipment-category/:cateId/equipments",
      element: auth(<EquipmentList />),
    },
    {
      path: "equipment-category/:cateId/equipment/create",
      element: auth(<EquipmentCreate />),
    },
    {
      path: "equipment/detail/:equId",
      element: auth(<EquipmentDetail />),
    },
  ],
};

export default routes;
