import { useTheme } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Breadcrumbs } from '@mui/material';
import MockData from "../helper/MockData";
import { useState, useEffect } from "react";
import { InfoApi } from "../api/InfoApi";
import ChangeMemberPasswordForm from '../components/tool/ChangeMemberPasswordForm';
// ----------------------------------------------------------------------

export default function ChangeMemberPasswordPage() {
  // const theme = useTheme();
  // const eventList = MockData.getEventList();

  return (
    <>

      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">更改密碼</Typography>
        </Breadcrumbs>
        <ChangeMemberPasswordForm />
      </Container>
    </>
  );
}
