
// ----------------------------------------------------------------------

export default function Breadcrumbs(theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          // backgroundColor: palette.grey[300],
          marginBottom: theme.spacing(2),
        },
      }
    },
  };
}
