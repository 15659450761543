import React, { useEffect } from "react";
import { Grid, TextField, Card, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";
import { dateValueToYYYYMMDD } from "../../helper/TimeHelper";
import CommonDataSelection from "../common/CommonDataSelection/CommonDataSelection";
import CommonDateRangePicker from "../common/CommonDateRangePicker";
import { set } from "date-fns";

function getDefaultEventID(eventList) {
    return -1;  // All 
}

function getEventFromList(eventList, eventID) {
    for (let event of eventList) {
        if (event.id === eventID) {
            return event;
        }
    }

    return null;
}

export default function MemberSkillDataQueryForm({ eventList, onQuery, onExport }) {
    const [eventID, setEventID] = React.useState(-1); // getDefaultEventID(eventList));
    const [event, setEvent] = React.useState(null);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [memberName, setMemberName] = React.useState("");
    const [memberID, setMemberID] = React.useState("");
    const [group, setGroup] = React.useState("");
    const [groupNum, setGroupNum] = React.useState("");


    useEffect(() => {
        const initialEventID = getDefaultEventID(eventList);
        setEventID(initialEventID);
        var event = getEventFromList(eventList, initialEventID);
        setEvent(event);

    }, []);

    const onEventChanged = (evt) => {
        setEventID(evt.target.value);

        var event = getEventFromList(eventList, evt.target.value);
        setEvent(event);

    };

    const createQueryFilter = () => {
        return {
            eventID,
            startDate: dateValueToYYYYMMDD(startDate),
            endDate: dateValueToYYYYMMDD(endDate),
            memberName,
            memberID,
            group,
            groupNum
        }
    };

    const onExportClicked = () => {
        if(onExport != null) {
            onExport(createQueryFilter());
        }
    }


    const onQueryClicked = () => {
        if (onQuery != null) {
            onQuery(createQueryFilter());
        }
    }

    // skill control


    const getEventListWithAll = () => {
        var eventListWithAll = [...eventList];
        eventListWithAll.unshift({ id: -1, name_zh: "全部" });
        return eventListWithAll;
    }

    return (<Card>
        <Box sx={{ padding: 2 }}>
            {/* <Typography variant="h5">Event Leaderboard</Typography> */}
            {/* {eventSelection} */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CommonDataSelection
                        key="event"
                        label="活動"
                        value={eventID}
                        dataList={getEventListWithAll()}
                        captionFn={(item) => item.name_zh}
                        onChange={onEventChanged}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CommonDateRangePicker
                        onChange={([startDate, endDate]) => {
                            console.debug("startDate: ", startDate);
                            console.debug("endDate: ", endDate);
                            setStartDate(startDate);
                            setEndDate(endDate);
                            // var dateStr = `${dateValueToYYYYMMDD(startDate)} ~ ${dateValueToYYYYMMDD(endDate)}`;
                            // setDateRange(dateStr);
                        }}
                    ></CommonDateRangePicker>
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        name="memberName"
                        id="memberName"
                        label="參與者名字"
                        onChange={(evt) => { setMemberName(evt.target.value) }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="memberID"
                        id="memberID"
                        label="EClassID"
                        onChange={(evt) => { setMemberID(evt.target.value) }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="group"
                        id="group"
                        label="組別/班別"
                        onChange={(evt) => { setGroup(evt.target.value) }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="groupNum"
                        id="groupNum"
                        label="班號"
                        onChange={(evt) => { setGroupNum(evt.target.value) }}
                    />
                </Grid>


            </Grid>
            <Grid container spacing={2} pt={1}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={onExportClicked}>匯出</Button>
                    <Button variant="contained" onClick={onQueryClicked}>查詢</Button>                    
                </Grid>
            </Grid>




        </Box>
        <pre>
            {/* DEBUG: eventID={eventID} timeslot: {timeslotID} skill: {skillID} selectedDate: {dateValueToTimeStr(selectedDate)} 
            <br/>
            {JSON.stringify(eventList, null, 2)} */}
        </pre>
    </Card>);
}