import { Box, Grid, Typography, Container, Stack } from "@mui/material";
import { useState } from "react";
import ColorDashCard from "../../common/ColorDashCard";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { BarChart, Bar, Cell, LabelList, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, AreaProps } from 'recharts';
import { min } from "numeric";
import { is } from "date-fns/locale";


const cardStyle = {
  cardColor: "rgba(255, 255, 255, 0.8)",
  iconBgColor: "#ffc",
  iconColor: "#f0f",
  maxWidth: 300,
  minWidth: 300,
}


const mockData =
{
  "skill_code": "balance",
  "sum_score": "141.5",
  "member_count": "2",
  "organization_count": "2"
}

const getBarData = (_performData) => {
  let result = []; // ...mockData];
  if (!_performData) {
    return result;
  }

  console.debug("_performData=", _performData)

  for (let item of _performData) {
    let _min = parseInt(item.min_score);
    let _avg = parseInt(item.avg_score);
    let _max = parseInt(item.max_score);

    // MIN: 1, AVG: 41  MAX: 83
    // MIN: 1, AVG: 40  MAX: 43

    _max = _max - _avg;
    _avg = _avg - _min;

    let newItem = {
      school_cls: item.school_cls,
      min_score: _min,
      max_score: _max,
      avg_score: _avg,
    }
    result.push(newItem);
  }

  return result;
}

const getMainStyle = (isKidTheme) => {
  if (isKidTheme) {
    // return cardStyle;
    return {
      background: "#FFE0C1",
      padding: "10px 50px",
      border: "8px solid #B96400",
      borderRadius: "20px",
    }
  } else {
    return {
      background: "rgba(0, 0, 0, 0.2)",
      padding: "10px 50px",
      borderRadius: "10px",
      border: "2px solid #fff",
    }
  }
}

const getChartStyle = (isKidTheme) => {
  if (isKidTheme) {
    return {
      maxColor: '#0054A7',
      avgColor: '#54AEA2',
      minColor: '#DA7077',
      gridColor: '#999',
      axisColor: 'black',
      labelColor: 'white',
    }
  } else {
    return {
      maxColor: '#FFB506',
      avgColor: '#D0F727',
      minColor: '#55EAF3',
      gridColor: '#555',
      axisColor: '#fff',
      labelColor: 'black',
    }
  }
}

export default function CoSportAnalyticsChart({ performData, theme }) {
  const isKidTheme = theme === "kid" || theme === "primary";

  const barData = getBarData(performData);
  console.debug("barData=", barData)

  const chartStyle = getChartStyle(isKidTheme);

  const maxColor = chartStyle.maxColor;
  const avgColor = chartStyle.avgColor;
  const minColor = chartStyle.minColor;
  const axisColor = chartStyle.axisColor;
  const gridColor = chartStyle.gridColor;
  const labelColor = chartStyle.labelColor;

  const style = getMainStyle(isKidTheme);
  // {
  //   background: "rgba(0, 0, 0, 0.2)",
  //   padding: "10px 50px",
  //   borderRadius: "10px",
  //   border: "2px solid #fff",
  // }

  const labelStyle = {
    width: "100%",
    maxWidth: "200px",
    color: `${ labelColor }`,
    textAlign: "center",
    padding: "4px",
    fontWeight: "bold",
    borderRadius: "5px",
  }
  const maxLabelStyle = {
    background: `${maxColor}`,
    ...labelStyle
  }
  const avgLabelStyle = {
    background: `${avgColor}`,
    ...labelStyle
  }
  const minLabelStyle = {
    background: `${minColor}`,
    ...labelStyle
  }

  return (
    <Box
      sx={style}
      display={"flex"}
      pt={6}
      width="100%"
    >

      <Stack>
        <BarChart
          width={800}
          height={300}
          data={getBarData(performData)}
          title="CoSportAnalyticsChart"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={gridColor} />
          <XAxis dataKey="school_cls" stroke={axisColor} />
          <YAxis stroke={axisColor} />

          <Bar dataKey="min_score" stackId="a" fill={maxColor} barSize={30} />
          <Bar dataKey="avg_score" stackId="a" fill={avgColor} barSize={30} />
          <Bar dataKey="max_score" stackId="a" fill={minColor} barSize={30} />
        </BarChart>
        <Box display="flex" sx={{ padding: 1, justifyContent: "space-around" }}>
          <Typography variant="h6" component="div"
            sx={maxLabelStyle}>
            最大
          </Typography>

          <Typography variant="h6" component="div"
            sx={avgLabelStyle}>
            平均
          </Typography>

          <Typography variant="h6" component="div"
            sx={minLabelStyle}>
            最小
          </Typography>

        </Box>
      </Stack>
    </Box >
  );
}
