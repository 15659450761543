import React, { useState, useEffect } from "react";
/** Helper */
import { useNavigate, useParams } from "react-router-dom";
import { getDataRequest } from "../../helper/AxiosHelper.js";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import CommonTable from "../../components/common/CommonTable/index.jsx";
import CommonFilter from "../../components/common/CommonFilter/index.jsx";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb/index.jsx";

import AddIcon from "@mui/icons-material/Add";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  getlocalStorageUserObject,
} from "../../helper/LocalStorageHelper.js";

/** Content */
import TableConfig from "./UserOrganizationMemberListTableContent.jsx";
export default function Component() {
  // URL
  // let { orgId } = useParams();
  const user = getlocalStorageUserObject();
  let orgId = user.organization_id || -1;

  console.debug("user=", user);

  const [filterQuery, setFilterQuery] = useState([]);
  const [orgData, setOrgData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + orgId);
      }
      let { record } = data;

      setOrgData(record);
    });
  }, []);

  return (
    <Container maxWidth={"lg"}>
      {/* <CommonBreadcrumb dbData={orgData} /> */}

      <Paper sx={{ px: 3, pb: 3, mt: 6 }} elevation={3}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {/* Filter */}
              <Box>
                <CommonFilter
                  filterConfig={TableConfig.filterConfig}
                  emitQueryFn={setFilterQuery}
                />
              </Box>
              {/* Page Action */}
              <Box>
                {/* <Button
                  size="small"
                  variant="contained"
                  endIcon={<AddIcon />}
                  onClick={() =>
                    navigate(`/organization/${orgId}/member/create`)
                  }
                >
                  新增會員
                </Button> */}
                {/* <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  endIcon={<ImportContactsIcon />}
                  onClick={() =>
                    navigate(`/organization/${orgId}/member/import`)
                  }
                  sx={{ ml: 2 }}
                >
                  匯入會員
                </Button> */}
                {/* <Button
                  size="small"
                  variant="contained"
                  color="info"
                  endIcon={<QrCodeIcon />}
                  onClick={() => navigate(`/member-qr?org=${orgId}`)}
                  sx={{ ml: 2 }}
                >
                  QR Code
                </Button> */}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CommonTable
              filterQuery={filterQuery}
              tableConfig={TableConfig}
              urlParma={{ orgId }}
              // debug
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
