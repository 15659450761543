import React, { useState, useEffect } from "react";
/** Helper */
import FormContent from "../../components/organization/orgMemberFormSchema.jsx";
import { postDataRequest, getDataRequest } from "../../helper/AxiosHelper";
import {
  isError,
  getErrorMsg,
  validateForm,

} from "../../helper/FormHelper.jsx";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AdminApi } from "../../api/AdminApi";

import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import OrganizationMissionDetailForm from "../../components/organization/OrganizationMissionDetailForm";

/** Component */
import {
  Box,
  Grid,
  Container,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Breadcrumbs,
  Paper,
  Button,
  BreadcrumbNode,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
/** Content */



const getDefaultMissionSetting = () => {
  return {
    id: 0,
    missionCycle: 'month',
    calcType: 'play_time',
    requireValue: 100,
    rewardPoint: 234,
    skill: "all",
  };
}

export default function Page() {
  // URL
  let { orgId, missionSettingId } = useParams();
  // navigate
  const navigate = useNavigate();
  // Formik
  const formik = useFormik(FormContent.FormikInstance);
  //create function
  const [isLoading, setIsLoading] = useState(true); // ?? 
  const [hasError, setHasError] = useState(false); // ??
  const [errorMessage, setErrorMessage] = useState(""); // ??
  const [activeMissionSettingId, setActiveMissionSettingId] = React.useState(0);
  const [activeOrgId, setActiveOrgId] = useState(0);
  const [orgData, setOrgData] = React.useState(null);
  const [missionSetting, setMissionSetting] = React.useState(null);

  useEffect(() => {
    setupAndLoadData();
  }, []);

  // Loading Organization Data
  useEffect(() => {
    getDataRequest(`organization/show/${orgId}`, (isSuccess, data) => {
      if (!isSuccess) {
        console.log("Data not found with id - " + orgId);
      }
      let { record } = data;

      setOrgData(record);
    });
  }, [activeOrgId]);

  const loadMissionSetting = (_missionSettingId) => {
    AdminApi.getOrgMissionSettingCallback(_missionSettingId, (isSuccess, msg, data) => {
      // console.debug("result: isSuccess=", isSuccess, " msg=", msg, " data=", data);
      setIsLoading(false);

      if (!isSuccess) {
        showError(msg);
        return;
      }

      if (data === null) {
        showError("Data not found");
        return;
      }

      setMissionSetting(data);
    });
  };

  const setupAndLoadData = () => {
    let orgIdValue = parseInt(orgId);
    let missionIdValue = parseInt(missionSettingId);

    // setActiveOrgId(parseInt(orgId));
    // setActiveMissionSettingId(parseInt(missionSettingId));

    if (orgIdValue === undefined || orgIdValue === 0 || isNaN(orgIdValue)) {
      setIsLoading(false);
      showError("Invalid Organization ID");
      return;
    }

    if (missionIdValue === undefined || isNaN(missionIdValue)) {
      setIsLoading(false);
      showError("Invalid Mission Setting ID");
      return;
    }

    // Set the state 
    setActiveOrgId(orgIdValue);
    setActiveMissionSettingId(missionIdValue);

    if (missionIdValue === 0) {
      setMissionSetting(getDefaultMissionSetting());
      setIsLoading(false);
    } else {
      loadMissionSetting(missionIdValue);
    }

    // if (missionIdValue === 0) {
    //   setMissionSetting(getDefaultMissionSetting());
    //   setIsLoading(false);
    // } else {
    //   loadMissionSetting();
    // }


    // if (missionSettingId === undefined || missionSettingId === null) {
    //   setIsLoading(false);
    //   showError("Invalid Mission Setting ID");
    //   return;
    // }



    //  || missionSettingId === undefined) {

    // formik.setValues(data);
    // setDataPadding(false);
  };

  const showError = (msg) => {
    setHasError(true);
    setErrorMessage(msg);
  };

  return (
    <Container maxWidth={"lg"}>
      {/* Backdrop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Error Message */}
      {hasError
        && <Paper sx={{ padding: 3, background: "#fdd" }}>{errorMessage}</Paper>}

      {/*BreadCrumb */}
      <Paper sx={{ px: 3, pt: 3, pb: 1, mb: 3}} elevation={3}>
        <Typography variant="h6" gutterBottom>
          獎勵任務設定
        </Typography>
        {/* <Divider /> */}
      </Paper>


      {/* From */}
      {
        missionSetting &&
        <OrganizationMissionDetailForm orgID={13} inputMission={missionSetting} />
      }
      <pre>
        activeOrgId: {activeOrgId} <br />
        activeMissionSettingId: {activeMissionSettingId} <br />
        missionSettingId: {missionSettingId} <br />
        missionSetitng: {JSON.stringify(missionSetting, null, 2)} <br />
        org: {JSON.stringify(orgData, null, 2)} <br />
      </pre>
    </Container>
  );
}
