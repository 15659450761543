import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={76}
      height={76}
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_8_3816)">
        <rect x={4} y={3.99994} width={72} height={72} rx={8} fill="#FDD501" />
      </g>
      <g filter="url(#filter1_d_8_3816)">
        <path
          d="M26.477 41.732c-1.333-.77-1.333-2.694 0-3.464l18.784-10.845c1.333-.77 3 .193 3 1.732v21.69c0 1.54-1.667 2.502-3 1.732L26.477 41.732z"
          fill="#516AB1"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8_3816"
          x={0}
          y={-0.0000610352}
          width={76}
          height={76}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-4} dy={-4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_3816"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_8_3816"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8_3816"
          x={23.4775}
          y={25.1522}
          width={24.7836}
          height={27.6954}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-2} dy={-2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_3816"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_8_3816"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgComponent;
