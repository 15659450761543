// const defaultLang = "en";   // available languages: en, zh
const defaultLang = "zh-HK";   // available languages: en, zh

export const getLang = () => {
    const lang = localStorage.getItem("lang");
    if(lang === false || lang === null || lang === undefined) {
        return defaultLang;
    }
    return lang;
};

export const localizedName = (dataObject, fieldName) => {

    const lang = getLang();

    const localizedName = lang === "en" ? `${fieldName}_en` : `${fieldName}_zh`;

    return dataObject[localizedName];
};
