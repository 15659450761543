import {
  Container,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import React from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CoSportRankingCarousel from "../components/leaderBoard/CoSportRankingCarousel";
import { useEffect, useState } from "react";

import { AdminApi } from "../api/AdminApi.js";
import { getCurrentDateStr } from "../helper/StringHelper.js";
import { getStoredAccountOrgType } from "../helper/AccountHelper.js";


const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];

export default function Page() {
  const navigate = useNavigate();
  useEffect(() => {
    // let timeslot = searchParams.get("timeslot") || 0;

    // AdminApi.loadEventLeaderboardCallback(eventId, TODAY, timeslot, (data) => {
    //   setLeaderData(data);
    // });
    // return;
    // // const result = await AdminApi.loadEvent(eventId, TODAY, timeslot);
  }, []);


  const handleOnBackClicked = () => {
    console.debug("handleOnBackClicked")
    navigate("/");
  };
  // forceUpdate(n => !n)
  const orgType = getStoredAccountOrgType();
  // console.debug("orgType: ", orgType);

  return (
    <CoSportRankingCarousel onBackClicked={handleOnBackClicked} orgType={orgType} />

  );
}
