import {
    Container,
    Typography,
    Box,

} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import SkillSelectionList from "../../components/app/SkillSelectionList";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";

export default function TestSkillSelectionList() {
    const { t } = useTranslation();

    const skillList = MockData.getSkillInfoList();

    let lang = getLang();

    const handleSelection = (skill) => {
        console.log("handleSelection: ", skill);
    }

    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test Selection List</Typography>



            <Box>
                <SkillSelectionList skillList={skillList} onSelect={handleSelection}/>
                <pre>
                    {JSON.stringify(skillList, null, 2)}
                </pre>
            </Box>

        </Container>
    )
}
