import React, { } from "react";
import { useState } from "react";
import { useTranslation, Translation } from "react-i18next";
import { useFormik } from "formik";
import {
    Stack, TextField, InputAdornment,
    Grid, Container, Box, Button,
    CardContent, Card, Typography,
    OutlinedInput, Paper,
    Divider, FormControl,
} from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
// import Typography from "../../theme/overrides/Typography";

export default function SponsorLogos() {
    const images = [
        "Kittenbot.webp",
        "Move-it-500.webp",
        "eco.webp",
        "hsta_logo.webp",
        "ingenious.webp",
        "logo_als.webp",
        "logo_avita.webp",
        "logo_hkba.webp",
        "logo_hkcka.webp",
        "logo_nexstgo.webp",
        "me_fitness.webp",
        "mosaic_logo.webp",
        "smartone_logo.webp",
        "tanita.webp",
        "technogym.webp",
    ];

    const logoList = images.map((image, index) => {
        return <Box component="img"
            src={`/assets/images/company-logo/${image}`}
            height={20}
            alt={image} key={index} />
    });

    return (
        <Stack direction="row" gap={1} sx={
            { 
            justifyContent: "center" }}>
            {logoList}
        </Stack>
    );
}