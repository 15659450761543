import {
    Container,
    Typography,
    Box,
    Stack,
    Button,
} from "@mui/material";
import React, {useRef} from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import { useReactToPrint } from "react-to-print";
import MockData from "../../helper/MockData";
import MemberQueryQRCardPrintList from "../../components/app/MemberQueryQRCardPrintList";

export default function TestMemberQueryQRCardPrintList() {
    const { t } = useTranslation();
    const componentToPrint = useRef(null);

    let lang = getLang();
    let memberQueryResult = MockData.getMemberQueryResult();
    let oneMember = memberQueryResult.data[0];
    
    const handlePrint = useReactToPrint({
        content: () => componentToPrint.current,
      });

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TestMemberQueryQRCardList Print</Typography>



                <Box>
                    <Button onClick={handlePrint}>Print</Button>
                    <MemberQueryQRCardPrintList 
                        memberQueryResult={memberQueryResult} 
                        componentToPrint={componentToPrint}
                        pageSize={5}/>
                </Box>
            </Stack>
        </Container>
    )
}
