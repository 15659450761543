import * as Yup from "yup";
import orgTypeList from "./data/orgTypeList.json";
import genderList from "./data/genderList.json";
import moment from "moment";
const FormControl = [
  {
    size: { xs: 12, sm: 6 },
    props: { id: "name_zh", label: "活動名稱 *", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    props: { id: "name_en", label: "Event Name *", type: "text" },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: "dateTime",
    props: {
      id: "start_at",
      label: "開始日期 *",
      inputFormat: "YYYY/MM/DD hh:mm ",
    },
  },
  {
    size: { xs: 12, sm: 6 },
    variant: "dateTime",
    props: {
      id: "end_at",
      label: "結束日期 *",
      inputFormat: "YYYY/MM/DD hh:mm ",
    },
  },
];

const Schema = Yup.object().shape({
  name_zh: Yup.string().required("別忘記輸入活動名稱"),
  name_en: Yup.string().required("Event Name is required"),
  start_at: Yup.date().required("別忘記選擇活動開始日期"),
  end_at: Yup.date()
    .min(Yup.ref("start_at"), "活動結束日期不能早於開始日期")
    .required("別忘記選擇活動結束日期"),
});

const FormikInstance = {
  initialValues: {
    name_zh: "",
    name_en: "",
    start_at: moment(),
    end_at: moment(),
  },
  validateOnMount: true,
  validationSchema: Schema,
};

const FormContent = {
  Schema,
  FormControl,
  FormikInstance,
};

export default FormContent;
