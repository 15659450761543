import {
  Box, Grid, Typography,
  Avatar,
  Container, Stack
} from "@mui/material";

import RoundIcon from "../../common/RoundIcon/RoundIcon";
import { useState } from "react";
import { formatNumber } from "../../../helper/StringHelper";

const MAIN_FONT = "Noto Sans TC";

const RankBadge = ({ rank }) => {
  // size, bgColor, color, padding, component
  const bgColor = '#00656C';

  return (
    <Avatar sx={{ width: 40, height: 40, background: bgColor }}>
      <Typography sx={
        {
          fontSize: '20px',
          fontWeight: 'bold', color: 'white'
        }
      }>
        {rank}
      </Typography>
    </Avatar>
  );
}

export default function CoSportRankingBarItem({ barData, barColor, unit, bestScore }) {

  const rankStr = `${barData.rank}`;
  const schoolName = barData.schoolName || '???';

  const score = barData.score || 0;
  bestScore = bestScore || 10;
  if (bestScore === 0) {
    bestScore = 10;
  }

  barColor = barColor || '#9DF9FF';

  const scoreStr = `${formatNumber(score)} ${unit}`

  const barPercent = score * 100 / bestScore + '%';

  return (
    <Box
      align="center"
      padding={0}
      // paddingTop={1}
      width="100%"
      minWidth={200}
      sx={{
        // background: 'rgba(0, 0, 0, 0.5)',
      }}
      position="relative"
    >
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
        <Box sx={{ paddingRight: '15px', textAlign: 'right' }} >
          <RankBadge rank={rankStr} />
        </Box>

        <Box sx={{ flexGrow: 1, padding: 0, }} >
          <Stack>
            { /* score */}
            <Box height={20}
              width={barPercent}
              minWidth={100}
              display={"flex"} justifyContent={"end"} paddingBottom={2}>
              <Typography sx={{ textWrap: 'nowrap', fontSize: '15px', fontWeight: 'bold', color: '#FFEC89' }}>
                {scoreStr}
              </Typography>
            </Box>
            <Stack height={20} width={barPercent}>
              <Box height={30} width={"100%"} sx={
                {
                  background: barColor,
                  // borderTop: '1px solid black',
                  // borderBottom: '1px solid white'
                }
              }></Box>
            </Stack>
            <Box height={20} display={"flex"} justifyContent={"start"}>
              <Typography sx={{ textWrap: 'nowrap', fontSize: '15px', fontWeight: 'bold', color: 'white' }}>
                {schoolName}
              </Typography>
            </Box>
          </Stack >
        </Box>
      </Box>

    </Box >
  );
}
