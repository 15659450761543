import { useTheme } from '@mui/material/styles';
/** Component */
import {
  Box,
  Grid,
  Container,
  TextField,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, } from 'react';
import { AdminApi } from "../../api/AdminApi";
import OrgMissionSettingListTable from "../../components/organization/organizationMissingSettings/OrgMissionSettingListTable";
import OrganizationDropdown from "../../components/organization/OrganizationDropdown";
import OrganizationMissionDetailFormDialog from "../../components/organization/OrganizationMissionDetailFormDialog";
import { toast } from "react-toastify";

// Testing URL 
// http://localhost:3000/organization/2/member/import
// ----------------------------------------------------------------------

export default function Page() {
  const [organization, setOrganization] = useState(null);
  const [missionSetting, setMissionSetting] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [settingList, setSettingList] = useState([]);

  const onOrganizationSelected = (org) => {
    // console.debug("onOrganizationSelected=", org);
    setOrganization(org);
  }


  const getOrgID = () => {
    if (organization === null) {
      return 0;
    }

    return organization.id;
  }

  useEffect(() => {
    if (missionSetting !== null) {
      console.debug("try to open missionSetting=", missionSetting);
      setIsDialogOpen(true);
    } else {
      console.debug("missionSetting is null");
      setIsDialogOpen(false);
    }
  }, [missionSetting]);

  const missionSettingSelected = (_mission) => {
    console.debug("missionSettingSelected: mission=", _mission);
    setMissionSetting(_mission);
    // setIsDialogOpen(true);
  }

  const updateMissionList = (_mission, _isCreate) => {
    console.debug("updateMissionList: mission=", _mission);
    console.debug("updateMissionList: _isCreate=", _isCreate);

    if (_isCreate) {
      setSettingList([...settingList, _mission]);
    } else {
      let newList = settingList.map((item) => {
        if (item.id === _mission.id) {
          // _mission.description = item.description;
          return _mission;
        }
        return item;
      });

      setSettingList(newList);
    }
  }

  const fetchSettingList = async () => {


    AdminApi.loadOrgMissionSettingListCallback(getOrgID(), (isSuccess, msg, data) => {
      if (isSuccess === false) {
        toast.error(msg);
        setSettingList([]);
        return;
      }
      console.log("data: ", data);
      setSettingList(data);
    });

    // setSettingList(data.data);            
  };

  useEffect(() => {
    if (organization === null) {
      return;
    }


    console.debug("Try to Fetch the Setting from org: ", organization);
    fetchSettingList();
  }, [organization]);


  const handleMissionSettingUpdate = (_mission, _isCreate) => {
    console.debug("handleMissionSettingUpdate: mission=", _mission);
    console.debug("handleMissionSettingUpdate: _isCreate=", _isCreate);
    updateMissionList(_mission, _isCreate);
    setIsDialogOpen(false);
    setMissionSetting(null);

    // setMissionSetting(mission);
  }
  const handleCloseDialog = () => {
    setMissionSetting(null);
  }

  const onCreate = () => {
    let newMission = {
      id: 0,
      mission_cycle: 'month',
      calc_type: 'play_time',
      require_value: 100,
      reward_point: 234,
      skill: "all",
    }

    setMissionSetting(newMission);
  }

  return (
    <>

      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            獎勵任務設定
          </Typography>

          <OrganizationDropdown onOrganizationSelected={onOrganizationSelected} />

          {/* <AdminEventStatSection  userID={user.id} mode="user" /> */}
          <Box mt={4}>
            {
              (organization !== null) &&
              <Box sx={{ px: 3, pb: 2 }}>
                <Button variant="contained" onClick={onCreate}>新增任務</Button>
              </Box>
            }

            <OrgMissionSettingListTable orgID={getOrgID()}
              inputList={settingList}
              onMissionSelected={missionSettingSelected} />
          </Box>

          {
            missionSetting &&
            <Box>
              <OrganizationMissionDetailFormDialog
                orgID={getOrgID()} open={isDialogOpen}
                inputMission={missionSetting}
                onClose={handleCloseDialog}
                onUpdate={handleMissionSettingUpdate}
              />
            </Box>
          }


        </Stack>

      </Container>
    </>
  );
}
