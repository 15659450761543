import {
    Container,
    Typography,
    Box,
    Dialog,
    Card,
    Button,
    CardContent,
    CardHeader,
    DialogTitle, 
    DialogContent,
    DialogActions,
    DialogContentText,
} from "@mui/material";
import { minWidth } from "@mui/system";
import React from 'react';
import { useState } from "react";
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import ConfirmInputDialog from "../../components/app/ConfirmInputDialog";
import MockData from "../../helper/MockData";
import { AdminApi } from "../../api/AdminApi";


export default function TestDialog() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [confirmResult, setConfirmResult] = useState(MockData.checkInputResult());

    let lang = getLang();

    // setConfirmResult(MockData.checkInputResult());

    // use

    // const memberSkillInfo = MockData.getMemberEventInfo().skillInfoList[0];


    const callCheckInput = async () => {
        const eventID = 1;
        const qrCodeID = 'BCDPMCF';
        const skill = 'situp';
        const score = '33';


        const result = await AdminApi.checkSkillInput(eventID, qrCodeID, skill, score);
        console.debug("result=", result);
        setConfirmResult(result);

        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    // reference 
    //  
    return (
        <Container maxWidth="lg">
            <Typography variant="h3">Test Dialog</Typography>

            <div>
                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
            </div>

            <Button variant="contained" onClick={() => setOpen(true)}>Open Dialog</Button>


            <Button variant="contained" sx={{ ml: 3 }} onClick={callCheckInput}>Call API & Open Dialog</Button>

            <ConfirmInputDialog confirmResult={confirmResult}
                open={open}
                onClose={() => setOpen(false)}
            />

{/* 
            <React.Fragment>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Open max-width dialog
                </Button>
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={open}
                    // onClose={handleClose}
                >
                    <DialogTitle>Optional sizes</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You can set my maximum width and whether to adapt or not.
                        </DialogContentText>
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment> */}
        </Container>
    )
}
