import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
    Avatar,
} from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import MainCard from "../MainCard/MainCard";
import RoundIcon from "../RoundIcon/RoundIcon";
import PendingIcon from '@mui/icons-material/Pending';

const ColorDashCard = ({
    label, value, icon,
    styleSetting
}) => {
    const cardColor = styleSetting?.cardColor || "#fff";
    const iconColor = styleSetting?.iconColor || "#fff";
    const iconBgColor = styleSetting?.iconBgColor || "#fcc";
    
    const mainStyle = {
        background: cardColor,
    }

    
    let maxWidth = styleSetting?.maxWidth || 0;
    if(maxWidth > 0){ 
        mainStyle.maxWidth = maxWidth;
    }

    let minWidth = styleSetting?.minWidth || 0;
    if(minWidth > 0){ 
        mainStyle.minWidth = minWidth;
    }
    
    let contentSX = {p:3}
    let iconComponent = icon

    return (<MainCard sx={mainStyle} border={false} boxShadow={true} contentSX={contentSX}>
        <Box display="flex" sx={{ alignItems: 'center',  paddingTop:1}}>
            <RoundIcon
                component={iconComponent}
                size={70}
                bgColor={iconBgColor}
                color={iconColor}
                padding={1}
            />

            <Stack sx={{ paddingLeft: 1, alignItems: "start" }} display={"flex"} >
            <Typography variant="h3" color="black">
                    {value}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                    {label}
                </Typography>

            </Stack>
        </Box>

    </MainCard>);

}

export default ColorDashCard;


