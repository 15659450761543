import React, { useState, useEffect } from "react";
/** Helper */

/** Component */
import { Box, Grid, Typography, Container } from "@mui/material";
import CommonTable from "../../components/common/CommonTable";
import CommonFilter from "../../components/common/CommonFilter";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
/** Content */
// import TableConfig from "../../components/organization/index/listTableContent";
import MemberSkillDataTable from "../../components/adminSkillRecord/MemberSkillDataTable";


export default function Component() {

  // const mode = 'admin';
  const mode = 'user';
  const user = {
    id: 5,
    organization_id: 62,
  }
  // const [filterQuery, setFilterQuery] = useState(false);

  // useEffect(() => {
  //   console.log("filterQuery", filterQuery);
  //   let filters = [];
  //   // filters.push({ 'field': 'display_name', 'value': "ken", 'op': 'like' });
  //   // setFilterQuery(filters);
  // }, []);



  return (
    <Container>
      <Grid container direction={"column"} gap={2}>

        <MemberSkillDataTable mode={mode} user={user} />
        
      </Grid>
    </Container>
  );
}
