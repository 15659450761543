import React from "react";
import { Grid } from "@mui/material";
import EventCard from "./EventCard";
export default function EventList({ eventList }) {
  const cardList = eventList.map((event, index) => {
    return (
      //   <Grid item xs={12}, >
      <EventCard key={index} event={event} />
      //   </Grid>
    );
  });

  return <Grid>{cardList}</Grid>;
}
