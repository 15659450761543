import {
    Typography,
    Box,
    Button,
    Dialog,
    Card,
    CardContent,
    CardHeader,
    CardActions,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MemberEventSkillInfoCard from "./MemberEventSkillInfoCard";
import { AdminApi } from "../../api/AdminApi";
import { toast } from "react-toastify";

export default function ConfirmInputDialog({ 
    confirmResult, eventID, open,
    onClose, handleSubmit }) {
    const { t } = useTranslation();

    console.debug("confirmResult=", confirmResult);
    // const memberEventInfo = confirmResult.memberEventInfo;
    const member = confirmResult.member;
    let skillInfo = {...confirmResult.skillInfo};
    const skillScore = confirmResult.skillValue;
    const skillInputData = confirmResult.skillInputData;
    // memberEventInfo.skill = skillInfo;
    // memberEventInfo.created_at = (new Date()).toLocaleString();
    // const qrCode = member.qrcode_id;
    const memberID = member.id;
    const memberName = member.display_name;

    const phoneNumber = member.phone_num;
    const gender = member.gender;
    const unitName = t("unit." + skillInfo.unit);// skillInfo.unit;

    skillInfo.score = skillScore;
    skillInfo.rank = confirmResult.rank;
    //const memberSkillInfo = MockData.getMemberEventInfo().skillInfoList[0];

    let lang = getLang();

    const onCancel = () => {
        console.debug("onCancel");
        onClose();
    }

    const onSumbit = async () => {
        // const memberEventID = memberEventInfo.id;
        const skill = skillInfo.code;
        const score = skillScore;
        console.debug("onSubmit: ", { eventID, memberID, skill, score });

        await AdminApi.submitScore(memberID, eventID, skill, score, skillInputData);
        // console.debug("result: ", result);
        // if(result) {
        //     alert("Success");
        // }
        toast.success("Score is submitted");
        onClose();
        // handleSubmit();
    }

    return (
        <Dialog open={open} fullWidth scroll="body">
            <Card>
                <CardHeader title="確定你的資料" />
                <CardContent>
                    <Stack>
                        <Box gap={3} pb={3}>
                            <Typography variant="h4" sx={{ width: '100%' }}>
                                參與者名字：{memberName}
                            </Typography>
                            <Typography variant="body1" sx={{ width: '100%' }}>
                                電話號碼：{phoneNumber}
                            </Typography>
                        </Box>

                        <Box component={MemberEventSkillInfoCard}
                            skillInfo={skillInfo}
                            gender={gender}
                            unitName={unitName} />

                        
                    </Stack>
                    <CardActions sx={{ justifyContent: "space-between" }} >
                        <Button variant="contained" color="error" onClick={onCancel}>取消</Button>
                        <Button variant="contained" color="primary" onClick={onSumbit}>提交紀錄</Button>

                    </CardActions>




                </CardContent>
            </Card>
        </Dialog>
    );
}