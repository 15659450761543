

const getCoSportOrganizationScore = () => {
    return {
        "organization": {
            "id": 11,
            "name_en": "合一堂學校",
            "name_zh": "合一堂學校"
        },
        "skill_info": {
            "total_score": 12958,
            "id": 1,
            "name_zh": "1分鐘仰臥起坐",
            "name_en": "1 min Sit-up",
            "code": "situp",
            "fitness_type": "endurance",
            "fitness_type_en": "1 min Sit-up",
            "fitness_type_zh": "1分鐘仰臥起坐",
            "unit": "time",
            "decimal_place": 0,
        }
    }
}

const getOneOverallLeaderboardData = () => {
    return getOverallLeaderboardData()[2];
}

const getOverallLeaderboardData = () => {
    return [
        {
            "id": 4,
            "skill_name": "Sit and Reach Test",
            "skill_code": "sitnreach",
            "color": "purple",
            "unit": "cm",
            "query_code": "sitnreach_all",
            "timeslot_id": 1,
            "next_timeslot_id": -1,
            "last_timeslot_id": -1,
            "start_at": "00:00",
            "end_at": "23:59",
            "mode": "all",
            "timeslot_caption": "整個活動",
            "result": [
                {
                    "rank": 1,
                    "score": 34,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 3933,
                            "name": "Toby",
                            "phone_num": "25952611",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 2,
                    "score": 30,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2934,
                            "name": "VTC",
                            "phone_num": "91239123",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 3,
                    "score": 22,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 4,
                            "name": "vtc-ce",
                            "phone_num": "af",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                }
            ]
        },
        {
            "id": 5,
            "skill_name": "Smart Vertical Jump",
            "skill_code": "vjump",
            "color": "blue",
            "unit": "cm",
            "query_code": "vjump_all",
            "timeslot_id": 1,
            "next_timeslot_id": -1,
            "last_timeslot_id": -1,
            "start_at": "00:00",
            "end_at": "23:59",
            "mode": "all",
            "timeslot_caption": "整個活動",
            "result": [
                {
                    "rank": 1,
                    "score": 155,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2934,
                            "name": "VTC",
                            "phone_num": "91239123",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 2,
                    "score": 143,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2943,
                            "name": "EDB03",
                            "phone_num": "99998886",
                            "gender": "F",
                            "group": "TBC",
                            "group_num": "TBC"
                        }
                    ]
                },
                {
                    "rank": 3,
                    "score": 91,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2937,
                            "name": "IVE04",
                            "phone_num": "99999998",
                            "gender": "M",
                            "group": "",
                            "group_num": "0"
                        }
                    ]
                },
                {
                    "rank": 4,
                    "score": 89,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2951,
                            "name": "將軍澳香島中學",
                            "phone_num": "99999986",
                            "gender": "M",
                            "group": "",
                            "group_num": "0"
                        }
                    ]
                },
                {
                    "rank": 5,
                    "score": 87,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2936,
                            "name": "IVE03",
                            "phone_num": "99999999",
                            "gender": "M",
                            "group": "",
                            "group_num": "0"
                        }
                    ]
                },
                {
                    "rank": 6,
                    "score": 80,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 3099,
                            "name": "Daz Ramirez",
                            "phone_num": "54491002",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 7,
                    "score": 77,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 3000,
                            "name": "陳文博",
                            "phone_num": "",
                            "gender": "M",
                            "group": "2B",
                            "group_num": "2"
                        }
                    ]
                },
                {
                    "rank": 8,
                    "score": 73,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 3117,
                            "name": "Shamir",
                            "phone_num": "5997 1556",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 9,
                    "score": 70,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 2959,
                            "name": "陳凱霖",
                            "phone_num": "",
                            "gender": "M",
                            "group": "1A",
                            "group_num": "2"
                        }
                    ]
                },
                {
                    "rank": 10,
                    "score": 69,
                    "unit": "cm",
                    "members": [
                        {
                            "member_id": 3142,
                            "name": "220144159",
                            "phone_num": "+85254006064",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                }
            ]
        },
        {
            "id": 6,
            "skill_name": "Single Leg Balance",
            "skill_code": "balance",
            "color": "orange",
            "unit": "pt",
            "query_code": "balance_all",
            "timeslot_id": 1,
            "next_timeslot_id": -1,
            "last_timeslot_id": -1,
            "start_at": "00:00",
            "end_at": "23:59",
            "mode": "all",
            "timeslot_caption": "整個活動",
            "result": [
                {
                    "rank": 1,
                    "score": 89,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3915,
                            "name": "May",
                            "phone_num": "52231807",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 2,
                    "score": 88,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 1,
                            "name": "Ken1233",
                            "phone_num": "91239123",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 3,
                    "score": 87.5,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3172,
                            "name": "Alexisthegoat",
                            "phone_num": "+85263248559",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        },
                        {
                            "member_id": 3171,
                            "name": "Celucia John Joseph Paule",
                            "phone_num": "60396944",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 4,
                    "score": 87,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3925,
                            "name": "Joeychu",
                            "phone_num": "67595803",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 5,
                    "score": 82.5,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3939,
                            "name": "wong ka kit",
                            "phone_num": "51160468",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        },
                        {
                            "member_id": 3890,
                            "name": "220073875",
                            "phone_num": "59179296",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 6,
                    "score": 81,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3933,
                            "name": "Toby",
                            "phone_num": "25952611",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        },
                        {
                            "member_id": 3131,
                            "name": "220073746",
                            "phone_num": "95530068",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 7,
                    "score": 80.5,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3132,
                            "name": "220136589",
                            "phone_num": "69317938",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 8,
                    "score": 79.5,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3917,
                            "name": "220076158",
                            "phone_num": "95285060",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 9,
                    "score": 79,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3919,
                            "name": "Leung chung yi",
                            "phone_num": "54153626",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                },
                {
                    "rank": 10,
                    "score": 76.5,
                    "unit": "pt",
                    "members": [
                        {
                            "member_id": 3896,
                            "name": "220159972",
                            "phone_num": "90806790",
                            "gender": "M",
                            "group": "",
                            "group_num": ""
                        }
                    ]
                }
            ]
        },
        {
            "id": 8,
            "skill_name": "Boxing",
            "skill_code": "yingwa_boxing",
            "color": "blue",
            "unit": "pt",
            "query_code": "yingwa_boxing_all",
            "timeslot_id": 1,
            "next_timeslot_id": -1,
            "last_timeslot_id": -1,
            "start_at": "00:00",
            "end_at": "23:59",
            "mode": "all",
            "timeslot_caption": "整個活動",
            "result": []
        },
        {
            "id": 9,
            "skill_name": "武藝操",
            "skill_code": "kungfu",
            "color": "green",
            "unit": "pt",
            "query_code": "kungfu_all",
            "timeslot_id": 1,
            "next_timeslot_id": -1,
            "last_timeslot_id": -1,
            "start_at": "00:00",
            "end_at": "23:59",
            "mode": "all",
            "timeslot_caption": "整個活動",
            "result": []
        },
        {
            "id": 10,
            "skill_name": "拉筋訓練",
            "skill_code": "stretching",
            "color": "orange",
            "unit": "pt",
            "query_code": "stretching_all",
            "timeslot_id": 1,
            "next_timeslot_id": -1,
            "last_timeslot_id": -1,
            "start_at": "00:00",
            "end_at": "23:59",
            "mode": "all",
            "timeslot_caption": "整個活動",
            "result": []
        }
    ]
}

const getSkillPerformStatistics = () => {
    const data = getOverallSkillStatistics();

    return data.records['balance'].performData;
}


const getSkillUsageStatistics = () => {
    const data = getOverallSkillStatistics();

    return data.records['balance'].usageData;
}

const getOneSkillStatistics = () => {
    const data = getOverallSkillStatistics();

    return data.records['balance'];
}

const getOverallSkillStatistics = () => {
    return {
        "skillInfo": [
            {
                "id": 1,
                "name_zh": "1分鐘仰臥起坐",
                "name_en": "1 min Sit-up",
                "code": "situp",
                "fitness_type": "endurance",
                "fitness_type_en": "1 min Sit-up",
                "fitness_type_zh": "1分鐘仰臥起坐",
                "unit": "time",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "auto_import": {
                        "type": "mqtt",
                        "devices": [
                            {
                                "name": "Situp Device 1",
                                "topic": "microbit/situp/1"
                            }
                        ]
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 25
                    },
                    {
                        "rank": 2,
                        "start": 25,
                        "end": 35
                    },
                    {
                        "rank": 3,
                        "start": 35,
                        "end": 44
                    },
                    {
                        "rank": 4,
                        "start": 44,
                        "end": 49
                    },
                    {
                        "rank": 5,
                        "start": 49
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 18
                    },
                    {
                        "rank": 2,
                        "start": 18,
                        "end": 29
                    },
                    {
                        "rank": 3,
                        "start": 29,
                        "end": 37
                    },
                    {
                        "rank": 4,
                        "start": 37,
                        "end": 43
                    },
                    {
                        "rank": 5,
                        "start": 43
                    }
                ],
                "created_at": "2022-11-28 07:47:19",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": "2022-11-28 07:47:19"
            },
            {
                "id": 2,
                "name_zh": "垂直跳躍",
                "name_en": "Vertical Jump",
                "code": "vjump",
                "fitness_type": "power",
                "fitness_type_en": "Vertical Jump",
                "fitness_type_zh": "垂直跳躍",
                "unit": "cm",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": null,
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 21
                    },
                    {
                        "rank": 2,
                        "start": 21,
                        "end": 31
                    },
                    {
                        "rank": 3,
                        "start": 31,
                        "end": 41
                    },
                    {
                        "rank": 4,
                        "start": 41,
                        "end": 51
                    },
                    {
                        "rank": 5,
                        "start": 51
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 21
                    },
                    {
                        "rank": 2,
                        "start": 21,
                        "end": 31
                    },
                    {
                        "rank": 3,
                        "start": 31,
                        "end": 41
                    },
                    {
                        "rank": 4,
                        "start": 41,
                        "end": 51
                    },
                    {
                        "rank": 5,
                        "start": 51
                    }
                ],
                "created_at": "2022-11-28 16:04:11",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": ""
            },
            {
                "id": 4,
                "name_zh": "坐地前彎",
                "name_en": "Sit and Reach",
                "code": "sitnreach",
                "fitness_type": "flexibility",
                "fitness_type_en": "Sit and Reach",
                "fitness_type_zh": "坐地前彎",
                "unit": "cm",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "auto_import": {
                        "type": "mqtt",
                        "devices": [
                            {
                                "name": "SitnReach Device 1",
                                "topic": "microbit/sitnreach/1"
                            }
                        ]
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 18
                    },
                    {
                        "rank": 2,
                        "start": 18,
                        "end": 28
                    },
                    {
                        "rank": 3,
                        "start": 28,
                        "end": 38
                    },
                    {
                        "rank": 4,
                        "start": 38,
                        "end": 48
                    },
                    {
                        "rank": 5,
                        "start": 48
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 18
                    },
                    {
                        "rank": 2,
                        "start": 18,
                        "end": 28
                    },
                    {
                        "rank": 3,
                        "start": 28,
                        "end": 38
                    },
                    {
                        "rank": 4,
                        "start": 38,
                        "end": 48
                    },
                    {
                        "rank": 5,
                        "start": 48
                    }
                ],
                "created_at": "2022-12-02 18:12:46",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": ""
            },
            {
                "id": 7,
                "name_zh": "手握力",
                "name_en": "Hand Grip",
                "code": "handgrip",
                "fitness_type": "grip_power",
                "fitness_type_en": "Hand Grip",
                "fitness_type_zh": "手握力",
                "unit": "kg",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "file_import": {
                        "parser": "HandGripParser"
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 40
                    },
                    {
                        "rank": 2,
                        "start": 40,
                        "end": 48
                    },
                    {
                        "rank": 3,
                        "start": 48,
                        "end": 56
                    },
                    {
                        "rank": 4,
                        "start": 56,
                        "end": 64
                    },
                    {
                        "rank": 5,
                        "start": 64
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 31
                    },
                    {
                        "rank": 2,
                        "start": 31,
                        "end": 41
                    },
                    {
                        "rank": 3,
                        "start": 41,
                        "end": 51
                    },
                    {
                        "rank": 4,
                        "start": 51,
                        "end": 60
                    },
                    {
                        "rank": 5,
                        "start": 60
                    }
                ],
                "created_at": "2022-12-02 18:12:46",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": ""
            },
            {
                "id": 8,
                "name_zh": "單腳平衡測試",
                "name_en": "Single-leg Balance",
                "code": "balance",
                "fitness_type": "balance",
                "fitness_type_en": "Single-leg Balance",
                "fitness_type_zh": "單腳平衡測試",
                "unit": "pt",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "auto_import": {
                        "type": "mqtt",
                        "devices": [
                            {
                                "name": "Balance Device 1",
                                "topic": "balance-device-1"
                            }
                        ]
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "end": 41
                    },
                    {
                        "rank": 2,
                        "start": 41,
                        "end": 56
                    },
                    {
                        "rank": 3,
                        "start": 56,
                        "end": 71
                    },
                    {
                        "rank": 4,
                        "start": 71,
                        "end": 86
                    },
                    {
                        "rank": 5,
                        "start": 86
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "end": 41
                    },
                    {
                        "rank": 2,
                        "start": 41,
                        "end": 56
                    },
                    {
                        "rank": 3,
                        "start": 56,
                        "end": 71
                    },
                    {
                        "rank": 4,
                        "start": 71,
                        "end": 86
                    },
                    {
                        "rank": 5,
                        "start": 86
                    }
                ],
                "created_at": "2022-12-02 18:12:46",
                "updated_at": "2023-12-04 17:47:02",
                "deleted_at": ""
            }
        ],
        "records": {
            "balance": {
                "performData": [
                    {
                        "school_cls": "P1",
                        "sum_score": "1439.5",
                        "min_score": "0.5",
                        "max_score": "68",
                        "avg_score": "36.91025641025641"
                    },
                    {
                        "school_cls": "P2",
                        "sum_score": "4262.5",
                        "min_score": "1",
                        "max_score": "90",
                        "avg_score": "41.78921568627451"
                    },
                    {
                        "school_cls": "P3",
                        "sum_score": "2656.5",
                        "min_score": "6.5",
                        "max_score": "80.5",
                        "avg_score": "38.5"
                    },
                    {
                        "school_cls": "P4",
                        "sum_score": "1803.5",
                        "min_score": "1",
                        "max_score": "83",
                        "avg_score": "41.94186046511628"
                    },
                    {
                        "school_cls": "P5",
                        "sum_score": "1987.5",
                        "min_score": "19.5",
                        "max_score": "84.5",
                        "avg_score": "55.208333333333336"
                    },
                    {
                        "school_cls": "P6",
                        "sum_score": "2512",
                        "min_score": "1",
                        "max_score": "86",
                        "avg_score": "58.41860465116279"
                    }
                ],
                "usageData": {
                    "skill_code": "balance",
                    "sum_score": "14661.5",
                    "member_count": "269",
                    "organization_count": "3"
                }
            },
            "handgrip": {
                "performData": [
                    {
                        "school_cls": "P1",
                        "sum_score": "508.53000020980835",
                        "min_score": "2.75",
                        "max_score": "11",
                        "avg_score": "6.9661643864357305"
                    },
                    {
                        "school_cls": "P2",
                        "sum_score": "1070.1499998569489",
                        "min_score": "2.700000047683716",
                        "max_score": "16.5",
                        "avg_score": "8.360546873882413"
                    },
                    {
                        "school_cls": "P3",
                        "sum_score": "1121.7499990463257",
                        "min_score": "3.5",
                        "max_score": "17",
                        "avg_score": "10.105855847264195"
                    },
                    {
                        "school_cls": "P4",
                        "sum_score": "577.1500015258789",
                        "min_score": "4",
                        "max_score": "25.5",
                        "avg_score": "12.279787266508063"
                    },
                    {
                        "school_cls": "P5",
                        "sum_score": "623.6000003814697",
                        "min_score": "5",
                        "max_score": "27.200000762939453",
                        "avg_score": "13.556521747423256"
                    },
                    {
                        "school_cls": "P6",
                        "sum_score": "982.9500007629395",
                        "min_score": "3",
                        "max_score": "33",
                        "avg_score": "16.382500012715656"
                    }
                ],
                "usageData": {
                    "skill_code": "handgrip",
                    "sum_score": "4884.130001783371",
                    "member_count": "412",
                    "organization_count": "4"
                }
            },
            "sitnreach": {
                "performData": [
                    {
                        "school_cls": "P1",
                        "sum_score": "999",
                        "min_score": "11",
                        "max_score": "35",
                        "avg_score": "22.2"
                    },
                    {
                        "school_cls": "P2",
                        "sum_score": "1622.5",
                        "min_score": "9",
                        "max_score": "42",
                        "avg_score": "24.96153846153846"
                    },
                    {
                        "school_cls": "P3",
                        "sum_score": "1824.5",
                        "min_score": "9",
                        "max_score": "46",
                        "avg_score": "23.694805194805195"
                    },
                    {
                        "school_cls": "P4",
                        "sum_score": "2446.5",
                        "min_score": "9",
                        "max_score": "55",
                        "avg_score": "27.183333333333334"
                    },
                    {
                        "school_cls": "P5",
                        "sum_score": "2194.5",
                        "min_score": "6",
                        "max_score": "40",
                        "avg_score": "24.115384615384617"
                    },
                    {
                        "school_cls": "P6",
                        "sum_score": "817.5",
                        "min_score": "14",
                        "max_score": "46",
                        "avg_score": "30.27777777777778"
                    }
                ],
                "usageData": {
                    "skill_code": "sitnreach",
                    "sum_score": "9904.5",
                    "member_count": "326",
                    "organization_count": "4"
                }
            },
            "situp": {
                "performData": [
                    {
                        "school_cls": "P1",
                        "sum_score": "4118",
                        "min_score": "15",
                        "max_score": "54",
                        "avg_score": "34.898305084745765"
                    },
                    {
                        "school_cls": "P2",
                        "sum_score": "4930",
                        "min_score": "4",
                        "max_score": "53",
                        "avg_score": "27.38888888888889"
                    },
                    {
                        "school_cls": "P3",
                        "sum_score": "2906",
                        "min_score": "11",
                        "max_score": "49",
                        "avg_score": "29.353535353535353"
                    },
                    {
                        "school_cls": "P4",
                        "sum_score": "2164",
                        "min_score": "1",
                        "max_score": "53",
                        "avg_score": "29.243243243243242"
                    },
                    {
                        "school_cls": "P5",
                        "sum_score": "2759",
                        "min_score": "12",
                        "max_score": "45",
                        "avg_score": "29.98913043478261"
                    },
                    {
                        "school_cls": "P6",
                        "sum_score": "809",
                        "min_score": "20",
                        "max_score": "49",
                        "avg_score": "31.115384615384617"
                    }
                ],
                "usageData": {
                    "skill_code": "situp",
                    "sum_score": "17686",
                    "member_count": "500",
                    "organization_count": "4"
                }
            },
            "vjump": {
                "performData": [
                    {
                        "school_cls": "P1",
                        "sum_score": "6663",
                        "min_score": "1",
                        "max_score": "208",
                        "avg_score": "23.21602787456446"
                    },
                    {
                        "school_cls": "P2",
                        "sum_score": "2228",
                        "min_score": "4",
                        "max_score": "60",
                        "avg_score": "19.042735042735043"
                    },
                    {
                        "school_cls": "P3",
                        "sum_score": "2762",
                        "min_score": "3",
                        "max_score": "55",
                        "avg_score": "21.246153846153845"
                    },
                    {
                        "school_cls": "P4",
                        "sum_score": "2209",
                        "min_score": "10",
                        "max_score": "90",
                        "avg_score": "33.98461538461538"
                    },
                    {
                        "school_cls": "P5",
                        "sum_score": "2421",
                        "min_score": "1",
                        "max_score": "91",
                        "avg_score": "23.057142857142857"
                    },
                    {
                        "school_cls": "P6",
                        "sum_score": "11178.79999923706",
                        "min_score": "1",
                        "max_score": "162",
                        "avg_score": "26.552969119327933"
                    }
                ],
                "usageData": {
                    "skill_code": "vjump",
                    "sum_score": "27461.79999923706",
                    "member_count": "441",
                    "organization_count": "5"
                }
            }
        }
    }
}

const getOneSkillTopOrganization = () => {
    const response = skillTopOrganization();
    return response.records.vjump;
}

const skillTopOrganization = () => {
    return {
        "records": {
            "vjump": [
                {
                    "organization_id": "57",
                    "sum_score": "16817",
                    "organization_name_en": "IVE(HW)",
                    "organization_name_zh": "IVE(HW)",
                    "rank": 1
                },
                {
                    "organization_id": "68",
                    "sum_score": "12958",
                    "organization_name_en": "合一堂學校",
                    "organization_name_zh": "合一堂學校",
                    "rank": 2
                },
                {
                    "organization_id": "56",
                    "sum_score": "9684",
                    "organization_name_en": "IVE(CW)",
                    "organization_name_zh": "IVE(CW)",
                    "rank": 3
                },
                {
                    "organization_id": "11",
                    "sum_score": "7890.799999237061",
                    "organization_name_en": "Ho Shun Primary School ",
                    "organization_name_zh": "可信學校",
                    "rank": 4
                },
                {
                    "organization_id": "62",
                    "sum_score": "7245",
                    "organization_name_en": "Salesian English School",
                    "organization_name_zh": "慈幼英文中學",
                    "rank": 5
                },
                {
                    "organization_id": "10",
                    "sum_score": "6579",
                    "organization_name_en": "Po Leung Kuk Luk Hing Too Primary School",
                    "organization_name_zh": "保良局陸慶濤小學",
                    "rank": 6
                },
                {
                    "organization_id": "19",
                    "sum_score": "5253",
                    "organization_name_en": "Cheung Sha Wan Catholic Secondary School ",
                    "organization_name_zh": "長沙灣天主教英文中學",
                    "rank": 7
                },
                {
                    "organization_id": "61",
                    "sum_score": "5098",
                    "organization_name_en": "VTC@Lok Fu Place",
                    "organization_name_zh": "VTC@Lok Fu Place",
                    "rank": 8
                },
                {
                    "organization_id": "114",
                    "sum_score": "4402",
                    "organization_name_en": "HKJC",
                    "organization_name_zh": "香港賽馬會",
                    "rank": 9
                },
                {
                    "organization_id": "66",
                    "sum_score": "4008",
                    "organization_name_en": "基督教聖約教會堅樂中學",
                    "organization_name_zh": "基督教聖約教會堅樂中學",
                    "rank": 10
                }
            ],
            "balance": [
                {
                    "organization_id": "68",
                    "sum_score": "9295",
                    "organization_name_en": "合一堂學校",
                    "organization_name_zh": "合一堂學校",
                    "rank": 1
                },
                {
                    "organization_id": "11",
                    "sum_score": "5052",
                    "organization_name_en": "Ho Shun Primary School ",
                    "organization_name_zh": "可信學校",
                    "rank": 2
                },
                {
                    "organization_id": "114",
                    "sum_score": "1991",
                    "organization_name_en": "HKJC",
                    "organization_name_zh": "香港賽馬會",
                    "rank": 3
                },
                {
                    "organization_id": "10",
                    "sum_score": "314.5",
                    "organization_name_en": "Po Leung Kuk Luk Hing Too Primary School",
                    "organization_name_zh": "保良局陸慶濤小學",
                    "rank": 4
                },
                {
                    "organization_id": "67",
                    "sum_score": "117",
                    "organization_name_en": "HT Engagement",
                    "organization_name_zh": "HT Engagement",
                    "rank": 5
                },
                {
                    "organization_id": "2",
                    "sum_score": "88",
                    "organization_name_en": "YC(TSW)",
                    "organization_name_zh": "YC(TSW)",
                    "rank": 6
                }
            ],
            "sitnreach": [
                {
                    "organization_id": "68",
                    "sum_score": "5894",
                    "organization_name_en": "合一堂學校",
                    "organization_name_zh": "合一堂學校",
                    "rank": 1
                },
                {
                    "organization_id": "11",
                    "sum_score": "3472.5",
                    "organization_name_en": "Ho Shun Primary School ",
                    "organization_name_zh": "可信學校",
                    "rank": 2
                },
                {
                    "organization_id": "114",
                    "sum_score": "2989.5",
                    "organization_name_en": "HKJC",
                    "organization_name_zh": "香港賽馬會",
                    "rank": 3
                },
                {
                    "organization_id": "16",
                    "sum_score": "600",
                    "organization_name_en": "Ying Wa College",
                    "organization_name_zh": "英華書院",
                    "rank": 4
                },
                {
                    "organization_id": "10",
                    "sum_score": "503",
                    "organization_name_en": "Po Leung Kuk Luk Hing Too Primary School",
                    "organization_name_zh": "保良局陸慶濤小學",
                    "rank": 5
                },
                {
                    "organization_id": "67",
                    "sum_score": "103.5",
                    "organization_name_en": "HT Engagement",
                    "organization_name_zh": "HT Engagement",
                    "rank": 6
                },
                {
                    "organization_id": "1",
                    "sum_score": "35",
                    "organization_name_en": "佛教林金殿小學",
                    "organization_name_zh": "佛教林金殿小學",
                    "rank": 7
                },
                {
                    "organization_id": "2",
                    "sum_score": "30",
                    "organization_name_en": "YC(TSW)",
                    "organization_name_zh": "YC(TSW)",
                    "rank": 8
                },
                {
                    "organization_id": "7",
                    "sum_score": "18.5",
                    "organization_name_en": "IVE10",
                    "organization_name_zh": "IVE10",
                    "rank": 9
                }
            ],
            "situp": [
                {
                    "organization_id": "68",
                    "sum_score": "9487",
                    "organization_name_en": "合一堂學校",
                    "organization_name_zh": "合一堂學校",
                    "rank": 1
                },
                {
                    "organization_id": "11",
                    "sum_score": "4504",
                    "organization_name_en": "Ho Shun Primary School ",
                    "organization_name_zh": "可信學校",
                    "rank": 2
                },
                {
                    "organization_id": "1",
                    "sum_score": "3470",
                    "organization_name_en": "佛教林金殿小學",
                    "organization_name_zh": "佛教林金殿小學",
                    "rank": 3
                },
                {
                    "organization_id": "10",
                    "sum_score": "225",
                    "organization_name_en": "Po Leung Kuk Luk Hing Too Primary School",
                    "organization_name_zh": "保良局陸慶濤小學",
                    "rank": 4
                },
                {
                    "organization_id": "2",
                    "sum_score": "119",
                    "organization_name_en": "YC(TSW)",
                    "organization_name_zh": "YC(TSW)",
                    "rank": 5
                }
            ],
            "handgrip": [
                {
                    "organization_id": "114",
                    "sum_score": "3935",
                    "organization_name_en": "HKJC",
                    "organization_name_zh": "香港賽馬會",
                    "rank": 1
                },
                {
                    "organization_id": "68",
                    "sum_score": "2147.130001783371",
                    "organization_name_en": "合一堂學校",
                    "organization_name_zh": "合一堂學校",
                    "rank": 2
                },
                {
                    "organization_id": "11",
                    "sum_score": "1388.5",
                    "organization_name_en": "Ho Shun Primary School ",
                    "organization_name_zh": "可信學校",
                    "rank": 3
                },
                {
                    "organization_id": "1",
                    "sum_score": "1281",
                    "organization_name_en": "佛教林金殿小學",
                    "organization_name_zh": "佛教林金殿小學",
                    "rank": 4
                },
                {
                    "organization_id": "10",
                    "sum_score": "67.5",
                    "organization_name_en": "Po Leung Kuk Luk Hing Too Primary School",
                    "organization_name_zh": "保良局陸慶濤小學",
                    "rank": 5
                },
                {
                    "organization_id": "67",
                    "sum_score": "23.5",
                    "organization_name_en": "HT Engagement",
                    "organization_name_zh": "HT Engagement",
                    "rank": 6
                },
                {
                    "organization_id": "2",
                    "sum_score": "17",
                    "organization_name_en": "YC(TSW)",
                    "organization_name_zh": "YC(TSW)",
                    "rank": 7
                }
            ]
        },
        "skillInfo": [
            {
                "id": 1,
                "name_zh": "1分鐘仰臥起坐",
                "name_en": "1 min Sit-up",
                "code": "situp",
                "fitness_type": "endurance",
                "fitness_type_en": "1 min Sit-up",
                "fitness_type_zh": "1分鐘仰臥起坐",
                "unit": "time",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "auto_import": {
                        "type": "mqtt",
                        "devices": [
                            {
                                "name": "Situp Device 1",
                                "topic": "microbit/situp/1"
                            }
                        ]
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 25
                    },
                    {
                        "rank": 2,
                        "start": 25,
                        "end": 35
                    },
                    {
                        "rank": 3,
                        "start": 35,
                        "end": 44
                    },
                    {
                        "rank": 4,
                        "start": 44,
                        "end": 49
                    },
                    {
                        "rank": 5,
                        "start": 49
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 18
                    },
                    {
                        "rank": 2,
                        "start": 18,
                        "end": 29
                    },
                    {
                        "rank": 3,
                        "start": 29,
                        "end": 37
                    },
                    {
                        "rank": 4,
                        "start": 37,
                        "end": 43
                    },
                    {
                        "rank": 5,
                        "start": 43
                    }
                ],
                "created_at": "2022-11-28 07:47:19",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": "2022-11-28 07:47:19"
            },
            {
                "id": 2,
                "name_zh": "垂直跳躍",
                "name_en": "Vertical Jump",
                "code": "vjump",
                "fitness_type": "power",
                "fitness_type_en": "Vertical Jump",
                "fitness_type_zh": "垂直跳躍",
                "unit": "cm",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": null,
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 21
                    },
                    {
                        "rank": 2,
                        "start": 21,
                        "end": 31
                    },
                    {
                        "rank": 3,
                        "start": 31,
                        "end": 41
                    },
                    {
                        "rank": 4,
                        "start": 41,
                        "end": 51
                    },
                    {
                        "rank": 5,
                        "start": 51
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 21
                    },
                    {
                        "rank": 2,
                        "start": 21,
                        "end": 31
                    },
                    {
                        "rank": 3,
                        "start": 31,
                        "end": 41
                    },
                    {
                        "rank": 4,
                        "start": 41,
                        "end": 51
                    },
                    {
                        "rank": 5,
                        "start": 51
                    }
                ],
                "created_at": "2022-11-28 16:04:11",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": ""
            },
            {
                "id": 4,
                "name_zh": "坐地前彎",
                "name_en": "Sit and Reach",
                "code": "sitnreach",
                "fitness_type": "flexibility",
                "fitness_type_en": "Sit and Reach",
                "fitness_type_zh": "坐地前彎",
                "unit": "cm",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "auto_import": {
                        "type": "mqtt",
                        "devices": [
                            {
                                "name": "SitnReach Device 1",
                                "topic": "microbit/sitnreach/1"
                            }
                        ]
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 18
                    },
                    {
                        "rank": 2,
                        "start": 18,
                        "end": 28
                    },
                    {
                        "rank": 3,
                        "start": 28,
                        "end": 38
                    },
                    {
                        "rank": 4,
                        "start": 38,
                        "end": 48
                    },
                    {
                        "rank": 5,
                        "start": 48
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 18
                    },
                    {
                        "rank": 2,
                        "start": 18,
                        "end": 28
                    },
                    {
                        "rank": 3,
                        "start": 28,
                        "end": 38
                    },
                    {
                        "rank": 4,
                        "start": 38,
                        "end": 48
                    },
                    {
                        "rank": 5,
                        "start": 48
                    }
                ],
                "created_at": "2022-12-02 18:12:46",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": ""
            },
            {
                "id": 7,
                "name_zh": "手握力",
                "name_en": "Hand Grip",
                "code": "handgrip",
                "fitness_type": "grip_power",
                "fitness_type_en": "Hand Grip",
                "fitness_type_zh": "手握力",
                "unit": "kg",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "file_import": {
                        "parser": "HandGripParser"
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 40
                    },
                    {
                        "rank": 2,
                        "start": 40,
                        "end": 48
                    },
                    {
                        "rank": 3,
                        "start": 48,
                        "end": 56
                    },
                    {
                        "rank": 4,
                        "start": 56,
                        "end": 64
                    },
                    {
                        "rank": 5,
                        "start": 64
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "start": 0,
                        "end": 31
                    },
                    {
                        "rank": 2,
                        "start": 31,
                        "end": 41
                    },
                    {
                        "rank": 3,
                        "start": 41,
                        "end": 51
                    },
                    {
                        "rank": 4,
                        "start": 51,
                        "end": 60
                    },
                    {
                        "rank": 5,
                        "start": 60
                    }
                ],
                "created_at": "2022-12-02 18:12:46",
                "updated_at": "2023-12-04 17:47:01",
                "deleted_at": ""
            },
            {
                "id": 8,
                "name_zh": "單腳平衡測試",
                "name_en": "Single-leg Balance",
                "code": "balance",
                "fitness_type": "balance",
                "fitness_type_en": "Single-leg Balance",
                "fitness_type_zh": "單腳平衡測試",
                "unit": "pt",
                "decimal_place": 0,
                "rank_direction": "desc",
                "import_setting": {
                    "auto_import": {
                        "type": "mqtt",
                        "devices": [
                            {
                                "name": "Balance Device 1",
                                "topic": "balance-device-1"
                            }
                        ]
                    }
                },
                "skill_rank_male": [
                    {
                        "rank": 1,
                        "end": 41
                    },
                    {
                        "rank": 2,
                        "start": 41,
                        "end": 56
                    },
                    {
                        "rank": 3,
                        "start": 56,
                        "end": 71
                    },
                    {
                        "rank": 4,
                        "start": 71,
                        "end": 86
                    },
                    {
                        "rank": 5,
                        "start": 86
                    }
                ],
                "skill_rank_female": [
                    {
                        "rank": 1,
                        "end": 41
                    },
                    {
                        "rank": 2,
                        "start": 41,
                        "end": 56
                    },
                    {
                        "rank": 3,
                        "start": 56,
                        "end": 71
                    },
                    {
                        "rank": 4,
                        "start": 71,
                        "end": 86
                    },
                    {
                        "rank": 5,
                        "start": 86
                    }
                ],
                "created_at": "2022-12-02 18:12:46",
                "updated_at": "2023-12-04 17:47:02",
                "deleted_at": ""
            }
        ]
    }
}

const checkInputResult = () => {
    return {
        "memberEventInfo": {
            "id": 8,
            "access_id": "20221129DCDJBCBJ",
            "short_access_id": "BCBJ",
            "event_id": 1,
            "member_id": 1,
            "created_at": "2022-11-29 18:08:25",
            "updated_at": "2022-11-29 18:08:25",
            "deleted_at": "",
            "skill_score": 23,
            "rank": 3
        },
        "member": {
            "id": 1,
            "join_time": "2021-11-23 08:14:33",
            "member_id": "",
            "user_type": "public",
            "display_name": "test",
            "email": "test@gmail.com",
            "phone_num": "91239123",
            "gender": "M",
            "dob": "2011-01-01",
            "created_at": "2022-11-25 17:58:16",
            "updated_at": "2022-11-25 17:58:16",
            "deleted_at": ""
        },
        "skillInfo": {
            "id": 1,
            "name_zh": "Sit Up",
            "name_en": "Sit Up",
            "code": "situp",
            "fitness_type_en": "Upper Body Endurance",
            "fitness_type_zh": "Upper Body Endurance",
            "unit": "time",
            "decimal_place": 0,
            "skill_rank_male": [
                {
                    "rank": 1,
                    "start": 0,
                    "end": 10
                },
                {
                    "rank": 2,
                    "start": 10,
                    "end": 20
                },
                {
                    "rank": 3,
                    "start": 20,
                    "end": 30
                },
                {
                    "rank": 4,
                    "start": 30,
                    "end": 40
                },
                {
                    "rank": 5,
                    "start": 40
                }
            ],
            "skill_rank_female": [
                {
                    "rank": 1,
                    "start": 0,
                    "end": 10
                },
                {
                    "rank": 2,
                    "start": 10,
                    "end": 20
                },
                {
                    "rank": 3,
                    "start": 20,
                    "end": 30
                },
                {
                    "rank": 4,
                    "start": 30,
                    "end": 40
                },
                {
                    "rank": 5,
                    "start": 40
                }
            ],
            "created_at": "2022-11-28 07:47:19",
            "updated_at": "2022-11-29 20:55:19",
            "deleted_at": "2022-11-28 07:47:19"
        },
        "skillValue": 23

    }
}

const getSkillInfoList = () => {
    return [
        {
            "id": 1,
            "name_zh": "Sit Up",
            "name_en": "Sit Up",
            "code": "situp",
            "fitness_type_en": "Upper Body Endurance",
            "fitness_type_zh": "Upper Body Endurance",
            "unit": "time",
            "decimal_place": 0,
            "skill_rank_male": [
                {
                    "rank": 1,
                    "start": 0,
                    "end": 10
                },
                {
                    "rank": 2,
                    "start": 10,
                    "end": 20
                },
                {
                    "rank": 3,
                    "start": 20,
                    "end": 30
                },
                {
                    "rank": 4,
                    "start": 30,
                    "end": 40
                },
                {
                    "rank": 5,
                    "start": 40
                }
            ],
            "skill_rank_female": [
                {
                    "rank": 1,
                    "start": 0,
                    "end": 10
                },
                {
                    "rank": 2,
                    "start": 10,
                    "end": 20
                },
                {
                    "rank": 3,
                    "start": 20,
                    "end": 30
                },
                {
                    "rank": 4,
                    "start": 30,
                    "end": 40
                },
                {
                    "rank": 5,
                    "start": 40
                }
            ],
            "created_at": "2022-11-28 07:47:19",
            "updated_at": "2022-11-29 20:55:19",
            "deleted_at": "2022-11-28 07:47:19"
        },
        {
            "id": 2,
            "name_zh": "Vertical Jump",
            "name_en": "Vertical Jump",
            "code": "vjump",
            "fitness_type_en": "Upper Body Endurance",
            "fitness_type_zh": "Upper Body Endurance",
            "unit": "m",
            "decimal_place": 2,
            "skill_rank_male": [
                {
                    "rank": 1,
                    "start": 0,
                    "end": 10
                },
                {
                    "rank": 2,
                    "start": 10,
                    "end": 20
                },
                {
                    "rank": 3,
                    "start": 20,
                    "end": 30
                },
                {
                    "rank": 4,
                    "start": 30,
                    "end": 40
                },
                {
                    "rank": 5,
                    "start": 40
                }
            ],
            "skill_rank_female": [
                {
                    "rank": 1,
                    "start": 0,
                    "end": 10
                },
                {
                    "rank": 2,
                    "start": 10,
                    "end": 20
                },
                {
                    "rank": 3,
                    "start": 20,
                    "end": 30
                },
                {
                    "rank": 4,
                    "start": 30,
                    "end": 40
                },
                {
                    "rank": 5,
                    "start": 40
                }
            ],
            "created_at": "2022-11-28 16:04:11",
            "updated_at": "2022-11-29 20:55:19",
            "deleted_at": ""
        }
    ]

}

const getMemberEventInfo = () => {
    return {
        "id": 4,
        "access_id": "202211270000000004",
        "short_access_id": "NAXZ",
        "event_id": 1,
        "member_id": 1,
        "created_at": "2022-11-27 20:42:11",
        "updated_at": "2022-11-27 20:42:11",
        "deleted_at": "",
        "event": {
            "id": 1,
            "name_en": "VTC Demo Day",
            "name_zh": "VTC 資訊日",
            "type": "public",
            "start_at": "2021-11-23 00:14:33",
            "end_at": "2021-12-20 23:59:00",
            "created_at": "2022-11-26 12:19:51",
            "updated_at": "2022-11-26 12:19:51",
            "deleted_at": ""
        },
        "member": {
            "id": 1,
            "join_time": "2021-11-23 08:14:33",
            "member_id": "",
            "user_type": "public",
            "display_name": "test",
            "email": "test@gmail.com",
            "password_hash": "2fe42f65bdc2ff4d87ccf1e99ea791fbe49eba44",
            "phone_num": "91239123",
            "gender": "M",
            "dob": "2011-01-01",
            "created_at": "2022-11-25 17:58:16",
            "updated_at": "2022-11-25 17:58:16",
            "deleted_at": ""
        },
        "skillInfoList": [
            {
                "id": "1",
                "member_event_info_id": 4,
                "skill_code": "situp",
                "skill_score": 30,
                "created_at": "2022-11-28 08:08:35",
                "updated_at": "2022-11-28 08:08:35",
                "deleted_at": "",
                "skill": {
                    "id": "1",
                    "fitness_type_zh": "Body Endurance",
                    "fitness_type_en": "Body Endurance",
                    "name_zh": "Sit Up",
                    "name_en": "Sit Up",
                    "code": "situp",
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-11-28 07:47:19",
                    "updated_at": "2022-11-28 16:04:11",
                    "deleted_at": "2022-11-28 07:47:19"
                },
                "rank": 4
            },
            {
                "id": "2",
                "member_event_info_id": 4,
                "skill_code": "vjump",
                "skill_score": 40,
                "created_at": "2022-11-28 08:08:35",
                "updated_at": "2022-11-28 08:08:35",
                "deleted_at": "",
                "skill": {
                    "id": "2",
                    "name_zh": "Vertical Jump",
                    "name_en": "Vertical Jump",
                    "code": "vjump",
                    "skill_rank_male": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "skill_rank_female": [
                        {
                            "rank": 1,
                            "start": 0,
                            "end": 10
                        },
                        {
                            "rank": 2,
                            "start": 10,
                            "end": 20
                        },
                        {
                            "rank": 3,
                            "start": 20,
                            "end": 30
                        },
                        {
                            "rank": 4,
                            "start": 30,
                            "end": 40
                        },
                        {
                            "rank": 5,
                            "start": 40
                        }
                    ],
                    "created_at": "2022-11-28 16:04:11",
                    "updated_at": "2022-11-28 16:04:11",
                    "deleted_at": ""
                },
                "rank": 5
            }
        ]
    };
}

const getLeaderboardEventList = () => {
    return [
        {
            "id": 9,
            "name_en": "VJUMP Together",
            "name_zh": "VJUMP Together",
            "type": "org",
            "organization_id": 2,
            "start_at": "2023-03-05 07:51:11",
            "end_at": "2023-03-31 07:51:11",
            "created_at": "2023-03-05 15:51:36",
            "updated_at": "2023-03-05 15:51:36",
            "deleted_at": "",
            "timeslotList": [
                {
                    "id": 1,
                    "timeslot_caption": "活動時段",
                    "start_at": "00:00",
                    "end_at": "23:15",
                    "break_time": false
                }
            ],
            "leaderboardSetting": [
                {
                    "id": 1,
                    "skill_name": "拳擊視頻跟練",
                    "skill_code": "moveit_coach",
                    "color": "orange",
                    "unit": "分"
                },
                {
                    "id": 2,
                    "skill_name": "拳擊答題",
                    "skill_code": "moveit_quiz",
                    "color": "orange",
                    "unit": "分"
                },
                {
                    "id": 3,
                    "skill_name": "AI拳擊",
                    "skill_code": "moveit_ai_punch",
                    "color": "orange",
                    "unit": "分"
                },
                {
                    "id": 4,
                    "skill_name": "Sit and Reach Test",
                    "skill_code": "sitnreach",
                    "color": "purple",
                    "unit": "cm"
                },
                {
                    "id": 5,
                    "skill_name": "Smart Vertical Jump",
                    "skill_code": "vjump",
                    "color": "blue",
                    "unit": "cm"
                }
            ]
        },
        {
            "id": 14,
            "name_en": "智能運動體驗日",
            "name_zh": "智能運動體驗日",
            "type": "public",
            "organization_id": 56,
            "start_at": "2023-04-22 14:00:19",
            "end_at": "2023-05-30 13:00:19",
            "created_at": "2023-04-21 16:34:54",
            "updated_at": "2023-04-21 16:36:34",
            "deleted_at": "",
            "timeslotList": [
                {
                    "id": 1,
                    "timeslot_caption": "活動時段",
                    "start_at": "00:00",
                    "end_at": "23:15",
                    "break_time": false
                }
            ],
            "leaderboardSetting": [
                {
                    "id": 1,
                    "skill_name": "拳擊視頻跟練",
                    "skill_code": "moveit_coach",
                    "color": "orange",
                    "unit": "分"
                },
                {
                    "id": 2,
                    "skill_name": "拳擊答題",
                    "skill_code": "moveit_quiz",
                    "color": "orange",
                    "unit": "分"
                },
                {
                    "id": 3,
                    "skill_name": "AI拳擊",
                    "skill_code": "moveit_ai_punch",
                    "color": "orange",
                    "unit": "分"
                },
                {
                    "id": 4,
                    "skill_name": "Sit and Reach Test",
                    "skill_code": "sitnreach",
                    "color": "purple",
                    "unit": "cm"
                },
                {
                    "id": 5,
                    "skill_name": "Smart Vertical Jump",
                    "skill_code": "vjump",
                    "color": "blue",
                    "unit": "cm"
                }
            ]
        }
    ];
}

const getEventList = () => {
    return [
        {
            "id": 1,
            "name_en": "VTC Future Skill Expo",
            "name_zh": "VTC 新世代新技能博覽",
            "type": "public",
            "start_at": "2022-12-09 00:14:33",
            "end_at": "2022-12-11 23:59:00",
            "created_at": "2022-11-26 12:19:51",
            "updated_at": "2022-11-26 12:19:51",
            "deleted_at": "",
            "timeslotList": [
                {
                    "id": 1,
                    "timeslot_caption": "09:00 - 10:00",
                    "start_at": "08:00",
                    "end_at": "09:55",
                    "break_time": false
                },
                {
                    "id": 2,
                    "timeslot_caption": "10:00 - 11:00",
                    "start_at": "09:55",
                    "end_at": "10:55",
                    "break_time": false
                },
                {
                    "id": 3,
                    "timeslot_caption": "11:00 - 12:00",
                    "start_at": "10:55",
                    "end_at": "11:55",
                    "break_time": false
                },
                {
                    "id": 4,
                    "timeslot_caption": "12:00 - 14:00",
                    "break_time": true,
                    "message": "比賽在14:00後繼續"
                },
                {
                    "id": 5,
                    "timeslot_caption": "14:00 - 15:00",
                    "start_at": "11:55",
                    "end_at": "14:55",
                    "break_time": false
                },
                {
                    "id": 6,
                    "timeslot_caption": "15:00 - 16:00",
                    "start_at": "14:55",
                    "end_at": "15:55",
                    "break_time": false
                },
                {
                    "id": 7,
                    "timeslot_caption": "16:00 - 17:00",
                    "start_at": "15:55",
                    "end_at": "16:55",
                    "break_time": false
                }
            ],
            "leaderboardSetting": [
                {
                    "id": 1,
                    "skill_name": "Smart Sit-up",
                    "skill_code": "situp",
                    "color": "orange",
                    "unit": "次"
                },
                {
                    "id": 2,
                    "skill_name": "Balance Challenge ",
                    "skill_code": "balance",
                    "color": "purple",
                    "unit": ""
                },
                {
                    "id": 3,
                    "skill_name": "Smart Vertical Jump",
                    "skill_code": "vjump",
                    "color": "blue",
                    "unit": "cm"
                },
                {
                    "id": 4,
                    "skill_name": "Smart Cycling ",
                    "skill_code": "bike",
                    "color": "green",
                    "unit": "MPH"
                }
            ]
        },
        {
            "id": 2,
            "name_en": "VTC Demo Day 2020",
            "name_zh": "VTC 資訊日 2020",
            "type": "public",
            "start_at": "2020-11-22 00:14:33",
            "end_at": "2020-12-20 23:59:00",
            "created_at": "2022-11-26 12:26:18",
            "updated_at": "2022-11-26 12:26:18",
            "deleted_at": "",
            "timeslotList": [
                {
                    "id": 1,
                    "timeslot_caption": "09:00 - 10:00",
                    "start_at": "08:00",
                    "end_at": "09:55",
                    "break_time": false
                },
                {
                    "id": 2,
                    "timeslot_caption": "10:00 - 11:00",
                    "start_at": "09:55",
                    "end_at": "10:55",
                    "break_time": false
                },
                {
                    "id": 3,
                    "timeslot_caption": "11:00 - 12:00",
                    "start_at": "10:55",
                    "end_at": "11:55",
                    "break_time": false
                },
                {
                    "id": 4,
                    "timeslot_caption": "12:00 - 14:00",
                    "break_time": true,
                    "message": "比賽在14:00後繼續"
                },
                {
                    "id": 5,
                    "timeslot_caption": "14:00 - 15:00",
                    "start_at": "11:55",
                    "end_at": "14:55",
                    "break_time": false
                },
                {
                    "id": 6,
                    "timeslot_caption": "15:00 - 16:00",
                    "start_at": "14:55",
                    "end_at": "15:55",
                    "break_time": false
                },
                {
                    "id": 7,
                    "timeslot_caption": "16:00 - 17:00",
                    "start_at": "15:55",
                    "end_at": "16:55",
                    "break_time": false
                }
            ],
            "leaderboardSetting": [
                {
                    "id": 1,
                    "skill_name": "Smart Sit-up",
                    "skill_code": "situp",
                    "color": "orange",
                    "unit": "次"
                },
                {
                    "id": 2,
                    "skill_name": "Balance Challenge ",
                    "skill_code": "balance",
                    "color": "purple",
                    "unit": ""
                },
                {
                    "id": 3,
                    "skill_name": "Smart Vertical Jump",
                    "skill_code": "vjump",
                    "color": "blue",
                    "unit": "cm"
                },
                {
                    "id": 4,
                    "skill_name": "Smart Cycling ",
                    "skill_code": "bike",
                    "color": "green",
                    "unit": "MPH"
                }
            ]
        }
    ];
}

const getHealthData = () => {
    return {
        "lastAcccessTime": "2022/12/04 11:00:00",
        "data": [
            {
                "model": "MC-980",
                "id_no": "92771219",
                "status": "0",
                "date": "2022/12/04",
                "time": "12:43",
                "body_type": "0",
                "gender": "1",
                "age": "22",
                "height": "168.0",
                "clothes_weight": "0.0",
                "weight": "53.1",
                "fat_p": "7.1",
                "fat_mass": "3.8",
                "ffm": "49.3",
                "muscle_mass": "46.7",
                "muscle_score": "-1",
                "bone_mass": "2.6",
                "tbw": "34.5",
                "tbw_p": "65.0",
                "icw": "21.6",
                "ecw": "12.9",
                "ecw_p": "37.4",
                "bmi": "18.8",
                "standard_body_weight": "62.1",
                "degree_of_obesity": "-14.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "48.8",
                "visceral_fat_rating": "1",
                "leg_muscle_score": "118",
                "bmr_kcal": "1399",
                "bmr_kj": "5853",
                "bmr_score": "16",
                "metabolic_age": "16",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "59.4",
                "predicted_fat_mass": "10.1",
                "fat_to_lose__gain": "6.3",
                "rl_fat_p": "9.3",
                "rl_fat_mass": "1.0",
                "rl_ffm": "9.4",
                "rl_muscle_mass": "8.9",
                "rl_fat_p_score": "-3",
                "rl_muscle_score": "0",
                "ll_fat_p": "8.0",
                "ll_fat_mass": "0.8",
                "ll_ffm": "9.5",
                "ll_muscle_mass": "9.0",
                "ll_fat_p_score": "-3",
                "ll_muscle_score": "0",
                "ra_fat_p": "5.4",
                "ra_fat_mass": "0.1",
                "ra_ffm": "2.6",
                "ra_muscle_mass": "2.4",
                "ra_fat_p_score": "-3",
                "ra_muscle_score": "0",
                "la_fat_p": "5.7",
                "la_fat_mass": "0.2",
                "la_ffm": "2.5",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "-3",
                "la_muscle_score": "-1",
                "trunk_fat_p": "6.3",
                "trunk_fat_mass": "1.7",
                "trunk_ffm": "25.3",
                "trunk_muscle_mass": "24.1",
                "trunk_fat_p_score": "-3",
                "trunk_muscle_score": "-2",
                "username": "FU",
                "dummy1": "1999/08/23"
            },
            {
                "model": "MC-980",
                "id_no": "92771219",
                "status": "0",
                "date": "2022/12/04",
                "time": "12:45",
                "body_type": "0",
                "gender": "1",
                "age": "22",
                "height": "168.0",
                "clothes_weight": "0.0",
                "weight": "52.9",
                "fat_p": "6.5",
                "fat_mass": "3.4",
                "ffm": "49.5",
                "muscle_mass": "46.9",
                "muscle_score": "-1",
                "bone_mass": "2.6",
                "tbw": "34.8",
                "tbw_p": "65.8",
                "icw": "21.8",
                "ecw": "13.0",
                "ecw_p": "37.4",
                "bmi": "18.7",
                "standard_body_weight": "62.1",
                "degree_of_obesity": "-14.8",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "48.8",
                "visceral_fat_rating": "1",
                "leg_muscle_score": "118",
                "bmr_kcal": "1402",
                "bmr_kj": "5866",
                "bmr_score": "16",
                "metabolic_age": "16",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "59.6",
                "predicted_fat_mass": "10.1",
                "fat_to_lose__gain": "6.7",
                "rl_fat_p": "9.0",
                "rl_fat_mass": "0.9",
                "rl_ffm": "9.4",
                "rl_muscle_mass": "8.9",
                "rl_fat_p_score": "-3",
                "rl_muscle_score": "0",
                "ll_fat_p": "7.6",
                "ll_fat_mass": "0.8",
                "ll_ffm": "9.5",
                "ll_muscle_mass": "9.0",
                "ll_fat_p_score": "-3",
                "ll_muscle_score": "0",
                "ra_fat_p": "5.4",
                "ra_fat_mass": "0.1",
                "ra_ffm": "2.5",
                "ra_muscle_mass": "2.3",
                "ra_fat_p_score": "-3",
                "ra_muscle_score": "-1",
                "la_fat_p": "5.1",
                "la_fat_mass": "0.1",
                "la_ffm": "2.5",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "-3",
                "la_muscle_score": "-1",
                "trunk_fat_p": "5.5",
                "trunk_fat_mass": "1.5",
                "trunk_ffm": "25.6",
                "trunk_muscle_mass": "24.4",
                "trunk_fat_p_score": "-3",
                "trunk_muscle_score": "-2",
                "username": "FU",
                "dummy1": "1999/08/23"
            },
            {
                "model": "MC-980",
                "id_no": "92771219",
                "status": "0",
                "date": "2022/12/04",
                "time": "12:48",
                "body_type": "0",
                "gender": "1",
                "age": "22",
                "height": "168.0",
                "clothes_weight": "0.0",
                "weight": "53.1",
                "fat_p": "7.7",
                "fat_mass": "4.1",
                "ffm": "49.0",
                "muscle_mass": "46.4",
                "muscle_score": "-1",
                "bone_mass": "2.6",
                "tbw": "34.1",
                "tbw_p": "64.2",
                "icw": "21.3",
                "ecw": "12.8",
                "ecw_p": "37.5",
                "bmi": "18.8",
                "standard_body_weight": "62.1",
                "degree_of_obesity": "-14.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "48.8",
                "visceral_fat_rating": "1",
                "leg_muscle_score": "116",
                "bmr_kcal": "1391",
                "bmr_kj": "5820",
                "bmr_score": "16",
                "metabolic_age": "16",
                "balancearm": "0",
                "balanceleg": "-1",
                "target_fat_p": "17",
                "predicted_weight": "59.0",
                "predicted_fat_mass": "10.0",
                "fat_to_lose__gain": "5.9",
                "rl_fat_p": "10.4",
                "rl_fat_mass": "1.1",
                "rl_ffm": "9.2",
                "rl_muscle_mass": "8.7",
                "rl_fat_p_score": "-3",
                "rl_muscle_score": "0",
                "ll_fat_p": "8.8",
                "ll_fat_mass": "0.9",
                "ll_ffm": "9.4",
                "ll_muscle_mass": "8.9",
                "ll_fat_p_score": "-3",
                "ll_muscle_score": "0",
                "ra_fat_p": "6.3",
                "ra_fat_mass": "0.2",
                "ra_ffm": "2.5",
                "ra_muscle_mass": "2.3",
                "ra_fat_p_score": "-3",
                "ra_muscle_score": "-1",
                "la_fat_p": "6.0",
                "la_fat_mass": "0.2",
                "la_ffm": "2.5",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "-3",
                "la_muscle_score": "-1",
                "trunk_fat_p": "6.3",
                "trunk_fat_mass": "1.7",
                "trunk_ffm": "25.4",
                "trunk_muscle_mass": "24.2",
                "trunk_fat_p_score": "-3",
                "trunk_muscle_score": "-2",
                "username": "FU",
                "dummy1": "1999/08/23"
            },
            {
                "model": "MC-980",
                "id_no": "210009498",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:46",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "180.0",
                "clothes_weight": "0.0",
                "weight": "64.5",
                "fat_p": "19.6",
                "fat_mass": "12.6",
                "ffm": "51.9",
                "muscle_mass": "49.2",
                "muscle_score": "-2",
                "bone_mass": "2.7",
                "tbw": "32.5",
                "tbw_p": "50.4",
                "icw": "19.6",
                "ecw": "12.9",
                "ecw_p": "39.7",
                "bmi": "19.9",
                "standard_body_weight": "71.3",
                "degree_of_obesity": "-9.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "56.1",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "106",
                "bmr_kcal": "1506",
                "bmr_kj": "6301",
                "bmr_score": "8",
                "metabolic_age": "22",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "62.5",
                "predicted_fat_mass": "10.6",
                "fat_to_lose__gain": "-2.0",
                "rl_fat_p": "16.6",
                "rl_fat_mass": "2.1",
                "rl_ffm": "10.5",
                "rl_muscle_mass": "9.9",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "-1",
                "ll_fat_p": "16.9",
                "ll_fat_mass": "2.1",
                "ll_ffm": "10.4",
                "ll_muscle_mass": "9.8",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "-1",
                "ra_fat_p": "15.2",
                "ra_fat_mass": "0.4",
                "ra_ffm": "2.5",
                "ra_muscle_mass": "2.3",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "-2",
                "la_fat_p": "15.5",
                "la_fat_mass": "0.4",
                "la_ffm": "2.4",
                "la_muscle_mass": "2.2",
                "la_fat_p_score": "0",
                "la_muscle_score": "-3",
                "trunk_fat_p": "22.6",
                "trunk_fat_mass": "7.6",
                "trunk_ffm": "26.1",
                "trunk_muscle_mass": "25.0",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-3",
                "username": "CHUNG_WANG_KIN",
                "dummy1": "2001/10/19"
            },
            {
                "model": "MC-980",
                "id_no": "210035062",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:16",
                "body_type": "0",
                "gender": "2",
                "age": "18",
                "height": "155.0",
                "clothes_weight": "0.0",
                "weight": "53.6",
                "fat_p": "27.3",
                "fat_mass": "14.6",
                "ffm": "39.0",
                "muscle_mass": "36.7",
                "muscle_score": "1",
                "bone_mass": "2.3",
                "tbw": "28.2",
                "tbw_p": "52.6",
                "icw": "17.5",
                "ecw": "10.7",
                "ecw_p": "37.9",
                "bmi": "22.3",
                "standard_body_weight": "52.9",
                "degree_of_obesity": "1.3",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "35.9",
                "visceral_fat_rating": "1",
                "leg_muscle_score": "110",
                "bmr_kcal": "1232",
                "bmr_kj": "5155",
                "bmr_score": "9",
                "metabolic_age": "19",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "28",
                "predicted_weight": "54.2",
                "predicted_fat_mass": "15.2",
                "fat_to_lose__gain": "0.6",
                "rl_fat_p": "29.8",
                "rl_fat_mass": "3.3",
                "rl_ffm": "7.8",
                "rl_muscle_mass": "7.3",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "2",
                "ll_fat_p": "29.5",
                "ll_fat_mass": "3.3",
                "ll_ffm": "7.8",
                "ll_muscle_mass": "7.3",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "2",
                "ra_fat_p": "23.9",
                "ra_fat_mass": "0.6",
                "ra_ffm": "1.8",
                "ra_muscle_mass": "1.7",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "0",
                "la_fat_p": "24.5",
                "la_fat_mass": "0.6",
                "la_ffm": "1.7",
                "la_muscle_mass": "1.6",
                "la_fat_p_score": "-1",
                "la_muscle_score": "0",
                "trunk_fat_p": "25.5",
                "trunk_fat_mass": "6.8",
                "trunk_ffm": "19.9",
                "trunk_muscle_mass": "18.8",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-1",
                "username": "FU_NGA_YIN",
                "dummy1": "2002/11/14"
            },
            {
                "model": "MC-980",
                "id_no": "210044808",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:00",
                "body_type": "0",
                "gender": "1",
                "age": "18",
                "height": "174.0",
                "clothes_weight": "0.0",
                "weight": "75.5",
                "fat_p": "14.7",
                "fat_mass": "11.1",
                "ffm": "64.4",
                "muscle_mass": "61.1",
                "muscle_score": "2",
                "bone_mass": "3.3",
                "tbw": "48.4",
                "tbw_p": "64.1",
                "icw": "32.0",
                "ecw": "16.4",
                "ecw_p": "33.9",
                "bmi": "24.9",
                "standard_body_weight": "66.6",
                "degree_of_obesity": "13.4",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "52.4",
                "visceral_fat_rating": "6",
                "leg_muscle_score": "110",
                "bmr_kcal": "1896",
                "bmr_kj": "7933",
                "bmr_score": "11",
                "metabolic_age": "17",
                "balancearm": "2",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "77.6",
                "predicted_fat_mass": "13.2",
                "fat_to_lose__gain": "2.1",
                "rl_fat_p": "15.5",
                "rl_fat_mass": "2.5",
                "rl_ffm": "13.5",
                "rl_muscle_mass": "12.8",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "3",
                "ll_fat_p": "14.7",
                "ll_fat_mass": "2.3",
                "ll_ffm": "13.5",
                "ll_muscle_mass": "12.8",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "3",
                "ra_fat_p": "12.2",
                "ra_fat_mass": "0.5",
                "ra_ffm": "3.7",
                "ra_muscle_mass": "3.5",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "3",
                "la_fat_p": "13.7",
                "la_fat_mass": "0.5",
                "la_ffm": "3.4",
                "la_muscle_mass": "3.2",
                "la_fat_p_score": "0",
                "la_muscle_score": "2",
                "trunk_fat_p": "14.9",
                "trunk_fat_mass": "5.3",
                "trunk_ffm": "30.3",
                "trunk_muscle_mass": "28.8",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "2",
                "username": "SIT_CHUN_MAN",
                "dummy1": "2002/10/24"
            },
            {
                "model": "MC-980",
                "id_no": "210046040",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:44",
                "body_type": "0",
                "gender": "1",
                "age": "18",
                "height": "180.0",
                "clothes_weight": "0.0",
                "weight": "78.0",
                "fat_p": "17.2",
                "fat_mass": "13.4",
                "ffm": "64.6",
                "muscle_mass": "61.3",
                "muscle_score": "1",
                "bone_mass": "3.3",
                "tbw": "46.0",
                "tbw_p": "59.0",
                "icw": "29.9",
                "ecw": "16.1",
                "ecw_p": "35.0",
                "bmi": "24.1",
                "standard_body_weight": "71.3",
                "degree_of_obesity": "9.4",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "56.1",
                "visceral_fat_rating": "6",
                "leg_muscle_score": "105",
                "bmr_kcal": "1907",
                "bmr_kj": "7979",
                "bmr_score": "9",
                "metabolic_age": "17",
                "balancearm": "1",
                "balanceleg": "-1",
                "target_fat_p": "17",
                "predicted_weight": "77.8",
                "predicted_fat_mass": "13.2",
                "fat_to_lose__gain": "-0.2",
                "rl_fat_p": "19.6",
                "rl_fat_mass": "3.0",
                "rl_ffm": "12.5",
                "rl_muscle_mass": "11.8",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "2",
                "ll_fat_p": "18.3",
                "ll_fat_mass": "2.9",
                "ll_ffm": "12.8",
                "ll_muscle_mass": "12.1",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "2",
                "ra_fat_p": "12.3",
                "ra_fat_mass": "0.5",
                "ra_ffm": "3.8",
                "ra_muscle_mass": "3.6",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "3",
                "la_fat_p": "14.1",
                "la_fat_mass": "0.6",
                "la_ffm": "3.5",
                "la_muscle_mass": "3.3",
                "la_fat_p_score": "0",
                "la_muscle_score": "2",
                "trunk_fat_p": "16.7",
                "trunk_fat_mass": "6.4",
                "trunk_ffm": "32.0",
                "trunk_muscle_mass": "30.5",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "1",
                "username": "LAM_CHING_PUI",
                "dummy1": "2002/10/27"
            },
            {
                "model": "MC-980",
                "id_no": "210062887",
                "status": "0",
                "date": "2022/12/04",
                "time": "14:07",
                "body_type": "0",
                "gender": "2",
                "age": "19",
                "height": "157.5",
                "clothes_weight": "0.0",
                "weight": "47.7",
                "fat_p": "29.0",
                "fat_mass": "13.8",
                "ffm": "33.9",
                "muscle_mass": "32.1",
                "muscle_score": "-2",
                "bone_mass": "1.8",
                "tbw": "23.0",
                "tbw_p": "48.2",
                "icw": "13.8",
                "ecw": "9.2",
                "ecw_p": "40.0",
                "bmi": "19.2",
                "standard_body_weight": "54.6",
                "degree_of_obesity": "-12.6",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "37.0",
                "visceral_fat_rating": "2",
                "leg_muscle_score": "108",
                "bmr_kcal": "1084",
                "bmr_kj": "4535",
                "bmr_score": "9",
                "metabolic_age": "19",
                "balancearm": "1",
                "balanceleg": "2",
                "target_fat_p": "28",
                "predicted_weight": "47.1",
                "predicted_fat_mass": "13.2",
                "fat_to_lose__gain": "-0.6",
                "rl_fat_p": "29.7",
                "rl_fat_mass": "3.0",
                "rl_ffm": "7.2",
                "rl_muscle_mass": "6.8",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "0",
                "ll_fat_p": "31.6",
                "ll_fat_mass": "3.1",
                "ll_ffm": "6.7",
                "ll_muscle_mass": "6.3",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "0",
                "ra_fat_p": "23.7",
                "ra_fat_mass": "0.5",
                "ra_ffm": "1.5",
                "ra_muscle_mass": "1.4",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "-2",
                "la_fat_p": "25.9",
                "la_fat_mass": "0.5",
                "la_ffm": "1.4",
                "la_muscle_mass": "1.3",
                "la_fat_p_score": "0",
                "la_muscle_score": "-2",
                "trunk_fat_p": "28.2",
                "trunk_fat_mass": "6.7",
                "trunk_ffm": "17.1",
                "trunk_muscle_mass": "16.3",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-4",
                "username": "FAN_SHUN_YU",
                "dummy1": "2001/12/26"
            },
            {
                "model": "MC-980",
                "id_no": "210069655",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:51",
                "body_type": "0",
                "gender": "2",
                "age": "18",
                "height": "159.0",
                "clothes_weight": "0.0",
                "weight": "58.5",
                "fat_p": "30.2",
                "fat_mass": "17.7",
                "ffm": "40.8",
                "muscle_mass": "38.4",
                "muscle_score": "0",
                "bone_mass": "2.4",
                "tbw": "29.1",
                "tbw_p": "49.7",
                "icw": "17.8",
                "ecw": "11.3",
                "ecw_p": "38.8",
                "bmi": "23.1",
                "standard_body_weight": "55.6",
                "degree_of_obesity": "5.2",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "37.7",
                "visceral_fat_rating": "3",
                "leg_muscle_score": "107",
                "bmr_kcal": "1299",
                "bmr_kj": "5435",
                "bmr_score": "8",
                "metabolic_age": "22",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "28",
                "predicted_weight": "56.7",
                "predicted_fat_mass": "15.9",
                "fat_to_lose__gain": "-1.8",
                "rl_fat_p": "30.8",
                "rl_fat_mass": "3.6",
                "rl_ffm": "8.2",
                "rl_muscle_mass": "7.7",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "2",
                "ll_fat_p": "30.6",
                "ll_fat_mass": "3.6",
                "ll_ffm": "8.2",
                "ll_muscle_mass": "7.7",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "2",
                "ra_fat_p": "26.9",
                "ra_fat_mass": "0.7",
                "ra_ffm": "1.9",
                "ra_muscle_mass": "1.8",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "0",
                "la_fat_p": "28.3",
                "la_fat_mass": "0.7",
                "la_ffm": "1.8",
                "la_muscle_mass": "1.7",
                "la_fat_p_score": "0",
                "la_muscle_score": "0",
                "trunk_fat_p": "30.5",
                "trunk_fat_mass": "9.1",
                "trunk_ffm": "20.7",
                "trunk_muscle_mass": "19.5",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-1",
                "username": "YEUNG_WING_YU",
                "dummy1": "2002/11/24"
            },
            {
                "model": "MC-980",
                "id_no": "210072101",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:27",
                "body_type": "0",
                "gender": "2",
                "age": "18",
                "height": "172.0",
                "clothes_weight": "0.0",
                "weight": "65.9",
                "fat_p": "32.1",
                "fat_mass": "21.2",
                "ffm": "44.7",
                "muscle_mass": "42.0",
                "muscle_score": "-1",
                "bone_mass": "2.7",
                "tbw": "30.7",
                "tbw_p": "46.6",
                "icw": "18.5",
                "ecw": "12.2",
                "ecw_p": "39.7",
                "bmi": "22.3",
                "standard_body_weight": "65.1",
                "degree_of_obesity": "1.2",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "44.0",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "101",
                "bmr_kcal": "1427",
                "bmr_kj": "5971",
                "bmr_score": "7",
                "metabolic_age": "27",
                "balancearm": "0",
                "balanceleg": "-1",
                "target_fat_p": "28",
                "predicted_weight": "62.1",
                "predicted_fat_mass": "17.4",
                "fat_to_lose__gain": "-3.8",
                "rl_fat_p": "31.7",
                "rl_fat_mass": "4.2",
                "rl_ffm": "9.1",
                "rl_muscle_mass": "8.5",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "1",
                "ll_fat_p": "31.3",
                "ll_fat_mass": "4.2",
                "ll_ffm": "9.2",
                "ll_muscle_mass": "8.6",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "2",
                "ra_fat_p": "29.3",
                "ra_fat_mass": "0.8",
                "ra_ffm": "2.0",
                "ra_muscle_mass": "1.9",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "0",
                "la_fat_p": "31.1",
                "la_fat_mass": "0.9",
                "la_ffm": "2.0",
                "la_muscle_mass": "1.9",
                "la_fat_p_score": "0",
                "la_muscle_score": "0",
                "trunk_fat_p": "33.1",
                "trunk_fat_mass": "11.1",
                "trunk_ffm": "22.4",
                "trunk_muscle_mass": "21.1",
                "trunk_fat_p_score": "1",
                "trunk_muscle_score": "-3",
                "username": "HUI_NGA_KIU",
                "dummy1": "2002/11/15"
            },
            {
                "model": "MC-980",
                "id_no": "210093090",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:49",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "179.0",
                "clothes_weight": "0.0",
                "weight": "78.1",
                "fat_p": "20.6",
                "fat_mass": "16.1",
                "ffm": "62.0",
                "muscle_mass": "58.8",
                "muscle_score": "0",
                "bone_mass": "3.2",
                "tbw": "41.0",
                "tbw_p": "52.5",
                "icw": "25.6",
                "ecw": "15.4",
                "ecw_p": "37.6",
                "bmi": "24.4",
                "standard_body_weight": "70.5",
                "degree_of_obesity": "10.8",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "55.5",
                "visceral_fat_rating": "8",
                "leg_muscle_score": "103",
                "bmr_kcal": "1817",
                "bmr_kj": "7602",
                "bmr_score": "8",
                "metabolic_age": "22",
                "balancearm": "2",
                "balanceleg": "2",
                "target_fat_p": "17",
                "predicted_weight": "74.7",
                "predicted_fat_mass": "12.7",
                "fat_to_lose__gain": "-3.4",
                "rl_fat_p": "18.6",
                "rl_fat_mass": "2.9",
                "rl_ffm": "12.6",
                "rl_muscle_mass": "11.9",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "2",
                "ll_fat_p": "19.4",
                "ll_fat_mass": "2.9",
                "ll_ffm": "12.1",
                "ll_muscle_mass": "11.4",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "1",
                "ra_fat_p": "14.8",
                "ra_fat_mass": "0.6",
                "ra_ffm": "3.3",
                "ra_muscle_mass": "3.1",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "1",
                "la_fat_p": "16.0",
                "la_fat_mass": "0.6",
                "la_ffm": "3.0",
                "la_muscle_mass": "2.8",
                "la_fat_p_score": "0",
                "la_muscle_score": "0",
                "trunk_fat_p": "22.7",
                "trunk_fat_mass": "9.1",
                "trunk_ffm": "31.0",
                "trunk_muscle_mass": "29.6",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "0",
                "username": "NGAI_CHEUK_KI",
                "dummy1": "2000/12/19"
            },
            {
                "model": "MC-980",
                "id_no": "210100141",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:43",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "182.0",
                "clothes_weight": "0.0",
                "weight": "86.3",
                "fat_p": "17.4",
                "fat_mass": "15.0",
                "ffm": "71.3",
                "muscle_mass": "67.6",
                "muscle_score": "2",
                "bone_mass": "3.7",
                "tbw": "53.0",
                "tbw_p": "61.4",
                "icw": "35.1",
                "ecw": "17.9",
                "ecw_p": "33.8",
                "bmi": "26.1",
                "standard_body_weight": "72.9",
                "degree_of_obesity": "18.4",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "57.4",
                "visceral_fat_rating": "8",
                "leg_muscle_score": "104",
                "bmr_kcal": "2116",
                "bmr_kj": "8853",
                "bmr_score": "11",
                "metabolic_age": "17",
                "balancearm": "0",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "85.9",
                "predicted_fat_mass": "14.6",
                "fat_to_lose__gain": "-0.4",
                "rl_fat_p": "19.3",
                "rl_fat_mass": "3.4",
                "rl_ffm": "14.3",
                "rl_muscle_mass": "13.5",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "3",
                "ll_fat_p": "19.3",
                "ll_fat_mass": "3.4",
                "ll_ffm": "14.1",
                "ll_muscle_mass": "13.3",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "3",
                "ra_fat_p": "13.8",
                "ra_fat_mass": "0.7",
                "ra_ffm": "4.1",
                "ra_muscle_mass": "3.8",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "3",
                "la_fat_p": "14.4",
                "la_fat_mass": "0.7",
                "la_ffm": "4.1",
                "la_muscle_mass": "3.9",
                "la_fat_p_score": "0",
                "la_muscle_score": "3",
                "trunk_fat_p": "16.4",
                "trunk_fat_mass": "6.8",
                "trunk_ffm": "34.7",
                "trunk_muscle_mass": "33.1",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "2",
                "username": "CHAN_SAI_WING",
                "dummy1": "2002/04/10"
            },
            {
                "model": "MC-980",
                "id_no": "210102392",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:38",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "171.0",
                "clothes_weight": "0.0",
                "weight": "51.9",
                "fat_p": "9.5",
                "fat_mass": "4.9",
                "ffm": "47.0",
                "muscle_mass": "44.5",
                "muscle_score": "-2",
                "bone_mass": "2.5",
                "tbw": "31.6",
                "tbw_p": "60.9",
                "icw": "19.4",
                "ecw": "12.2",
                "ecw_p": "38.6",
                "bmi": "17.7",
                "standard_body_weight": "64.3",
                "degree_of_obesity": "-19.3",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.6",
                "visceral_fat_rating": "1",
                "leg_muscle_score": "113",
                "bmr_kcal": "1342",
                "bmr_kj": "5615",
                "bmr_score": "15",
                "metabolic_age": "16",
                "balancearm": "2",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "56.6",
                "predicted_fat_mass": "9.6",
                "fat_to_lose__gain": "4.7",
                "rl_fat_p": "11.4",
                "rl_fat_mass": "1.1",
                "rl_ffm": "8.9",
                "rl_muscle_mass": "8.4",
                "rl_fat_p_score": "-2",
                "rl_muscle_score": "-2",
                "ll_fat_p": "10.8",
                "ll_fat_mass": "1.1",
                "ll_ffm": "8.9",
                "ll_muscle_mass": "8.4",
                "ll_fat_p_score": "-3",
                "ll_muscle_score": "-2",
                "ra_fat_p": "6.2",
                "ra_fat_mass": "0.2",
                "ra_ffm": "2.5",
                "ra_muscle_mass": "2.3",
                "ra_fat_p_score": "-3",
                "ra_muscle_score": "-2",
                "la_fat_p": "7.0",
                "la_fat_mass": "0.2",
                "la_ffm": "2.3",
                "la_muscle_mass": "2.1",
                "la_fat_p_score": "-3",
                "la_muscle_score": "-2",
                "trunk_fat_p": "8.6",
                "trunk_fat_mass": "2.3",
                "trunk_ffm": "24.4",
                "trunk_muscle_mass": "23.3",
                "trunk_fat_p_score": "-2",
                "trunk_muscle_score": "-3",
                "username": "CHENG_CHUN_NOK",
                "dummy1": "2000/09/28"
            },
            {
                "model": "MC-980",
                "id_no": "210104490",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:53",
                "body_type": "0",
                "gender": "2",
                "age": "19",
                "height": "163.5",
                "clothes_weight": "0.0",
                "weight": "60.6",
                "fat_p": "33.6",
                "fat_mass": "20.4",
                "ffm": "40.2",
                "muscle_mass": "37.8",
                "muscle_score": "-1",
                "bone_mass": "2.4",
                "tbw": "27.7",
                "tbw_p": "45.7",
                "icw": "16.4",
                "ecw": "11.3",
                "ecw_p": "40.8",
                "bmi": "22.7",
                "standard_body_weight": "58.8",
                "degree_of_obesity": "3.1",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "39.8",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "102",
                "bmr_kcal": "1288",
                "bmr_kj": "5389",
                "bmr_score": "7",
                "metabolic_age": "30",
                "balancearm": "2",
                "balanceleg": "2",
                "target_fat_p": "28",
                "predicted_weight": "55.8",
                "predicted_fat_mass": "15.6",
                "fat_to_lose__gain": "-4.8",
                "rl_fat_p": "32.8",
                "rl_fat_mass": "4.0",
                "rl_ffm": "8.2",
                "rl_muscle_mass": "7.7",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "1",
                "ll_fat_p": "33.1",
                "ll_fat_mass": "4.0",
                "ll_ffm": "8.0",
                "ll_muscle_mass": "7.5",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "1",
                "ra_fat_p": "29.1",
                "ra_fat_mass": "0.8",
                "ra_ffm": "1.9",
                "ra_muscle_mass": "1.8",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "0",
                "la_fat_p": "31.8",
                "la_fat_mass": "0.8",
                "la_ffm": "1.7",
                "la_muscle_mass": "1.6",
                "la_fat_p_score": "1",
                "la_muscle_score": "-1",
                "trunk_fat_p": "34.6",
                "trunk_fat_mass": "10.8",
                "trunk_ffm": "20.4",
                "trunk_muscle_mass": "19.2",
                "trunk_fat_p_score": "1",
                "trunk_muscle_score": "-2",
                "username": "LI_SUET_KAM",
                "dummy1": "2002/05/27"
            },
            {
                "model": "MC-980",
                "id_no": "210105044",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:40",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "177.0",
                "clothes_weight": "0.0",
                "weight": "67.9",
                "fat_p": "12.5",
                "fat_mass": "8.5",
                "ffm": "59.4",
                "muscle_mass": "56.3",
                "muscle_score": "0",
                "bone_mass": "3.1",
                "tbw": "41.9",
                "tbw_p": "61.7",
                "icw": "27.0",
                "ecw": "14.9",
                "ecw_p": "35.6",
                "bmi": "21.7",
                "standard_body_weight": "68.9",
                "degree_of_obesity": "-1.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "54.2",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "106",
                "bmr_kcal": "1719",
                "bmr_kj": "7192",
                "bmr_score": "14",
                "metabolic_age": "17",
                "balancearm": "0",
                "balanceleg": "2",
                "target_fat_p": "17",
                "predicted_weight": "71.6",
                "predicted_fat_mass": "12.2",
                "fat_to_lose__gain": "3.7",
                "rl_fat_p": "15.5",
                "rl_fat_mass": "2.1",
                "rl_ffm": "11.2",
                "rl_muscle_mass": "10.6",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "0",
                "ll_fat_p": "16.3",
                "ll_fat_mass": "2.1",
                "ll_ffm": "10.8",
                "ll_muscle_mass": "10.2",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "0",
                "ra_fat_p": "8.6",
                "ra_fat_mass": "0.3",
                "ra_ffm": "3.4",
                "ra_muscle_mass": "3.2",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "2",
                "la_fat_p": "8.6",
                "la_fat_mass": "0.3",
                "la_ffm": "3.4",
                "la_muscle_mass": "3.2",
                "la_fat_p_score": "-2",
                "la_muscle_score": "2",
                "trunk_fat_p": "10.8",
                "trunk_fat_mass": "3.7",
                "trunk_ffm": "30.6",
                "trunk_muscle_mass": "29.1",
                "trunk_fat_p_score": "-2",
                "trunk_muscle_score": "0",
                "username": "CHOW_SIU_HANG",
                "dummy1": "2001/10/03"
            },
            {
                "model": "MC-980",
                "id_no": "210105394",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:51",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "73.4",
                "fat_p": "18.7",
                "fat_mass": "13.7",
                "ffm": "59.7",
                "muscle_mass": "56.6",
                "muscle_score": "2",
                "bone_mass": "3.1",
                "tbw": "42.7",
                "tbw_p": "58.2",
                "icw": "27.2",
                "ecw": "15.5",
                "ecw_p": "36.3",
                "bmi": "25.4",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "15.4",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "7",
                "leg_muscle_score": "106",
                "bmr_kcal": "1748",
                "bmr_kj": "7314",
                "bmr_score": "9",
                "metabolic_age": "19",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "71.9",
                "predicted_fat_mass": "12.2",
                "fat_to_lose__gain": "-1.5",
                "rl_fat_p": "19.4",
                "rl_fat_mass": "2.9",
                "rl_ffm": "12.0",
                "rl_muscle_mass": "11.3",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "2",
                "ll_fat_p": "19.2",
                "ll_fat_mass": "2.8",
                "ll_ffm": "11.9",
                "ll_muscle_mass": "11.2",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "2",
                "ra_fat_p": "13.7",
                "ra_fat_mass": "0.5",
                "ra_ffm": "3.4",
                "ra_muscle_mass": "3.2",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "3",
                "la_fat_p": "15.1",
                "la_fat_mass": "0.6",
                "la_ffm": "3.2",
                "la_muscle_mass": "3.0",
                "la_fat_p_score": "0",
                "la_muscle_score": "2",
                "trunk_fat_p": "19.1",
                "trunk_fat_mass": "6.9",
                "trunk_ffm": "29.2",
                "trunk_muscle_mass": "27.9",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "1",
                "username": "HUNGPAKSHING",
                "dummy1": "2001/12/18"
            },
            {
                "model": "MC-980",
                "id_no": "210112729",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:33",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "79.7",
                "fat_p": "22.7",
                "fat_mass": "18.1",
                "ffm": "61.6",
                "muscle_mass": "58.4",
                "muscle_score": "2",
                "bone_mass": "3.2",
                "tbw": "43.5",
                "tbw_p": "54.6",
                "icw": "27.5",
                "ecw": "16.0",
                "ecw_p": "36.8",
                "bmi": "27.6",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "25.3",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "9",
                "leg_muscle_score": "102",
                "bmr_kcal": "1809",
                "bmr_kj": "7569",
                "bmr_score": "8",
                "metabolic_age": "27",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "74.2",
                "predicted_fat_mass": "12.6",
                "fat_to_lose__gain": "-5.5",
                "rl_fat_p": "22.9",
                "rl_fat_mass": "3.7",
                "rl_ffm": "12.3",
                "rl_muscle_mass": "11.6",
                "rl_fat_p_score": "2",
                "rl_muscle_score": "3",
                "ll_fat_p": "22.2",
                "ll_fat_mass": "3.5",
                "ll_ffm": "12.4",
                "ll_muscle_mass": "11.7",
                "ll_fat_p_score": "1",
                "ll_muscle_score": "3",
                "ra_fat_p": "17.3",
                "ra_fat_mass": "0.7",
                "ra_ffm": "3.3",
                "ra_muscle_mass": "3.1",
                "ra_fat_p_score": "1",
                "ra_muscle_score": "2",
                "la_fat_p": "18.2",
                "la_fat_mass": "0.7",
                "la_ffm": "3.2",
                "la_muscle_mass": "3.0",
                "la_fat_p_score": "1",
                "la_muscle_score": "2",
                "trunk_fat_p": "23.8",
                "trunk_fat_mass": "9.5",
                "trunk_ffm": "30.4",
                "trunk_muscle_mass": "29.0",
                "trunk_fat_p_score": "1",
                "trunk_muscle_score": "1",
                "username": "CHOY_KWOK_HEI",
                "dummy1": "2001/07/27"
            },
            {
                "model": "MC-980",
                "id_no": "210115001",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:10",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "174.0",
                "clothes_weight": "0.0",
                "weight": "102.7",
                "fat_p": "31.9",
                "fat_mass": "32.8",
                "ffm": "69.9",
                "muscle_mass": "66.3",
                "muscle_score": "4",
                "bone_mass": "3.6",
                "tbw": "47.0",
                "tbw_p": "45.8",
                "icw": "28.9",
                "ecw": "18.1",
                "ecw_p": "38.5",
                "bmi": "33.9",
                "standard_body_weight": "66.6",
                "degree_of_obesity": "54.2",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "52.4",
                "visceral_fat_rating": "15",
                "leg_muscle_score": "94",
                "bmr_kcal": "2115",
                "bmr_kj": "8849",
                "bmr_score": "3",
                "metabolic_age": "34",
                "balancearm": "2",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "84.2",
                "predicted_fat_mass": "14.3",
                "fat_to_lose__gain": "-18.5",
                "rl_fat_p": "29.3",
                "rl_fat_mass": "6.1",
                "rl_ffm": "14.6",
                "rl_muscle_mass": "13.8",
                "rl_fat_p_score": "3",
                "rl_muscle_score": "4",
                "ll_fat_p": "29.1",
                "ll_fat_mass": "5.9",
                "ll_ffm": "14.3",
                "ll_muscle_mass": "13.5",
                "ll_fat_p_score": "3",
                "ll_muscle_score": "4",
                "ra_fat_p": "23.6",
                "ra_fat_mass": "1.1",
                "ra_ffm": "3.7",
                "ra_muscle_mass": "3.5",
                "ra_fat_p_score": "3",
                "ra_muscle_score": "3",
                "la_fat_p": "25.1",
                "la_fat_mass": "1.1",
                "la_ffm": "3.4",
                "la_muscle_mass": "3.2",
                "la_fat_p_score": "3",
                "la_muscle_score": "2",
                "trunk_fat_p": "35.4",
                "trunk_fat_mass": "18.6",
                "trunk_ffm": "33.9",
                "trunk_muscle_mass": "32.3",
                "trunk_fat_p_score": "3",
                "trunk_muscle_score": "2",
                "username": "LEUNG_TSZ_KIN",
                "dummy1": "2002/08/23"
            },
            {
                "model": "MC-980",
                "id_no": "210117749",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:03",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "172.0",
                "clothes_weight": "0.0",
                "weight": "73.9",
                "fat_p": "26.0",
                "fat_mass": "19.2",
                "ffm": "54.7",
                "muscle_mass": "51.9",
                "muscle_score": "0",
                "bone_mass": "2.8",
                "tbw": "35.1",
                "tbw_p": "47.5",
                "icw": "21.2",
                "ecw": "13.9",
                "ecw_p": "39.6",
                "bmi": "25.0",
                "standard_body_weight": "65.1",
                "degree_of_obesity": "13.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "51.2",
                "visceral_fat_rating": "9",
                "leg_muscle_score": "101",
                "bmr_kcal": "1604",
                "bmr_kj": "6711",
                "bmr_score": "6",
                "metabolic_age": "35",
                "balancearm": "1",
                "balanceleg": "-1",
                "target_fat_p": "17",
                "predicted_weight": "65.9",
                "predicted_fat_mass": "11.2",
                "fat_to_lose__gain": "-8.0",
                "rl_fat_p": "23.0",
                "rl_fat_mass": "3.4",
                "rl_ffm": "11.4",
                "rl_muscle_mass": "10.8",
                "rl_fat_p_score": "2",
                "rl_muscle_score": "2",
                "ll_fat_p": "22.1",
                "ll_fat_mass": "3.3",
                "ll_ffm": "11.6",
                "ll_muscle_mass": "11.0",
                "ll_fat_p_score": "1",
                "ll_muscle_score": "2",
                "ra_fat_p": "19.7",
                "ra_fat_mass": "0.7",
                "ra_ffm": "2.8",
                "ra_muscle_mass": "2.6",
                "ra_fat_p_score": "2",
                "ra_muscle_score": "0",
                "la_fat_p": "21.0",
                "la_fat_mass": "0.7",
                "la_ffm": "2.6",
                "la_muscle_mass": "2.4",
                "la_fat_p_score": "2",
                "la_muscle_score": "-1",
                "trunk_fat_p": "29.7",
                "trunk_fat_mass": "11.1",
                "trunk_ffm": "26.3",
                "trunk_muscle_mass": "25.1",
                "trunk_fat_p_score": "2",
                "trunk_muscle_score": "-2",
                "username": "TSOI_HIU_WANG",
                "dummy1": "2001/04/02"
            },
            {
                "model": "MC-980",
                "id_no": "210119964",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:07",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "176.0",
                "clothes_weight": "0.0",
                "weight": "63.7",
                "fat_p": "9.8",
                "fat_mass": "6.2",
                "ffm": "57.5",
                "muscle_mass": "54.5",
                "muscle_score": "0",
                "bone_mass": "3.0",
                "tbw": "40.8",
                "tbw_p": "64.1",
                "icw": "26.4",
                "ecw": "14.4",
                "ecw_p": "35.3",
                "bmi": "20.6",
                "standard_body_weight": "68.1",
                "degree_of_obesity": "-6.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "53.6",
                "visceral_fat_rating": "2",
                "leg_muscle_score": "113",
                "bmr_kcal": "1663",
                "bmr_kj": "6958",
                "bmr_score": "14",
                "metabolic_age": "16",
                "balancearm": "0",
                "balanceleg": "2",
                "target_fat_p": "17",
                "predicted_weight": "69.3",
                "predicted_fat_mass": "11.8",
                "fat_to_lose__gain": "5.6",
                "rl_fat_p": "10.9",
                "rl_fat_mass": "1.4",
                "rl_ffm": "11.5",
                "rl_muscle_mass": "10.9",
                "rl_fat_p_score": "-3",
                "rl_muscle_score": "1",
                "ll_fat_p": "11.2",
                "ll_fat_mass": "1.4",
                "ll_ffm": "11.2",
                "ll_muscle_mass": "10.6",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "0",
                "ra_fat_p": "8.1",
                "ra_fat_mass": "0.3",
                "ra_ffm": "3.1",
                "ra_muscle_mass": "2.9",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "0",
                "la_fat_p": "8.4",
                "la_fat_mass": "0.3",
                "la_ffm": "3.0",
                "la_muscle_mass": "2.8",
                "la_fat_p_score": "-2",
                "la_muscle_score": "0",
                "trunk_fat_p": "8.9",
                "trunk_fat_mass": "2.8",
                "trunk_ffm": "28.7",
                "trunk_muscle_mass": "27.3",
                "trunk_fat_p_score": "-2",
                "trunk_muscle_score": "-1",
                "username": "WONG_KA_HEI",
                "dummy1": "2002/07/06"
            },
            {
                "model": "MC-980",
                "id_no": "210122840",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:55",
                "body_type": "0",
                "gender": "2",
                "age": "19",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "62.1",
                "fat_p": "27.9",
                "fat_mass": "17.3",
                "ffm": "44.8",
                "muscle_mass": "42.0",
                "muscle_score": "0",
                "bone_mass": "2.8",
                "tbw": "31.0",
                "tbw_p": "49.9",
                "icw": "19.1",
                "ecw": "11.9",
                "ecw_p": "38.4",
                "bmi": "21.5",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "-2.4",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "43.0",
                "visceral_fat_rating": "3",
                "leg_muscle_score": "103",
                "bmr_kcal": "1398",
                "bmr_kj": "5849",
                "bmr_score": "8",
                "metabolic_age": "19",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "28",
                "predicted_weight": "62.2",
                "predicted_fat_mass": "17.4",
                "fat_to_lose__gain": "0.1",
                "rl_fat_p": "30.4",
                "rl_fat_mass": "3.8",
                "rl_ffm": "8.6",
                "rl_muscle_mass": "8.1",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "1",
                "ll_fat_p": "30.0",
                "ll_fat_mass": "3.7",
                "ll_ffm": "8.6",
                "ll_muscle_mass": "8.1",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "1",
                "ra_fat_p": "24.2",
                "ra_fat_mass": "0.7",
                "ra_ffm": "2.1",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "0",
                "la_fat_p": "25.8",
                "la_fat_mass": "0.7",
                "la_ffm": "2.0",
                "la_muscle_mass": "1.9",
                "la_fat_p_score": "0",
                "la_muscle_score": "0",
                "trunk_fat_p": "26.3",
                "trunk_fat_mass": "8.4",
                "trunk_ffm": "23.5",
                "trunk_muscle_mass": "21.9",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-2",
                "username": "LEE_WING_YI",
                "dummy1": "2002/09/19"
            },
            {
                "model": "MC-980",
                "id_no": "210126297",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:10",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "165.0",
                "clothes_weight": "0.0",
                "weight": "81.0",
                "fat_p": "24.1",
                "fat_mass": "19.5",
                "ffm": "61.5",
                "muscle_mass": "58.3",
                "muscle_score": "3",
                "bone_mass": "3.2",
                "tbw": "43.7",
                "tbw_p": "54.0",
                "icw": "27.3",
                "ecw": "16.4",
                "ecw_p": "37.5",
                "bmi": "29.8",
                "standard_body_weight": "59.9",
                "degree_of_obesity": "35.2",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "47.1",
                "visceral_fat_rating": "11",
                "leg_muscle_score": "104",
                "bmr_kcal": "1801",
                "bmr_kj": "7535",
                "bmr_score": "7",
                "metabolic_age": "31",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "74.1",
                "predicted_fat_mass": "12.6",
                "fat_to_lose__gain": "-6.9",
                "rl_fat_p": "23.7",
                "rl_fat_mass": "3.9",
                "rl_ffm": "12.4",
                "rl_muscle_mass": "11.7",
                "rl_fat_p_score": "2",
                "rl_muscle_score": "3",
                "ll_fat_p": "22.8",
                "ll_fat_mass": "3.7",
                "ll_ffm": "12.5",
                "ll_muscle_mass": "11.8",
                "ll_fat_p_score": "2",
                "ll_muscle_score": "3",
                "ra_fat_p": "17.9",
                "ra_fat_mass": "0.7",
                "ra_ffm": "3.3",
                "ra_muscle_mass": "3.1",
                "ra_fat_p_score": "1",
                "ra_muscle_score": "3",
                "la_fat_p": "19.0",
                "la_fat_mass": "0.7",
                "la_ffm": "3.1",
                "la_muscle_mass": "2.9",
                "la_fat_p_score": "2",
                "la_muscle_score": "2",
                "trunk_fat_p": "25.8",
                "trunk_fat_mass": "10.5",
                "trunk_ffm": "30.2",
                "trunk_muscle_mass": "28.8",
                "trunk_fat_p_score": "1",
                "trunk_muscle_score": "2",
                "username": "LUO_SHUN_LIN",
                "dummy1": "2000/04/12"
            },
            {
                "model": "MC-980",
                "id_no": "210136659",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:41",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "175.0",
                "clothes_weight": "0.0",
                "weight": "66.4",
                "fat_p": "18.2",
                "fat_mass": "12.1",
                "ffm": "54.3",
                "muscle_mass": "51.5",
                "muscle_score": "-1",
                "bone_mass": "2.8",
                "tbw": "36.2",
                "tbw_p": "54.5",
                "icw": "22.5",
                "ecw": "13.7",
                "ecw_p": "37.8",
                "bmi": "21.7",
                "standard_body_weight": "67.4",
                "degree_of_obesity": "-1.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "53.0",
                "visceral_fat_rating": "5",
                "leg_muscle_score": "103",
                "bmr_kcal": "1584",
                "bmr_kj": "6627",
                "bmr_score": "9",
                "metabolic_age": "19",
                "balancearm": "1",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "65.4",
                "predicted_fat_mass": "11.1",
                "fat_to_lose__gain": "-1.0",
                "rl_fat_p": "18.3",
                "rl_fat_mass": "2.4",
                "rl_ffm": "10.6",
                "rl_muscle_mass": "10.0",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "0",
                "ll_fat_p": "17.7",
                "ll_fat_mass": "2.3",
                "ll_ffm": "10.7",
                "ll_muscle_mass": "10.1",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "0",
                "ra_fat_p": "13.6",
                "ra_fat_mass": "0.5",
                "ra_ffm": "2.9",
                "ra_muscle_mass": "2.7",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "0",
                "la_fat_p": "14.5",
                "la_fat_mass": "0.5",
                "la_ffm": "2.7",
                "la_muscle_mass": "2.5",
                "la_fat_p_score": "0",
                "la_muscle_score": "-1",
                "trunk_fat_p": "18.9",
                "trunk_fat_mass": "6.4",
                "trunk_ffm": "27.4",
                "trunk_muscle_mass": "26.2",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-2",
                "username": "CHEN_TSZ_LAM",
                "dummy1": "2002/05/17"
            },
            {
                "model": "MC-980",
                "id_no": "210138689",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:07",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "171.0",
                "clothes_weight": "0.0",
                "weight": "85.4",
                "fat_p": "27.2",
                "fat_mass": "23.2",
                "ffm": "62.2",
                "muscle_mass": "59.0",
                "muscle_score": "2",
                "bone_mass": "3.2",
                "tbw": "42.5",
                "tbw_p": "49.8",
                "icw": "26.4",
                "ecw": "16.1",
                "ecw_p": "37.9",
                "bmi": "29.2",
                "standard_body_weight": "64.3",
                "degree_of_obesity": "32.8",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.6",
                "visceral_fat_rating": "12",
                "leg_muscle_score": "101",
                "bmr_kcal": "1831",
                "bmr_kj": "7661",
                "bmr_score": "6",
                "metabolic_age": "36",
                "balancearm": "0",
                "balanceleg": "2",
                "target_fat_p": "17",
                "predicted_weight": "74.9",
                "predicted_fat_mass": "12.7",
                "fat_to_lose__gain": "-10.5",
                "rl_fat_p": "24.1",
                "rl_fat_mass": "4.3",
                "rl_ffm": "13.6",
                "rl_muscle_mass": "12.9",
                "rl_fat_p_score": "2",
                "rl_muscle_score": "4",
                "ll_fat_p": "24.8",
                "ll_fat_mass": "4.3",
                "ll_ffm": "13.0",
                "ll_muscle_mass": "12.3",
                "ll_fat_p_score": "2",
                "ll_muscle_score": "3",
                "ra_fat_p": "20.9",
                "ra_fat_mass": "0.8",
                "ra_ffm": "3.2",
                "ra_muscle_mass": "3.0",
                "ra_fat_p_score": "2",
                "ra_muscle_score": "2",
                "la_fat_p": "21.9",
                "la_fat_mass": "0.9",
                "la_ffm": "3.1",
                "la_muscle_mass": "2.9",
                "la_fat_p_score": "2",
                "la_muscle_score": "1",
                "trunk_fat_p": "30.6",
                "trunk_fat_mass": "12.9",
                "trunk_ffm": "29.3",
                "trunk_muscle_mass": "27.9",
                "trunk_fat_p_score": "2",
                "trunk_muscle_score": "2",
                "username": "NG_KEI_YIN",
                "dummy1": "2000/03/24"
            },
            {
                "model": "MC-980",
                "id_no": "210144487",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:28",
                "body_type": "0",
                "gender": "2",
                "age": "22",
                "height": "157.0",
                "clothes_weight": "0.0",
                "weight": "63.2",
                "fat_p": "34.8",
                "fat_mass": "22.0",
                "ffm": "41.2",
                "muscle_mass": "38.8",
                "muscle_score": "2",
                "bone_mass": "2.4",
                "tbw": "30.4",
                "tbw_p": "48.1",
                "icw": "18.3",
                "ecw": "12.1",
                "ecw_p": "39.8",
                "bmi": "25.6",
                "standard_body_weight": "54.2",
                "degree_of_obesity": "16.6",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "36.7",
                "visceral_fat_rating": "5",
                "leg_muscle_score": "101",
                "bmr_kcal": "1301",
                "bmr_kj": "5443",
                "bmr_score": "7",
                "metabolic_age": "37",
                "balancearm": "1",
                "balanceleg": "2",
                "target_fat_p": "28",
                "predicted_weight": "57.2",
                "predicted_fat_mass": "16.0",
                "fat_to_lose__gain": "-6.0",
                "rl_fat_p": "34.0",
                "rl_fat_mass": "4.4",
                "rl_ffm": "8.5",
                "rl_muscle_mass": "8.0",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "3",
                "ll_fat_p": "34.3",
                "ll_fat_mass": "4.3",
                "ll_ffm": "8.3",
                "ll_muscle_mass": "7.8",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "3",
                "ra_fat_p": "31.8",
                "ra_fat_mass": "0.9",
                "ra_ffm": "2.0",
                "ra_muscle_mass": "1.9",
                "ra_fat_p_score": "1",
                "ra_muscle_score": "2",
                "la_fat_p": "33.1",
                "la_fat_mass": "0.9",
                "la_ffm": "1.9",
                "la_muscle_mass": "1.8",
                "la_fat_p_score": "1",
                "la_muscle_score": "1",
                "trunk_fat_p": "35.9",
                "trunk_fat_mass": "11.5",
                "trunk_ffm": "20.5",
                "trunk_muscle_mass": "19.3",
                "trunk_fat_p_score": "1",
                "trunk_muscle_score": "1",
                "username": "CHAO_CHUNG_YAN",
                "dummy1": "1999/08/05"
            },
            {
                "model": "MC-980",
                "id_no": "210147754",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:49",
                "body_type": "0",
                "gender": "1",
                "age": "17",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "65.8",
                "fat_p": "16.9",
                "fat_mass": "11.1",
                "ffm": "54.7",
                "muscle_mass": "51.9",
                "muscle_score": "",
                "bone_mass": "2.8",
                "tbw": "40.0",
                "tbw_p": "60.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1681",
                "bmr_kj": "7033",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "65.1",
                "predicted_fat_mass": "10.4",
                "fat_to_lose__gain": "-0.7",
                "rl_fat_p": "16.8",
                "rl_fat_mass": "2.2",
                "rl_ffm": "11.1",
                "rl_muscle_mass": "10.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "17.1",
                "ll_fat_mass": "2.2",
                "ll_ffm": "10.8",
                "ll_muscle_mass": "10.2",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "11.8",
                "ra_fat_mass": "0.4",
                "ra_ffm": "3.0",
                "ra_muscle_mass": "2.8",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "13.1",
                "la_fat_mass": "0.4",
                "la_ffm": "2.8",
                "la_muscle_mass": "2.6",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "17.9",
                "trunk_fat_mass": "5.9",
                "trunk_ffm": "27.0",
                "trunk_muscle_mass": "25.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "CHOW_TIN_CHI",
                "dummy1": "2003/11/28"
            },
            {
                "model": "MC-980",
                "id_no": "210158351",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:47",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "178.0",
                "clothes_weight": "0.0",
                "weight": "76.0",
                "fat_p": "19.8",
                "fat_mass": "15.0",
                "ffm": "61.0",
                "muscle_mass": "57.8",
                "muscle_score": "0",
                "bone_mass": "3.2",
                "tbw": "41.3",
                "tbw_p": "54.3",
                "icw": "26.0",
                "ecw": "15.3",
                "ecw_p": "37.0",
                "bmi": "24.0",
                "standard_body_weight": "69.7",
                "degree_of_obesity": "9.0",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "54.9",
                "visceral_fat_rating": "7",
                "leg_muscle_score": "103",
                "bmr_kcal": "1774",
                "bmr_kj": "7422",
                "bmr_score": "9",
                "metabolic_age": "22",
                "balancearm": "0",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "73.5",
                "predicted_fat_mass": "12.5",
                "fat_to_lose__gain": "-2.5",
                "rl_fat_p": "20.3",
                "rl_fat_mass": "3.0",
                "rl_ffm": "11.8",
                "rl_muscle_mass": "11.2",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "1",
                "ll_fat_p": "20.7",
                "ll_fat_mass": "3.0",
                "ll_ffm": "11.6",
                "ll_muscle_mass": "11.0",
                "ll_fat_p_score": "1",
                "ll_muscle_score": "1",
                "ra_fat_p": "13.8",
                "ra_fat_mass": "0.5",
                "ra_ffm": "3.4",
                "ra_muscle_mass": "3.2",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "2",
                "la_fat_p": "14.7",
                "la_fat_mass": "0.6",
                "la_ffm": "3.3",
                "la_muscle_mass": "3.1",
                "la_fat_p_score": "0",
                "la_muscle_score": "1",
                "trunk_fat_p": "20.4",
                "trunk_fat_mass": "7.9",
                "trunk_ffm": "30.9",
                "trunk_muscle_mass": "29.3",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "0",
                "username": "CAI_XUE_JIA",
                "dummy1": "1999/12/27"
            },
            {
                "model": "MC-980",
                "id_no": "210160788",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:03",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "171.0",
                "clothes_weight": "0.0",
                "weight": "65.4",
                "fat_p": "14.1",
                "fat_mass": "9.2",
                "ffm": "56.2",
                "muscle_mass": "53.3",
                "muscle_score": "0",
                "bone_mass": "2.9",
                "tbw": "39.2",
                "tbw_p": "59.9",
                "icw": "24.8",
                "ecw": "14.4",
                "ecw_p": "36.7",
                "bmi": "22.4",
                "standard_body_weight": "64.3",
                "degree_of_obesity": "1.7",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.6",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "109",
                "bmr_kcal": "1632",
                "bmr_kj": "6828",
                "bmr_score": "12",
                "metabolic_age": "17",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "67.7",
                "predicted_fat_mass": "11.5",
                "fat_to_lose__gain": "2.3",
                "rl_fat_p": "15.8",
                "rl_fat_mass": "2.0",
                "rl_ffm": "10.8",
                "rl_muscle_mass": "10.2",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "1",
                "ll_fat_p": "15.0",
                "ll_fat_mass": "1.9",
                "ll_ffm": "10.8",
                "ll_muscle_mass": "10.2",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "1",
                "ra_fat_p": "10.5",
                "ra_fat_mass": "0.4",
                "ra_ffm": "3.0",
                "ra_muscle_mass": "2.8",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "1",
                "la_fat_p": "10.7",
                "la_fat_mass": "0.3",
                "la_ffm": "2.9",
                "la_muscle_mass": "2.7",
                "la_fat_p_score": "-2",
                "la_muscle_score": "0",
                "trunk_fat_p": "13.8",
                "trunk_fat_mass": "4.6",
                "trunk_ffm": "28.7",
                "trunk_muscle_mass": "27.4",
                "trunk_fat_p_score": "-2",
                "trunk_muscle_score": "0",
                "username": "LEE_CHUN_HIN",
                "dummy1": "2002/07/27"
            },
            {
                "model": "MC-980",
                "id_no": "210168079",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:12",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "165.0",
                "clothes_weight": "0.0",
                "weight": "84.9",
                "fat_p": "32.0",
                "fat_mass": "27.2",
                "ffm": "57.7",
                "muscle_mass": "54.7",
                "muscle_score": "2",
                "bone_mass": "3.0",
                "tbw": "39.2",
                "tbw_p": "46.2",
                "icw": "23.9",
                "ecw": "15.3",
                "ecw_p": "39.0",
                "bmi": "31.2",
                "standard_body_weight": "59.9",
                "degree_of_obesity": "41.7",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "47.1",
                "visceral_fat_rating": "13",
                "leg_muscle_score": "96",
                "bmr_kcal": "1706",
                "bmr_kj": "7138",
                "bmr_score": "3",
                "metabolic_age": "36",
                "balancearm": "0",
                "balanceleg": "-1",
                "target_fat_p": "17",
                "predicted_weight": "69.5",
                "predicted_fat_mass": "11.8",
                "fat_to_lose__gain": "-15.4",
                "rl_fat_p": "28.9",
                "rl_fat_mass": "5.1",
                "rl_ffm": "12.5",
                "rl_muscle_mass": "11.8",
                "rl_fat_p_score": "3",
                "rl_muscle_score": "3",
                "ll_fat_p": "28.5",
                "ll_fat_mass": "5.1",
                "ll_ffm": "12.7",
                "ll_muscle_mass": "12.0",
                "ll_fat_p_score": "3",
                "ll_muscle_score": "4",
                "ra_fat_p": "24.6",
                "ra_fat_mass": "1.0",
                "ra_ffm": "3.0",
                "ra_muscle_mass": "2.8",
                "ra_fat_p_score": "3",
                "ra_muscle_score": "2",
                "la_fat_p": "26.0",
                "la_fat_mass": "1.0",
                "la_ffm": "2.9",
                "la_muscle_mass": "2.7",
                "la_fat_p_score": "4",
                "la_muscle_score": "1",
                "trunk_fat_p": "36.1",
                "trunk_fat_mass": "15.0",
                "trunk_ffm": "26.6",
                "trunk_muscle_mass": "25.4",
                "trunk_fat_p_score": "3",
                "trunk_muscle_score": "2",
                "username": "TSOI_KA_MAN",
                "dummy1": "2000/10/19"
            },
            {
                "model": "MC-980",
                "id_no": "210168829",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:57",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "63.3",
                "fat_p": "16.1",
                "fat_mass": "10.2",
                "ffm": "53.1",
                "muscle_mass": "50.3",
                "muscle_score": "0",
                "bone_mass": "2.8",
                "tbw": "36.4",
                "tbw_p": "57.5",
                "icw": "22.8",
                "ecw": "13.6",
                "ecw_p": "37.4",
                "bmi": "21.9",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "-0.5",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "5",
                "leg_muscle_score": "111",
                "bmr_kcal": "1545",
                "bmr_kj": "6464",
                "bmr_score": "10",
                "metabolic_age": "18",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "64.0",
                "predicted_fat_mass": "10.9",
                "fat_to_lose__gain": "0.7",
                "rl_fat_p": "14.7",
                "rl_fat_mass": "1.9",
                "rl_ffm": "11.1",
                "rl_muscle_mass": "10.5",
                "rl_fat_p_score": "-2",
                "rl_muscle_score": "2",
                "ll_fat_p": "14.3",
                "ll_fat_mass": "1.8",
                "ll_ffm": "11.0",
                "ll_muscle_mass": "10.4",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "1",
                "ra_fat_p": "13.0",
                "ra_fat_mass": "0.4",
                "ra_ffm": "2.7",
                "ra_muscle_mass": "2.5",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "0",
                "la_fat_p": "14.0",
                "la_fat_mass": "0.4",
                "la_ffm": "2.6",
                "la_muscle_mass": "2.4",
                "la_fat_p_score": "0",
                "la_muscle_score": "-1",
                "trunk_fat_p": "18.2",
                "trunk_fat_mass": "5.7",
                "trunk_ffm": "25.7",
                "trunk_muscle_mass": "24.5",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-2",
                "username": "TANG_KA_HO",
                "dummy1": "2002/07/20"
            },
            {
                "model": "MC-980",
                "id_no": "210172498",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:56",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "59.6",
                "fat_p": "8.6",
                "fat_mass": "5.1",
                "ffm": "54.5",
                "muscle_mass": "51.7",
                "muscle_score": "0",
                "bone_mass": "2.8",
                "tbw": "39.6",
                "tbw_p": "66.4",
                "icw": "25.5",
                "ecw": "14.1",
                "ecw_p": "35.6",
                "bmi": "20.6",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "-6.3",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "2",
                "leg_muscle_score": "111",
                "bmr_kcal": "1557",
                "bmr_kj": "6514",
                "bmr_score": "15",
                "metabolic_age": "16",
                "balancearm": "0",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "65.7",
                "predicted_fat_mass": "11.2",
                "fat_to_lose__gain": "6.1",
                "rl_fat_p": "12.4",
                "rl_fat_mass": "1.5",
                "rl_ffm": "10.4",
                "rl_muscle_mass": "9.8",
                "rl_fat_p_score": "-2",
                "rl_muscle_score": "0",
                "ll_fat_p": "12.7",
                "ll_fat_mass": "1.5",
                "ll_ffm": "10.2",
                "ll_muscle_mass": "9.6",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "0",
                "ra_fat_p": "5.5",
                "ra_fat_mass": "0.2",
                "ra_ffm": "3.2",
                "ra_muscle_mass": "3.0",
                "ra_fat_p_score": "-3",
                "ra_muscle_score": "2",
                "la_fat_p": "6.4",
                "la_fat_mass": "0.2",
                "la_ffm": "3.1",
                "la_muscle_mass": "2.9",
                "la_fat_p_score": "-3",
                "la_muscle_score": "2",
                "trunk_fat_p": "5.8",
                "trunk_fat_mass": "1.7",
                "trunk_ffm": "27.6",
                "trunk_muscle_mass": "26.4",
                "trunk_fat_p_score": "-3",
                "trunk_muscle_score": "0",
                "username": "CHEN_PAK_YIN",
                "dummy1": "2000/07/15"
            },
            {
                "model": "MC-980",
                "id_no": "210172738",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:14",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "173.0",
                "clothes_weight": "0.0",
                "weight": "57.8",
                "fat_p": "14.2",
                "fat_mass": "8.2",
                "ffm": "49.6",
                "muscle_mass": "47.0",
                "muscle_score": "-2",
                "bone_mass": "2.6",
                "tbw": "33.3",
                "tbw_p": "57.6",
                "icw": "20.6",
                "ecw": "12.7",
                "ecw_p": "38.1",
                "bmi": "19.3",
                "standard_body_weight": "65.8",
                "degree_of_obesity": "-12.2",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "51.8",
                "visceral_fat_rating": "2",
                "leg_muscle_score": "111",
                "bmr_kcal": "1439",
                "bmr_kj": "6021",
                "bmr_score": "12",
                "metabolic_age": "17",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "59.8",
                "predicted_fat_mass": "10.2",
                "fat_to_lose__gain": "2.0",
                "rl_fat_p": "14.2",
                "rl_fat_mass": "1.6",
                "rl_ffm": "9.9",
                "rl_muscle_mass": "9.4",
                "rl_fat_p_score": "-2",
                "rl_muscle_score": "0",
                "ll_fat_p": "13.8",
                "ll_fat_mass": "1.6",
                "ll_ffm": "9.9",
                "ll_muscle_mass": "9.4",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "0",
                "ra_fat_p": "11.4",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.6",
                "ra_muscle_mass": "2.4",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "-1",
                "la_fat_p": "11.6",
                "la_fat_mass": "0.3",
                "la_ffm": "2.5",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "-1",
                "la_muscle_score": "-2",
                "trunk_fat_p": "15.1",
                "trunk_fat_mass": "4.4",
                "trunk_ffm": "24.7",
                "trunk_muscle_mass": "23.5",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "-3",
                "username": "TSUI_CHI_FAI",
                "dummy1": "2002/03/01"
            },
            {
                "model": "MC-980",
                "id_no": "210173028",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:31",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "175.0",
                "clothes_weight": "0.0",
                "weight": "54.1",
                "fat_p": "13.8",
                "fat_mass": "7.5",
                "ffm": "46.6",
                "muscle_mass": "44.2",
                "muscle_score": "-3",
                "bone_mass": "2.4",
                "tbw": "30.4",
                "tbw_p": "56.2",
                "icw": "18.5",
                "ecw": "11.9",
                "ecw_p": "39.1",
                "bmi": "17.7",
                "standard_body_weight": "67.4",
                "degree_of_obesity": "-19.7",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "53.0",
                "visceral_fat_rating": "1",
                "leg_muscle_score": "112",
                "bmr_kcal": "1347",
                "bmr_kj": "5636",
                "bmr_score": "13",
                "metabolic_age": "17",
                "balancearm": "1",
                "balanceleg": "2",
                "target_fat_p": "17",
                "predicted_weight": "56.1",
                "predicted_fat_mass": "9.5",
                "fat_to_lose__gain": "2.0",
                "rl_fat_p": "9.9",
                "rl_fat_mass": "1.1",
                "rl_ffm": "9.8",
                "rl_muscle_mass": "9.3",
                "rl_fat_p_score": "-3",
                "rl_muscle_score": "-1",
                "ll_fat_p": "11.9",
                "ll_fat_mass": "1.3",
                "ll_ffm": "9.4",
                "ll_muscle_mass": "8.9",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "-1",
                "ra_fat_p": "10.6",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.4",
                "ra_muscle_mass": "2.2",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "-2",
                "la_fat_p": "11.5",
                "la_fat_mass": "0.3",
                "la_ffm": "2.2",
                "la_muscle_mass": "2.1",
                "la_fat_p_score": "-2",
                "la_muscle_score": "-3",
                "trunk_fat_p": "16.5",
                "trunk_fat_mass": "4.5",
                "trunk_ffm": "22.8",
                "trunk_muscle_mass": "21.7",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "-4",
                "username": "CHEUNG_CHEUK_NAM",
                "dummy1": "2001/03/19"
            },
            {
                "model": "MC-980",
                "id_no": "210174559",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:06",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "175.0",
                "clothes_weight": "0.0",
                "weight": "69.9",
                "fat_p": "18.1",
                "fat_mass": "12.7",
                "ffm": "57.2",
                "muscle_mass": "54.2",
                "muscle_score": "0",
                "bone_mass": "3.0",
                "tbw": "39.9",
                "tbw_p": "57.1",
                "icw": "25.4",
                "ecw": "14.5",
                "ecw_p": "36.3",
                "bmi": "22.8",
                "standard_body_weight": "67.4",
                "degree_of_obesity": "3.7",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "53.0",
                "visceral_fat_rating": "6",
                "leg_muscle_score": "107",
                "bmr_kcal": "1672",
                "bmr_kj": "6996",
                "bmr_score": "9",
                "metabolic_age": "18",
                "balancearm": "1",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "68.9",
                "predicted_fat_mass": "11.7",
                "fat_to_lose__gain": "-1.0",
                "rl_fat_p": "16.8",
                "rl_fat_mass": "2.4",
                "rl_ffm": "12.1",
                "rl_muscle_mass": "11.4",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "2",
                "ll_fat_p": "17.4",
                "ll_fat_mass": "2.5",
                "ll_ffm": "11.8",
                "ll_muscle_mass": "11.2",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "2",
                "ra_fat_p": "13.6",
                "ra_fat_mass": "0.5",
                "ra_ffm": "3.2",
                "ra_muscle_mass": "3.0",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "1",
                "la_fat_p": "15.4",
                "la_fat_mass": "0.5",
                "la_ffm": "3.0",
                "la_muscle_mass": "2.8",
                "la_fat_p_score": "0",
                "la_muscle_score": "0",
                "trunk_fat_p": "20.1",
                "trunk_fat_mass": "6.8",
                "trunk_ffm": "27.1",
                "trunk_muscle_mass": "25.8",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "0",
                "username": "SHUM_HUNG_CHUN",
                "dummy1": "2002/06/20"
            },
            {
                "model": "MC-980",
                "id_no": "210180984",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:21",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "176.0",
                "clothes_weight": "0.0",
                "weight": "62.0",
                "fat_p": "9.4",
                "fat_mass": "5.8",
                "ffm": "56.2",
                "muscle_mass": "53.3",
                "muscle_score": "0",
                "bone_mass": "2.9",
                "tbw": "39.4",
                "tbw_p": "63.5",
                "icw": "25.2",
                "ecw": "14.2",
                "ecw_p": "36.0",
                "bmi": "20.0",
                "standard_body_weight": "68.1",
                "degree_of_obesity": "-9.0",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "53.6",
                "visceral_fat_rating": "2",
                "leg_muscle_score": "115",
                "bmr_kcal": "1616",
                "bmr_kj": "6761",
                "bmr_score": "15",
                "metabolic_age": "16",
                "balancearm": "0",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "67.7",
                "predicted_fat_mass": "11.5",
                "fat_to_lose__gain": "5.7",
                "rl_fat_p": "9.5",
                "rl_fat_mass": "1.2",
                "rl_ffm": "11.3",
                "rl_muscle_mass": "10.7",
                "rl_fat_p_score": "-3",
                "rl_muscle_score": "1",
                "ll_fat_p": "10.0",
                "ll_fat_mass": "1.2",
                "ll_ffm": "11.1",
                "ll_muscle_mass": "10.5",
                "ll_fat_p_score": "-3",
                "ll_muscle_score": "0",
                "ra_fat_p": "8.1",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.9",
                "ra_muscle_mass": "2.7",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "0",
                "la_fat_p": "8.2",
                "la_fat_mass": "0.3",
                "la_ffm": "2.8",
                "la_muscle_mass": "2.6",
                "la_fat_p_score": "-2",
                "la_muscle_score": "-1",
                "trunk_fat_p": "9.1",
                "trunk_fat_mass": "2.8",
                "trunk_ffm": "28.1",
                "trunk_muscle_mass": "26.8",
                "trunk_fat_p_score": "-2",
                "trunk_muscle_score": "-2",
                "username": "YAU_CHEUK_WAA",
                "dummy1": "2000/11/18"
            },
            {
                "model": "MC-980",
                "id_no": "210181502",
                "status": "0",
                "date": "2022/12/04",
                "time": "16:04",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "175.0",
                "clothes_weight": "0.0",
                "weight": "89.7",
                "fat_p": "31.2",
                "fat_mass": "28.0",
                "ffm": "61.7",
                "muscle_mass": "58.5",
                "muscle_score": "2",
                "bone_mass": "3.2",
                "tbw": "38.4",
                "tbw_p": "42.8",
                "icw": "22.9",
                "ecw": "15.5",
                "ecw_p": "40.4",
                "bmi": "29.3",
                "standard_body_weight": "67.4",
                "degree_of_obesity": "33.1",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "53.0",
                "visceral_fat_rating": "13",
                "leg_muscle_score": "93",
                "bmr_kcal": "1829",
                "bmr_kj": "7653",
                "bmr_score": "4",
                "metabolic_age": "36",
                "balancearm": "0",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "74.3",
                "predicted_fat_mass": "12.6",
                "fat_to_lose__gain": "-15.4",
                "rl_fat_p": "27.5",
                "rl_fat_mass": "4.8",
                "rl_ffm": "12.7",
                "rl_muscle_mass": "12.0",
                "rl_fat_p_score": "3",
                "rl_muscle_score": "2",
                "ll_fat_p": "27.6",
                "ll_fat_mass": "4.8",
                "ll_ffm": "12.5",
                "ll_muscle_mass": "11.8",
                "ll_fat_p_score": "3",
                "ll_muscle_score": "2",
                "ra_fat_p": "23.4",
                "ra_fat_mass": "0.9",
                "ra_ffm": "2.9",
                "ra_muscle_mass": "2.7",
                "ra_fat_p_score": "3",
                "ra_muscle_score": "0",
                "la_fat_p": "24.1",
                "la_fat_mass": "0.9",
                "la_ffm": "2.9",
                "la_muscle_mass": "2.7",
                "la_fat_p_score": "3",
                "la_muscle_score": "0",
                "trunk_fat_p": "35.1",
                "trunk_fat_mass": "16.6",
                "trunk_ffm": "30.7",
                "trunk_muscle_mass": "29.3",
                "trunk_fat_p_score": "3",
                "trunk_muscle_score": "0",
                "username": "WONG_KWUN_HIM",
                "dummy1": "2000/05/19"
            },
            {
                "model": "MC-980",
                "id_no": "210194491",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:52",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "82.7",
                "fat_p": "24.7",
                "fat_mass": "20.4",
                "ffm": "62.3",
                "muscle_mass": "59.1",
                "muscle_score": "2",
                "bone_mass": "3.2",
                "tbw": "44.9",
                "tbw_p": "54.3",
                "icw": "28.6",
                "ecw": "16.3",
                "ecw_p": "36.3",
                "bmi": "28.6",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "30.0",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "10",
                "leg_muscle_score": "101",
                "bmr_kcal": "1843",
                "bmr_kj": "7711",
                "bmr_score": "7",
                "metabolic_age": "30",
                "balancearm": "1",
                "balanceleg": "1",
                "target_fat_p": "17",
                "predicted_weight": "75.1",
                "predicted_fat_mass": "12.8",
                "fat_to_lose__gain": "-7.6",
                "rl_fat_p": "23.2",
                "rl_fat_mass": "4.1",
                "rl_ffm": "13.5",
                "rl_muscle_mass": "12.8",
                "rl_fat_p_score": "2",
                "rl_muscle_score": "4",
                "ll_fat_p": "23.3",
                "ll_fat_mass": "4.0",
                "ll_ffm": "13.2",
                "ll_muscle_mass": "12.5",
                "ll_fat_p_score": "2",
                "ll_muscle_score": "3",
                "ra_fat_p": "19.1",
                "ra_fat_mass": "0.8",
                "ra_ffm": "3.5",
                "ra_muscle_mass": "3.3",
                "ra_fat_p_score": "2",
                "ra_muscle_score": "3",
                "la_fat_p": "20.7",
                "la_fat_mass": "0.9",
                "la_ffm": "3.3",
                "la_muscle_mass": "3.1",
                "la_fat_p_score": "2",
                "la_muscle_score": "2",
                "trunk_fat_p": "26.9",
                "trunk_fat_mass": "10.6",
                "trunk_ffm": "28.8",
                "trunk_muscle_mass": "27.4",
                "trunk_fat_p_score": "1",
                "trunk_muscle_score": "2",
                "username": "CHEUNG_TSUN_LOK",
                "dummy1": "2002/04/15"
            },
            {
                "model": "MC-980",
                "id_no": "210197604",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:58",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "79.3",
                "fat_p": "25.0",
                "fat_mass": "19.8",
                "ffm": "59.5",
                "muscle_mass": "56.4",
                "muscle_score": "2",
                "bone_mass": "3.1",
                "tbw": "38.5",
                "tbw_p": "48.5",
                "icw": "23.3",
                "ecw": "15.2",
                "ecw_p": "39.5",
                "bmi": "27.4",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "24.7",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "10",
                "leg_muscle_score": "101",
                "bmr_kcal": "1748",
                "bmr_kj": "7314",
                "bmr_score": "7",
                "metabolic_age": "32",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "71.7",
                "predicted_fat_mass": "12.2",
                "fat_to_lose__gain": "-7.6",
                "rl_fat_p": "22.8",
                "rl_fat_mass": "3.5",
                "rl_ffm": "11.8",
                "rl_muscle_mass": "11.2",
                "rl_fat_p_score": "2",
                "rl_muscle_score": "2",
                "ll_fat_p": "22.7",
                "ll_fat_mass": "3.4",
                "ll_ffm": "11.7",
                "ll_muscle_mass": "11.1",
                "ll_fat_p_score": "2",
                "ll_muscle_score": "2",
                "ra_fat_p": "18.0",
                "ra_fat_mass": "0.6",
                "ra_ffm": "2.9",
                "ra_muscle_mass": "2.7",
                "ra_fat_p_score": "1",
                "ra_muscle_score": "0",
                "la_fat_p": "18.8",
                "la_fat_mass": "0.6",
                "la_ffm": "2.8",
                "la_muscle_mass": "2.6",
                "la_fat_p_score": "2",
                "la_muscle_score": "0",
                "trunk_fat_p": "27.9",
                "trunk_fat_mass": "11.7",
                "trunk_ffm": "30.3",
                "trunk_muscle_mass": "28.8",
                "trunk_fat_p_score": "2",
                "trunk_muscle_score": "1",
                "username": "LAW_TSUN_TING",
                "dummy1": "2000/12/30"
            },
            {
                "model": "MC-980",
                "id_no": "210200084",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:54",
                "body_type": "0",
                "gender": "1",
                "age": "23",
                "height": "171.0",
                "clothes_weight": "0.0",
                "weight": "64.1",
                "fat_p": "13.4",
                "fat_mass": "8.6",
                "ffm": "55.5",
                "muscle_mass": "52.6",
                "muscle_score": "0",
                "bone_mass": "2.9",
                "tbw": "39.3",
                "tbw_p": "61.3",
                "icw": "24.9",
                "ecw": "14.4",
                "ecw_p": "36.6",
                "bmi": "21.9",
                "standard_body_weight": "64.3",
                "degree_of_obesity": "-0.3",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.6",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "103",
                "bmr_kcal": "1583",
                "bmr_kj": "6623",
                "bmr_score": "14",
                "metabolic_age": "17",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "66.9",
                "predicted_fat_mass": "11.4",
                "fat_to_lose__gain": "2.8",
                "rl_fat_p": "15.4",
                "rl_fat_mass": "1.9",
                "rl_ffm": "10.7",
                "rl_muscle_mass": "10.1",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "1",
                "ll_fat_p": "15.0",
                "ll_fat_mass": "1.9",
                "ll_ffm": "10.7",
                "ll_muscle_mass": "10.1",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "1",
                "ra_fat_p": "10.3",
                "ra_fat_mass": "0.3",
                "ra_ffm": "3.0",
                "ra_muscle_mass": "2.8",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "1",
                "la_fat_p": "10.6",
                "la_fat_mass": "0.3",
                "la_ffm": "2.9",
                "la_muscle_mass": "2.7",
                "la_fat_p_score": "-2",
                "la_muscle_score": "0",
                "trunk_fat_p": "13.0",
                "trunk_fat_mass": "4.2",
                "trunk_ffm": "28.2",
                "trunk_muscle_mass": "26.9",
                "trunk_fat_p_score": "-2",
                "trunk_muscle_score": "0",
                "username": "CHEN_ZE_HONG",
                "dummy1": "1998/06/16"
            },
            {
                "model": "MC-980",
                "id_no": "210204894",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:57",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "170.0",
                "clothes_weight": "0.0",
                "weight": "119.4",
                "fat_p": "41.7",
                "fat_mass": "49.8",
                "ffm": "69.6",
                "muscle_mass": "66.0",
                "muscle_score": "4",
                "bone_mass": "3.6",
                "tbw": "45.3",
                "tbw_p": "37.9",
                "icw": "26.9",
                "ecw": "18.4",
                "ecw_p": "40.6",
                "bmi": "41.3",
                "standard_body_weight": "63.6",
                "degree_of_obesity": "87.7",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "50.1",
                "visceral_fat_rating": "21",
                "leg_muscle_score": "88",
                "bmr_kcal": "2145",
                "bmr_kj": "8975",
                "bmr_score": "1",
                "metabolic_age": "34",
                "balancearm": "1",
                "balanceleg": "-1",
                "target_fat_p": "17",
                "predicted_weight": "83.9",
                "predicted_fat_mass": "14.3",
                "fat_to_lose__gain": "-35.5",
                "rl_fat_p": "37.1",
                "rl_fat_mass": "9.0",
                "rl_ffm": "15.3",
                "rl_muscle_mass": "14.5",
                "rl_fat_p_score": "4",
                "rl_muscle_score": "4",
                "ll_fat_p": "36.8",
                "ll_fat_mass": "9.0",
                "ll_ffm": "15.5",
                "ll_muscle_mass": "14.7",
                "ll_fat_p_score": "4",
                "ll_muscle_score": "4",
                "ra_fat_p": "31.0",
                "ra_fat_mass": "1.6",
                "ra_ffm": "3.6",
                "ra_muscle_mass": "3.4",
                "ra_fat_p_score": "4",
                "ra_muscle_score": "3",
                "la_fat_p": "33.0",
                "la_fat_mass": "1.7",
                "la_ffm": "3.4",
                "la_muscle_mass": "3.2",
                "la_fat_p_score": "4",
                "la_muscle_score": "3",
                "trunk_fat_p": "47.3",
                "trunk_fat_mass": "28.5",
                "trunk_ffm": "31.8",
                "trunk_muscle_mass": "30.2",
                "trunk_fat_p_score": "4",
                "trunk_muscle_score": "2",
                "username": "SUBONGKOT_PERAWI",
                "dummy1": "2001/11/30"
            },
            {
                "model": "MC-980",
                "id_no": "210213264",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:41",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "182.0",
                "clothes_weight": "0.0",
                "weight": "71.3",
                "fat_p": "18.5",
                "fat_mass": "13.2",
                "ffm": "58.1",
                "muscle_mass": "55.1",
                "muscle_score": "-1",
                "bone_mass": "3.0",
                "tbw": "37.1",
                "tbw_p": "52.0",
                "icw": "22.8",
                "ecw": "14.3",
                "ecw_p": "38.5",
                "bmi": "21.5",
                "standard_body_weight": "72.9",
                "degree_of_obesity": "-2.2",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "57.4",
                "visceral_fat_rating": "5",
                "leg_muscle_score": "103",
                "bmr_kcal": "1684",
                "bmr_kj": "7046",
                "bmr_score": "10",
                "metabolic_age": "20",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "70.0",
                "predicted_fat_mass": "11.9",
                "fat_to_lose__gain": "-1.3",
                "rl_fat_p": "18.1",
                "rl_fat_mass": "2.4",
                "rl_ffm": "10.9",
                "rl_muscle_mass": "10.3",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "0",
                "ll_fat_p": "18.3",
                "ll_fat_mass": "2.4",
                "ll_ffm": "10.8",
                "ll_muscle_mass": "10.2",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "0",
                "ra_fat_p": "13.7",
                "ra_fat_mass": "0.4",
                "ra_ffm": "2.8",
                "ra_muscle_mass": "2.6",
                "ra_fat_p_score": "0",
                "ra_muscle_score": "-2",
                "la_fat_p": "13.5",
                "la_fat_mass": "0.4",
                "la_ffm": "2.8",
                "la_muscle_mass": "2.6",
                "la_fat_p_score": "0",
                "la_muscle_score": "-2",
                "trunk_fat_p": "19.8",
                "trunk_fat_mass": "7.6",
                "trunk_ffm": "30.8",
                "trunk_muscle_mass": "29.4",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-1",
                "username": "SINGH_HARVEER",
                "dummy1": "2000/08/25"
            },
            {
                "model": "MC-980",
                "id_no": "210242223",
                "status": "0",
                "date": "2022/12/04",
                "time": "12:51",
                "body_type": "0",
                "gender": "1",
                "age": "20",
                "height": "168.0",
                "clothes_weight": "0.0",
                "weight": "61.2",
                "fat_p": "15.9",
                "fat_mass": "9.7",
                "ffm": "51.5",
                "muscle_mass": "48.8",
                "muscle_score": "0",
                "bone_mass": "2.7",
                "tbw": "34.7",
                "tbw_p": "56.7",
                "icw": "21.3",
                "ecw": "13.4",
                "ecw_p": "38.6",
                "bmi": "21.7",
                "standard_body_weight": "62.1",
                "degree_of_obesity": "-1.4",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "48.8",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "111",
                "bmr_kcal": "1488",
                "bmr_kj": "6226",
                "bmr_score": "11",
                "metabolic_age": "18",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "62.0",
                "predicted_fat_mass": "10.5",
                "fat_to_lose__gain": "0.8",
                "rl_fat_p": "16.4",
                "rl_fat_mass": "2.0",
                "rl_ffm": "10.0",
                "rl_muscle_mass": "9.4",
                "rl_fat_p_score": "-1",
                "rl_muscle_score": "0",
                "ll_fat_p": "16.1",
                "ll_fat_mass": "1.9",
                "ll_ffm": "9.9",
                "ll_muscle_mass": "9.4",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "0",
                "ra_fat_p": "11.4",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.6",
                "ra_muscle_mass": "2.4",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "0",
                "la_fat_p": "11.7",
                "la_fat_mass": "0.3",
                "la_ffm": "2.5",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "-1",
                "la_muscle_score": "-1",
                "trunk_fat_p": "16.4",
                "trunk_fat_mass": "5.2",
                "trunk_ffm": "26.5",
                "trunk_muscle_mass": "25.3",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "-1",
                "username": "KWAN_WAI_LOK",
                "dummy1": "2000/10/15"
            },
            {
                "model": "MC-980",
                "id_no": "210242223",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:12",
                "body_type": "0",
                "gender": "1",
                "age": "21",
                "height": "168.0",
                "clothes_weight": "0.0",
                "weight": "60.5",
                "fat_p": "15.7",
                "fat_mass": "9.5",
                "ffm": "51.0",
                "muscle_mass": "48.3",
                "muscle_score": "0",
                "bone_mass": "2.7",
                "tbw": "34.2",
                "tbw_p": "56.5",
                "icw": "20.9",
                "ecw": "13.3",
                "ecw_p": "38.9",
                "bmi": "21.4",
                "standard_body_weight": "62.1",
                "degree_of_obesity": "-2.6",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "48.8",
                "visceral_fat_rating": "4",
                "leg_muscle_score": "111",
                "bmr_kcal": "1467",
                "bmr_kj": "6138",
                "bmr_score": "12",
                "metabolic_age": "18",
                "balancearm": "0",
                "balanceleg": "0",
                "target_fat_p": "17",
                "predicted_weight": "61.4",
                "predicted_fat_mass": "10.4",
                "fat_to_lose__gain": "0.9",
                "rl_fat_p": "17.0",
                "rl_fat_mass": "2.0",
                "rl_ffm": "9.7",
                "rl_muscle_mass": "9.2",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "0",
                "ll_fat_p": "16.8",
                "ll_fat_mass": "1.9",
                "ll_ffm": "9.6",
                "ll_muscle_mass": "9.1",
                "ll_fat_p_score": "-1",
                "ll_muscle_score": "0",
                "ra_fat_p": "11.3",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.6",
                "ra_muscle_mass": "2.4",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "0",
                "la_fat_p": "11.1",
                "la_fat_mass": "0.3",
                "la_ffm": "2.6",
                "la_muscle_mass": "2.4",
                "la_fat_p_score": "-2",
                "la_muscle_score": "0",
                "trunk_fat_p": "15.9",
                "trunk_fat_mass": "5.0",
                "trunk_ffm": "26.5",
                "trunk_muscle_mass": "25.2",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "-1",
                "username": "KWAN_WAI_LOK",
                "dummy1": "2000/10/15"
            },
            {
                "model": "MC-980",
                "id_no": "210393560",
                "status": "0",
                "date": "2022/12/04",
                "time": "17:05",
                "body_type": "0",
                "gender": "1",
                "age": "19",
                "height": "178.0",
                "clothes_weight": "0.0",
                "weight": "71.5",
                "fat_p": "19.1",
                "fat_mass": "13.7",
                "ffm": "57.8",
                "muscle_mass": "54.8",
                "muscle_score": "0",
                "bone_mass": "3.0",
                "tbw": "37.6",
                "tbw_p": "52.6",
                "icw": "23.2",
                "ecw": "14.4",
                "ecw_p": "38.3",
                "bmi": "22.6",
                "standard_body_weight": "69.7",
                "degree_of_obesity": "2.6",
                "standard_fat_p": "17.0",
                "standard_muscle_mass": "54.9",
                "visceral_fat_rating": "6",
                "leg_muscle_score": "103",
                "bmr_kcal": "1692",
                "bmr_kj": "7079",
                "bmr_score": "8",
                "metabolic_age": "20",
                "balancearm": "2",
                "balanceleg": "2",
                "target_fat_p": "17",
                "predicted_weight": "69.6",
                "predicted_fat_mass": "11.8",
                "fat_to_lose__gain": "-1.9",
                "rl_fat_p": "19.1",
                "rl_fat_mass": "2.6",
                "rl_ffm": "11.0",
                "rl_muscle_mass": "10.4",
                "rl_fat_p_score": "0",
                "rl_muscle_score": "0",
                "ll_fat_p": "19.7",
                "ll_fat_mass": "2.6",
                "ll_ffm": "10.7",
                "ll_muscle_mass": "10.1",
                "ll_fat_p_score": "0",
                "ll_muscle_score": "0",
                "ra_fat_p": "12.2",
                "ra_fat_mass": "0.4",
                "ra_ffm": "3.2",
                "ra_muscle_mass": "3.0",
                "ra_fat_p_score": "-1",
                "ra_muscle_score": "1",
                "la_fat_p": "13.5",
                "la_fat_mass": "0.5",
                "la_ffm": "2.9",
                "la_muscle_mass": "2.7",
                "la_fat_p_score": "0",
                "la_muscle_score": "0",
                "trunk_fat_p": "20.2",
                "trunk_fat_mass": "7.6",
                "trunk_ffm": "30.0",
                "trunk_muscle_mass": "28.6",
                "trunk_fat_p_score": "0",
                "trunk_muscle_score": "-1",
                "username": "YIU_WAI_KIT",
                "dummy1": "2002/08/14"
            },
            {
                "model": "MC-980",
                "id_no": "210438963",
                "status": "0",
                "date": "2022/12/04",
                "time": "15:25",
                "body_type": "0",
                "gender": "2",
                "age": "18",
                "height": "173.5",
                "clothes_weight": "0.0",
                "weight": "59.1",
                "fat_p": "25.8",
                "fat_mass": "15.2",
                "ffm": "43.9",
                "muscle_mass": "41.2",
                "muscle_score": "-2",
                "bone_mass": "2.7",
                "tbw": "29.3",
                "tbw_p": "49.6",
                "icw": "18.0",
                "ecw": "11.3",
                "ecw_p": "38.6",
                "bmi": "19.6",
                "standard_body_weight": "66.2",
                "degree_of_obesity": "-10.7",
                "standard_fat_p": "28.0",
                "standard_muscle_mass": "44.7",
                "visceral_fat_rating": "2",
                "leg_muscle_score": "109",
                "bmr_kcal": "1370",
                "bmr_kj": "5732",
                "bmr_score": "9",
                "metabolic_age": "19",
                "balancearm": "1",
                "balanceleg": "1",
                "target_fat_p": "28",
                "predicted_weight": "61.0",
                "predicted_fat_mass": "17.1",
                "fat_to_lose__gain": "1.9",
                "rl_fat_p": "28.0",
                "rl_fat_mass": "3.3",
                "rl_ffm": "8.5",
                "rl_muscle_mass": "8.0",
                "rl_fat_p_score": "-2",
                "rl_muscle_score": "0",
                "ll_fat_p": "27.9",
                "ll_fat_mass": "3.3",
                "ll_ffm": "8.4",
                "ll_muscle_mass": "7.9",
                "ll_fat_p_score": "-2",
                "ll_muscle_score": "0",
                "ra_fat_p": "20.9",
                "ra_fat_mass": "0.5",
                "ra_ffm": "2.0",
                "ra_muscle_mass": "1.9",
                "ra_fat_p_score": "-2",
                "ra_muscle_score": "-1",
                "la_fat_p": "23.4",
                "la_fat_mass": "0.6",
                "la_ffm": "1.9",
                "la_muscle_mass": "1.8",
                "la_fat_p_score": "-1",
                "la_muscle_score": "-1",
                "trunk_fat_p": "24.5",
                "trunk_fat_mass": "7.5",
                "trunk_ffm": "23.1",
                "trunk_muscle_mass": "21.6",
                "trunk_fat_p_score": "-1",
                "trunk_muscle_score": "-2",
                "username": "CHING_KA_YAM",
                "dummy1": "2003/07/03"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014103",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:24",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "134.0",
                "clothes_weight": "0.0",
                "weight": "32.2",
                "fat_p": "23.5",
                "fat_mass": "7.6",
                "ffm": "24.6",
                "muscle_mass": "23.3",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "18.0",
                "tbw_p": "55.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "17.9",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1144",
                "bmr_kj": "4786",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "30.4",
                "predicted_fat_mass": "5.8",
                "fat_to_lose__gain": "-1.8",
                "rl_fat_p": "27.4",
                "rl_fat_mass": "1.7",
                "rl_ffm": "4.4",
                "rl_muscle_mass": "4.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "27.5",
                "ll_fat_mass": "1.7",
                "ll_ffm": "4.4",
                "ll_muscle_mass": "4.2",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "22.3",
                "ra_fat_mass": "0.3",
                "ra_ffm": "0.9",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "21.8",
                "la_fat_mass": "0.3",
                "la_ffm": "1.0",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "20.6",
                "trunk_fat_mass": "3.6",
                "trunk_ffm": "13.9",
                "trunk_muscle_mass": "13.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4103",
                "dummy1": "2012/09/29"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014109",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:01",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "129.0",
                "clothes_weight": "0.0",
                "weight": "23.7",
                "fat_p": "7.2",
                "fat_mass": "1.7",
                "ffm": "22.0",
                "muscle_mass": "21.0",
                "muscle_score": "",
                "bone_mass": "1.0",
                "tbw": "16.1",
                "tbw_p": "67.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "14.2",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1101",
                "bmr_kj": "4607",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "26.2",
                "predicted_fat_mass": "4.2",
                "fat_to_lose__gain": "2.5",
                "rl_fat_p": "12.3",
                "rl_fat_mass": "0.6",
                "rl_ffm": "4.0",
                "rl_muscle_mass": "3.8",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "12.1",
                "ll_fat_mass": "0.6",
                "ll_ffm": "4.0",
                "ll_muscle_mass": "3.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "3.9",
                "ra_fat_mass": "0.0",
                "ra_ffm": "0.9",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "6.3",
                "la_fat_mass": "0.1",
                "la_ffm": "0.9",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.0",
                "trunk_fat_mass": "0.4",
                "trunk_ffm": "12.2",
                "trunk_muscle_mass": "11.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4109",
                "dummy1": "2012/09/27"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014118",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:16",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "132.0",
                "clothes_weight": "0.0",
                "weight": "45.9",
                "fat_p": "47.3",
                "fat_mass": "21.7",
                "ffm": "24.2",
                "muscle_mass": "22.9",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "17.7",
                "tbw_p": "38.6",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "26.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1202",
                "bmr_kj": "5029",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "29.9",
                "predicted_fat_mass": "5.7",
                "fat_to_lose__gain": "-16.0",
                "rl_fat_p": "53.0",
                "rl_fat_mass": "4.7",
                "rl_ffm": "4.2",
                "rl_muscle_mass": "4.0",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "52.9",
                "ll_fat_mass": "4.7",
                "ll_ffm": "4.2",
                "ll_muscle_mass": "4.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "40.2",
                "ra_fat_mass": "0.8",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "41.1",
                "la_fat_mass": "0.9",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "44.4",
                "trunk_fat_mass": "10.6",
                "trunk_ffm": "13.3",
                "trunk_muscle_mass": "12.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4118",
                "dummy1": "2012/04/07"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014122",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:19",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "130.0",
                "clothes_weight": "0.0",
                "weight": "37.9",
                "fat_p": "34.7",
                "fat_mass": "13.2",
                "ffm": "24.7",
                "muscle_mass": "23.5",
                "muscle_score": "",
                "bone_mass": "1.2",
                "tbw": "18.1",
                "tbw_p": "47.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1193",
                "bmr_kj": "4992",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "29.4",
                "predicted_fat_mass": "4.7",
                "fat_to_lose__gain": "-8.5",
                "rl_fat_p": "40.4",
                "rl_fat_mass": "3.3",
                "rl_ffm": "4.8",
                "rl_muscle_mass": "4.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "40.1",
                "ll_fat_mass": "3.2",
                "ll_ffm": "4.8",
                "ll_muscle_mass": "4.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "30.2",
                "ra_fat_mass": "0.5",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "30.0",
                "la_fat_mass": "0.5",
                "la_ffm": "1.2",
                "la_muscle_mass": "1.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "31.0",
                "trunk_fat_mass": "5.7",
                "trunk_ffm": "12.7",
                "trunk_muscle_mass": "12.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4122",
                "dummy1": "2012/09/26"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014210",
                "status": "1",
                "date": "2021/09/30",
                "time": "12:22",
                "body_type": "0",
                "gender": "1",
                "age": "8",
                "height": "131.0",
                "clothes_weight": "0.0",
                "weight": "22.8",
                "fat_p": "3.0",
                "fat_mass": "0.7",
                "ffm": "22.1",
                "muscle_mass": "21.1",
                "muscle_score": "",
                "bone_mass": "1.0",
                "tbw": "16.2",
                "tbw_p": "71.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1109",
                "bmr_kj": "4640",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "26.3",
                "predicted_fat_mass": "4.2",
                "fat_to_lose__gain": "3.5",
                "rl_fat_p": "",
                "rl_fat_mass": "",
                "rl_ffm": "",
                "rl_muscle_mass": "",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "",
                "ll_fat_mass": "",
                "ll_ffm": "",
                "ll_muscle_mass": "",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "",
                "ra_fat_mass": "",
                "ra_ffm": "",
                "ra_muscle_mass": "",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "",
                "la_fat_mass": "",
                "la_ffm": "",
                "la_muscle_mass": "",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "",
                "trunk_fat_mass": "",
                "trunk_ffm": "",
                "trunk_muscle_mass": "",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4210",
                "dummy1": "2012/11/27"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014211",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:07",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "136.0",
                "clothes_weight": "0.0",
                "weight": "30.7",
                "fat_p": "14.0",
                "fat_mass": "4.3",
                "ffm": "26.4",
                "muscle_mass": "25.1",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "19.3",
                "tbw_p": "62.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1210",
                "bmr_kj": "5063",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "31.4",
                "predicted_fat_mass": "5.0",
                "fat_to_lose__gain": "0.7",
                "rl_fat_p": "19.5",
                "rl_fat_mass": "1.2",
                "rl_ffm": "4.8",
                "rl_muscle_mass": "4.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "19.6",
                "ll_fat_mass": "1.1",
                "ll_ffm": "4.7",
                "ll_muscle_mass": "4.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "11.3",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "13.0",
                "la_fat_mass": "0.2",
                "la_ffm": "1.1",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "10.4",
                "trunk_fat_mass": "1.7",
                "trunk_ffm": "14.7",
                "trunk_muscle_mass": "14.0",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4211",
                "dummy1": "2012/01/21"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014213",
                "status": "1",
                "date": "2021/09/30",
                "time": "12:12",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "137.0",
                "clothes_weight": "0.0",
                "weight": "25.1",
                "fat_p": "3.0",
                "fat_mass": "0.8",
                "ffm": "24.3",
                "muscle_mass": "23.2",
                "muscle_score": "",
                "bone_mass": "1.1",
                "tbw": "17.8",
                "tbw_p": "70.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1153",
                "bmr_kj": "4824",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "28.9",
                "predicted_fat_mass": "4.6",
                "fat_to_lose__gain": "3.8",
                "rl_fat_p": "",
                "rl_fat_mass": "",
                "rl_ffm": "",
                "rl_muscle_mass": "",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "",
                "ll_fat_mass": "",
                "ll_ffm": "",
                "ll_muscle_mass": "",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "",
                "ra_fat_mass": "",
                "ra_ffm": "",
                "ra_muscle_mass": "",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "",
                "la_fat_mass": "",
                "la_ffm": "",
                "la_muscle_mass": "",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "",
                "trunk_fat_mass": "",
                "trunk_ffm": "",
                "trunk_muscle_mass": "",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4213",
                "dummy1": "2012/05/29"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014215",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:04",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "133.0",
                "clothes_weight": "0.0",
                "weight": "33.7",
                "fat_p": "20.2",
                "fat_mass": "6.8",
                "ffm": "26.9",
                "muscle_mass": "25.6",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "19.7",
                "tbw_p": "58.5",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "19.1",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1228",
                "bmr_kj": "5138",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "32.0",
                "predicted_fat_mass": "5.1",
                "fat_to_lose__gain": "-1.7",
                "rl_fat_p": "26.4",
                "rl_fat_mass": "1.8",
                "rl_ffm": "4.9",
                "rl_muscle_mass": "4.7",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "26.9",
                "ll_fat_mass": "1.8",
                "ll_ffm": "4.8",
                "ll_muscle_mass": "4.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "16.3",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "17.9",
                "la_fat_mass": "0.3",
                "la_ffm": "1.2",
                "la_muscle_mass": "1.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "15.4",
                "trunk_fat_mass": "2.7",
                "trunk_ffm": "14.8",
                "trunk_muscle_mass": "14.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4215",
                "dummy1": "2012/01/12"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014221",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:10",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "140.0",
                "clothes_weight": "0.0",
                "weight": "26.9",
                "fat_p": "11.0",
                "fat_mass": "3.0",
                "ffm": "23.9",
                "muscle_mass": "22.7",
                "muscle_score": "",
                "bone_mass": "1.2",
                "tbw": "17.5",
                "tbw_p": "65.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.7",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1105",
                "bmr_kj": "4623",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "29.5",
                "predicted_fat_mass": "5.6",
                "fat_to_lose__gain": "2.6",
                "rl_fat_p": "21.6",
                "rl_fat_mass": "1.2",
                "rl_ffm": "4.3",
                "rl_muscle_mass": "4.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "22.2",
                "ll_fat_mass": "1.2",
                "ll_ffm": "4.3",
                "ll_muscle_mass": "4.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "5.5",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "4.8",
                "la_fat_mass": "0.1",
                "la_ffm": "1.1",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.0",
                "trunk_fat_mass": "0.4",
                "trunk_ffm": "13.1",
                "trunk_muscle_mass": "12.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4221",
                "dummy1": "2012/06/22"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014222",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:54",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "131.0",
                "clothes_weight": "0.0",
                "weight": "28.3",
                "fat_p": "21.9",
                "fat_mass": "6.2",
                "ffm": "22.1",
                "muscle_mass": "21.0",
                "muscle_score": "",
                "bone_mass": "1.1",
                "tbw": "16.2",
                "tbw_p": "57.2",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.5",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1077",
                "bmr_kj": "4506",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "27.3",
                "predicted_fat_mass": "5.2",
                "fat_to_lose__gain": "-1.0",
                "rl_fat_p": "29.3",
                "rl_fat_mass": "1.6",
                "rl_ffm": "3.8",
                "rl_muscle_mass": "3.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "30.1",
                "ll_fat_mass": "1.6",
                "ll_ffm": "3.7",
                "ll_muscle_mass": "3.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "15.3",
                "ra_fat_mass": "0.2",
                "ra_ffm": "0.9",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "17.1",
                "la_fat_mass": "0.2",
                "la_ffm": "0.9",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "16.9",
                "trunk_fat_mass": "2.6",
                "trunk_ffm": "12.8",
                "trunk_muscle_mass": "12.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4222",
                "dummy1": "2012/06/13"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014225",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:56",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "131.0",
                "clothes_weight": "0.0",
                "weight": "32.7",
                "fat_p": "24.6",
                "fat_mass": "8.0",
                "ffm": "24.7",
                "muscle_mass": "23.5",
                "muscle_score": "",
                "bone_mass": "1.2",
                "tbw": "18.1",
                "tbw_p": "55.4",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "19.1",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1178",
                "bmr_kj": "4929",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "29.4",
                "predicted_fat_mass": "4.7",
                "fat_to_lose__gain": "-3.3",
                "rl_fat_p": "29.6",
                "rl_fat_mass": "1.9",
                "rl_ffm": "4.4",
                "rl_muscle_mass": "4.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "30.7",
                "ll_fat_mass": "1.9",
                "ll_ffm": "4.3",
                "ll_muscle_mass": "4.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "19.6",
                "ra_fat_mass": "0.3",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "20.4",
                "la_fat_mass": "0.3",
                "la_ffm": "1.0",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "20.6",
                "trunk_fat_mass": "3.6",
                "trunk_ffm": "13.9",
                "trunk_muscle_mass": "13.2",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4225",
                "dummy1": "2012/09/13"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014307",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:47",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "142.0",
                "clothes_weight": "0.0",
                "weight": "27.1",
                "fat_p": "10.5",
                "fat_mass": "2.8",
                "ffm": "24.3",
                "muscle_mass": "23.0",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "17.8",
                "tbw_p": "65.7",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1112",
                "bmr_kj": "4653",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "30.0",
                "predicted_fat_mass": "5.7",
                "fat_to_lose__gain": "2.9",
                "rl_fat_p": "18.9",
                "rl_fat_mass": "1.0",
                "rl_ffm": "4.1",
                "rl_muscle_mass": "3.9",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "19.5",
                "ll_fat_mass": "1.0",
                "ll_ffm": "4.0",
                "ll_muscle_mass": "3.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "6.3",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.0",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "7.0",
                "la_fat_mass": "0.1",
                "la_ffm": "0.9",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "4.0",
                "trunk_fat_mass": "0.6",
                "trunk_ffm": "14.3",
                "trunk_muscle_mass": "13.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4307",
                "dummy1": "2012/03/30"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014313",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:45",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "123.0",
                "clothes_weight": "0.0",
                "weight": "22.7",
                "fat_p": "13.3",
                "fat_mass": "3.0",
                "ffm": "19.7",
                "muscle_mass": "18.8",
                "muscle_score": "",
                "bone_mass": "0.9",
                "tbw": "14.4",
                "tbw_p": "63.4",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1006",
                "bmr_kj": "4209",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "24.3",
                "predicted_fat_mass": "4.6",
                "fat_to_lose__gain": "1.6",
                "rl_fat_p": "21.8",
                "rl_fat_mass": "1.0",
                "rl_ffm": "3.5",
                "rl_muscle_mass": "3.3",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "22.4",
                "ll_fat_mass": "1.0",
                "ll_ffm": "3.5",
                "ll_muscle_mass": "3.3",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "8.5",
                "ra_fat_mass": "0.1",
                "ra_ffm": "0.9",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "8.9",
                "la_fat_mass": "0.1",
                "la_ffm": "0.8",
                "la_muscle_mass": "0.8",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "6.8",
                "trunk_fat_mass": "0.8",
                "trunk_ffm": "11.0",
                "trunk_muscle_mass": "10.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4313",
                "dummy1": "2012/07/29"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014324",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:42",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "130.0",
                "clothes_weight": "0.0",
                "weight": "30.0",
                "fat_p": "18.7",
                "fat_mass": "5.6",
                "ffm": "24.4",
                "muscle_mass": "23.3",
                "muscle_score": "",
                "bone_mass": "1.1",
                "tbw": "17.9",
                "tbw_p": "59.7",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "17.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1166",
                "bmr_kj": "4879",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "29.0",
                "predicted_fat_mass": "4.6",
                "fat_to_lose__gain": "-1.0",
                "rl_fat_p": "25.6",
                "rl_fat_mass": "1.5",
                "rl_ffm": "4.4",
                "rl_muscle_mass": "4.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "25.7",
                "ll_fat_mass": "1.5",
                "ll_ffm": "4.3",
                "ll_muscle_mass": "4.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "14.7",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "16.7",
                "la_fat_mass": "0.2",
                "la_ffm": "1.0",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "13.9",
                "trunk_fat_mass": "2.2",
                "trunk_ffm": "13.6",
                "trunk_muscle_mass": "13.0",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4324",
                "dummy1": "2012/04/08"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014513",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:50",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "137.0",
                "clothes_weight": "0.0",
                "weight": "32.2",
                "fat_p": "16.8",
                "fat_mass": "5.4",
                "ffm": "26.8",
                "muscle_mass": "25.5",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "19.6",
                "tbw_p": "60.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "17.2",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1222",
                "bmr_kj": "5113",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "31.9",
                "predicted_fat_mass": "5.1",
                "fat_to_lose__gain": "-0.3",
                "rl_fat_p": "23.7",
                "rl_fat_mass": "1.5",
                "rl_ffm": "4.8",
                "rl_muscle_mass": "4.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "23.0",
                "ll_fat_mass": "1.4",
                "ll_ffm": "4.8",
                "ll_muscle_mass": "4.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "13.5",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "15.6",
                "la_fat_mass": "0.2",
                "la_ffm": "1.1",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "12.3",
                "trunk_fat_mass": "2.1",
                "trunk_ffm": "15.0",
                "trunk_muscle_mass": "14.3",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4513",
                "dummy1": "2012/08/20"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014523",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:37",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "138.0",
                "clothes_weight": "0.0",
                "weight": "42.1",
                "fat_p": "36.2",
                "fat_mass": "15.2",
                "ffm": "26.9",
                "muscle_mass": "25.6",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "19.7",
                "tbw_p": "46.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.1",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1237",
                "bmr_kj": "5176",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "32.0",
                "predicted_fat_mass": "5.1",
                "fat_to_lose__gain": "-10.1",
                "rl_fat_p": "39.0",
                "rl_fat_mass": "3.5",
                "rl_ffm": "5.4",
                "rl_muscle_mass": "5.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "41.2",
                "ll_fat_mass": "3.6",
                "ll_ffm": "5.2",
                "ll_muscle_mass": "5.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "28.7",
                "ra_fat_mass": "0.6",
                "ra_ffm": "1.4",
                "ra_muscle_mass": "1.3",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "30.4",
                "la_fat_mass": "0.6",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "33.7",
                "trunk_fat_mass": "6.9",
                "trunk_ffm": "13.6",
                "trunk_muscle_mass": "13.0",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4523",
                "dummy1": "2011/05/16"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100014525",
                "status": "0",
                "date": "2021/09/30",
                "time": "12:39",
                "body_type": "0",
                "gender": "1",
                "age": "8",
                "height": "140.0",
                "clothes_weight": "0.0",
                "weight": "32.0",
                "fat_p": "13.0",
                "fat_mass": "4.2",
                "ffm": "27.8",
                "muscle_mass": "26.5",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "20.3",
                "tbw_p": "63.4",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1251",
                "bmr_kj": "5234",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "33.1",
                "predicted_fat_mass": "5.3",
                "fat_to_lose__gain": "1.1",
                "rl_fat_p": "19.9",
                "rl_fat_mass": "1.2",
                "rl_ffm": "4.9",
                "rl_muscle_mass": "4.7",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "19.6",
                "ll_fat_mass": "1.2",
                "ll_ffm": "4.8",
                "ll_muscle_mass": "4.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "10.6",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "12.4",
                "la_fat_mass": "0.2",
                "la_ffm": "1.2",
                "la_muscle_mass": "1.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "8.7",
                "trunk_fat_mass": "1.5",
                "trunk_ffm": "15.8",
                "trunk_muscle_mass": "15.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4525",
                "dummy1": "2012/12/05"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015109",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:21",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "147.0",
                "clothes_weight": "0.0",
                "weight": "29.8",
                "fat_p": "9.1",
                "fat_mass": "2.7",
                "ffm": "27.1",
                "muscle_mass": "25.6",
                "muscle_score": "",
                "bone_mass": "1.5",
                "tbw": "19.8",
                "tbw_p": "66.4",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1179",
                "bmr_kj": "4933",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "33.5",
                "predicted_fat_mass": "6.4",
                "fat_to_lose__gain": "3.7",
                "rl_fat_p": "16.8",
                "rl_fat_mass": "0.9",
                "rl_ffm": "4.7",
                "rl_muscle_mass": "4.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "17.0",
                "ll_fat_mass": "1.0",
                "ll_ffm": "4.7",
                "ll_muscle_mass": "4.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "7.0",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "7.1",
                "la_fat_mass": "0.1",
                "la_ffm": "1.1",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.7",
                "trunk_fat_mass": "0.6",
                "trunk_ffm": "15.5",
                "trunk_muscle_mass": "14.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5109",
                "dummy1": "2011/10/12"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015110",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:14",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "145.0",
                "clothes_weight": "0.0",
                "weight": "39.1",
                "fat_p": "20.3",
                "fat_mass": "7.9",
                "ffm": "31.2",
                "muscle_mass": "29.6",
                "muscle_score": "",
                "bone_mass": "1.6",
                "tbw": "22.8",
                "tbw_p": "58.3",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "18.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1323",
                "bmr_kj": "5535",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "37.1",
                "predicted_fat_mass": "5.9",
                "fat_to_lose__gain": "-2.0",
                "rl_fat_p": "25.4",
                "rl_fat_mass": "1.9",
                "rl_ffm": "5.7",
                "rl_muscle_mass": "5.4",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "25.0",
                "ll_fat_mass": "1.9",
                "ll_ffm": "5.7",
                "ll_muscle_mass": "5.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "17.6",
                "ra_fat_mass": "0.3",
                "ra_ffm": "1.4",
                "ra_muscle_mass": "1.3",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "18.0",
                "la_fat_mass": "0.3",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "17.0",
                "trunk_fat_mass": "3.5",
                "trunk_ffm": "17.1",
                "trunk_muscle_mass": "16.3",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5A10",
                "dummy1": "2011/06/17"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015113",
                "status": "1",
                "date": "2021/09/30",
                "time": "08:59",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "135.0",
                "clothes_weight": "0.0",
                "weight": "24.5",
                "fat_p": "4.0",
                "fat_mass": "1.0",
                "ffm": "23.5",
                "muscle_mass": "22.4",
                "muscle_score": "",
                "bone_mass": "1.1",
                "tbw": "17.2",
                "tbw_p": "70.2",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1124",
                "bmr_kj": "4703",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "28.0",
                "predicted_fat_mass": "4.5",
                "fat_to_lose__gain": "3.5",
                "rl_fat_p": "",
                "rl_fat_mass": "",
                "rl_ffm": "",
                "rl_muscle_mass": "",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "",
                "ll_fat_mass": "",
                "ll_ffm": "",
                "ll_muscle_mass": "",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "",
                "ra_fat_mass": "",
                "ra_ffm": "",
                "ra_muscle_mass": "",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "",
                "la_fat_mass": "",
                "la_ffm": "",
                "la_muscle_mass": "",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "",
                "trunk_fat_mass": "",
                "trunk_ffm": "",
                "trunk_muscle_mass": "",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "HUGO",
                "dummy1": "2011/06/20"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015115",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:04",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "130.0",
                "clothes_weight": "0.0",
                "weight": "31.5",
                "fat_p": "25.5",
                "fat_mass": "8.0",
                "ffm": "23.5",
                "muscle_mass": "22.3",
                "muscle_score": "",
                "bone_mass": "1.2",
                "tbw": "17.2",
                "tbw_p": "54.6",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "18.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1118",
                "bmr_kj": "4678",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "29.0",
                "predicted_fat_mass": "5.5",
                "fat_to_lose__gain": "-2.5",
                "rl_fat_p": "31.8",
                "rl_fat_mass": "1.9",
                "rl_ffm": "4.1",
                "rl_muscle_mass": "3.9",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "32.3",
                "ll_fat_mass": "2.0",
                "ll_ffm": "4.1",
                "ll_muscle_mass": "3.9",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "20.9",
                "ra_fat_mass": "0.3",
                "ra_ffm": "1.0",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "21.9",
                "la_fat_mass": "0.3",
                "la_ffm": "0.9",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "20.7",
                "trunk_fat_mass": "3.5",
                "trunk_ffm": "13.4",
                "trunk_muscle_mass": "12.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "TANG",
                "dummy1": "2011/11/10"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015203",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:17",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "165.0",
                "clothes_weight": "0.0",
                "weight": "59.4",
                "fat_p": "31.9",
                "fat_mass": "18.9",
                "ffm": "40.5",
                "muscle_mass": "38.0",
                "muscle_score": "",
                "bone_mass": "2.5",
                "tbw": "29.6",
                "tbw_p": "49.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "21.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1611",
                "bmr_kj": "6740",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "50.6",
                "predicted_fat_mass": "10.1",
                "fat_to_lose__gain": "-8.8",
                "rl_fat_p": "37.0",
                "rl_fat_mass": "4.1",
                "rl_ffm": "6.9",
                "rl_muscle_mass": "6.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "38.1",
                "ll_fat_mass": "4.2",
                "ll_ffm": "6.8",
                "ll_muscle_mass": "6.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "27.0",
                "ra_fat_mass": "0.7",
                "ra_ffm": "1.9",
                "ra_muscle_mass": "1.8",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "27.9",
                "la_fat_mass": "0.7",
                "la_ffm": "1.9",
                "la_muscle_mass": "1.8",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "28.6",
                "trunk_fat_mass": "9.2",
                "trunk_ffm": "23.0",
                "trunk_muscle_mass": "21.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5203",
                "dummy1": "2011/03/09"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015204",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:24",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "158.0",
                "clothes_weight": "0.0",
                "weight": "40.0",
                "fat_p": "9.7",
                "fat_mass": "3.9",
                "ffm": "36.1",
                "muscle_mass": "34.3",
                "muscle_score": "",
                "bone_mass": "1.8",
                "tbw": "26.4",
                "tbw_p": "66.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1438",
                "bmr_kj": "6017",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "43.0",
                "predicted_fat_mass": "6.9",
                "fat_to_lose__gain": "3.0",
                "rl_fat_p": "15.2",
                "rl_fat_mass": "1.2",
                "rl_ffm": "6.5",
                "rl_muscle_mass": "6.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "14.8",
                "ll_fat_mass": "1.1",
                "ll_ffm": "6.5",
                "ll_muscle_mass": "6.2",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "7.9",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.4",
                "ra_muscle_mass": "1.3",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "9.3",
                "la_fat_mass": "0.2",
                "la_ffm": "1.6",
                "la_muscle_mass": "1.5",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "6.1",
                "trunk_fat_mass": "1.3",
                "trunk_ffm": "20.1",
                "trunk_muscle_mass": "19.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5204",
                "dummy1": "2011/08/19"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015214",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:07",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "147.0",
                "clothes_weight": "0.0",
                "weight": "34.2",
                "fat_p": "16.2",
                "fat_mass": "5.5",
                "ffm": "28.7",
                "muscle_mass": "27.1",
                "muscle_score": "",
                "bone_mass": "1.6",
                "tbw": "21.0",
                "tbw_p": "61.4",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1234",
                "bmr_kj": "5163",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "35.9",
                "predicted_fat_mass": "7.2",
                "fat_to_lose__gain": "1.7",
                "rl_fat_p": "25.4",
                "rl_fat_mass": "1.7",
                "rl_ffm": "5.1",
                "rl_muscle_mass": "4.8",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "25.4",
                "ll_fat_mass": "1.8",
                "ll_ffm": "5.2",
                "ll_muscle_mass": "4.9",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "11.9",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "11.1",
                "la_fat_mass": "0.2",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "9.1",
                "trunk_fat_mass": "1.6",
                "trunk_ffm": "15.9",
                "trunk_muscle_mass": "15.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "LUNG",
                "dummy1": "2011/09/14"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015216",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:11",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "145.0",
                "clothes_weight": "0.0",
                "weight": "52.9",
                "fat_p": "34.4",
                "fat_mass": "18.2",
                "ffm": "34.7",
                "muscle_mass": "32.7",
                "muscle_score": "",
                "bone_mass": "2.0",
                "tbw": "25.4",
                "tbw_p": "48.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "25.2",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1450",
                "bmr_kj": "6067",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "42.8",
                "predicted_fat_mass": "8.1",
                "fat_to_lose__gain": "-10.1",
                "rl_fat_p": "39.3",
                "rl_fat_mass": "3.9",
                "rl_ffm": "6.1",
                "rl_muscle_mass": "5.8",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "38.5",
                "ll_fat_mass": "3.9",
                "ll_ffm": "6.3",
                "ll_muscle_mass": "6.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "32.4",
                "ra_fat_mass": "0.8",
                "ra_ffm": "1.6",
                "ra_muscle_mass": "1.5",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "30.5",
                "la_fat_mass": "0.7",
                "la_ffm": "1.7",
                "la_muscle_mass": "1.6",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "31.9",
                "trunk_fat_mass": "8.9",
                "trunk_ffm": "19.0",
                "trunk_muscle_mass": "17.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "111",
                "dummy1": "2011/12/20"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015302",
                "status": "",
                "date": "2021/09/30",
                "time": "09:43",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "148.0",
                "clothes_weight": "0.0",
                "weight": "36.0",
                "fat_p": "",
                "fat_mass": "",
                "ffm": "",
                "muscle_mass": "",
                "muscle_score": "",
                "bone_mass": "",
                "tbw": "",
                "tbw_p": "",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "",
                "bmr_kj": "",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "",
                "predicted_weight": "",
                "predicted_fat_mass": "",
                "fat_to_lose__gain": "",
                "rl_fat_p": "",
                "rl_fat_mass": "",
                "rl_ffm": "",
                "rl_muscle_mass": "",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "",
                "ll_fat_mass": "",
                "ll_ffm": "",
                "ll_muscle_mass": "",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "",
                "ra_fat_mass": "",
                "ra_ffm": "",
                "ra_muscle_mass": "",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "",
                "la_fat_mass": "",
                "la_ffm": "",
                "la_muscle_mass": "",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "",
                "trunk_fat_mass": "",
                "trunk_ffm": "",
                "trunk_muscle_mass": "",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5302",
                "dummy1": "2011/10/22"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015302",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:45",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "148.0",
                "clothes_weight": "0.0",
                "weight": "36.0",
                "fat_p": "14.3",
                "fat_mass": "5.1",
                "ffm": "30.9",
                "muscle_mass": "29.1",
                "muscle_score": "",
                "bone_mass": "1.8",
                "tbw": "22.6",
                "tbw_p": "62.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1285",
                "bmr_kj": "5376",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "38.1",
                "predicted_fat_mass": "7.2",
                "fat_to_lose__gain": "2.1",
                "rl_fat_p": "22.3",
                "rl_fat_mass": "1.7",
                "rl_ffm": "6.0",
                "rl_muscle_mass": "5.7",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "23.2",
                "ll_fat_mass": "1.8",
                "ll_ffm": "5.8",
                "ll_muscle_mass": "5.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "10.1",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.5",
                "ra_muscle_mass": "1.4",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "9.7",
                "la_fat_mass": "0.2",
                "la_ffm": "1.5",
                "la_muscle_mass": "1.4",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "6.9",
                "trunk_fat_mass": "1.2",
                "trunk_ffm": "16.1",
                "trunk_muscle_mass": "15.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5302",
                "dummy1": "2011/10/22"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015307",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:56",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "153.0",
                "clothes_weight": "0.0",
                "weight": "38.5",
                "fat_p": "17.2",
                "fat_mass": "6.6",
                "ffm": "31.9",
                "muscle_mass": "30.1",
                "muscle_score": "",
                "bone_mass": "1.8",
                "tbw": "23.4",
                "tbw_p": "60.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1318",
                "bmr_kj": "5515",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "39.4",
                "predicted_fat_mass": "7.5",
                "fat_to_lose__gain": "0.9",
                "rl_fat_p": "24.5",
                "rl_fat_mass": "1.7",
                "rl_ffm": "5.2",
                "rl_muscle_mass": "4.9",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "24.9",
                "ll_fat_mass": "1.7",
                "ll_ffm": "5.2",
                "ll_muscle_mass": "4.9",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "12.9",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.4",
                "ra_muscle_mass": "1.3",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "14.1",
                "la_fat_mass": "0.2",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "13.0",
                "trunk_fat_mass": "2.8",
                "trunk_ffm": "18.8",
                "trunk_muscle_mass": "17.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5307",
                "dummy1": "2011/12/11"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015315",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:32",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "140.0",
                "clothes_weight": "0.0",
                "weight": "44.6",
                "fat_p": "30.1",
                "fat_mass": "13.4",
                "ffm": "31.2",
                "muscle_mass": "29.6",
                "muscle_score": "",
                "bone_mass": "1.6",
                "tbw": "22.8",
                "tbw_p": "51.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1337",
                "bmr_kj": "5594",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "37.1",
                "predicted_fat_mass": "5.9",
                "fat_to_lose__gain": "-7.5",
                "rl_fat_p": "34.5",
                "rl_fat_mass": "3.3",
                "rl_ffm": "6.2",
                "rl_muscle_mass": "5.9",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "34.3",
                "ll_fat_mass": "3.2",
                "ll_ffm": "6.1",
                "ll_muscle_mass": "5.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "26.4",
                "ra_fat_mass": "0.5",
                "ra_ffm": "1.5",
                "ra_muscle_mass": "1.4",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "26.9",
                "la_fat_mass": "0.6",
                "la_ffm": "1.5",
                "la_muscle_mass": "1.4",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "26.7",
                "trunk_fat_mass": "5.8",
                "trunk_ffm": "15.9",
                "trunk_muscle_mass": "15.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5315",
                "dummy1": "2011/04/30"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015320",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:48",
                "body_type": "0",
                "gender": "2",
                "age": "9",
                "height": "127.0",
                "clothes_weight": "0.0",
                "weight": "27.2",
                "fat_p": "16.3",
                "fat_mass": "4.4",
                "ffm": "22.8",
                "muscle_mass": "21.6",
                "muscle_score": "",
                "bone_mass": "1.2",
                "tbw": "16.7",
                "tbw_p": "61.4",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.9",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "19.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1084",
                "bmr_kj": "4535",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "19",
                "predicted_weight": "28.1",
                "predicted_fat_mass": "5.3",
                "fat_to_lose__gain": "0.9",
                "rl_fat_p": "23.7",
                "rl_fat_mass": "1.3",
                "rl_ffm": "4.3",
                "rl_muscle_mass": "4.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "23.9",
                "ll_fat_mass": "1.4",
                "ll_ffm": "4.3",
                "ll_muscle_mass": "4.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "11.9",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.0",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "12.8",
                "la_fat_mass": "0.1",
                "la_ffm": "1.0",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "10.9",
                "trunk_fat_mass": "1.5",
                "trunk_ffm": "12.2",
                "trunk_muscle_mass": "11.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5320",
                "dummy1": "2011/12/23"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015321",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:53",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "148.0",
                "clothes_weight": "0.0",
                "weight": "29.9",
                "fat_p": "10.8",
                "fat_mass": "3.2",
                "ffm": "26.7",
                "muscle_mass": "25.3",
                "muscle_score": "",
                "bone_mass": "1.4",
                "tbw": "19.5",
                "tbw_p": "65.2",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.7",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1174",
                "bmr_kj": "4912",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "33.4",
                "predicted_fat_mass": "6.7",
                "fat_to_lose__gain": "3.5",
                "rl_fat_p": "19.5",
                "rl_fat_mass": "1.2",
                "rl_ffm": "4.8",
                "rl_muscle_mass": "4.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "19.9",
                "ll_fat_mass": "1.2",
                "ll_ffm": "4.8",
                "ll_muscle_mass": "4.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "7.1",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.1",
                "ra_muscle_mass": "1.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "6.5",
                "la_fat_mass": "0.1",
                "la_ffm": "1.1",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.9",
                "trunk_fat_mass": "0.6",
                "trunk_ffm": "14.9",
                "trunk_muscle_mass": "14.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5321",
                "dummy1": "2011/05/31"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015517",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:37",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "152.0",
                "clothes_weight": "0.0",
                "weight": "49.9",
                "fat_p": "29.4",
                "fat_mass": "14.7",
                "ffm": "35.2",
                "muscle_mass": "33.4",
                "muscle_score": "",
                "bone_mass": "1.8",
                "tbw": "25.8",
                "tbw_p": "51.7",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "21.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1441",
                "bmr_kj": "6029",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "41.9",
                "predicted_fat_mass": "6.7",
                "fat_to_lose__gain": "-8.0",
                "rl_fat_p": "36.0",
                "rl_fat_mass": "3.7",
                "rl_ffm": "6.5",
                "rl_muscle_mass": "6.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "35.6",
                "ll_fat_mass": "3.5",
                "ll_ffm": "6.4",
                "ll_muscle_mass": "6.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "23.4",
                "ra_fat_mass": "0.5",
                "ra_ffm": "1.6",
                "ra_muscle_mass": "1.5",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "24.8",
                "la_fat_mass": "0.6",
                "la_ffm": "1.7",
                "la_muscle_mass": "1.6",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "25.2",
                "trunk_fat_mass": "6.4",
                "trunk_ffm": "19.0",
                "trunk_muscle_mass": "18.0",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5517",
                "dummy1": "2011/08/22"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100015520",
                "status": "0",
                "date": "2021/09/30",
                "time": "09:40",
                "body_type": "0",
                "gender": "1",
                "age": "9",
                "height": "148.0",
                "clothes_weight": "0.0",
                "weight": "41.0",
                "fat_p": "19.9",
                "fat_mass": "8.2",
                "ffm": "32.8",
                "muscle_mass": "31.2",
                "muscle_score": "",
                "bone_mass": "1.6",
                "tbw": "24.0",
                "tbw_p": "58.5",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "18.7",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1376",
                "bmr_kj": "5757",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "39.0",
                "predicted_fat_mass": "6.2",
                "fat_to_lose__gain": "-2.0",
                "rl_fat_p": "24.2",
                "rl_fat_mass": "1.9",
                "rl_ffm": "6.1",
                "rl_muscle_mass": "5.8",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "24.4",
                "ll_fat_mass": "1.9",
                "ll_ffm": "5.9",
                "ll_muscle_mass": "5.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "16.0",
                "ra_fat_mass": "0.3",
                "ra_ffm": "1.4",
                "ra_muscle_mass": "1.3",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "17.1",
                "la_fat_mass": "0.3",
                "la_ffm": "1.5",
                "la_muscle_mass": "1.4",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "17.5",
                "trunk_fat_mass": "3.8",
                "trunk_ffm": "17.9",
                "trunk_muscle_mass": "17.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "5520",
                "dummy1": "2011/11/29"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016102",
                "status": "0",
                "date": "2021/09/30",
                "time": "10:40",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "158.0",
                "clothes_weight": "0.0",
                "weight": "73.2",
                "fat_p": "44.5",
                "fat_mass": "32.6",
                "ffm": "40.6",
                "muscle_mass": "38.5",
                "muscle_score": "",
                "bone_mass": "2.1",
                "tbw": "29.7",
                "tbw_p": "40.6",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "29.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1625",
                "bmr_kj": "6799",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "48.3",
                "predicted_fat_mass": "7.7",
                "fat_to_lose__gain": "-24.9",
                "rl_fat_p": "52.7",
                "rl_fat_mass": "9.5",
                "rl_ffm": "8.5",
                "rl_muscle_mass": "8.0",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "50.1",
                "ll_fat_mass": "8.5",
                "ll_ffm": "8.5",
                "ll_muscle_mass": "8.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "38.1",
                "ra_fat_mass": "1.4",
                "ra_ffm": "2.2",
                "ra_muscle_mass": "2.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "38.3",
                "la_fat_mass": "1.4",
                "la_ffm": "2.3",
                "la_muscle_mass": "2.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "38.2",
                "trunk_fat_mass": "11.8",
                "trunk_ffm": "19.1",
                "trunk_muscle_mass": "18.2",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6102",
                "dummy1": "2010/11/30"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016104",
                "status": "0",
                "date": "2021/09/30",
                "time": "10:35",
                "body_type": "0",
                "gender": "2",
                "age": "12",
                "height": "155.0",
                "clothes_weight": "0.0",
                "weight": "41.6",
                "fat_p": "15.6",
                "fat_mass": "6.5",
                "ffm": "35.1",
                "muscle_mass": "33.0",
                "muscle_score": "",
                "bone_mass": "2.1",
                "tbw": "25.7",
                "tbw_p": "61.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "17.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "23.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1386",
                "bmr_kj": "5799",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "23",
                "predicted_weight": "45.6",
                "predicted_fat_mass": "10.5",
                "fat_to_lose__gain": "4.0",
                "rl_fat_p": "23.6",
                "rl_fat_mass": "2.0",
                "rl_ffm": "6.5",
                "rl_muscle_mass": "6.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "24.1",
                "ll_fat_mass": "2.0",
                "ll_ffm": "6.2",
                "ll_muscle_mass": "5.9",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "13.2",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.6",
                "ra_muscle_mass": "1.5",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "11.7",
                "la_fat_mass": "0.2",
                "la_ffm": "1.7",
                "la_muscle_mass": "1.6",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "9.9",
                "trunk_fat_mass": "2.1",
                "trunk_ffm": "19.1",
                "trunk_muscle_mass": "17.9",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6104",
                "dummy1": "2009/08/17"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016114",
                "status": "0",
                "date": "2021/09/30",
                "time": "10:31",
                "body_type": "0",
                "gender": "2",
                "age": "11",
                "height": "140.0",
                "clothes_weight": "0.0",
                "weight": "28.8",
                "fat_p": "13.4",
                "fat_mass": "3.9",
                "ffm": "24.9",
                "muscle_mass": "23.6",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "18.2",
                "tbw_p": "63.2",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "14.7",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "22.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1131",
                "bmr_kj": "4732",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "22",
                "predicted_weight": "31.9",
                "predicted_fat_mass": "7.0",
                "fat_to_lose__gain": "3.1",
                "rl_fat_p": "21.9",
                "rl_fat_mass": "1.3",
                "rl_ffm": "4.7",
                "rl_muscle_mass": "4.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "22.6",
                "ll_fat_mass": "1.4",
                "ll_ffm": "4.7",
                "ll_muscle_mass": "4.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "9.3",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.0",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "8.6",
                "la_fat_mass": "0.1",
                "la_ffm": "1.0",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "6.9",
                "trunk_fat_mass": "1.0",
                "trunk_ffm": "13.5",
                "trunk_muscle_mass": "12.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6114",
                "dummy1": "2010/06/11"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016210",
                "status": "0",
                "date": "2021/09/30",
                "time": "10:47",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "152.0",
                "clothes_weight": "0.0",
                "weight": "45.6",
                "fat_p": "18.2",
                "fat_mass": "8.3",
                "ffm": "37.3",
                "muscle_mass": "35.4",
                "muscle_score": "",
                "bone_mass": "1.9",
                "tbw": "27.3",
                "tbw_p": "59.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "19.7",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1479",
                "bmr_kj": "6188",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "44.4",
                "predicted_fat_mass": "7.1",
                "fat_to_lose__gain": "-1.2",
                "rl_fat_p": "23.9",
                "rl_fat_mass": "2.2",
                "rl_ffm": "6.9",
                "rl_muscle_mass": "6.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "24.8",
                "ll_fat_mass": "2.2",
                "ll_ffm": "6.6",
                "ll_muscle_mass": "6.3",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "16.8",
                "ra_fat_mass": "0.3",
                "ra_ffm": "1.5",
                "ra_muscle_mass": "1.4",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "15.9",
                "la_fat_mass": "0.3",
                "la_ffm": "1.8",
                "la_muscle_mass": "1.7",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "13.9",
                "trunk_fat_mass": "3.3",
                "trunk_ffm": "20.5",
                "trunk_muscle_mass": "19.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6210",
                "dummy1": "2010/10/17"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016211",
                "status": "0",
                "date": "2021/09/30",
                "time": "10:28",
                "body_type": "0",
                "gender": "2",
                "age": "11",
                "height": "156.0",
                "clothes_weight": "0.0",
                "weight": "51.0",
                "fat_p": "26.9",
                "fat_mass": "13.7",
                "ffm": "37.3",
                "muscle_mass": "35.1",
                "muscle_score": "",
                "bone_mass": "2.2",
                "tbw": "27.3",
                "tbw_p": "53.5",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "21.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "22.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1493",
                "bmr_kj": "6247",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "22",
                "predicted_weight": "47.8",
                "predicted_fat_mass": "10.5",
                "fat_to_lose__gain": "-3.2",
                "rl_fat_p": "31.0",
                "rl_fat_mass": "3.1",
                "rl_ffm": "7.0",
                "rl_muscle_mass": "6.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "31.0",
                "ll_fat_mass": "3.1",
                "ll_ffm": "6.9",
                "ll_muscle_mass": "6.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "22.5",
                "ra_fat_mass": "0.5",
                "ra_ffm": "1.8",
                "ra_muscle_mass": "1.7",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "24.5",
                "la_fat_mass": "0.6",
                "la_ffm": "1.7",
                "la_muscle_mass": "1.6",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "24.3",
                "trunk_fat_mass": "6.4",
                "trunk_ffm": "19.9",
                "trunk_muscle_mass": "18.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6211",
                "dummy1": "2010/09/17"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016216",
                "status": "0",
                "date": "2021/09/30",
                "time": "10:25",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "153.0",
                "clothes_weight": "0.0",
                "weight": "53.0",
                "fat_p": "27.5",
                "fat_mass": "14.6",
                "ffm": "38.4",
                "muscle_mass": "36.1",
                "muscle_score": "",
                "bone_mass": "2.3",
                "tbw": "28.1",
                "tbw_p": "53.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1533",
                "bmr_kj": "6414",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "48.0",
                "predicted_fat_mass": "9.6",
                "fat_to_lose__gain": "-5.0",
                "rl_fat_p": "31.8",
                "rl_fat_mass": "3.5",
                "rl_ffm": "7.4",
                "rl_muscle_mass": "7.0",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "33.0",
                "ll_fat_mass": "3.5",
                "ll_ffm": "7.2",
                "ll_muscle_mass": "6.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "20.6",
                "ra_fat_mass": "0.5",
                "ra_ffm": "2.0",
                "ra_muscle_mass": "1.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "23.8",
                "la_fat_mass": "0.6",
                "la_ffm": "1.8",
                "la_muscle_mass": "1.7",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "24.5",
                "trunk_fat_mass": "6.5",
                "trunk_ffm": "20.0",
                "trunk_muscle_mass": "18.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6216",
                "dummy1": "2011/04/27"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016219",
                "status": "1",
                "date": "2021/09/30",
                "time": "10:44",
                "body_type": "0",
                "gender": "1",
                "age": "11",
                "height": "153.0",
                "clothes_weight": "0.0",
                "weight": "29.4",
                "fat_p": "3.0",
                "fat_mass": "0.9",
                "ffm": "28.5",
                "muscle_mass": "27.1",
                "muscle_score": "",
                "bone_mass": "1.4",
                "tbw": "20.9",
                "tbw_p": "71.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "12.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1226",
                "bmr_kj": "5130",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "33.9",
                "predicted_fat_mass": "5.4",
                "fat_to_lose__gain": "4.5",
                "rl_fat_p": "",
                "rl_fat_mass": "",
                "rl_ffm": "",
                "rl_muscle_mass": "",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "",
                "ll_fat_mass": "",
                "ll_ffm": "",
                "ll_muscle_mass": "",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "",
                "ra_fat_mass": "",
                "ra_ffm": "",
                "ra_muscle_mass": "",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "",
                "la_fat_mass": "",
                "la_ffm": "",
                "la_muscle_mass": "",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "",
                "trunk_fat_mass": "",
                "trunk_ffm": "",
                "trunk_muscle_mass": "",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6219",
                "dummy1": "2009/10/17"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016503",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:08",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "147.0",
                "clothes_weight": "0.0",
                "weight": "30.1",
                "fat_p": "10.3",
                "fat_mass": "3.1",
                "ffm": "27.0",
                "muscle_mass": "25.5",
                "muscle_score": "",
                "bone_mass": "1.5",
                "tbw": "19.8",
                "tbw_p": "65.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.9",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1181",
                "bmr_kj": "4941",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "33.8",
                "predicted_fat_mass": "6.8",
                "fat_to_lose__gain": "3.7",
                "rl_fat_p": "19.4",
                "rl_fat_mass": "1.2",
                "rl_ffm": "5.1",
                "rl_muscle_mass": "4.8",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "20.0",
                "ll_fat_mass": "1.3",
                "ll_ffm": "5.1",
                "ll_muscle_mass": "4.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "5.9",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "5.5",
                "la_fat_mass": "0.1",
                "la_ffm": "1.2",
                "la_muscle_mass": "1.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.0",
                "trunk_fat_mass": "0.4",
                "trunk_ffm": "14.4",
                "trunk_muscle_mass": "13.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6503",
                "dummy1": "2010/11/30"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016504",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:06",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "149.0",
                "clothes_weight": "0.0",
                "weight": "33.2",
                "fat_p": "14.8",
                "fat_mass": "4.9",
                "ffm": "28.3",
                "muscle_mass": "26.7",
                "muscle_score": "",
                "bone_mass": "1.6",
                "tbw": "20.7",
                "tbw_p": "62.3",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1222",
                "bmr_kj": "5113",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "35.4",
                "predicted_fat_mass": "7.1",
                "fat_to_lose__gain": "2.2",
                "rl_fat_p": "22.3",
                "rl_fat_mass": "1.5",
                "rl_ffm": "5.3",
                "rl_muscle_mass": "5.0",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "23.0",
                "ll_fat_mass": "1.6",
                "ll_ffm": "5.3",
                "ll_muscle_mass": "5.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "9.4",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "10.6",
                "la_fat_mass": "0.1",
                "la_ffm": "1.1",
                "la_muscle_mass": "1.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "9.4",
                "trunk_fat_mass": "1.6",
                "trunk_ffm": "15.4",
                "trunk_muscle_mass": "14.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6504",
                "dummy1": "2010/12/27"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016507",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:14",
                "body_type": "0",
                "gender": "2",
                "age": "10",
                "height": "140.0",
                "clothes_weight": "0.0",
                "weight": "29.5",
                "fat_p": "15.3",
                "fat_mass": "4.5",
                "ffm": "25.0",
                "muscle_mass": "23.7",
                "muscle_score": "",
                "bone_mass": "1.3",
                "tbw": "18.3",
                "tbw_p": "62.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.1",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "20.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1140",
                "bmr_kj": "4770",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "20",
                "predicted_weight": "31.3",
                "predicted_fat_mass": "6.3",
                "fat_to_lose__gain": "1.8",
                "rl_fat_p": "22.8",
                "rl_fat_mass": "1.3",
                "rl_ffm": "4.3",
                "rl_muscle_mass": "4.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "23.3",
                "ll_fat_mass": "1.3",
                "ll_ffm": "4.3",
                "ll_muscle_mass": "4.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "12.1",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.0",
                "ra_muscle_mass": "0.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "11.6",
                "la_fat_mass": "0.1",
                "la_ffm": "1.0",
                "la_muscle_mass": "0.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "10.6",
                "trunk_fat_mass": "1.7",
                "trunk_ffm": "14.4",
                "trunk_muscle_mass": "13.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6507",
                "dummy1": "2010/11/26"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016508",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:16",
                "body_type": "0",
                "gender": "1",
                "age": "11",
                "height": "152.0",
                "clothes_weight": "0.0",
                "weight": "55.4",
                "fat_p": "29.1",
                "fat_mass": "16.1",
                "ffm": "39.3",
                "muscle_mass": "37.3",
                "muscle_score": "",
                "bone_mass": "2.0",
                "tbw": "28.8",
                "tbw_p": "52.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "24.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1534",
                "bmr_kj": "6418",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "46.8",
                "predicted_fat_mass": "7.5",
                "fat_to_lose__gain": "-8.6",
                "rl_fat_p": "33.5",
                "rl_fat_mass": "4.0",
                "rl_ffm": "7.9",
                "rl_muscle_mass": "7.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "33.6",
                "ll_fat_mass": "3.9",
                "ll_ffm": "7.7",
                "ll_muscle_mass": "7.3",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "25.6",
                "ra_fat_mass": "0.7",
                "ra_ffm": "1.9",
                "ra_muscle_mass": "1.8",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "26.1",
                "la_fat_mass": "0.7",
                "la_ffm": "1.9",
                "la_muscle_mass": "1.8",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "25.5",
                "trunk_fat_mass": "6.8",
                "trunk_ffm": "19.9",
                "trunk_muscle_mass": "18.9",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6508",
                "dummy1": "2010/06/15"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016513",
                "status": "1",
                "date": "2021/09/30",
                "time": "10:58",
                "body_type": "0",
                "gender": "1",
                "age": "12",
                "height": "149.0",
                "clothes_weight": "0.0",
                "weight": "30.1",
                "fat_p": "3.0",
                "fat_mass": "0.9",
                "ffm": "29.2",
                "muscle_mass": "27.8",
                "muscle_score": "",
                "bone_mass": "1.4",
                "tbw": "21.4",
                "tbw_p": "71.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "13.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1220",
                "bmr_kj": "5104",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "34.8",
                "predicted_fat_mass": "5.6",
                "fat_to_lose__gain": "4.7",
                "rl_fat_p": "",
                "rl_fat_mass": "",
                "rl_ffm": "",
                "rl_muscle_mass": "",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "",
                "ll_fat_mass": "",
                "ll_ffm": "",
                "ll_muscle_mass": "",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "",
                "ra_fat_mass": "",
                "ra_ffm": "",
                "ra_muscle_mass": "",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "",
                "la_fat_mass": "",
                "la_ffm": "",
                "la_muscle_mass": "",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "",
                "trunk_fat_mass": "",
                "trunk_ffm": "",
                "trunk_muscle_mass": "",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6513",
                "dummy1": "2009/05/14"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016516",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:01",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "150.0",
                "clothes_weight": "0.0",
                "weight": "31.9",
                "fat_p": "6.6",
                "fat_mass": "2.1",
                "ffm": "29.8",
                "muscle_mass": "28.3",
                "muscle_score": "",
                "bone_mass": "1.5",
                "tbw": "21.8",
                "tbw_p": "68.3",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "14.2",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1276",
                "bmr_kj": "5339",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "35.5",
                "predicted_fat_mass": "5.7",
                "fat_to_lose__gain": "3.6",
                "rl_fat_p": "12.4",
                "rl_fat_mass": "0.8",
                "rl_ffm": "5.4",
                "rl_muscle_mass": "5.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "11.9",
                "ll_fat_mass": "0.7",
                "ll_ffm": "5.3",
                "ll_muscle_mass": "5.1",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "3.6",
                "ra_fat_mass": "0.0",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "6.2",
                "la_fat_mass": "0.1",
                "la_ffm": "1.2",
                "la_muscle_mass": "1.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.0",
                "trunk_fat_mass": "0.5",
                "trunk_ffm": "16.7",
                "trunk_muscle_mass": "15.9",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6516",
                "dummy1": "2010/12/04"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016517",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:11",
                "body_type": "0",
                "gender": "1",
                "age": "11",
                "height": "156.0",
                "clothes_weight": "0.0",
                "weight": "60.9",
                "fat_p": "33.0",
                "fat_mass": "20.1",
                "ffm": "40.8",
                "muscle_mass": "38.7",
                "muscle_score": "",
                "bone_mass": "2.1",
                "tbw": "29.9",
                "tbw_p": "49.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "25.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1583",
                "bmr_kj": "6623",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "48.6",
                "predicted_fat_mass": "7.8",
                "fat_to_lose__gain": "-12.3",
                "rl_fat_p": "36.7",
                "rl_fat_mass": "4.7",
                "rl_ffm": "8.1",
                "rl_muscle_mass": "7.7",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "39.4",
                "ll_fat_mass": "5.0",
                "ll_ffm": "7.7",
                "ll_muscle_mass": "7.3",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "28.5",
                "ra_fat_mass": "0.8",
                "ra_ffm": "2.0",
                "ra_muscle_mass": "1.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "27.0",
                "la_fat_mass": "0.8",
                "la_ffm": "2.1",
                "la_muscle_mass": "2.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "29.6",
                "trunk_fat_mass": "8.8",
                "trunk_ffm": "20.9",
                "trunk_muscle_mass": "19.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6517",
                "dummy1": "2010/03/08"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016610",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:22",
                "body_type": "0",
                "gender": "1",
                "age": "10",
                "height": "143.0",
                "clothes_weight": "0.0",
                "weight": "34.0",
                "fat_p": "11.3",
                "fat_mass": "3.8",
                "ffm": "30.2",
                "muscle_mass": "28.7",
                "muscle_score": "",
                "bone_mass": "1.5",
                "tbw": "22.1",
                "tbw_p": "65.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1289",
                "bmr_kj": "5393",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "36.0",
                "predicted_fat_mass": "5.8",
                "fat_to_lose__gain": "2.0",
                "rl_fat_p": "15.9",
                "rl_fat_mass": "1.1",
                "rl_ffm": "5.7",
                "rl_muscle_mass": "5.4",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "15.7",
                "ll_fat_mass": "1.0",
                "ll_ffm": "5.6",
                "ll_muscle_mass": "5.3",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "9.1",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.2",
                "ra_muscle_mass": "1.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "10.7",
                "la_fat_mass": "0.2",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "7.9",
                "trunk_fat_mass": "1.4",
                "trunk_ffm": "16.4",
                "trunk_muscle_mass": "15.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6610",
                "dummy1": "2010/11/05"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016612",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:19",
                "body_type": "0",
                "gender": "1",
                "age": "11",
                "height": "145.0",
                "clothes_weight": "0.0",
                "weight": "32.7",
                "fat_p": "8.1",
                "fat_mass": "2.6",
                "ffm": "30.1",
                "muscle_mass": "28.6",
                "muscle_score": "",
                "bone_mass": "1.5",
                "tbw": "22.0",
                "tbw_p": "67.3",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1268",
                "bmr_kj": "5305",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "35.8",
                "predicted_fat_mass": "5.7",
                "fat_to_lose__gain": "3.1",
                "rl_fat_p": "12.9",
                "rl_fat_mass": "0.9",
                "rl_ffm": "5.8",
                "rl_muscle_mass": "5.5",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "12.6",
                "ll_fat_mass": "0.8",
                "ll_ffm": "5.7",
                "ll_muscle_mass": "5.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "5.9",
                "ra_fat_mass": "0.1",
                "ra_ffm": "1.3",
                "ra_muscle_mass": "1.2",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "7.9",
                "la_fat_mass": "0.1",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "4.2",
                "trunk_fat_mass": "0.7",
                "trunk_ffm": "16.0",
                "trunk_muscle_mass": "15.3",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6612",
                "dummy1": "2010/08/12"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016618",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:04",
                "body_type": "0",
                "gender": "2",
                "age": "11",
                "height": "140.0",
                "clothes_weight": "0.0",
                "weight": "37.8",
                "fat_p": "23.3",
                "fat_mass": "8.8",
                "ffm": "29.0",
                "muscle_mass": "27.4",
                "muscle_score": "",
                "bone_mass": "1.6",
                "tbw": "21.2",
                "tbw_p": "56.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "19.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "22.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1254",
                "bmr_kj": "5247",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "22",
                "predicted_weight": "37.2",
                "predicted_fat_mass": "8.2",
                "fat_to_lose__gain": "-0.6",
                "rl_fat_p": "30.1",
                "rl_fat_mass": "2.2",
                "rl_ffm": "5.0",
                "rl_muscle_mass": "4.7",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "31.0",
                "ll_fat_mass": "2.2",
                "ll_ffm": "4.9",
                "ll_muscle_mass": "4.6",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "19.0",
                "ra_fat_mass": "0.3",
                "ra_ffm": "1.3",
                "ra_muscle_mass": "1.2",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "19.2",
                "la_fat_mass": "0.3",
                "la_ffm": "1.3",
                "la_muscle_mass": "1.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "18.7",
                "trunk_fat_mass": "3.8",
                "trunk_ffm": "16.5",
                "trunk_muscle_mass": "15.7",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6618",
                "dummy1": "2010/03/23"
            },
            {
                "model": "MC-980",
                "id_no": "000000M100016619",
                "status": "0",
                "date": "2021/09/30",
                "time": "11:25",
                "body_type": "0",
                "gender": "1",
                "age": "12",
                "height": "173.0",
                "clothes_weight": "0.0",
                "weight": "60.7",
                "fat_p": "17.4",
                "fat_mass": "10.6",
                "ffm": "50.1",
                "muscle_mass": "47.4",
                "muscle_score": "",
                "bone_mass": "2.7",
                "tbw": "36.7",
                "tbw_p": "60.5",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "20.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1794",
                "bmr_kj": "7506",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "59.6",
                "predicted_fat_mass": "9.5",
                "fat_to_lose__gain": "-1.1",
                "rl_fat_p": "22.1",
                "rl_fat_mass": "2.6",
                "rl_ffm": "9.1",
                "rl_muscle_mass": "8.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "22.8",
                "ll_fat_mass": "2.6",
                "ll_ffm": "8.9",
                "ll_muscle_mass": "8.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "13.9",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.1",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "14.5",
                "la_fat_mass": "0.4",
                "la_ffm": "2.4",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "14.6",
                "trunk_fat_mass": "4.7",
                "trunk_ffm": "27.6",
                "trunk_muscle_mass": "26.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "6619",
                "dummy1": "2009/02/19"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014111",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:11",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "163.0",
                "clothes_weight": "0.0",
                "weight": "83.5",
                "fat_p": "35.6",
                "fat_mass": "29.7",
                "ffm": "53.8",
                "muscle_mass": "50.9",
                "muscle_score": "",
                "bone_mass": "2.9",
                "tbw": "39.4",
                "tbw_p": "47.2",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "31.4",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1826",
                "bmr_kj": "7640",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "63.3",
                "predicted_fat_mass": "9.5",
                "fat_to_lose__gain": "-20.2",
                "rl_fat_p": "33.4",
                "rl_fat_mass": "6.0",
                "rl_ffm": "12.0",
                "rl_muscle_mass": "11.3",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "32.4",
                "ll_fat_mass": "5.8",
                "ll_ffm": "12.1",
                "ll_muscle_mass": "11.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "29.2",
                "ra_fat_mass": "1.2",
                "ra_ffm": "2.9",
                "ra_muscle_mass": "2.7",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "30.0",
                "la_fat_mass": "1.2",
                "la_ffm": "2.8",
                "la_muscle_mass": "2.6",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "39.2",
                "trunk_fat_mass": "15.5",
                "trunk_ffm": "24.0",
                "trunk_muscle_mass": "22.9",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4111",
                "dummy1": "2006/08/07"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014301",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:48",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "174.0",
                "clothes_weight": "0.0",
                "weight": "55.3",
                "fat_p": "9.9",
                "fat_mass": "5.5",
                "ffm": "49.8",
                "muscle_mass": "47.1",
                "muscle_score": "",
                "bone_mass": "2.7",
                "tbw": "36.5",
                "tbw_p": "66.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "18.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1654",
                "bmr_kj": "6920",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "58.6",
                "predicted_fat_mass": "8.8",
                "fat_to_lose__gain": "3.3",
                "rl_fat_p": "10.3",
                "rl_fat_mass": "1.2",
                "rl_ffm": "10.2",
                "rl_muscle_mass": "9.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "10.8",
                "ll_fat_mass": "1.2",
                "ll_ffm": "9.9",
                "ll_muscle_mass": "9.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "8.7",
                "ra_fat_mass": "0.2",
                "ra_ffm": "2.5",
                "ra_muscle_mass": "2.3",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "9.1",
                "la_fat_mass": "0.3",
                "la_ffm": "2.5",
                "la_muscle_mass": "2.3",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "9.5",
                "trunk_fat_mass": "2.6",
                "trunk_ffm": "24.7",
                "trunk_muscle_mass": "23.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4301",
                "dummy1": "2006/04/18"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014302",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:30",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "180.0",
                "clothes_weight": "0.0",
                "weight": "106.8",
                "fat_p": "41.5",
                "fat_mass": "44.3",
                "ffm": "62.5",
                "muscle_mass": "59.1",
                "muscle_score": "",
                "bone_mass": "3.4",
                "tbw": "45.8",
                "tbw_p": "42.9",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "33.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "2122",
                "bmr_kj": "8878",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "73.5",
                "predicted_fat_mass": "11.0",
                "fat_to_lose__gain": "-33.3",
                "rl_fat_p": "39.4",
                "rl_fat_mass": "9.1",
                "rl_ffm": "14.0",
                "rl_muscle_mass": "13.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "40.7",
                "ll_fat_mass": "9.3",
                "ll_ffm": "13.6",
                "ll_muscle_mass": "12.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "35.3",
                "ra_fat_mass": "1.8",
                "ra_ffm": "3.3",
                "ra_muscle_mass": "3.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "33.7",
                "la_fat_mass": "1.7",
                "la_ffm": "3.3",
                "la_muscle_mass": "3.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "44.2",
                "trunk_fat_mass": "22.4",
                "trunk_ffm": "28.3",
                "trunk_muscle_mass": "26.9",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4302",
                "dummy1": "2006/09/30"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014303",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:52",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "164.0",
                "clothes_weight": "0.0",
                "weight": "59.9",
                "fat_p": "22.0",
                "fat_mass": "13.2",
                "ffm": "46.7",
                "muscle_mass": "44.2",
                "muscle_score": "",
                "bone_mass": "2.5",
                "tbw": "34.2",
                "tbw_p": "57.1",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.3",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1586",
                "bmr_kj": "6636",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "54.9",
                "predicted_fat_mass": "8.2",
                "fat_to_lose__gain": "-5.0",
                "rl_fat_p": "21.3",
                "rl_fat_mass": "2.7",
                "rl_ffm": "10.0",
                "rl_muscle_mass": "9.4",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "22.1",
                "ll_fat_mass": "2.8",
                "ll_ffm": "9.8",
                "ll_muscle_mass": "9.3",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "19.1",
                "ra_fat_mass": "0.6",
                "ra_ffm": "2.4",
                "ra_muscle_mass": "2.2",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "19.4",
                "la_fat_mass": "0.6",
                "la_ffm": "2.4",
                "la_muscle_mass": "2.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "22.7",
                "trunk_fat_mass": "6.5",
                "trunk_ffm": "22.1",
                "trunk_muscle_mass": "21.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4303",
                "dummy1": "2006/05/24"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014304",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:46",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "173.0",
                "clothes_weight": "0.0",
                "weight": "55.8",
                "fat_p": "19.3",
                "fat_mass": "10.8",
                "ffm": "45.0",
                "muscle_mass": "42.6",
                "muscle_score": "",
                "bone_mass": "2.4",
                "tbw": "32.9",
                "tbw_p": "59.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "18.6",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1534",
                "bmr_kj": "6418",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "52.9",
                "predicted_fat_mass": "7.9",
                "fat_to_lose__gain": "-2.9",
                "rl_fat_p": "16.8",
                "rl_fat_mass": "2.0",
                "rl_ffm": "9.8",
                "rl_muscle_mass": "9.3",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "15.1",
                "ll_fat_mass": "1.8",
                "ll_ffm": "10.1",
                "ll_muscle_mass": "9.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "18.1",
                "ra_fat_mass": "0.5",
                "ra_ffm": "2.2",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "18.9",
                "la_fat_mass": "0.5",
                "la_ffm": "2.1",
                "la_muscle_mass": "2.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "22.4",
                "trunk_fat_mass": "6.0",
                "trunk_ffm": "20.8",
                "trunk_muscle_mass": "19.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4304",
                "dummy1": "2006/05/16"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014305",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:14",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "172.0",
                "clothes_weight": "0.0",
                "weight": "58.6",
                "fat_p": "19.2",
                "fat_mass": "11.3",
                "ffm": "47.3",
                "muscle_mass": "44.8",
                "muscle_score": "",
                "bone_mass": "2.5",
                "tbw": "34.6",
                "tbw_p": "59.0",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "19.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1599",
                "bmr_kj": "6690",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "55.6",
                "predicted_fat_mass": "8.3",
                "fat_to_lose__gain": "-3.0",
                "rl_fat_p": "19.2",
                "rl_fat_mass": "2.3",
                "rl_ffm": "9.8",
                "rl_muscle_mass": "9.3",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "20.3",
                "ll_fat_mass": "2.4",
                "ll_ffm": "9.5",
                "ll_muscle_mass": "9.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "16.7",
                "ra_fat_mass": "0.5",
                "ra_ffm": "2.3",
                "ra_muscle_mass": "2.1",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "16.4",
                "la_fat_mass": "0.5",
                "la_ffm": "2.4",
                "la_muscle_mass": "2.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "19.4",
                "trunk_fat_mass": "5.6",
                "trunk_ffm": "23.3",
                "trunk_muscle_mass": "22.2",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4305",
                "dummy1": "2006/02/20"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014306",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:20",
                "body_type": "0",
                "gender": "1",
                "age": "14",
                "height": "163.0",
                "clothes_weight": "0.0",
                "weight": "45.2",
                "fat_p": "10.2",
                "fat_mass": "4.6",
                "ffm": "40.6",
                "muscle_mass": "38.5",
                "muscle_score": "",
                "bone_mass": "2.1",
                "tbw": "29.7",
                "tbw_p": "65.7",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "17.0",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1448",
                "bmr_kj": "6058",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "47.8",
                "predicted_fat_mass": "7.2",
                "fat_to_lose__gain": "2.6",
                "rl_fat_p": "13.6",
                "rl_fat_mass": "1.2",
                "rl_ffm": "7.6",
                "rl_muscle_mass": "7.2",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "13.5",
                "ll_fat_mass": "1.2",
                "ll_ffm": "7.6",
                "ll_muscle_mass": "7.2",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "7.5",
                "ra_fat_mass": "0.2",
                "ra_ffm": "1.9",
                "ra_muscle_mass": "1.8",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "8.4",
                "la_fat_mass": "0.2",
                "la_ffm": "1.8",
                "la_muscle_mass": "1.7",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "7.7",
                "trunk_fat_mass": "1.8",
                "trunk_ffm": "21.7",
                "trunk_muscle_mass": "20.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4306",
                "dummy1": "2006/11/21"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014307",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:28",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "172.0",
                "clothes_weight": "0.0",
                "weight": "67.5",
                "fat_p": "25.1",
                "fat_mass": "16.9",
                "ffm": "50.6",
                "muscle_mass": "47.9",
                "muscle_score": "",
                "bone_mass": "2.7",
                "tbw": "37.0",
                "tbw_p": "54.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1703",
                "bmr_kj": "7125",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "59.5",
                "predicted_fat_mass": "8.9",
                "fat_to_lose__gain": "-8.0",
                "rl_fat_p": "25.3",
                "rl_fat_mass": "3.5",
                "rl_ffm": "10.2",
                "rl_muscle_mass": "9.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "24.7",
                "ll_fat_mass": "3.4",
                "ll_ffm": "10.3",
                "ll_muscle_mass": "9.7",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "20.0",
                "ra_fat_mass": "0.7",
                "ra_ffm": "2.6",
                "ra_muscle_mass": "2.4",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "20.7",
                "la_fat_mass": "0.6",
                "la_ffm": "2.4",
                "la_muscle_mass": "2.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "25.7",
                "trunk_fat_mass": "8.7",
                "trunk_ffm": "25.1",
                "trunk_muscle_mass": "24.0",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4307",
                "dummy1": "2006/07/02"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014308",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:43",
                "body_type": "0",
                "gender": "1",
                "age": "16",
                "height": "171.0",
                "clothes_weight": "0.0",
                "weight": "65.8",
                "fat_p": "19.0",
                "fat_mass": "12.5",
                "ffm": "53.3",
                "muscle_mass": "50.5",
                "muscle_score": "",
                "bone_mass": "2.8",
                "tbw": "39.0",
                "tbw_p": "59.3",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "22.5",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1712",
                "bmr_kj": "7163",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "63.5",
                "predicted_fat_mass": "10.2",
                "fat_to_lose__gain": "-2.3",
                "rl_fat_p": "17.3",
                "rl_fat_mass": "2.4",
                "rl_ffm": "11.3",
                "rl_muscle_mass": "10.7",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "16.8",
                "ll_fat_mass": "2.3",
                "ll_ffm": "11.3",
                "ll_muscle_mass": "10.7",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "15.9",
                "ra_fat_mass": "0.5",
                "ra_ffm": "2.7",
                "ra_muscle_mass": "2.5",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "16.0",
                "la_fat_mass": "0.5",
                "la_ffm": "2.7",
                "la_muscle_mass": "2.5",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "21.2",
                "trunk_fat_mass": "6.8",
                "trunk_ffm": "25.3",
                "trunk_muscle_mass": "24.1",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4308",
                "dummy1": "2005/05/05"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014309",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:41",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "173.0",
                "clothes_weight": "0.0",
                "weight": "49.3",
                "fat_p": "10.4",
                "fat_mass": "5.1",
                "ffm": "44.2",
                "muscle_mass": "41.9",
                "muscle_score": "",
                "bone_mass": "2.3",
                "tbw": "32.4",
                "tbw_p": "65.7",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "16.5",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1497",
                "bmr_kj": "6263",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "52.0",
                "predicted_fat_mass": "7.8",
                "fat_to_lose__gain": "2.7",
                "rl_fat_p": "10.6",
                "rl_fat_mass": "1.0",
                "rl_ffm": "8.8",
                "rl_muscle_mass": "8.3",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "10.9",
                "ll_fat_mass": "1.1",
                "ll_ffm": "8.7",
                "ll_muscle_mass": "8.2",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "7.7",
                "ra_fat_mass": "0.2",
                "ra_ffm": "2.2",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "8.8",
                "la_fat_mass": "0.2",
                "la_ffm": "2.1",
                "la_muscle_mass": "2.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "10.4",
                "trunk_fat_mass": "2.6",
                "trunk_ffm": "22.4",
                "trunk_muscle_mass": "21.4",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4309",
                "dummy1": "2006/04/06"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014310",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:18",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "177.0",
                "clothes_weight": "0.0",
                "weight": "44.3",
                "fat_p": "4.0",
                "fat_mass": "1.8",
                "ffm": "42.5",
                "muscle_mass": "40.3",
                "muscle_score": "",
                "bone_mass": "2.2",
                "tbw": "31.1",
                "tbw_p": "70.2",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "14.1",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1444",
                "bmr_kj": "6042",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "50.0",
                "predicted_fat_mass": "7.5",
                "fat_to_lose__gain": "5.7",
                "rl_fat_p": "4.1",
                "rl_fat_mass": "0.4",
                "rl_ffm": "8.6",
                "rl_muscle_mass": "8.1",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "5.4",
                "ll_fat_mass": "0.5",
                "ll_ffm": "8.3",
                "ll_muscle_mass": "7.8",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "4.1",
                "ra_fat_mass": "0.1",
                "ra_ffm": "2.0",
                "ra_muscle_mass": "1.9",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "4.3",
                "la_fat_mass": "0.1",
                "la_ffm": "2.1",
                "la_muscle_mass": "2.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "3.2",
                "trunk_fat_mass": "0.7",
                "trunk_ffm": "21.5",
                "trunk_muscle_mass": "20.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4310",
                "dummy1": "2005/10/11"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014312",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:50",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "174.0",
                "clothes_weight": "0.0",
                "weight": "58.0",
                "fat_p": "15.9",
                "fat_mass": "9.2",
                "ffm": "48.8",
                "muscle_mass": "46.2",
                "muscle_score": "",
                "bone_mass": "2.6",
                "tbw": "35.7",
                "tbw_p": "61.6",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "19.2",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1634",
                "bmr_kj": "6837",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "57.4",
                "predicted_fat_mass": "8.6",
                "fat_to_lose__gain": "-0.6",
                "rl_fat_p": "16.7",
                "rl_fat_mass": "2.0",
                "rl_ffm": "9.8",
                "rl_muscle_mass": "9.3",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "17.7",
                "ll_fat_mass": "2.0",
                "ll_ffm": "9.5",
                "ll_muscle_mass": "9.0",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "12.3",
                "ra_fat_mass": "0.3",
                "ra_ffm": "2.4",
                "ra_muscle_mass": "2.2",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "13.1",
                "la_fat_mass": "0.4",
                "la_ffm": "2.4",
                "la_muscle_mass": "2.2",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "15.4",
                "trunk_fat_mass": "4.5",
                "trunk_ffm": "24.7",
                "trunk_muscle_mass": "23.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4312",
                "dummy1": "2006/06/08"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014313",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:34",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "167.0",
                "clothes_weight": "0.0",
                "weight": "48.8",
                "fat_p": "10.2",
                "fat_mass": "5.0",
                "ffm": "43.8",
                "muscle_mass": "41.5",
                "muscle_score": "",
                "bone_mass": "2.3",
                "tbw": "32.1",
                "tbw_p": "65.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "17.5",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1487",
                "bmr_kj": "6222",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "51.5",
                "predicted_fat_mass": "7.7",
                "fat_to_lose__gain": "2.7",
                "rl_fat_p": "11.4",
                "rl_fat_mass": "1.1",
                "rl_ffm": "8.9",
                "rl_muscle_mass": "8.4",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "10.9",
                "ll_fat_mass": "1.1",
                "ll_ffm": "8.9",
                "ll_muscle_mass": "8.4",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "8.3",
                "ra_fat_mass": "0.2",
                "ra_ffm": "2.2",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "9.5",
                "la_fat_mass": "0.2",
                "la_ffm": "2.2",
                "la_muscle_mass": "2.1",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "10.0",
                "trunk_fat_mass": "2.4",
                "trunk_ffm": "21.6",
                "trunk_muscle_mass": "20.6",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4313",
                "dummy1": "2006/07/19"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014314",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:32",
                "body_type": "0",
                "gender": "1",
                "age": "16",
                "height": "164.0",
                "clothes_weight": "0.0",
                "weight": "42.6",
                "fat_p": "11.9",
                "fat_mass": "5.1",
                "ffm": "37.5",
                "muscle_mass": "35.5",
                "muscle_score": "",
                "bone_mass": "2.0",
                "tbw": "27.5",
                "tbw_p": "64.6",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.8",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "16.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1262",
                "bmr_kj": "5280",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "16",
                "predicted_weight": "44.6",
                "predicted_fat_mass": "7.1",
                "fat_to_lose__gain": "2.0",
                "rl_fat_p": "10.7",
                "rl_fat_mass": "1.0",
                "rl_ffm": "8.0",
                "rl_muscle_mass": "7.6",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "11.9",
                "ll_fat_mass": "1.1",
                "ll_ffm": "7.9",
                "ll_muscle_mass": "7.5",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "9.7",
                "ra_fat_mass": "0.2",
                "ra_ffm": "2.1",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "10.0",
                "la_fat_mass": "0.2",
                "la_ffm": "2.0",
                "la_muscle_mass": "1.9",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "12.9",
                "trunk_fat_mass": "2.6",
                "trunk_ffm": "17.5",
                "trunk_muscle_mass": "16.5",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4314",
                "dummy1": "2005/10/05"
            },
            {
                "model": "MC-980",
                "id_no": "002021D200014315",
                "status": "0",
                "date": "2022/12/04",
                "time": "13:39",
                "body_type": "0",
                "gender": "1",
                "age": "15",
                "height": "171.0",
                "clothes_weight": "0.0",
                "weight": "45.2",
                "fat_p": "6.0",
                "fat_mass": "2.7",
                "ffm": "42.5",
                "muscle_mass": "40.3",
                "muscle_score": "",
                "bone_mass": "2.2",
                "tbw": "31.1",
                "tbw_p": "68.8",
                "icw": "",
                "ecw": "",
                "ecw_p": "",
                "bmi": "15.5",
                "standard_body_weight": "",
                "degree_of_obesity": "",
                "standard_fat_p": "15.0",
                "standard_muscle_mass": "",
                "visceral_fat_rating": "",
                "leg_muscle_score": "",
                "bmr_kcal": "1445",
                "bmr_kj": "6046",
                "bmr_score": "",
                "metabolic_age": "",
                "balancearm": "",
                "balanceleg": "",
                "target_fat_p": "15",
                "predicted_weight": "50.0",
                "predicted_fat_mass": "7.5",
                "fat_to_lose__gain": "4.8",
                "rl_fat_p": "8.5",
                "rl_fat_mass": "0.8",
                "rl_ffm": "8.3",
                "rl_muscle_mass": "7.8",
                "rl_fat_p_score": "",
                "rl_muscle_score": "",
                "ll_fat_p": "9.0",
                "ll_fat_mass": "0.8",
                "ll_ffm": "8.2",
                "ll_muscle_mass": "7.7",
                "ll_fat_p_score": "",
                "ll_muscle_score": "",
                "ra_fat_p": "5.2",
                "ra_fat_mass": "0.1",
                "ra_ffm": "2.1",
                "ra_muscle_mass": "2.0",
                "ra_fat_p_score": "",
                "ra_muscle_score": "",
                "la_fat_p": "5.3",
                "la_fat_mass": "0.1",
                "la_ffm": "2.1",
                "la_muscle_mass": "2.0",
                "la_fat_p_score": "",
                "la_muscle_score": "",
                "trunk_fat_p": "4.0",
                "trunk_fat_mass": "0.9",
                "trunk_ffm": "21.8",
                "trunk_muscle_mass": "20.8",
                "trunk_fat_p_score": "",
                "trunk_muscle_score": "",
                "username": "4315",
                "dummy1": "2006/04/28"
            }
        ]
    }
}

const getMemberQueryResult = () => {
    return {
        "total": 21,
        "data": [
            {
                "id": 1,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "CLXPDK",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "李煜昌",
                "email": "albertcheuk",
                "group": "1A",
                "group_num": "21001R",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 2,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "CXDVWA",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "葉港超",
                "email": "biochung",
                "group": "1A",
                "group_num": "21002Y",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 3,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "EJFVDK",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "賈安良",
                "email": "pchoi",
                "group": "1A",
                "group_num": "21003B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 4,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "FSKNTB",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "黃匡中",
                "email": "johnnykwong",
                "group": "1A",
                "group_num": "20005R",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 5,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "HCLFVN",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "楊永勣",
                "email": "wlam",
                "group": "1A",
                "group_num": "21005R",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 6,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "JJPYJJ",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "彭耀鈞",
                "email": "georgelau",
                "group": "1A",
                "group_num": "20007B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 7,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "KKGYYR",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "梁錦棠",
                "email": "georgelau",
                "group": "1A",
                "group_num": "21006Y",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 8,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "LUJEXA",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "葉志毅",
                "email": "drtommylau",
                "group": "1A",
                "group_num": "21007B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 9,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "NJMBAQ",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "郭家熙",
                "email": "fleung",
                "group": "1B",
                "group_num": "21009R",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 10,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "PFEXPP",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "盧兆祖",
                "email": "fleung",
                "group": "1B",
                "group_num": "21010Y",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 11,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "QAXTTD",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "毛旭文",
                "email": "fleung",
                "group": "1B",
                "group_num": "21037R",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 12,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "RRCAAK",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "杜春澤",
                "email": "fredcfli",
                "group": "1B",
                "group_num": "21011B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 13,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "STNVJU",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "徐梓柔",
                "email": "samli",
                "group": "1B",
                "group_num": "21012G",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 14,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "UCDXGS",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "黃卓彤",
                "email": "slliu",
                "group": "1B",
                "group_num": "20006Y",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 15,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "WATPHJ",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "王子宸",
                "email": "jim_luk",
                "group": "1C",
                "group_num": "20007B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 16,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "WKUUQX",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "韓明睿",
                "email": "makwaikei",
                "group": "1C",
                "group_num": "21006Y",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 17,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "XRVGZK",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "胡梓茵",
                "email": "llong",
                "group": "1C",
                "group_num": "21007B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 18,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "ZKWZKS",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "黃泳芯",
                "email": "paulwktsang",
                "group": "1C",
                "group_num": "21009R",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 19,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "BAJLDCM",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "高浩文",
                "email": "michaelwang",
                "group": "1C",
                "group_num": "20007B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 20,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "BCDPMCF",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "劉澤銘",
                "email": "sywong",
                "group": "1C",
                "group_num": "21006Y",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            },
            {
                "id": 21,
                "join_time": "2022-12-15 11:07:39",
                "qrcode_id": "BDBCBSS",
                "member_id": "",
                "organization_id": 1,
                "user_type": "school",
                "display_name": "吳文傑",
                "email": "wmwong",
                "group": "1C",
                "group_num": "21007B",
                "phone_num": "",
                "gender": "M",
                "dob": "2018-12-25",
                "created_at": "2022-12-15 11:07:39",
                "updated_at": "2022-12-15 11:07:39",
                "deleted_at": ""
            }
        ],
        "page": 1,
        "page_size": -1
    };
}

const getTimeslotLeaderboardResponse = () => {
    return {
        "message": "Success",
        "result": getTimeslotLeaderboard()
    }
}


const getOneTimeslotLeaderboardResponse = () => {
    return {
        "message": "Success",
        "result": getOneTimeslotLeaderboard()
    }
}

const getTimeslotLeaderboard = () => {
    return [
        {
            "id": 1,
            "skill_name": "Smart Sit-up",
            "skill_code": "situp",
            "color": "orange",
            "unit": "次",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": [
                {
                    "rank": 1,
                    "unit": "次",
                    "score": 49,
                    "members": [
                        {
                            "name": "劉澤銘",
                            "phone_num": "",
                            "member": {
                                "id": 2241,
                                "member_event_info_id": 14,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 20,
                                "display_name": "劉澤銘",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1C",
                                "group_num": "21006Y"
                            }
                        },
                        {
                            "name": "陳映霏",
                            "phone_num": "",
                            "member": {
                                "id": 2281,
                                "member_event_info_id": 74,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 60,
                                "display_name": "陳映霏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "蕭日言",
                            "phone_num": "",
                            "member": {
                                "id": 2365,
                                "member_event_info_id": 158,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 144,
                                "display_name": "蕭日言",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "蕭瑜慧",
                            "phone_num": "",
                            "member": {
                                "id": 2390,
                                "member_event_info_id": 183,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 169,
                                "display_name": "蕭瑜慧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "黃奕僖",
                            "phone_num": "",
                            "member": {
                                "id": 2444,
                                "member_event_info_id": 237,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 223,
                                "display_name": "黃奕僖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "葉梓俊",
                            "phone_num": "",
                            "member": {
                                "id": 2447,
                                "member_event_info_id": 240,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 226,
                                "display_name": "葉梓俊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "25"
                            }
                        },
                        {
                            "name": "陳漢基",
                            "phone_num": "",
                            "member": {
                                "id": 2514,
                                "member_event_info_id": 307,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 293,
                                "display_name": "陳漢基",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "羅樂熙",
                            "phone_num": "",
                            "member": {
                                "id": 2545,
                                "member_event_info_id": 338,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 324,
                                "display_name": "羅樂熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "鄭建諾",
                            "phone_num": "",
                            "member": {
                                "id": 2668,
                                "member_event_info_id": 461,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 447,
                                "display_name": "鄭建諾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "崔霆謙",
                            "phone_num": "",
                            "member": {
                                "id": 2757,
                                "member_event_info_id": 550,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 536,
                                "display_name": "崔霆謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "羅樂晴",
                            "phone_num": "",
                            "member": {
                                "id": 2828,
                                "member_event_info_id": 621,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 607,
                                "display_name": "羅樂晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "黃梓霖",
                            "phone_num": "",
                            "member": {
                                "id": 2909,
                                "member_event_info_id": 702,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 688,
                                "display_name": "黃梓霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "黃俊鏗",
                            "phone_num": "",
                            "member": {
                                "id": 3222,
                                "member_event_info_id": 1015,
                                "skill_code": "situp",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1001,
                                "display_name": "黃俊鏗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "18"
                            }
                        }
                    ]
                },
                {
                    "rank": 2,
                    "unit": "次",
                    "score": 48,
                    "members": [
                        {
                            "name": "蔡信霖",
                            "phone_num": "",
                            "member": {
                                "id": 2307,
                                "member_event_info_id": 100,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 86,
                                "display_name": "蔡信霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "黃淽琳",
                            "phone_num": "",
                            "member": {
                                "id": 2344,
                                "member_event_info_id": 137,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 123,
                                "display_name": "黃淽琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "何寧莉",
                            "phone_num": "",
                            "member": {
                                "id": 2352,
                                "member_event_info_id": 145,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 131,
                                "display_name": "何寧莉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "王佩婷",
                            "phone_num": "",
                            "member": {
                                "id": 2414,
                                "member_event_info_id": 207,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 193,
                                "display_name": "王佩婷",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "黃允希",
                            "phone_num": "",
                            "member": {
                                "id": 2419,
                                "member_event_info_id": 212,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 198,
                                "display_name": "黃允希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "周梓朗",
                            "phone_num": "",
                            "member": {
                                "id": 2542,
                                "member_event_info_id": 335,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 321,
                                "display_name": "周梓朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "胡逸嵐",
                            "phone_num": "",
                            "member": {
                                "id": 2664,
                                "member_event_info_id": 457,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 443,
                                "display_name": "胡逸嵐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "呂婉婷",
                            "phone_num": "",
                            "member": {
                                "id": 2697,
                                "member_event_info_id": 490,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 476,
                                "display_name": "呂婉婷",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "何莉娜",
                            "phone_num": "",
                            "member": {
                                "id": 2805,
                                "member_event_info_id": 598,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 584,
                                "display_name": "何莉娜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "朱晉霆",
                            "phone_num": "",
                            "member": {
                                "id": 2822,
                                "member_event_info_id": 615,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 601,
                                "display_name": "朱晉霆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "蕭榳禧",
                            "phone_num": "",
                            "member": {
                                "id": 2881,
                                "member_event_info_id": 674,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 660,
                                "display_name": "蕭榳禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "蕭日言",
                            "phone_num": "",
                            "member": {
                                "id": 2904,
                                "member_event_info_id": 697,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 683,
                                "display_name": "蕭日言",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "梁嘉敏",
                            "phone_num": "",
                            "member": {
                                "id": 2924,
                                "member_event_info_id": 717,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 703,
                                "display_name": "梁嘉敏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "郭釺瑩",
                            "phone_num": "",
                            "member": {
                                "id": 2970,
                                "member_event_info_id": 763,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 749,
                                "display_name": "郭釺瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "林丞置",
                            "phone_num": "",
                            "member": {
                                "id": 2973,
                                "member_event_info_id": 766,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 752,
                                "display_name": "林丞置",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "劉語心",
                            "phone_num": "",
                            "member": {
                                "id": 3124,
                                "member_event_info_id": 917,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 903,
                                "display_name": "劉語心",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "譚永其",
                            "phone_num": "",
                            "member": {
                                "id": 3130,
                                "member_event_info_id": 923,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 909,
                                "display_name": "譚永其",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "黃梓烔",
                            "phone_num": "",
                            "member": {
                                "id": 3133,
                                "member_event_info_id": 926,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 912,
                                "display_name": "黃梓烔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "林莉",
                            "phone_num": "",
                            "member": {
                                "id": 3141,
                                "member_event_info_id": 934,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 920,
                                "display_name": "林莉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "孫曉晴",
                            "phone_num": "",
                            "member": {
                                "id": 3199,
                                "member_event_info_id": 992,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 978,
                                "display_name": "孫曉晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "林靖曦",
                            "phone_num": "",
                            "member": {
                                "id": 3316,
                                "member_event_info_id": 1109,
                                "skill_code": "situp",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1095,
                                "display_name": "林靖曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "4"
                            }
                        }
                    ]
                },
                {
                    "rank": 3,
                    "unit": "次",
                    "score": 47,
                    "members": [
                        {
                            "name": "彭耀鈞",
                            "phone_num": "",
                            "member": {
                                "id": 2227,
                                "member_event_info_id": 23,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 6,
                                "display_name": "彭耀鈞",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "20007B"
                            }
                        },
                        {
                            "name": "楊博喬",
                            "phone_num": "",
                            "member": {
                                "id": 2297,
                                "member_event_info_id": 90,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 76,
                                "display_name": "楊博喬",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "梁力仁",
                            "phone_num": "",
                            "member": {
                                "id": 2314,
                                "member_event_info_id": 107,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 93,
                                "display_name": "梁力仁",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "梁雅晴",
                            "phone_num": "",
                            "member": {
                                "id": 2315,
                                "member_event_info_id": 108,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 94,
                                "display_name": "梁雅晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "陳美妍",
                            "phone_num": "",
                            "member": {
                                "id": 2330,
                                "member_event_info_id": 123,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 109,
                                "display_name": "陳美妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "鄧懿涵",
                            "phone_num": "",
                            "member": {
                                "id": 2367,
                                "member_event_info_id": 160,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 146,
                                "display_name": "鄧懿涵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "李書妍",
                            "phone_num": "",
                            "member": {
                                "id": 2382,
                                "member_event_info_id": 175,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 161,
                                "display_name": "李書妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "龎天欣",
                            "phone_num": "",
                            "member": {
                                "id": 2389,
                                "member_event_info_id": 182,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 168,
                                "display_name": "龎天欣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "嚴弘",
                            "phone_num": "",
                            "member": {
                                "id": 2420,
                                "member_event_info_id": 213,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 199,
                                "display_name": "嚴弘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "陳若妍",
                            "phone_num": "",
                            "member": {
                                "id": 2423,
                                "member_event_info_id": 216,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 202,
                                "display_name": "陳若妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "譚鈺鏵",
                            "phone_num": "",
                            "member": {
                                "id": 2443,
                                "member_event_info_id": 236,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 222,
                                "display_name": "譚鈺鏵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "林凱妮",
                            "phone_num": "",
                            "member": {
                                "id": 2582,
                                "member_event_info_id": 375,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 361,
                                "display_name": "林凱妮",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "黃逸謙",
                            "phone_num": "",
                            "member": {
                                "id": 2702,
                                "member_event_info_id": 495,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 481,
                                "display_name": "黃逸謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "蔡芷晴",
                            "phone_num": "",
                            "member": {
                                "id": 2711,
                                "member_event_info_id": 504,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 490,
                                "display_name": "蔡芷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "盧芷穎",
                            "phone_num": "",
                            "member": {
                                "id": 2719,
                                "member_event_info_id": 512,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 498,
                                "display_name": "盧芷穎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "葉采怡",
                            "phone_num": "",
                            "member": {
                                "id": 2752,
                                "member_event_info_id": 545,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 531,
                                "display_name": "葉采怡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "李祈昱",
                            "phone_num": "",
                            "member": {
                                "id": 2764,
                                "member_event_info_id": 557,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 543,
                                "display_name": "李祈昱",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "潘梓朗",
                            "phone_num": "",
                            "member": {
                                "id": 2880,
                                "member_event_info_id": 673,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 659,
                                "display_name": "潘梓朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "袁子晉",
                            "phone_num": "",
                            "member": {
                                "id": 2886,
                                "member_event_info_id": 679,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 665,
                                "display_name": "袁子晉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "林溢曦",
                            "phone_num": "",
                            "member": {
                                "id": 2897,
                                "member_event_info_id": 690,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 676,
                                "display_name": "林溢曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "邱曉盈",
                            "phone_num": "",
                            "member": {
                                "id": 2985,
                                "member_event_info_id": 778,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 764,
                                "display_name": "邱曉盈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "24"
                            }
                        },
                        {
                            "name": "周梓朗",
                            "phone_num": "",
                            "member": {
                                "id": 3081,
                                "member_event_info_id": 874,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 860,
                                "display_name": "周梓朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "梁芯悠",
                            "phone_num": "",
                            "member": {
                                "id": 3125,
                                "member_event_info_id": 918,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 904,
                                "display_name": "梁芯悠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "張靖研",
                            "phone_num": "",
                            "member": {
                                "id": 3225,
                                "member_event_info_id": 1018,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1004,
                                "display_name": "張靖研",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "黎晞琳",
                            "phone_num": "",
                            "member": {
                                "id": 3230,
                                "member_event_info_id": 1023,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1009,
                                "display_name": "黎晞琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "鄧卓欣",
                            "phone_num": "",
                            "member": {
                                "id": 3262,
                                "member_event_info_id": 1055,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1041,
                                "display_name": "鄧卓欣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "譚嘉穎",
                            "phone_num": "",
                            "member": {
                                "id": 3283,
                                "member_event_info_id": 1076,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1062,
                                "display_name": "譚嘉穎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "楊芷唯",
                            "phone_num": "",
                            "member": {
                                "id": 3289,
                                "member_event_info_id": 1082,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1068,
                                "display_name": "楊芷唯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "王詩蘅",
                            "phone_num": "",
                            "member": {
                                "id": 3310,
                                "member_event_info_id": 1103,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1089,
                                "display_name": "王詩蘅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "田頌禮",
                            "phone_num": "",
                            "member": {
                                "id": 3328,
                                "member_event_info_id": 1121,
                                "skill_code": "situp",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1107,
                                "display_name": "田頌禮",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "16"
                            }
                        }
                    ]
                },
                {
                    "rank": 4,
                    "unit": "次",
                    "score": 46,
                    "members": [
                        {
                            "name": "王子宸",
                            "phone_num": "",
                            "member": {
                                "id": 2236,
                                "member_event_info_id": 17,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 15,
                                "display_name": "王子宸",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1C",
                                "group_num": "20007B"
                            }
                        },
                        {
                            "name": "李建熹",
                            "phone_num": "",
                            "member": {
                                "id": 2246,
                                "member_event_info_id": 39,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 25,
                                "display_name": "李建熹",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "20005R"
                            }
                        },
                        {
                            "name": "李樂希",
                            "phone_num": "",
                            "member": {
                                "id": 2333,
                                "member_event_info_id": 126,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 112,
                                "display_name": "李樂希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "劉亦羚",
                            "phone_num": "",
                            "member": {
                                "id": 2380,
                                "member_event_info_id": 173,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 159,
                                "display_name": "劉亦羚",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "謝啟賢",
                            "phone_num": "",
                            "member": {
                                "id": 2392,
                                "member_event_info_id": 185,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 171,
                                "display_name": "謝啟賢",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "吳心柔",
                            "phone_num": "",
                            "member": {
                                "id": 2439,
                                "member_event_info_id": 232,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 218,
                                "display_name": "吳心柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "龎天雨",
                            "phone_num": "",
                            "member": {
                                "id": 2481,
                                "member_event_info_id": 274,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 260,
                                "display_name": "龎天雨",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "周柏熙",
                            "phone_num": "",
                            "member": {
                                "id": 2520,
                                "member_event_info_id": 313,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 299,
                                "display_name": "周柏熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "楊沁洹",
                            "phone_num": "",
                            "member": {
                                "id": 2534,
                                "member_event_info_id": 327,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 313,
                                "display_name": "楊沁洹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "呂婉瑩",
                            "phone_num": "",
                            "member": {
                                "id": 2548,
                                "member_event_info_id": 341,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 327,
                                "display_name": "呂婉瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "曾澤禧",
                            "phone_num": "",
                            "member": {
                                "id": 2572,
                                "member_event_info_id": 365,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 351,
                                "display_name": "曾澤禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "鄭浩謙",
                            "phone_num": "",
                            "member": {
                                "id": 2577,
                                "member_event_info_id": 370,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 356,
                                "display_name": "鄭浩謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "廖偲蓓",
                            "phone_num": "",
                            "member": {
                                "id": 2630,
                                "member_event_info_id": 423,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 409,
                                "display_name": "廖偲蓓",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "陳峻熙",
                            "phone_num": "",
                            "member": {
                                "id": 2689,
                                "member_event_info_id": 482,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 468,
                                "display_name": "陳峻熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "張卓熙",
                            "phone_num": "",
                            "member": {
                                "id": 2708,
                                "member_event_info_id": 501,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 487,
                                "display_name": "張卓熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "陳泳霖",
                            "phone_num": "",
                            "member": {
                                "id": 2843,
                                "member_event_info_id": 636,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 622,
                                "display_name": "陳泳霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "劉姿妤",
                            "phone_num": "",
                            "member": {
                                "id": 2871,
                                "member_event_info_id": 664,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 650,
                                "display_name": "劉姿妤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "馮文深",
                            "phone_num": "",
                            "member": {
                                "id": 2889,
                                "member_event_info_id": 682,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 668,
                                "display_name": "馮文深",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "曾子琪",
                            "phone_num": "",
                            "member": {
                                "id": 3008,
                                "member_event_info_id": 801,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 787,
                                "display_name": "曾子琪",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "張正南",
                            "phone_num": "",
                            "member": {
                                "id": 3031,
                                "member_event_info_id": 824,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 810,
                                "display_name": "張正南",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "麥浚熙",
                            "phone_num": "",
                            "member": {
                                "id": 3068,
                                "member_event_info_id": 861,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 847,
                                "display_name": "麥浚熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "毛嘉朗",
                            "phone_num": "",
                            "member": {
                                "id": 3108,
                                "member_event_info_id": 901,
                                "skill_code": "situp",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 887,
                                "display_name": "毛嘉朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "14"
                            }
                        }
                    ]
                },
                {
                    "rank": 5,
                    "unit": "次",
                    "score": 45,
                    "members": [
                        {
                            "name": "張沃正",
                            "phone_num": "",
                            "member": {
                                "id": 2243,
                                "member_event_info_id": 36,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 22,
                                "display_name": "張沃正",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "21001R"
                            }
                        },
                        {
                            "name": "陳可瑩",
                            "phone_num": "",
                            "member": {
                                "id": 2348,
                                "member_event_info_id": 141,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 127,
                                "display_name": "陳可瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "郭曉曈",
                            "phone_num": "",
                            "member": {
                                "id": 2354,
                                "member_event_info_id": 147,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 133,
                                "display_name": "郭曉曈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "黃子晴",
                            "phone_num": "",
                            "member": {
                                "id": 2416,
                                "member_event_info_id": 209,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 195,
                                "display_name": "黃子晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "何庭匡",
                            "phone_num": "",
                            "member": {
                                "id": 2428,
                                "member_event_info_id": 221,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 207,
                                "display_name": "何庭匡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "賴承澤",
                            "phone_num": "",
                            "member": {
                                "id": 2433,
                                "member_event_info_id": 226,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 212,
                                "display_name": "賴承澤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "張軒銘",
                            "phone_num": "",
                            "member": {
                                "id": 2489,
                                "member_event_info_id": 282,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 268,
                                "display_name": "張軒銘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "呂文曦",
                            "phone_num": "",
                            "member": {
                                "id": 2527,
                                "member_event_info_id": 320,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 306,
                                "display_name": "呂文曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "鄭澄浩",
                            "phone_num": "",
                            "member": {
                                "id": 2540,
                                "member_event_info_id": 333,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 319,
                                "display_name": "鄭澄浩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "周柏源",
                            "phone_num": "",
                            "member": {
                                "id": 2541,
                                "member_event_info_id": 334,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 320,
                                "display_name": "周柏源",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "鄧栩諾",
                            "phone_num": "",
                            "member": {
                                "id": 2611,
                                "member_event_info_id": 404,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 390,
                                "display_name": "鄧栩諾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "黃稀妍",
                            "phone_num": "",
                            "member": {
                                "id": 2727,
                                "member_event_info_id": 520,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 506,
                                "display_name": "黃稀妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "羅家蔚",
                            "phone_num": "",
                            "member": {
                                "id": 2741,
                                "member_event_info_id": 534,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 520,
                                "display_name": "羅家蔚",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "蘇佩悠",
                            "phone_num": "",
                            "member": {
                                "id": 2814,
                                "member_event_info_id": 607,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 593,
                                "display_name": "蘇佩悠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "喻梓恩",
                            "phone_num": "",
                            "member": {
                                "id": 2838,
                                "member_event_info_id": 631,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 617,
                                "display_name": "喻梓恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "崔珀僖",
                            "phone_num": "",
                            "member": {
                                "id": 2867,
                                "member_event_info_id": 660,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 646,
                                "display_name": "崔珀僖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "彭加希",
                            "phone_num": "",
                            "member": {
                                "id": 2878,
                                "member_event_info_id": 671,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 657,
                                "display_name": "彭加希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "陳芊楺",
                            "phone_num": "",
                            "member": {
                                "id": 2912,
                                "member_event_info_id": 705,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 691,
                                "display_name": "陳芊楺",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "李智恩",
                            "phone_num": "",
                            "member": {
                                "id": 2926,
                                "member_event_info_id": 719,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 705,
                                "display_name": "李智恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "揭清堯",
                            "phone_num": "",
                            "member": {
                                "id": 2943,
                                "member_event_info_id": 736,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 722,
                                "display_name": "揭清堯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "沈靖堯",
                            "phone_num": "",
                            "member": {
                                "id": 2950,
                                "member_event_info_id": 743,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 729,
                                "display_name": "沈靖堯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "周穎彤",
                            "phone_num": "",
                            "member": {
                                "id": 2964,
                                "member_event_info_id": 757,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 743,
                                "display_name": "周穎彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "吳旻珊",
                            "phone_num": "",
                            "member": {
                                "id": 3042,
                                "member_event_info_id": 835,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 821,
                                "display_name": "吳旻珊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "高穎䤭",
                            "phone_num": "",
                            "member": {
                                "id": 3104,
                                "member_event_info_id": 897,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 883,
                                "display_name": "高穎䤭",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "柳彥彤",
                            "phone_num": "",
                            "member": {
                                "id": 3213,
                                "member_event_info_id": 1006,
                                "skill_code": "situp",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 992,
                                "display_name": "柳彥彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "9"
                            }
                        }
                    ]
                },
                {
                    "rank": 6,
                    "unit": "次",
                    "score": 44,
                    "members": [
                        {
                            "name": "徐梓柔",
                            "phone_num": "",
                            "member": {
                                "id": 2255,
                                "member_event_info_id": 48,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 34,
                                "display_name": "徐梓柔",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1B",
                                "group_num": "21012G"
                            }
                        },
                        {
                            "name": "馮紀悠",
                            "phone_num": "",
                            "member": {
                                "id": 2265,
                                "member_event_info_id": 58,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 44,
                                "display_name": "馮紀悠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "蕭榳禧",
                            "phone_num": "",
                            "member": {
                                "id": 2342,
                                "member_event_info_id": 135,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 121,
                                "display_name": "蕭榳禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "陳柏霖",
                            "phone_num": "",
                            "member": {
                                "id": 2366,
                                "member_event_info_id": 159,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 145,
                                "display_name": "陳柏霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "何承羲",
                            "phone_num": "",
                            "member": {
                                "id": 2427,
                                "member_event_info_id": 220,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 206,
                                "display_name": "何承羲",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "林信銘",
                            "phone_num": "",
                            "member": {
                                "id": 2457,
                                "member_event_info_id": 250,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 236,
                                "display_name": "林信銘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "蘇毅朗",
                            "phone_num": "",
                            "member": {
                                "id": 2482,
                                "member_event_info_id": 275,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 261,
                                "display_name": "蘇毅朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "楊芷柔",
                            "phone_num": "",
                            "member": {
                                "id": 2486,
                                "member_event_info_id": 279,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 265,
                                "display_name": "楊芷柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "黎亦珈",
                            "phone_num": "",
                            "member": {
                                "id": 2524,
                                "member_event_info_id": 317,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 303,
                                "display_name": "黎亦珈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "李彩瞳",
                            "phone_num": "",
                            "member": {
                                "id": 2677,
                                "member_event_info_id": 470,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 456,
                                "display_name": "李彩瞳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "譚嘉穎",
                            "phone_num": "",
                            "member": {
                                "id": 2744,
                                "member_event_info_id": 537,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 523,
                                "display_name": "譚嘉穎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "黃詩晴",
                            "phone_num": "",
                            "member": {
                                "id": 2747,
                                "member_event_info_id": 540,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 526,
                                "display_name": "黃詩晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "鍾佇信",
                            "phone_num": "",
                            "member": {
                                "id": 2803,
                                "member_event_info_id": 596,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 582,
                                "display_name": "鍾佇信",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "劉彥希",
                            "phone_num": "",
                            "member": {
                                "id": 2851,
                                "member_event_info_id": 644,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 630,
                                "display_name": "劉彥希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "文匡正",
                            "phone_num": "",
                            "member": {
                                "id": 2856,
                                "member_event_info_id": 649,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 635,
                                "display_name": "文匡正",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "李樂希",
                            "phone_num": "",
                            "member": {
                                "id": 2872,
                                "member_event_info_id": 665,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 651,
                                "display_name": "李樂希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "潘姍姍",
                            "phone_num": "",
                            "member": {
                                "id": 2902,
                                "member_event_info_id": 695,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 681,
                                "display_name": "潘姍姍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "黃愷晴",
                            "phone_num": "",
                            "member": {
                                "id": 3155,
                                "member_event_info_id": 948,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 934,
                                "display_name": "黃愷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "王嘉瑩",
                            "phone_num": "",
                            "member": {
                                "id": 3309,
                                "member_event_info_id": 1102,
                                "skill_code": "situp",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1088,
                                "display_name": "王嘉瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "18"
                            }
                        }
                    ]
                },
                {
                    "rank": 7,
                    "unit": "次",
                    "score": 43,
                    "members": [
                        {
                            "name": "司徒誌銓",
                            "phone_num": "",
                            "member": {
                                "id": 2277,
                                "member_event_info_id": 70,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 56,
                                "display_name": "司徒誌銓",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "梁芷炘",
                            "phone_num": "",
                            "member": {
                                "id": 2336,
                                "member_event_info_id": 129,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 115,
                                "display_name": "梁芷炘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "彭加希",
                            "phone_num": "",
                            "member": {
                                "id": 2339,
                                "member_event_info_id": 132,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 118,
                                "display_name": "彭加希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "楊悅彤",
                            "phone_num": "",
                            "member": {
                                "id": 2372,
                                "member_event_info_id": 165,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 151,
                                "display_name": "楊悅彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "25"
                            }
                        },
                        {
                            "name": "賴澤盈",
                            "phone_num": "",
                            "member": {
                                "id": 2405,
                                "member_event_info_id": 198,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 184,
                                "display_name": "賴澤盈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "謝佩瑩",
                            "phone_num": "",
                            "member": {
                                "id": 2554,
                                "member_event_info_id": 347,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 333,
                                "display_name": "謝佩瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "陳映臻",
                            "phone_num": "",
                            "member": {
                                "id": 2557,
                                "member_event_info_id": 350,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 336,
                                "display_name": "陳映臻",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "黃愷晴",
                            "phone_num": "",
                            "member": {
                                "id": 2616,
                                "member_event_info_id": 409,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 395,
                                "display_name": "黃愷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "翁子傑",
                            "phone_num": "",
                            "member": {
                                "id": 2665,
                                "member_event_info_id": 458,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 444,
                                "display_name": "翁子傑",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "24"
                            }
                        },
                        {
                            "name": "黃子悠",
                            "phone_num": "",
                            "member": {
                                "id": 2684,
                                "member_event_info_id": 477,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 463,
                                "display_name": "黃子悠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "馮文蕎",
                            "phone_num": "",
                            "member": {
                                "id": 2712,
                                "member_event_info_id": 505,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 491,
                                "display_name": "馮文蕎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "羅卓祈",
                            "phone_num": "",
                            "member": {
                                "id": 2718,
                                "member_event_info_id": 511,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 497,
                                "display_name": "羅卓祈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "温天玥",
                            "phone_num": "",
                            "member": {
                                "id": 2726,
                                "member_event_info_id": 519,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 505,
                                "display_name": "温天玥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "李卓希",
                            "phone_num": "",
                            "member": {
                                "id": 2739,
                                "member_event_info_id": 532,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 518,
                                "display_name": "李卓希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "何寧莉",
                            "phone_num": "",
                            "member": {
                                "id": 2891,
                                "member_event_info_id": 684,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 670,
                                "display_name": "何寧莉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "盧善敏",
                            "phone_num": "",
                            "member": {
                                "id": 3088,
                                "member_event_info_id": 881,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 867,
                                "display_name": "盧善敏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "陳映臻",
                            "phone_num": "",
                            "member": {
                                "id": 3096,
                                "member_event_info_id": 889,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 875,
                                "display_name": "陳映臻",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "侯卓伶",
                            "phone_num": "",
                            "member": {
                                "id": 3138,
                                "member_event_info_id": 931,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 917,
                                "display_name": "侯卓伶",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "李詩雅",
                            "phone_num": "",
                            "member": {
                                "id": 3193,
                                "member_event_info_id": 986,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 972,
                                "display_name": "李詩雅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "彭志軒",
                            "phone_num": "",
                            "member": {
                                "id": 3197,
                                "member_event_info_id": 990,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 976,
                                "display_name": "彭志軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "薩穎汶",
                            "phone_num": "",
                            "member": {
                                "id": 3218,
                                "member_event_info_id": 1011,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 997,
                                "display_name": "薩穎汶",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "鄧智航",
                            "phone_num": "",
                            "member": {
                                "id": 3298,
                                "member_event_info_id": 1091,
                                "skill_code": "situp",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1077,
                                "display_name": "鄧智航",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "7"
                            }
                        }
                    ]
                },
                {
                    "rank": 8,
                    "unit": "次",
                    "score": 42,
                    "members": [
                        {
                            "name": "梁禮博",
                            "phone_num": "",
                            "member": {
                                "id": 2334,
                                "member_event_info_id": 127,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 113,
                                "display_name": "梁禮博",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "潘梓朗",
                            "phone_num": "",
                            "member": {
                                "id": 2341,
                                "member_event_info_id": 134,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 120,
                                "display_name": "潘梓朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "温施樂",
                            "phone_num": "",
                            "member": {
                                "id": 2393,
                                "member_event_info_id": 186,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 172,
                                "display_name": "温施樂",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "揭清堯",
                            "phone_num": "",
                            "member": {
                                "id": 2404,
                                "member_event_info_id": 197,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 183,
                                "display_name": "揭清堯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "黃紫瀅",
                            "phone_num": "",
                            "member": {
                                "id": 2417,
                                "member_event_info_id": 210,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 196,
                                "display_name": "黃紫瀅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "黃逸寧",
                            "phone_num": "",
                            "member": {
                                "id": 2418,
                                "member_event_info_id": 211,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 197,
                                "display_name": "黃逸寧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "邱曉盈",
                            "phone_num": "",
                            "member": {
                                "id": 2446,
                                "member_event_info_id": 239,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 225,
                                "display_name": "邱曉盈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "24"
                            }
                        },
                        {
                            "name": "陳心柔",
                            "phone_num": "",
                            "member": {
                                "id": 2450,
                                "member_event_info_id": 243,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 229,
                                "display_name": "陳心柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "劉蔚螢",
                            "phone_num": "",
                            "member": {
                                "id": 2498,
                                "member_event_info_id": 291,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 277,
                                "display_name": "劉蔚螢",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "黃詩雅",
                            "phone_num": "",
                            "member": {
                                "id": 2580,
                                "member_event_info_id": 373,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 359,
                                "display_name": "黃詩雅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "鍾傑鏵",
                            "phone_num": "",
                            "member": {
                                "id": 2597,
                                "member_event_info_id": 390,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 376,
                                "display_name": "鍾傑鏵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "黃辰萱",
                            "phone_num": "",
                            "member": {
                                "id": 2617,
                                "member_event_info_id": 410,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 396,
                                "display_name": "黃辰萱",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "鄧綽賢",
                            "phone_num": "",
                            "member": {
                                "id": 2635,
                                "member_event_info_id": 428,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 414,
                                "display_name": "鄧綽賢",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "陳子昊",
                            "phone_num": "",
                            "member": {
                                "id": 2645,
                                "member_event_info_id": 438,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 424,
                                "display_name": "陳子昊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "尹玥甯",
                            "phone_num": "",
                            "member": {
                                "id": 2701,
                                "member_event_info_id": 494,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 480,
                                "display_name": "尹玥甯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "賴楚葇",
                            "phone_num": "",
                            "member": {
                                "id": 2761,
                                "member_event_info_id": 554,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 540,
                                "display_name": "賴楚葇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "區國棟",
                            "phone_num": "",
                            "member": {
                                "id": 2785,
                                "member_event_info_id": 578,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 564,
                                "display_name": "區國棟",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "吳俊霆",
                            "phone_num": "",
                            "member": {
                                "id": 2927,
                                "member_event_info_id": 720,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 706,
                                "display_name": "吳俊霆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "譚鈺鏵",
                            "phone_num": "",
                            "member": {
                                "id": 2982,
                                "member_event_info_id": 775,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 761,
                                "display_name": "譚鈺鏵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "莊鎧澄",
                            "phone_num": "",
                            "member": {
                                "id": 3012,
                                "member_event_info_id": 805,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 791,
                                "display_name": "莊鎧澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "蘇毅希",
                            "phone_num": "",
                            "member": {
                                "id": 3044,
                                "member_event_info_id": 837,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 823,
                                "display_name": "蘇毅希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "韋逸彥",
                            "phone_num": "",
                            "member": {
                                "id": 3047,
                                "member_event_info_id": 840,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 826,
                                "display_name": "韋逸彥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "關竣宇",
                            "phone_num": "",
                            "member": {
                                "id": 3211,
                                "member_event_info_id": 1004,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 990,
                                "display_name": "關竣宇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "陳卓禧",
                            "phone_num": "",
                            "member": {
                                "id": 3226,
                                "member_event_info_id": 1019,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1005,
                                "display_name": "陳卓禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "盧芷穎",
                            "phone_num": "",
                            "member": {
                                "id": 3258,
                                "member_event_info_id": 1051,
                                "skill_code": "situp",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1037,
                                "display_name": "盧芷穎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "12"
                            }
                        }
                    ]
                },
                {
                    "rank": 9,
                    "unit": "次",
                    "score": 41,
                    "members": [
                        {
                            "name": "毛旭文",
                            "phone_num": "",
                            "member": {
                                "id": 2232,
                                "member_event_info_id": 28,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 11,
                                "display_name": "毛旭文",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1B",
                                "group_num": "21037R"
                            }
                        },
                        {
                            "name": "葉穎欣",
                            "phone_num": "",
                            "member": {
                                "id": 2245,
                                "member_event_info_id": 38,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 24,
                                "display_name": "葉穎欣",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "21003B"
                            }
                        },
                        {
                            "name": "羅家輝",
                            "phone_num": "",
                            "member": {
                                "id": 2247,
                                "member_event_info_id": 40,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 26,
                                "display_name": "羅家輝",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "21005R"
                            }
                        },
                        {
                            "name": "劉彥希",
                            "phone_num": "",
                            "member": {
                                "id": 2312,
                                "member_event_info_id": 105,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 91,
                                "display_name": "劉彥希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "邱靖曦",
                            "phone_num": "",
                            "member": {
                                "id": 2345,
                                "member_event_info_id": 138,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 124,
                                "display_name": "邱靖曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "李溢瑜",
                            "phone_num": "",
                            "member": {
                                "id": 2360,
                                "member_event_info_id": 153,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 139,
                                "display_name": "李溢瑜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "李智恩",
                            "phone_num": "",
                            "member": {
                                "id": 2387,
                                "member_event_info_id": 180,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 166,
                                "display_name": "李智恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "馬靖頤",
                            "phone_num": "",
                            "member": {
                                "id": 2607,
                                "member_event_info_id": 400,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 386,
                                "display_name": "馬靖頤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "江逸晴",
                            "phone_num": "",
                            "member": {
                                "id": 2626,
                                "member_event_info_id": 419,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 405,
                                "display_name": "江逸晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "李銳熙",
                            "phone_num": "",
                            "member": {
                                "id": 2656,
                                "member_event_info_id": 449,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 435,
                                "display_name": "李銳熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "黎晞琳",
                            "phone_num": "",
                            "member": {
                                "id": 2691,
                                "member_event_info_id": 484,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 470,
                                "display_name": "黎晞琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "楊悅兒",
                            "phone_num": "",
                            "member": {
                                "id": 2705,
                                "member_event_info_id": 498,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 484,
                                "display_name": "楊悅兒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "李明恩",
                            "phone_num": "",
                            "member": {
                                "id": 2781,
                                "member_event_info_id": 574,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 560,
                                "display_name": "李明恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "黃畹庭",
                            "phone_num": "",
                            "member": {
                                "id": 2791,
                                "member_event_info_id": 584,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 570,
                                "display_name": "黃畹庭",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "余寶",
                            "phone_num": "",
                            "member": {
                                "id": 2862,
                                "member_event_info_id": 655,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 641,
                                "display_name": "余寶",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "張溱柔",
                            "phone_num": "",
                            "member": {
                                "id": 2888,
                                "member_event_info_id": 681,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 667,
                                "display_name": "張溱柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "林駿謙",
                            "phone_num": "",
                            "member": {
                                "id": 2995,
                                "member_event_info_id": 788,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 774,
                                "display_name": "林駿謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "蘇善恩",
                            "phone_num": "",
                            "member": {
                                "id": 3001,
                                "member_event_info_id": 794,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 780,
                                "display_name": "蘇善恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "黎柏軒",
                            "phone_num": "",
                            "member": {
                                "id": 3015,
                                "member_event_info_id": 808,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 794,
                                "display_name": "黎柏軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "陳茈榆",
                            "phone_num": "",
                            "member": {
                                "id": 3030,
                                "member_event_info_id": 823,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 809,
                                "display_name": "陳茈榆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "陳靜",
                            "phone_num": "",
                            "member": {
                                "id": 3074,
                                "member_event_info_id": 867,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 853,
                                "display_name": "陳靜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "鄭芷曈",
                            "phone_num": "",
                            "member": {
                                "id": 3097,
                                "member_event_info_id": 890,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 876,
                                "display_name": "鄭芷曈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "何允恩",
                            "phone_num": "",
                            "member": {
                                "id": 3118,
                                "member_event_info_id": 911,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 897,
                                "display_name": "何允恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "譚文俊",
                            "phone_num": "",
                            "member": {
                                "id": 3220,
                                "member_event_info_id": 1013,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 999,
                                "display_name": "譚文俊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "陳峻熙",
                            "phone_num": "",
                            "member": {
                                "id": 3228,
                                "member_event_info_id": 1021,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1007,
                                "display_name": "陳峻熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "陳曉瞳",
                            "phone_num": "",
                            "member": {
                                "id": 3270,
                                "member_event_info_id": 1063,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1049,
                                "display_name": "陳曉瞳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "朱晉緯",
                            "phone_num": "",
                            "member": {
                                "id": 3274,
                                "member_event_info_id": 1067,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1053,
                                "display_name": "朱晉緯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "陳熙予",
                            "phone_num": "",
                            "member": {
                                "id": 3292,
                                "member_event_info_id": 1085,
                                "skill_code": "situp",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1071,
                                "display_name": "陳熙予",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "1"
                            }
                        }
                    ]
                },
                {
                    "rank": 10,
                    "unit": "次",
                    "score": 40,
                    "members": [
                        {
                            "name": "馬巧倩",
                            "phone_num": "",
                            "member": {
                                "id": 2292,
                                "member_event_info_id": 85,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 71,
                                "display_name": "馬巧倩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "喻梓恩",
                            "phone_num": "",
                            "member": {
                                "id": 2299,
                                "member_event_info_id": 92,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 78,
                                "display_name": "喻梓恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "張海約",
                            "phone_num": "",
                            "member": {
                                "id": 2306,
                                "member_event_info_id": 99,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 85,
                                "display_name": "張海約",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "麥凱惠",
                            "phone_num": "",
                            "member": {
                                "id": 2362,
                                "member_event_info_id": 155,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 141,
                                "display_name": "麥凱惠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "蔡莉娜",
                            "phone_num": "",
                            "member": {
                                "id": 2413,
                                "member_event_info_id": 206,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 192,
                                "display_name": "蔡莉娜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "曾彥翰",
                            "phone_num": "",
                            "member": {
                                "id": 2465,
                                "member_event_info_id": 258,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 244,
                                "display_name": "曾彥翰",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "陳柏匡",
                            "phone_num": "",
                            "member": {
                                "id": 2470,
                                "member_event_info_id": 263,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:22",
                                "updated_at": "2022-12-21 09:41:22",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 249,
                                "display_name": "陳柏匡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "高天佑",
                            "phone_num": "",
                            "member": {
                                "id": 2581,
                                "member_event_info_id": 374,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 360,
                                "display_name": "高天佑",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "林泓竣",
                            "phone_num": "",
                            "member": {
                                "id": 2653,
                                "member_event_info_id": 446,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 432,
                                "display_name": "林泓竣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "譚文俊",
                            "phone_num": "",
                            "member": {
                                "id": 2681,
                                "member_event_info_id": 474,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 460,
                                "display_name": "譚文俊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "陳卓禧",
                            "phone_num": "",
                            "member": {
                                "id": 2687,
                                "member_event_info_id": 480,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 466,
                                "display_name": "陳卓禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "黃堯權",
                            "phone_num": "",
                            "member": {
                                "id": 2703,
                                "member_event_info_id": 496,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 482,
                                "display_name": "黃堯權",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "黃暟殷",
                            "phone_num": "",
                            "member": {
                                "id": 2769,
                                "member_event_info_id": 562,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 548,
                                "display_name": "黃暟殷",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "甄悅",
                            "phone_num": "",
                            "member": {
                                "id": 2793,
                                "member_event_info_id": 586,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 572,
                                "display_name": "甄悅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "凌朗星",
                            "phone_num": "",
                            "member": {
                                "id": 2855,
                                "member_event_info_id": 648,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 634,
                                "display_name": "凌朗星",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "謝藹琳",
                            "phone_num": "",
                            "member": {
                                "id": 2907,
                                "member_event_info_id": 700,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 686,
                                "display_name": "謝藹琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "陳子昊",
                            "phone_num": "",
                            "member": {
                                "id": 3184,
                                "member_event_info_id": 977,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 963,
                                "display_name": "陳子昊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "葉曉霖",
                            "phone_num": "",
                            "member": {
                                "id": 3276,
                                "member_event_info_id": 1069,
                                "skill_code": "situp",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:41:23",
                                "updated_at": "2022-12-21 09:41:23",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1055,
                                "display_name": "葉曉霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "8"
                            }
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "skill_name": "ALS Roping",
            "skill_code": "skipping",
            "color": "purple",
            "unit": "",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": []
        },
        {
            "id": 3,
            "skill_name": "Smart Vertical Jump",
            "skill_code": "vjump",
            "color": "blue",
            "unit": "cm",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": []
        },
        {
            "id": 4,
            "skill_name": "PingPong Challenge",
            "skill_code": "pingpong",
            "color": "green",
            "unit": "秒",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": []
        },
        {
            "id": 5,
            "skill_name": "T-Test",
            "skill_code": "ttest",
            "color": "orange",
            "unit": "MPH",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": []
        },
        {
            "id": 6,
            "skill_name": "Hand Grip",
            "skill_code": "handgrip",
            "color": "purple",
            "unit": "Kg/F",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": []
        },
        {
            "id": 7,
            "skill_name": "Football Challenge",
            "skill_code": "football",
            "color": "blue",
            "unit": "秒",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": [
                {
                    "rank": 1,
                    "unit": "秒",
                    "score": 49,
                    "members": [
                        {
                            "name": "凌朗星",
                            "phone_num": "",
                            "member": {
                                "id": 94,
                                "member_event_info_id": 109,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 95,
                                "display_name": "凌朗星",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "劉亦羚",
                            "phone_num": "",
                            "member": {
                                "id": 158,
                                "member_event_info_id": 173,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 159,
                                "display_name": "劉亦羚",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "何庭匡",
                            "phone_num": "",
                            "member": {
                                "id": 206,
                                "member_event_info_id": 221,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 207,
                                "display_name": "何庭匡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "嚴清悅",
                            "phone_num": "",
                            "member": {
                                "id": 246,
                                "member_event_info_id": 261,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 247,
                                "display_name": "嚴清悅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "余文諾",
                            "phone_num": "",
                            "member": {
                                "id": 485,
                                "member_event_info_id": 500,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 486,
                                "display_name": "余文諾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "鄭伊晴",
                            "phone_num": "",
                            "member": {
                                "id": 511,
                                "member_event_info_id": 526,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 512,
                                "display_name": "鄭伊晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "楊思朗",
                            "phone_num": "",
                            "member": {
                                "id": 527,
                                "member_event_info_id": 542,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 528,
                                "display_name": "楊思朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "胡佩瑜",
                            "phone_num": "",
                            "member": {
                                "id": 570,
                                "member_event_info_id": 585,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 571,
                                "display_name": "胡佩瑜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "余寶",
                            "phone_num": "",
                            "member": {
                                "id": 640,
                                "member_event_info_id": 655,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 641,
                                "display_name": "余寶",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "呂聚涅",
                            "phone_num": "",
                            "member": {
                                "id": 655,
                                "member_event_info_id": 670,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 656,
                                "display_name": "呂聚涅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "謝藹琳",
                            "phone_num": "",
                            "member": {
                                "id": 685,
                                "member_event_info_id": 700,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 686,
                                "display_name": "謝藹琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "張曦綸",
                            "phone_num": "",
                            "member": {
                                "id": 718,
                                "member_event_info_id": 733,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 719,
                                "display_name": "張曦綸",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "黃海燁",
                            "phone_num": "",
                            "member": {
                                "id": 732,
                                "member_event_info_id": 747,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 733,
                                "display_name": "黃海燁",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "顏均嵐",
                            "phone_num": "",
                            "member": {
                                "id": 821,
                                "member_event_info_id": 836,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 822,
                                "display_name": "顏均嵐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "莊卓楓",
                            "phone_num": "",
                            "member": {
                                "id": 878,
                                "member_event_info_id": 893,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 879,
                                "display_name": "莊卓楓",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "黃晞玥",
                            "phone_num": "",
                            "member": {
                                "id": 910,
                                "member_event_info_id": 925,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 911,
                                "display_name": "黃晞玥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "鍾傑鏵",
                            "phone_num": "",
                            "member": {
                                "id": 914,
                                "member_event_info_id": 929,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 915,
                                "display_name": "鍾傑鏵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "陳梓浚",
                            "phone_num": "",
                            "member": {
                                "id": 938,
                                "member_event_info_id": 953,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 939,
                                "display_name": "陳梓浚",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "林洛辰",
                            "phone_num": "",
                            "member": {
                                "id": 1009,
                                "member_event_info_id": 1024,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1010,
                                "display_name": "林洛辰",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "張卓熙",
                            "phone_num": "",
                            "member": {
                                "id": 1025,
                                "member_event_info_id": 1040,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1026,
                                "display_name": "張卓熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "謝曉惠",
                            "phone_num": "",
                            "member": {
                                "id": 1065,
                                "member_event_info_id": 1080,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1066,
                                "display_name": "謝曉惠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "蕭榳禧",
                            "phone_num": "",
                            "member": {
                                "id": 1231,
                                "member_event_info_id": 135,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 121,
                                "display_name": "蕭榳禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "郭可昕",
                            "phone_num": "",
                            "member": {
                                "id": 1244,
                                "member_event_info_id": 148,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 134,
                                "display_name": "郭可昕",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "林溢曦",
                            "phone_num": "",
                            "member": {
                                "id": 1247,
                                "member_event_info_id": 151,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 137,
                                "display_name": "林溢曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "馮卓盈",
                            "phone_num": "",
                            "member": {
                                "id": 1265,
                                "member_event_info_id": 169,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 155,
                                "display_name": "馮卓盈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "陳頌軒",
                            "phone_num": "",
                            "member": {
                                "id": 1337,
                                "member_event_info_id": 241,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 227,
                                "display_name": "陳頌軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "林宥希",
                            "phone_num": "",
                            "member": {
                                "id": 1366,
                                "member_event_info_id": 270,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 256,
                                "display_name": "林宥希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "黃子謙",
                            "phone_num": "",
                            "member": {
                                "id": 1374,
                                "member_event_info_id": 278,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 264,
                                "display_name": "黃子謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "鍾佇恩",
                            "phone_num": "",
                            "member": {
                                "id": 1451,
                                "member_event_info_id": 355,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 341,
                                "display_name": "鍾佇恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "楊梓傑",
                            "phone_num": "",
                            "member": {
                                "id": 1485,
                                "member_event_info_id": 389,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 375,
                                "display_name": "楊梓傑",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "高旨樂",
                            "phone_num": "",
                            "member": {
                                "id": 1514,
                                "member_event_info_id": 418,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 404,
                                "display_name": "高旨樂",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "蔣智傑",
                            "phone_num": "",
                            "member": {
                                "id": 1599,
                                "member_event_info_id": 503,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 489,
                                "display_name": "蔣智傑",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "伍偉熙",
                            "phone_num": "",
                            "member": {
                                "id": 1611,
                                "member_event_info_id": 515,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 501,
                                "display_name": "伍偉熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "鍾佇信",
                            "phone_num": "",
                            "member": {
                                "id": 1692,
                                "member_event_info_id": 596,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 582,
                                "display_name": "鍾佇信",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "陳柏匡",
                            "phone_num": "",
                            "member": {
                                "id": 1898,
                                "member_event_info_id": 802,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 788,
                                "display_name": "陳柏匡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "黃鍵朗",
                            "phone_num": "",
                            "member": {
                                "id": 1937,
                                "member_event_info_id": 841,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 827,
                                "display_name": "黃鍵朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "楊子健",
                            "phone_num": "",
                            "member": {
                                "id": 1941,
                                "member_event_info_id": 845,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 831,
                                "display_name": "楊子健",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "鄭澄浩",
                            "phone_num": "",
                            "member": {
                                "id": 1968,
                                "member_event_info_id": 872,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 858,
                                "display_name": "鄭澄浩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "黃梓烔",
                            "phone_num": "",
                            "member": {
                                "id": 2022,
                                "member_event_info_id": 926,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 912,
                                "display_name": "黃梓烔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "李詩雅",
                            "phone_num": "",
                            "member": {
                                "id": 2082,
                                "member_event_info_id": 986,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 972,
                                "display_name": "李詩雅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "區國棟",
                            "phone_num": "",
                            "member": {
                                "id": 2213,
                                "member_event_info_id": 1117,
                                "skill_code": "football",
                                "skill_score": 49,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1103,
                                "display_name": "區國棟",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "12"
                            }
                        }
                    ]
                },
                {
                    "rank": 2,
                    "unit": "秒",
                    "score": 48,
                    "members": [
                        {
                            "name": "陳綺晴",
                            "phone_num": "",
                            "member": {
                                "id": 38,
                                "member_event_info_id": 53,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 39,
                                "display_name": "陳綺晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "曾信柔",
                            "phone_num": "",
                            "member": {
                                "id": 190,
                                "member_event_info_id": 205,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 191,
                                "display_name": "曾信柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "蔡莉娜",
                            "phone_num": "",
                            "member": {
                                "id": 191,
                                "member_event_info_id": 206,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 192,
                                "display_name": "蔡莉娜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "林丞置",
                            "phone_num": "",
                            "member": {
                                "id": 212,
                                "member_event_info_id": 227,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 213,
                                "display_name": "林丞置",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "陳柏匡",
                            "phone_num": "",
                            "member": {
                                "id": 248,
                                "member_event_info_id": 263,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 249,
                                "display_name": "陳柏匡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "張正南",
                            "phone_num": "",
                            "member": {
                                "id": 270,
                                "member_event_info_id": 285,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 271,
                                "display_name": "張正南",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "蔣昊",
                            "phone_num": "",
                            "member": {
                                "id": 338,
                                "member_event_info_id": 353,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 339,
                                "display_name": "蔣昊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "鄧綽賢",
                            "phone_num": "",
                            "member": {
                                "id": 413,
                                "member_event_info_id": 428,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 414,
                                "display_name": "鄧綽賢",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "林洛辰",
                            "phone_num": "",
                            "member": {
                                "id": 470,
                                "member_event_info_id": 485,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 471,
                                "display_name": "林洛辰",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "黃稀妍",
                            "phone_num": "",
                            "member": {
                                "id": 505,
                                "member_event_info_id": 520,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 506,
                                "display_name": "黃稀妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "林湝碧",
                            "phone_num": "",
                            "member": {
                                "id": 556,
                                "member_event_info_id": 571,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 557,
                                "display_name": "林湝碧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "孫敬然",
                            "phone_num": "",
                            "member": {
                                "id": 593,
                                "member_event_info_id": 608,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 594,
                                "display_name": "孫敬然",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "朱晉霆",
                            "phone_num": "",
                            "member": {
                                "id": 600,
                                "member_event_info_id": 615,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 601,
                                "display_name": "朱晉霆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "梁雅晴",
                            "phone_num": "",
                            "member": {
                                "id": 632,
                                "member_event_info_id": 647,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 633,
                                "display_name": "梁雅晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "陳美妍",
                            "phone_num": "",
                            "member": {
                                "id": 647,
                                "member_event_info_id": 662,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 648,
                                "display_name": "陳美妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "馮文深",
                            "phone_num": "",
                            "member": {
                                "id": 667,
                                "member_event_info_id": 682,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 668,
                                "display_name": "馮文深",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "馮卓盈",
                            "phone_num": "",
                            "member": {
                                "id": 693,
                                "member_event_info_id": 708,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 694,
                                "display_name": "馮卓盈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "周柏熙",
                            "phone_num": "",
                            "member": {
                                "id": 837,
                                "member_event_info_id": 852,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 838,
                                "display_name": "周柏熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "黃靖淳",
                            "phone_num": "",
                            "member": {
                                "id": 848,
                                "member_event_info_id": 863,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 849,
                                "display_name": "黃靖淳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "黎善柏",
                            "phone_num": "",
                            "member": {
                                "id": 918,
                                "member_event_info_id": 933,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 919,
                                "display_name": "黎善柏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "羅永皓",
                            "phone_num": "",
                            "member": {
                                "id": 1037,
                                "member_event_info_id": 1052,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1038,
                                "display_name": "羅永皓",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "王詩蘅",
                            "phone_num": "",
                            "member": {
                                "id": 1088,
                                "member_event_info_id": 1103,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1089,
                                "display_name": "王詩蘅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "邱靖曦",
                            "phone_num": "",
                            "member": {
                                "id": 1234,
                                "member_event_info_id": 138,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 124,
                                "display_name": "邱靖曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "梁嘉敏",
                            "phone_num": "",
                            "member": {
                                "id": 1274,
                                "member_event_info_id": 178,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 164,
                                "display_name": "梁嘉敏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "徐悅喬",
                            "phone_num": "",
                            "member": {
                                "id": 1526,
                                "member_event_info_id": 430,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 416,
                                "display_name": "徐悅喬",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "陳柏爾",
                            "phone_num": "",
                            "member": {
                                "id": 1532,
                                "member_event_info_id": 436,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 422,
                                "display_name": "陳柏爾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "鄧雲中",
                            "phone_num": "",
                            "member": {
                                "id": 1647,
                                "member_event_info_id": 551,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 537,
                                "display_name": "鄧雲中",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "周奕希",
                            "phone_num": "",
                            "member": {
                                "id": 1691,
                                "member_event_info_id": 595,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 581,
                                "display_name": "周奕希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "陳泳霖",
                            "phone_num": "",
                            "member": {
                                "id": 1732,
                                "member_event_info_id": 636,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 622,
                                "display_name": "陳泳霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "簡喜兒",
                            "phone_num": "",
                            "member": {
                                "id": 1759,
                                "member_event_info_id": 663,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 649,
                                "display_name": "簡喜兒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "葉小說",
                            "phone_num": "",
                            "member": {
                                "id": 1781,
                                "member_event_info_id": 685,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 671,
                                "display_name": "葉小說",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "曾信柔",
                            "phone_num": "",
                            "member": {
                                "id": 1840,
                                "member_event_info_id": 744,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 730,
                                "display_name": "曾信柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "薩穎軒",
                            "phone_num": "",
                            "member": {
                                "id": 1870,
                                "member_event_info_id": 774,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 760,
                                "display_name": "薩穎軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "林莉",
                            "phone_num": "",
                            "member": {
                                "id": 2030,
                                "member_event_info_id": 934,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 920,
                                "display_name": "林莉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "柳彥彤",
                            "phone_num": "",
                            "member": {
                                "id": 2102,
                                "member_event_info_id": 1006,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 992,
                                "display_name": "柳彥彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "張靖烯",
                            "phone_num": "",
                            "member": {
                                "id": 2162,
                                "member_event_info_id": 1066,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1052,
                                "display_name": "張靖烯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "許嘉榆",
                            "phone_num": "",
                            "member": {
                                "id": 2164,
                                "member_event_info_id": 1068,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1054,
                                "display_name": "許嘉榆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "梁百乘",
                            "phone_num": "",
                            "member": {
                                "id": 2208,
                                "member_event_info_id": 1112,
                                "skill_code": "football",
                                "skill_score": 48,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1098,
                                "display_name": "梁百乘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "7"
                            }
                        }
                    ]
                },
                {
                    "rank": 3,
                    "unit": "秒",
                    "score": 47,
                    "members": [
                        {
                            "name": "李建熹",
                            "phone_num": "",
                            "member": {
                                "id": 24,
                                "member_event_info_id": 39,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 25,
                                "display_name": "李建熹",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "20005R"
                            }
                        },
                        {
                            "name": "羅家輝",
                            "phone_num": "",
                            "member": {
                                "id": 25,
                                "member_event_info_id": 40,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 26,
                                "display_name": "羅家輝",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "21005R"
                            }
                        },
                        {
                            "name": "陳彩兒",
                            "phone_num": "",
                            "member": {
                                "id": 34,
                                "member_event_info_id": 49,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 35,
                                "display_name": "陳彩兒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "陳梓豐",
                            "phone_num": "",
                            "member": {
                                "id": 58,
                                "member_event_info_id": 73,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 59,
                                "display_name": "陳梓豐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "陳智琛",
                            "phone_num": "",
                            "member": {
                                "id": 80,
                                "member_event_info_id": 95,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 81,
                                "display_name": "陳智琛",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "陳雅惠",
                            "phone_num": "",
                            "member": {
                                "id": 294,
                                "member_event_info_id": 309,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 295,
                                "display_name": "陳雅惠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "鄭伊淳",
                            "phone_num": "",
                            "member": {
                                "id": 296,
                                "member_event_info_id": 311,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 297,
                                "display_name": "鄭伊淳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "馮雪心",
                            "phone_num": "",
                            "member": {
                                "id": 342,
                                "member_event_info_id": 357,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 343,
                                "display_name": "馮雪心",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "黃梓烔",
                            "phone_num": "",
                            "member": {
                                "id": 372,
                                "member_event_info_id": 387,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 373,
                                "display_name": "黃梓烔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "盧咏彤",
                            "phone_num": "",
                            "member": {
                                "id": 435,
                                "member_event_info_id": 450,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 436,
                                "display_name": "盧咏彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "柳彥彤",
                            "phone_num": "",
                            "member": {
                                "id": 452,
                                "member_event_info_id": 467,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 453,
                                "display_name": "柳彥彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "陳峻熙",
                            "phone_num": "",
                            "member": {
                                "id": 467,
                                "member_event_info_id": 482,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 468,
                                "display_name": "陳峻熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "羅卓茲",
                            "phone_num": "",
                            "member": {
                                "id": 474,
                                "member_event_info_id": 489,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 475,
                                "display_name": "羅卓茲",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "李明恩",
                            "phone_num": "",
                            "member": {
                                "id": 559,
                                "member_event_info_id": 574,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 560,
                                "display_name": "李明恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "黃俊豪",
                            "phone_num": "",
                            "member": {
                                "id": 568,
                                "member_event_info_id": 583,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 569,
                                "display_name": "黃俊豪",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "石銘顥",
                            "phone_num": "",
                            "member": {
                                "id": 681,
                                "member_event_info_id": 696,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 682,
                                "display_name": "石銘顥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "譚鈺鏵",
                            "phone_num": "",
                            "member": {
                                "id": 760,
                                "member_event_info_id": 775,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 761,
                                "display_name": "譚鈺鏵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "黃奕僖",
                            "phone_num": "",
                            "member": {
                                "id": 761,
                                "member_event_info_id": 776,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 762,
                                "display_name": "黃奕僖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "陳駿杰",
                            "phone_num": "",
                            "member": {
                                "id": 853,
                                "member_event_info_id": 868,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 854,
                                "display_name": "陳駿杰",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "彭彥怡",
                            "phone_num": "",
                            "member": {
                                "id": 927,
                                "member_event_info_id": 942,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 928,
                                "display_name": "彭彥怡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "叶梓銘",
                            "phone_num": "",
                            "member": {
                                "id": 968,
                                "member_event_info_id": 983,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 969,
                                "display_name": "叶梓銘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "黃堯棟",
                            "phone_num": "",
                            "member": {
                                "id": 980,
                                "member_event_info_id": 995,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 981,
                                "display_name": "黃堯棟",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "彭楷善",
                            "phone_num": "",
                            "member": {
                                "id": 1016,
                                "member_event_info_id": 1031,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1017,
                                "display_name": "彭楷善",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "凌彩橋",
                            "phone_num": "",
                            "member": {
                                "id": 1082,
                                "member_event_info_id": 1097,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1083,
                                "display_name": "凌彩橋",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "譚政熙",
                            "phone_num": "",
                            "member": {
                                "id": 1084,
                                "member_event_info_id": 1099,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1085,
                                "display_name": "譚政熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "李躒",
                            "phone_num": "",
                            "member": {
                                "id": 1202,
                                "member_event_info_id": 106,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 92,
                                "display_name": "李躒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "鄧詩澄",
                            "phone_num": "",
                            "member": {
                                "id": 1208,
                                "member_event_info_id": 112,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 98,
                                "display_name": "鄧詩澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "麥凱惠",
                            "phone_num": "",
                            "member": {
                                "id": 1251,
                                "member_event_info_id": 155,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 141,
                                "display_name": "麥凱惠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "梁倩嫣",
                            "phone_num": "",
                            "member": {
                                "id": 1325,
                                "member_event_info_id": 229,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 215,
                                "display_name": "梁倩嫣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "鄭博文",
                            "phone_num": "",
                            "member": {
                                "id": 1340,
                                "member_event_info_id": 244,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 230,
                                "display_name": "鄭博文",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "莊鎧澄",
                            "phone_num": "",
                            "member": {
                                "id": 1362,
                                "member_event_info_id": 266,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 252,
                                "display_name": "莊鎧澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "賴靖潼",
                            "phone_num": "",
                            "member": {
                                "id": 1364,
                                "member_event_info_id": 268,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 254,
                                "display_name": "賴靖潼",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "黃辰萱",
                            "phone_num": "",
                            "member": {
                                "id": 1506,
                                "member_event_info_id": 410,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 396,
                                "display_name": "黃辰萱",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "馮文蕎",
                            "phone_num": "",
                            "member": {
                                "id": 1601,
                                "member_event_info_id": 505,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 491,
                                "display_name": "馮文蕎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "鄭梓驄",
                            "phone_num": "",
                            "member": {
                                "id": 1689,
                                "member_event_info_id": 593,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 579,
                                "display_name": "鄭梓驄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "關永祥",
                            "phone_num": "",
                            "member": {
                                "id": 1902,
                                "member_event_info_id": 806,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 792,
                                "display_name": "關永祥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "劉芊彤",
                            "phone_num": "",
                            "member": {
                                "id": 1906,
                                "member_event_info_id": 810,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 796,
                                "display_name": "劉芊彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "李卓霖",
                            "phone_num": "",
                            "member": {
                                "id": 1975,
                                "member_event_info_id": 879,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 865,
                                "display_name": "李卓霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "陳映臻",
                            "phone_num": "",
                            "member": {
                                "id": 1985,
                                "member_event_info_id": 889,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 875,
                                "display_name": "陳映臻",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "高穎䤭",
                            "phone_num": "",
                            "member": {
                                "id": 1993,
                                "member_event_info_id": 897,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 883,
                                "display_name": "高穎䤭",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "侯卓伶",
                            "phone_num": "",
                            "member": {
                                "id": 2027,
                                "member_event_info_id": 931,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 917,
                                "display_name": "侯卓伶",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "李銳熙",
                            "phone_num": "",
                            "member": {
                                "id": 2084,
                                "member_event_info_id": 988,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 974,
                                "display_name": "李銳熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "王智瑤",
                            "phone_num": "",
                            "member": {
                                "id": 2090,
                                "member_event_info_id": 994,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 980,
                                "display_name": "王智瑤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "莫梓維",
                            "phone_num": "",
                            "member": {
                                "id": 2211,
                                "member_event_info_id": 1115,
                                "skill_code": "football",
                                "skill_score": 47,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1101,
                                "display_name": "莫梓維",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "10"
                            }
                        }
                    ]
                },
                {
                    "rank": 4,
                    "unit": "秒",
                    "score": 46,
                    "members": [
                        {
                            "name": "何柏熹",
                            "phone_num": "",
                            "member": {
                                "id": 62,
                                "member_event_info_id": 77,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 63,
                                "display_name": "何柏熹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "鄧懿涵",
                            "phone_num": "",
                            "member": {
                                "id": 145,
                                "member_event_info_id": 160,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 146,
                                "display_name": "鄧懿涵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "王竣昇",
                            "phone_num": "",
                            "member": {
                                "id": 147,
                                "member_event_info_id": 162,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 148,
                                "display_name": "王竣昇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "關俊希",
                            "phone_num": "",
                            "member": {
                                "id": 204,
                                "member_event_info_id": 219,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 205,
                                "display_name": "關俊希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "鄭芷曈",
                            "phone_num": "",
                            "member": {
                                "id": 336,
                                "member_event_info_id": 351,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 337,
                                "display_name": "鄭芷曈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "黃鋑霆",
                            "phone_num": "",
                            "member": {
                                "id": 416,
                                "member_event_info_id": 431,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 417,
                                "display_name": "黃鋑霆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "叶梓銘",
                            "phone_num": "",
                            "member": {
                                "id": 429,
                                "member_event_info_id": 444,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 430,
                                "display_name": "叶梓銘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "羅子友",
                            "phone_num": "",
                            "member": {
                                "id": 453,
                                "member_event_info_id": 468,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 454,
                                "display_name": "羅子友",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "金冕旒",
                            "phone_num": "",
                            "member": {
                                "id": 491,
                                "member_event_info_id": 506,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 492,
                                "display_name": "金冕旒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "吳嘉麟",
                            "phone_num": "",
                            "member": {
                                "id": 521,
                                "member_event_info_id": 536,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 522,
                                "display_name": "吳嘉麟",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "劉靖晞",
                            "phone_num": "",
                            "member": {
                                "id": 557,
                                "member_event_info_id": 572,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 558,
                                "display_name": "劉靖晞",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "區國棟",
                            "phone_num": "",
                            "member": {
                                "id": 563,
                                "member_event_info_id": 578,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 564,
                                "display_name": "區國棟",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "許哲弘",
                            "phone_num": "",
                            "member": {
                                "id": 627,
                                "member_event_info_id": 642,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 628,
                                "display_name": "許哲弘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "袁子晉",
                            "phone_num": "",
                            "member": {
                                "id": 664,
                                "member_event_info_id": 679,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 665,
                                "display_name": "袁子晉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "關俊希",
                            "phone_num": "",
                            "member": {
                                "id": 743,
                                "member_event_info_id": 758,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 744,
                                "display_name": "關俊希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "鄭博文",
                            "phone_num": "",
                            "member": {
                                "id": 768,
                                "member_event_info_id": 783,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 769,
                                "display_name": "鄭博文",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "温天玥",
                            "phone_num": "",
                            "member": {
                                "id": 1043,
                                "member_event_info_id": 1058,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1044,
                                "display_name": "温天玥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "李祈昱",
                            "phone_num": "",
                            "member": {
                                "id": 1081,
                                "member_event_info_id": 1096,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1082,
                                "display_name": "李祈昱",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "方芷盈",
                            "phone_num": "",
                            "member": {
                                "id": 1093,
                                "member_event_info_id": 1108,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1094,
                                "display_name": "方芷盈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "盧兆祖",
                            "phone_num": "",
                            "member": {
                                "id": 1120,
                                "member_event_info_id": 27,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 10,
                                "display_name": "盧兆祖",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1B",
                                "group_num": "21010Y"
                            }
                        },
                        {
                            "name": "呂澄",
                            "phone_num": "",
                            "member": {
                                "id": 1179,
                                "member_event_info_id": 83,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 69,
                                "display_name": "呂澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "蔡信霖",
                            "phone_num": "",
                            "member": {
                                "id": 1196,
                                "member_event_info_id": 100,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 86,
                                "display_name": "蔡信霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "梁芯宜",
                            "phone_num": "",
                            "member": {
                                "id": 1224,
                                "member_event_info_id": 128,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 114,
                                "display_name": "梁芯宜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "王佩婷",
                            "phone_num": "",
                            "member": {
                                "id": 1303,
                                "member_event_info_id": 207,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 193,
                                "display_name": "王佩婷",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "麥焯嵐",
                            "phone_num": "",
                            "member": {
                                "id": 1439,
                                "member_event_info_id": 343,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 329,
                                "display_name": "麥焯嵐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "饒浩曦",
                            "phone_num": "",
                            "member": {
                                "id": 1465,
                                "member_event_info_id": 369,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 355,
                                "display_name": "饒浩曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "馬靖頤",
                            "phone_num": "",
                            "member": {
                                "id": 1496,
                                "member_event_info_id": 400,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 386,
                                "display_name": "馬靖頤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "唐日翹",
                            "phone_num": "",
                            "member": {
                                "id": 1501,
                                "member_event_info_id": 405,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 391,
                                "display_name": "唐日翹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "王智瑤",
                            "phone_num": "",
                            "member": {
                                "id": 1551,
                                "member_event_info_id": 455,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 441,
                                "display_name": "王智瑤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "黃薏舒",
                            "phone_num": "",
                            "member": {
                                "id": 1822,
                                "member_event_info_id": 726,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 712,
                                "display_name": "黃薏舒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "吳旻珊",
                            "phone_num": "",
                            "member": {
                                "id": 1931,
                                "member_event_info_id": 835,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 821,
                                "display_name": "吳旻珊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "鄭建諾",
                            "phone_num": "",
                            "member": {
                                "id": 2096,
                                "member_event_info_id": 1000,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 986,
                                "display_name": "鄭建諾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "葉采怡",
                            "phone_num": "",
                            "member": {
                                "id": 2180,
                                "member_event_info_id": 1084,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1070,
                                "display_name": "葉采怡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "周約恒",
                            "phone_num": "",
                            "member": {
                                "id": 2184,
                                "member_event_info_id": 1088,
                                "skill_code": "football",
                                "skill_score": 46,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1074,
                                "display_name": "周約恒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "4"
                            }
                        }
                    ]
                },
                {
                    "rank": 5,
                    "unit": "秒",
                    "score": 45,
                    "members": [
                        {
                            "name": "劉宛曈",
                            "phone_num": "",
                            "member": {
                                "id": 47,
                                "member_event_info_id": 62,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 48,
                                "display_name": "劉宛曈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "文匡正",
                            "phone_num": "",
                            "member": {
                                "id": 95,
                                "member_event_info_id": 110,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 96,
                                "display_name": "文匡正",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "梁禮博",
                            "phone_num": "",
                            "member": {
                                "id": 112,
                                "member_event_info_id": 127,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 113,
                                "display_name": "梁禮博",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "袁嘉謙",
                            "phone_num": "",
                            "member": {
                                "id": 124,
                                "member_event_info_id": 139,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 125,
                                "display_name": "袁嘉謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "何浩然",
                            "phone_num": "",
                            "member": {
                                "id": 129,
                                "member_event_info_id": 144,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 130,
                                "display_name": "何浩然",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "吳俊霆",
                            "phone_num": "",
                            "member": {
                                "id": 166,
                                "member_event_info_id": 181,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 167,
                                "display_name": "吳俊霆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "袁曦朗",
                            "phone_num": "",
                            "member": {
                                "id": 199,
                                "member_event_info_id": 214,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 200,
                                "display_name": "袁曦朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "24"
                            }
                        },
                        {
                            "name": "蔡昀澔",
                            "phone_num": "",
                            "member": {
                                "id": 261,
                                "member_event_info_id": 276,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 262,
                                "display_name": "蔡昀澔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "謝佩瑩",
                            "phone_num": "",
                            "member": {
                                "id": 332,
                                "member_event_info_id": 347,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 333,
                                "display_name": "謝佩瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "藍琬晴",
                            "phone_num": "",
                            "member": {
                                "id": 361,
                                "member_event_info_id": 376,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 362,
                                "display_name": "藍琬晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "鄧栩諾",
                            "phone_num": "",
                            "member": {
                                "id": 389,
                                "member_event_info_id": 404,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 390,
                                "display_name": "鄧栩諾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "黃愷晴",
                            "phone_num": "",
                            "member": {
                                "id": 394,
                                "member_event_info_id": 409,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 395,
                                "display_name": "黃愷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "林文昊",
                            "phone_num": "",
                            "member": {
                                "id": 406,
                                "member_event_info_id": 421,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 407,
                                "display_name": "林文昊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "鄭建諾",
                            "phone_num": "",
                            "member": {
                                "id": 446,
                                "member_event_info_id": 461,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 447,
                                "display_name": "鄭建諾",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "伍海慈",
                            "phone_num": "",
                            "member": {
                                "id": 499,
                                "member_event_info_id": 514,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 500,
                                "display_name": "伍海慈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "黃子晴",
                            "phone_num": "",
                            "member": {
                                "id": 733,
                                "member_event_info_id": 748,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 734,
                                "display_name": "黃子晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "陳心柔",
                            "phone_num": "",
                            "member": {
                                "id": 767,
                                "member_event_info_id": 782,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 768,
                                "display_name": "陳心柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "張俊彥",
                            "phone_num": "",
                            "member": {
                                "id": 876,
                                "member_event_info_id": 891,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 877,
                                "display_name": "張俊彥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "黃晞朗",
                            "phone_num": "",
                            "member": {
                                "id": 890,
                                "member_event_info_id": 905,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 891,
                                "display_name": "黃晞朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "張靖研",
                            "phone_num": "",
                            "member": {
                                "id": 1003,
                                "member_event_info_id": 1018,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1004,
                                "display_name": "張靖研",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "田頌禮",
                            "phone_num": "",
                            "member": {
                                "id": 1106,
                                "member_event_info_id": 1121,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1107,
                                "display_name": "田頌禮",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "蘇佩悠",
                            "phone_num": "",
                            "member": {
                                "id": 1164,
                                "member_event_info_id": 68,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 54,
                                "display_name": "蘇佩悠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "朱晉霆",
                            "phone_num": "",
                            "member": {
                                "id": 1172,
                                "member_event_info_id": 76,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 62,
                                "display_name": "朱晉霆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "劉比",
                            "phone_num": "",
                            "member": {
                                "id": 1175,
                                "member_event_info_id": 79,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 65,
                                "display_name": "劉比",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "姚穎熹",
                            "phone_num": "",
                            "member": {
                                "id": 1286,
                                "member_event_info_id": 190,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 176,
                                "display_name": "姚穎熹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "25"
                            }
                        },
                        {
                            "name": "練鎧億",
                            "phone_num": "",
                            "member": {
                                "id": 1298,
                                "member_event_info_id": 202,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 188,
                                "display_name": "練鎧億",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "林智奇",
                            "phone_num": "",
                            "member": {
                                "id": 1344,
                                "member_event_info_id": 248,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 234,
                                "display_name": "林智奇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "張軒銘",
                            "phone_num": "",
                            "member": {
                                "id": 1378,
                                "member_event_info_id": 282,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 268,
                                "display_name": "張軒銘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "林凱妮",
                            "phone_num": "",
                            "member": {
                                "id": 1471,
                                "member_event_info_id": 375,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 361,
                                "display_name": "林凱妮",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "潘啓樂",
                            "phone_num": "",
                            "member": {
                                "id": 1498,
                                "member_event_info_id": 402,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 388,
                                "display_name": "潘啓樂",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "劉佳瑩",
                            "phone_num": "",
                            "member": {
                                "id": 1584,
                                "member_event_info_id": 488,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 474,
                                "display_name": "劉佳瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "袁嘉謙",
                            "phone_num": "",
                            "member": {
                                "id": 1774,
                                "member_event_info_id": 678,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 664,
                                "display_name": "袁嘉謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "黎思楷",
                            "phone_num": "",
                            "member": {
                                "id": 1789,
                                "member_event_info_id": 693,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 679,
                                "display_name": "黎思楷",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "張詠禎",
                            "phone_num": "",
                            "member": {
                                "id": 1852,
                                "member_event_info_id": 756,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 742,
                                "display_name": "張詠禎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "蘇善恩",
                            "phone_num": "",
                            "member": {
                                "id": 1890,
                                "member_event_info_id": 794,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 780,
                                "display_name": "蘇善恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "蘇毅希",
                            "phone_num": "",
                            "member": {
                                "id": 1933,
                                "member_event_info_id": 837,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 823,
                                "display_name": "蘇毅希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "施珀暘",
                            "phone_num": "",
                            "member": {
                                "id": 2018,
                                "member_event_info_id": 922,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 908,
                                "display_name": "施珀暘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "胡逸嵐",
                            "phone_num": "",
                            "member": {
                                "id": 2092,
                                "member_event_info_id": 996,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 982,
                                "display_name": "胡逸嵐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "尹玥甯",
                            "phone_num": "",
                            "member": {
                                "id": 2129,
                                "member_event_info_id": 1033,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1019,
                                "display_name": "尹玥甯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "金冕旒",
                            "phone_num": "",
                            "member": {
                                "id": 2141,
                                "member_event_info_id": 1045,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1031,
                                "display_name": "金冕旒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "朱晉緯",
                            "phone_num": "",
                            "member": {
                                "id": 2163,
                                "member_event_info_id": 1067,
                                "skill_code": "football",
                                "skill_score": 45,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1053,
                                "display_name": "朱晉緯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "6"
                            }
                        }
                    ]
                },
                {
                    "rank": 6,
                    "unit": "秒",
                    "score": 44,
                    "members": [
                        {
                            "name": "陳柏霖",
                            "phone_num": "",
                            "member": {
                                "id": 144,
                                "member_event_info_id": 159,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 145,
                                "display_name": "陳柏霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "林礎垣",
                            "phone_num": "",
                            "member": {
                                "id": 272,
                                "member_event_info_id": 287,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 273,
                                "display_name": "林礎垣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "周梓朗",
                            "phone_num": "",
                            "member": {
                                "id": 320,
                                "member_event_info_id": 335,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 321,
                                "display_name": "周梓朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "吳彥希",
                            "phone_num": "",
                            "member": {
                                "id": 330,
                                "member_event_info_id": 345,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 331,
                                "display_name": "吳彥希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "陳思衡",
                            "phone_num": "",
                            "member": {
                                "id": 422,
                                "member_event_info_id": 437,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 423,
                                "display_name": "陳思衡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "鍾柏翹",
                            "phone_num": "",
                            "member": {
                                "id": 447,
                                "member_event_info_id": 462,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 448,
                                "display_name": "鍾柏翹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "張進軒",
                            "phone_num": "",
                            "member": {
                                "id": 487,
                                "member_event_info_id": 502,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 488,
                                "display_name": "張進軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "鄒諾希",
                            "phone_num": "",
                            "member": {
                                "id": 579,
                                "member_event_info_id": 594,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 580,
                                "display_name": "鄒諾希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "劉宛曈",
                            "phone_num": "",
                            "member": {
                                "id": 586,
                                "member_event_info_id": 601,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 587,
                                "display_name": "劉宛曈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "何柏熹",
                            "phone_num": "",
                            "member": {
                                "id": 601,
                                "member_event_info_id": 616,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 602,
                                "display_name": "何柏熹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "林梓澄",
                            "phone_num": "",
                            "member": {
                                "id": 602,
                                "member_event_info_id": 617,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 603,
                                "display_name": "林梓澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "劉彥希",
                            "phone_num": "",
                            "member": {
                                "id": 629,
                                "member_event_info_id": 644,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 630,
                                "display_name": "劉彥希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "陳欣淇",
                            "phone_num": "",
                            "member": {
                                "id": 643,
                                "member_event_info_id": 658,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 644,
                                "display_name": "陳欣淇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "鄧雅琳",
                            "phone_num": "",
                            "member": {
                                "id": 708,
                                "member_event_info_id": 723,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 709,
                                "display_name": "鄧雅琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "曾子琪",
                            "phone_num": "",
                            "member": {
                                "id": 786,
                                "member_event_info_id": 801,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 787,
                                "display_name": "曾子琪",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "呂蕙晴",
                            "phone_num": "",
                            "member": {
                                "id": 818,
                                "member_event_info_id": 833,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 819,
                                "display_name": "呂蕙晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "馮雪心",
                            "phone_num": "",
                            "member": {
                                "id": 881,
                                "member_event_info_id": 896,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 882,
                                "display_name": "馮雪心",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "張鈞瑜",
                            "phone_num": "",
                            "member": {
                                "id": 940,
                                "member_event_info_id": 955,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 941,
                                "display_name": "張鈞瑜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "廖偲蓓",
                            "phone_num": "",
                            "member": {
                                "id": 947,
                                "member_event_info_id": 962,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 948,
                                "display_name": "廖偲蓓",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "關俊龍",
                            "phone_num": "",
                            "member": {
                                "id": 988,
                                "member_event_info_id": 1003,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 989,
                                "display_name": "關俊龍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "陳聞浩",
                            "phone_num": "",
                            "member": {
                                "id": 1049,
                                "member_event_info_id": 1064,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1050,
                                "display_name": "陳聞浩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "林煒喬",
                            "phone_num": "",
                            "member": {
                                "id": 1055,
                                "member_event_info_id": 1070,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1056,
                                "display_name": "林煒喬",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "鍾旨麒",
                            "phone_num": "",
                            "member": {
                                "id": 1133,
                                "member_event_info_id": 37,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 23,
                                "display_name": "鍾旨麒",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1A",
                                "group_num": "21002Y"
                            }
                        },
                        {
                            "name": "林晞桐",
                            "phone_num": "",
                            "member": {
                                "id": 1156,
                                "member_event_info_id": 60,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 46,
                                "display_name": "林晞桐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "謝藹琳",
                            "phone_num": "",
                            "member": {
                                "id": 1257,
                                "member_event_info_id": 161,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 147,
                                "display_name": "謝藹琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "張曦綸",
                            "phone_num": "",
                            "member": {
                                "id": 1290,
                                "member_event_info_id": 194,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 180,
                                "display_name": "張曦綸",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "梁芷晴",
                            "phone_num": "",
                            "member": {
                                "id": 1435,
                                "member_event_info_id": 339,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 325,
                                "display_name": "梁芷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "何允恩",
                            "phone_num": "",
                            "member": {
                                "id": 1468,
                                "member_event_info_id": 372,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 358,
                                "display_name": "何允恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "鄧柏豪",
                            "phone_num": "",
                            "member": {
                                "id": 1525,
                                "member_event_info_id": 429,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 415,
                                "display_name": "鄧柏豪",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "陳襄",
                            "phone_num": "",
                            "member": {
                                "id": 1556,
                                "member_event_info_id": 460,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 446,
                                "display_name": "陳襄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "馮梓珊",
                            "phone_num": "",
                            "member": {
                                "id": 1559,
                                "member_event_info_id": 463,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 449,
                                "display_name": "馮梓珊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "莊晧天",
                            "phone_num": "",
                            "member": {
                                "id": 1755,
                                "member_event_info_id": 659,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 645,
                                "display_name": "莊晧天",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "羅子浩",
                            "phone_num": "",
                            "member": {
                                "id": 1927,
                                "member_event_info_id": 831,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 817,
                                "display_name": "羅子浩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "尹依然",
                            "phone_num": "",
                            "member": {
                                "id": 2043,
                                "member_event_info_id": 947,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 933,
                                "display_name": "尹依然",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "黎晞琳",
                            "phone_num": "",
                            "member": {
                                "id": 2119,
                                "member_event_info_id": 1023,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1009,
                                "display_name": "黎晞琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "羅家蔚",
                            "phone_num": "",
                            "member": {
                                "id": 2169,
                                "member_event_info_id": 1073,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1059,
                                "display_name": "羅家蔚",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "鄧心悅",
                            "phone_num": "",
                            "member": {
                                "id": 2173,
                                "member_event_info_id": 1077,
                                "skill_code": "football",
                                "skill_score": 44,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1063,
                                "display_name": "鄧心悅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "16"
                            }
                        }
                    ]
                },
                {
                    "rank": 7,
                    "unit": "秒",
                    "score": 43,
                    "members": [
                        {
                            "name": "范嫝娜",
                            "phone_num": "",
                            "member": {
                                "id": 180,
                                "member_event_info_id": 195,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 181,
                                "display_name": "范嫝娜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "楊沁洹",
                            "phone_num": "",
                            "member": {
                                "id": 312,
                                "member_event_info_id": 327,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 313,
                                "display_name": "楊沁洹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "盧芷穎",
                            "phone_num": "",
                            "member": {
                                "id": 497,
                                "member_event_info_id": 512,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 498,
                                "display_name": "盧芷穎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "黃逸寧",
                            "phone_num": "",
                            "member": {
                                "id": 735,
                                "member_event_info_id": 750,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 736,
                                "display_name": "黃逸寧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "吳心柔",
                            "phone_num": "",
                            "member": {
                                "id": 756,
                                "member_event_info_id": 771,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 757,
                                "display_name": "吳心柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "曾彥翰",
                            "phone_num": "",
                            "member": {
                                "id": 782,
                                "member_event_info_id": 797,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 783,
                                "display_name": "曾彥翰",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "楊沁洹",
                            "phone_num": "",
                            "member": {
                                "id": 851,
                                "member_event_info_id": 866,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 852,
                                "display_name": "楊沁洹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "麥瀠之",
                            "phone_num": "",
                            "member": {
                                "id": 948,
                                "member_event_info_id": 963,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 949,
                                "display_name": "麥瀠之",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "吳嘉麟",
                            "phone_num": "",
                            "member": {
                                "id": 1060,
                                "member_event_info_id": 1075,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1061,
                                "display_name": "吳嘉麟",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "毛旭文",
                            "phone_num": "",
                            "member": {
                                "id": 1121,
                                "member_event_info_id": 28,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 11,
                                "display_name": "毛旭文",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1B",
                                "group_num": "21037R"
                            }
                        },
                        {
                            "name": "陳欣淇",
                            "phone_num": "",
                            "member": {
                                "id": 1215,
                                "member_event_info_id": 119,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 105,
                                "display_name": "陳欣淇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "李帕庭",
                            "phone_num": "",
                            "member": {
                                "id": 1270,
                                "member_event_info_id": 174,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 160,
                                "display_name": "李帕庭",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "沈靖堯",
                            "phone_num": "",
                            "member": {
                                "id": 1300,
                                "member_event_info_id": 204,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 190,
                                "display_name": "沈靖堯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "黃海燁",
                            "phone_num": "",
                            "member": {
                                "id": 1304,
                                "member_event_info_id": 208,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 194,
                                "display_name": "黃海燁",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "羅卓莉",
                            "phone_num": "",
                            "member": {
                                "id": 1347,
                                "member_event_info_id": 251,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 237,
                                "display_name": "羅卓莉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "呂文曦",
                            "phone_num": "",
                            "member": {
                                "id": 1416,
                                "member_event_info_id": 320,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 306,
                                "display_name": "呂文曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "陳國豪",
                            "phone_num": "",
                            "member": {
                                "id": 1487,
                                "member_event_info_id": 391,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 377,
                                "display_name": "陳國豪",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "黃婉晴",
                            "phone_num": "",
                            "member": {
                                "id": 1529,
                                "member_event_info_id": 433,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 419,
                                "display_name": "黃婉晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "曾駿文",
                            "phone_num": "",
                            "member": {
                                "id": 1550,
                                "member_event_info_id": 454,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 440,
                                "display_name": "曾駿文",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "鄭瑋瑜",
                            "phone_num": "",
                            "member": {
                                "id": 1644,
                                "member_event_info_id": 548,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 534,
                                "display_name": "鄭瑋瑜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "陳嘉珈",
                            "phone_num": "",
                            "member": {
                                "id": 1686,
                                "member_event_info_id": 590,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 576,
                                "display_name": "陳嘉珈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "林泓睿",
                            "phone_num": "",
                            "member": {
                                "id": 1696,
                                "member_event_info_id": 600,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 586,
                                "display_name": "林泓睿",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "張海約",
                            "phone_num": "",
                            "member": {
                                "id": 1734,
                                "member_event_info_id": 638,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 624,
                                "display_name": "張海約",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "邱靖曦",
                            "phone_num": "",
                            "member": {
                                "id": 1773,
                                "member_event_info_id": 677,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 663,
                                "display_name": "邱靖曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "盧心穎",
                            "phone_num": "",
                            "member": {
                                "id": 1865,
                                "member_event_info_id": 769,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 755,
                                "display_name": "盧心穎",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "陳國豪",
                            "phone_num": "",
                            "member": {
                                "id": 2026,
                                "member_event_info_id": 930,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 916,
                                "display_name": "陳國豪",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "莫晙顥",
                            "phone_num": "",
                            "member": {
                                "id": 2036,
                                "member_event_info_id": 940,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 926,
                                "display_name": "莫晙顥",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "陳雅倩",
                            "phone_num": "",
                            "member": {
                                "id": 2048,
                                "member_event_info_id": 952,
                                "skill_code": "football",
                                "skill_score": 43,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 938,
                                "display_name": "陳雅倩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "1"
                            }
                        }
                    ]
                },
                {
                    "rank": 8,
                    "unit": "秒",
                    "score": 42,
                    "members": [
                        {
                            "name": "胡梓茵",
                            "phone_num": "",
                            "member": {
                                "id": 16,
                                "member_event_info_id": 33,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 17,
                                "display_name": "胡梓茵",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1C",
                                "group_num": "21007B"
                            }
                        },
                        {
                            "name": "勞宇鍵",
                            "phone_num": "",
                            "member": {
                                "id": 49,
                                "member_event_info_id": 64,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 50,
                                "display_name": "勞宇鍵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "林梓澄",
                            "phone_num": "",
                            "member": {
                                "id": 63,
                                "member_event_info_id": 78,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 64,
                                "display_name": "林梓澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "嚴峻熙",
                            "phone_num": "",
                            "member": {
                                "id": 76,
                                "member_event_info_id": 91,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 77,
                                "display_name": "嚴峻熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "李桉瑜",
                            "phone_num": "",
                            "member": {
                                "id": 115,
                                "member_event_info_id": 130,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 116,
                                "display_name": "李桉瑜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "潘梓朗",
                            "phone_num": "",
                            "member": {
                                "id": 119,
                                "member_event_info_id": 134,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 120,
                                "display_name": "潘梓朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "蕭日言",
                            "phone_num": "",
                            "member": {
                                "id": 143,
                                "member_event_info_id": 158,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 144,
                                "display_name": "蕭日言",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "鄧雅琳",
                            "phone_num": "",
                            "member": {
                                "id": 169,
                                "member_event_info_id": 184,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 170,
                                "display_name": "鄧雅琳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "劉安霖",
                            "phone_num": "",
                            "member": {
                                "id": 185,
                                "member_event_info_id": 200,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 186,
                                "display_name": "劉安霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "鄒芷澄",
                            "phone_num": "",
                            "member": {
                                "id": 299,
                                "member_event_info_id": 314,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 300,
                                "display_name": "鄒芷澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "鄭澄浩",
                            "phone_num": "",
                            "member": {
                                "id": 318,
                                "member_event_info_id": 333,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 319,
                                "display_name": "鄭澄浩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "陳子昊",
                            "phone_num": "",
                            "member": {
                                "id": 423,
                                "member_event_info_id": 438,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 424,
                                "display_name": "陳子昊",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "蔡芷晴",
                            "phone_num": "",
                            "member": {
                                "id": 489,
                                "member_event_info_id": 504,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 490,
                                "display_name": "蔡芷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "凌彩橋",
                            "phone_num": "",
                            "member": {
                                "id": 543,
                                "member_event_info_id": 558,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 544,
                                "display_name": "凌彩橋",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "黃津澤",
                            "phone_num": "",
                            "member": {
                                "id": 546,
                                "member_event_info_id": 561,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 547,
                                "display_name": "黃津澤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "陳歡欣",
                            "phone_num": "",
                            "member": {
                                "id": 620,
                                "member_event_info_id": 635,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 621,
                                "display_name": "陳歡欣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "劉峻宇",
                            "phone_num": "",
                            "member": {
                                "id": 676,
                                "member_event_info_id": 691,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 677,
                                "display_name": "劉峻宇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "梁嘉敏",
                            "phone_num": "",
                            "member": {
                                "id": 702,
                                "member_event_info_id": 717,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 703,
                                "display_name": "梁嘉敏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "梁梓釗",
                            "phone_num": "",
                            "member": {
                                "id": 703,
                                "member_event_info_id": 718,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 704,
                                "display_name": "梁梓釗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "翁梓浩",
                            "phone_num": "",
                            "member": {
                                "id": 739,
                                "member_event_info_id": 754,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 740,
                                "display_name": "翁梓浩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "25"
                            }
                        },
                        {
                            "name": "陳倩瑩",
                            "phone_num": "",
                            "member": {
                                "id": 834,
                                "member_event_info_id": 849,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 835,
                                "display_name": "陳倩瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "梁詠絲",
                            "phone_num": "",
                            "member": {
                                "id": 843,
                                "member_event_info_id": 858,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 844,
                                "display_name": "梁詠絲",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "高芷瑩",
                            "phone_num": "",
                            "member": {
                                "id": 917,
                                "member_event_info_id": 932,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 918,
                                "display_name": "高芷瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "陳峻熙",
                            "phone_num": "",
                            "member": {
                                "id": 1006,
                                "member_event_info_id": 1021,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1007,
                                "display_name": "陳峻熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "杜上文",
                            "phone_num": "",
                            "member": {
                                "id": 1209,
                                "member_event_info_id": 113,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 99,
                                "display_name": "杜上文",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "馮文深",
                            "phone_num": "",
                            "member": {
                                "id": 1239,
                                "member_event_info_id": 143,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 129,
                                "display_name": "馮文深",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "何寧莉",
                            "phone_num": "",
                            "member": {
                                "id": 1241,
                                "member_event_info_id": 145,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 131,
                                "display_name": "何寧莉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "陳心柔",
                            "phone_num": "",
                            "member": {
                                "id": 1339,
                                "member_event_info_id": 243,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 229,
                                "display_name": "陳心柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "陳怡君",
                            "phone_num": "",
                            "member": {
                                "id": 1360,
                                "member_event_info_id": 264,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 250,
                                "display_name": "陳怡君",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "陳茈榆",
                            "phone_num": "",
                            "member": {
                                "id": 1380,
                                "member_event_info_id": 284,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 270,
                                "display_name": "陳茈榆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "莊卓楓",
                            "phone_num": "",
                            "member": {
                                "id": 1450,
                                "member_event_info_id": 354,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 340,
                                "display_name": "莊卓楓",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "麥瀠之",
                            "phone_num": "",
                            "member": {
                                "id": 1520,
                                "member_event_info_id": 424,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 410,
                                "display_name": "麥瀠之",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "文泳晴",
                            "phone_num": "",
                            "member": {
                                "id": 1521,
                                "member_event_info_id": 425,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 411,
                                "display_name": "文泳晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "冼俊康",
                            "phone_num": "",
                            "member": {
                                "id": 1523,
                                "member_event_info_id": 427,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 413,
                                "display_name": "冼俊康",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "林泓竣",
                            "phone_num": "",
                            "member": {
                                "id": 1542,
                                "member_event_info_id": 446,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 432,
                                "display_name": "林泓竣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "郭芷熒",
                            "phone_num": "",
                            "member": {
                                "id": 1649,
                                "member_event_info_id": 553,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 539,
                                "display_name": "郭芷熒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "陳彩兒",
                            "phone_num": "",
                            "member": {
                                "id": 1684,
                                "member_event_info_id": 588,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 574,
                                "display_name": "陳彩兒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "梁博聞",
                            "phone_num": "",
                            "member": {
                                "id": 1698,
                                "member_event_info_id": 602,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 588,
                                "display_name": "梁博聞",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "黃楚霖",
                            "phone_num": "",
                            "member": {
                                "id": 1723,
                                "member_event_info_id": 627,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 613,
                                "display_name": "黃楚霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "梁力仁",
                            "phone_num": "",
                            "member": {
                                "id": 1742,
                                "member_event_info_id": 646,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 632,
                                "display_name": "梁力仁",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "鄧懿涵",
                            "phone_num": "",
                            "member": {
                                "id": 1795,
                                "member_event_info_id": 699,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 685,
                                "display_name": "鄧懿涵",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "梁芷晴",
                            "phone_num": "",
                            "member": {
                                "id": 1974,
                                "member_event_info_id": 878,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 864,
                                "display_name": "梁芷晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "潘啓樂",
                            "phone_num": "",
                            "member": {
                                "id": 2037,
                                "member_event_info_id": 941,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 927,
                                "display_name": "潘啓樂",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "張栩樂",
                            "phone_num": "",
                            "member": {
                                "id": 2050,
                                "member_event_info_id": 954,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 940,
                                "display_name": "張栩樂",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "3"
                            }
                        },
                        {
                            "name": "林衍宏",
                            "phone_num": "",
                            "member": {
                                "id": 2055,
                                "member_event_info_id": 959,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 945,
                                "display_name": "林衍宏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "冼俊康",
                            "phone_num": "",
                            "member": {
                                "id": 2062,
                                "member_event_info_id": 966,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 952,
                                "display_name": "冼俊康",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "鄧綽賢",
                            "phone_num": "",
                            "member": {
                                "id": 2063,
                                "member_event_info_id": 967,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 953,
                                "display_name": "鄧綽賢",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "陳襄",
                            "phone_num": "",
                            "member": {
                                "id": 2095,
                                "member_event_info_id": 999,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 985,
                                "display_name": "陳襄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "黃詩晴",
                            "phone_num": "",
                            "member": {
                                "id": 2175,
                                "member_event_info_id": 1079,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1065,
                                "display_name": "黃詩晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "陳熙予",
                            "phone_num": "",
                            "member": {
                                "id": 2181,
                                "member_event_info_id": 1085,
                                "skill_code": "football",
                                "skill_score": 42,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1071,
                                "display_name": "陳熙予",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "1"
                            }
                        }
                    ]
                },
                {
                    "rank": 9,
                    "unit": "秒",
                    "score": 41,
                    "members": [
                        {
                            "name": "甄鎬翹",
                            "phone_num": "",
                            "member": {
                                "id": 78,
                                "member_event_info_id": 93,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 79,
                                "display_name": "甄鎬翹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "林嘉欣",
                            "phone_num": "",
                            "member": {
                                "id": 135,
                                "member_event_info_id": 150,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 136,
                                "display_name": "林嘉欣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "李書妍",
                            "phone_num": "",
                            "member": {
                                "id": 160,
                                "member_event_info_id": 175,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 161,
                                "display_name": "李書妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "馮梓榗",
                            "phone_num": "",
                            "member": {
                                "id": 231,
                                "member_event_info_id": 246,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 232,
                                "display_name": "馮梓榗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "吳軒宇",
                            "phone_num": "",
                            "member": {
                                "id": 280,
                                "member_event_info_id": 295,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 281,
                                "display_name": "吳軒宇",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "陳靜",
                            "phone_num": "",
                            "member": {
                                "id": 313,
                                "member_event_info_id": 328,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 314,
                                "display_name": "陳靜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "羅鴻兒",
                            "phone_num": "",
                            "member": {
                                "id": 381,
                                "member_event_info_id": 396,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 382,
                                "display_name": "羅鴻兒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "陳卓禧",
                            "phone_num": "",
                            "member": {
                                "id": 465,
                                "member_event_info_id": 480,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 466,
                                "display_name": "陳卓禧",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "鄧茗晞",
                            "phone_num": "",
                            "member": {
                                "id": 565,
                                "member_event_info_id": 580,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 566,
                                "display_name": "鄧茗晞",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "冼文軒",
                            "phone_num": "",
                            "member": {
                                "id": 611,
                                "member_event_info_id": 626,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 612,
                                "display_name": "冼文軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "彭加希",
                            "phone_num": "",
                            "member": {
                                "id": 656,
                                "member_event_info_id": 671,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 657,
                                "display_name": "彭加希",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1D",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "張溱柔",
                            "phone_num": "",
                            "member": {
                                "id": 666,
                                "member_event_info_id": 681,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 667,
                                "display_name": "張溱柔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "麥凱惠",
                            "phone_num": "",
                            "member": {
                                "id": 679,
                                "member_event_info_id": 694,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 680,
                                "display_name": "麥凱惠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "陳柏霖",
                            "phone_num": "",
                            "member": {
                                "id": 683,
                                "member_event_info_id": 698,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 684,
                                "display_name": "陳柏霖",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "謝啟賢",
                            "phone_num": "",
                            "member": {
                                "id": 709,
                                "member_event_info_id": 724,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 710,
                                "display_name": "謝啟賢",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "賴承澤",
                            "phone_num": "",
                            "member": {
                                "id": 750,
                                "member_event_info_id": 765,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 751,
                                "display_name": "賴承澤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "張正南",
                            "phone_num": "",
                            "member": {
                                "id": 809,
                                "member_event_info_id": 824,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 810,
                                "display_name": "張正南",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3C",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "陳漢基",
                            "phone_num": "",
                            "member": {
                                "id": 831,
                                "member_event_info_id": 846,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 832,
                                "display_name": "陳漢基",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "馮詩澄",
                            "phone_num": "",
                            "member": {
                                "id": 840,
                                "member_event_info_id": 855,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 841,
                                "display_name": "馮詩澄",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "陳嘉銘",
                            "phone_num": "",
                            "member": {
                                "id": 1005,
                                "member_event_info_id": 1020,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1006,
                                "display_name": "陳嘉銘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "2"
                            }
                        },
                        {
                            "name": "鍾俊灝",
                            "phone_num": "",
                            "member": {
                                "id": 1046,
                                "member_event_info_id": 1061,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1047,
                                "display_name": "鍾俊灝",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "郭芷熒",
                            "phone_num": "",
                            "member": {
                                "id": 1077,
                                "member_event_info_id": 1092,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1078,
                                "display_name": "郭芷熒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "梁博聞",
                            "phone_num": "",
                            "member": {
                                "id": 1159,
                                "member_event_info_id": 63,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 49,
                                "display_name": "梁博聞",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1A",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "許哲弘",
                            "phone_num": "",
                            "member": {
                                "id": 1199,
                                "member_event_info_id": 103,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 89,
                                "display_name": "許哲弘",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "黃子晴",
                            "phone_num": "",
                            "member": {
                                "id": 1305,
                                "member_event_info_id": 209,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 195,
                                "display_name": "黃子晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "郭釺瑩",
                            "phone_num": "",
                            "member": {
                                "id": 1320,
                                "member_event_info_id": 224,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 210,
                                "display_name": "郭釺瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "蘇毅朗",
                            "phone_num": "",
                            "member": {
                                "id": 1371,
                                "member_event_info_id": 275,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 261,
                                "display_name": "蘇毅朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "陳雅倩",
                            "phone_num": "",
                            "member": {
                                "id": 1509,
                                "member_event_info_id": 413,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 399,
                                "display_name": "陳雅倩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "林衍宏",
                            "phone_num": "",
                            "member": {
                                "id": 1516,
                                "member_event_info_id": 420,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 406,
                                "display_name": "林衍宏",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "胡逸嵐",
                            "phone_num": "",
                            "member": {
                                "id": 1553,
                                "member_event_info_id": 457,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 443,
                                "display_name": "胡逸嵐",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "鍾俊灝",
                            "phone_num": "",
                            "member": {
                                "id": 1618,
                                "member_event_info_id": 522,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 508,
                                "display_name": "鍾俊灝",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "陳智琛",
                            "phone_num": "",
                            "member": {
                                "id": 1730,
                                "member_event_info_id": 634,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 620,
                                "display_name": "陳智琛",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "張芊幸",
                            "phone_num": "",
                            "member": {
                                "id": 1733,
                                "member_event_info_id": 637,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 623,
                                "display_name": "張芊幸",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "4"
                            }
                        },
                        {
                            "name": "余俊翹",
                            "phone_num": "",
                            "member": {
                                "id": 1752,
                                "member_event_info_id": 656,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 642,
                                "display_name": "余俊翹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "23"
                            }
                        },
                        {
                            "name": "何庭匡",
                            "phone_num": "",
                            "member": {
                                "id": 1856,
                                "member_event_info_id": 760,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 746,
                                "display_name": "何庭匡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "6"
                            }
                        },
                        {
                            "name": "蘇毅朗",
                            "phone_num": "",
                            "member": {
                                "id": 1910,
                                "member_event_info_id": 814,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 800,
                                "display_name": "蘇毅朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "蔡昀澔",
                            "phone_num": "",
                            "member": {
                                "id": 1911,
                                "member_event_info_id": 815,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 801,
                                "display_name": "蔡昀澔",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "毛嘉朗",
                            "phone_num": "",
                            "member": {
                                "id": 1997,
                                "member_event_info_id": 901,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 887,
                                "display_name": "毛嘉朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "黃宇誠",
                            "phone_num": "",
                            "member": {
                                "id": 2023,
                                "member_event_info_id": 927,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 913,
                                "display_name": "黃宇誠",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "19"
                            }
                        },
                        {
                            "name": "黃婉晴",
                            "phone_num": "",
                            "member": {
                                "id": 2068,
                                "member_event_info_id": 972,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 958,
                                "display_name": "黃婉晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "周洛瑤",
                            "phone_num": "",
                            "member": {
                                "id": 2076,
                                "member_event_info_id": 980,
                                "skill_code": "football",
                                "skill_score": 41,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 966,
                                "display_name": "周洛瑤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "7"
                            }
                        }
                    ]
                },
                {
                    "rank": 10,
                    "unit": "秒",
                    "score": 40,
                    "members": [
                        {
                            "name": "黃卓彤",
                            "phone_num": "",
                            "member": {
                                "id": 13,
                                "member_event_info_id": 31,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 14,
                                "display_name": "黃卓彤",
                                "gender": "M",
                                "organization_id": "1",
                                "group": "1B",
                                "group_num": "20006Y"
                            }
                        },
                        {
                            "name": "陳若妍",
                            "phone_num": "",
                            "member": {
                                "id": 201,
                                "member_event_info_id": 216,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 202,
                                "display_name": "陳若妍",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "林駿謙",
                            "phone_num": "",
                            "member": {
                                "id": 234,
                                "member_event_info_id": 249,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 235,
                                "display_name": "林駿謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "李嘉耀",
                            "phone_num": "",
                            "member": {
                                "id": 237,
                                "member_event_info_id": 252,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 238,
                                "display_name": "李嘉耀",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "李映姿",
                            "phone_num": "",
                            "member": {
                                "id": 238,
                                "member_event_info_id": 253,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 239,
                                "display_name": "李映姿",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "蔡信恩",
                            "phone_num": "",
                            "member": {
                                "id": 268,
                                "member_event_info_id": 283,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 269,
                                "display_name": "蔡信恩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "22"
                            }
                        },
                        {
                            "name": "黃晞朗",
                            "phone_num": "",
                            "member": {
                                "id": 351,
                                "member_event_info_id": 366,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 352,
                                "display_name": "黃晞朗",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4B",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "林倩而",
                            "phone_num": "",
                            "member": {
                                "id": 494,
                                "member_event_info_id": 509,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 495,
                                "display_name": "林倩而",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "羅卓祈",
                            "phone_num": "",
                            "member": {
                                "id": 496,
                                "member_event_info_id": 511,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 497,
                                "display_name": "羅卓祈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6A",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "麥智堯",
                            "phone_num": "",
                            "member": {
                                "id": 520,
                                "member_event_info_id": 535,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 521,
                                "display_name": "麥智堯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6B",
                                "group_num": "13"
                            }
                        },
                        {
                            "name": "李祈昱",
                            "phone_num": "",
                            "member": {
                                "id": 542,
                                "member_event_info_id": 557,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 543,
                                "display_name": "李祈昱",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "羅樂晴",
                            "phone_num": "",
                            "member": {
                                "id": 606,
                                "member_event_info_id": 621,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:03",
                                "updated_at": "2022-12-21 09:36:03",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 607,
                                "display_name": "羅樂晴",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "11"
                            }
                        },
                        {
                            "name": "楊博喬",
                            "phone_num": "",
                            "member": {
                                "id": 614,
                                "member_event_info_id": 629,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 615,
                                "display_name": "楊博喬",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "范嫝娜",
                            "phone_num": "",
                            "member": {
                                "id": 719,
                                "member_event_info_id": 734,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 720,
                                "display_name": "范嫝娜",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2C",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "呂津安",
                            "phone_num": "",
                            "member": {
                                "id": 755,
                                "member_event_info_id": 770,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 756,
                                "display_name": "呂津安",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2D",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "袁善妤",
                            "phone_num": "",
                            "member": {
                                "id": 805,
                                "member_event_info_id": 820,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 806,
                                "display_name": "袁善妤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "20"
                            }
                        },
                        {
                            "name": "周柏源",
                            "phone_num": "",
                            "member": {
                                "id": 858,
                                "member_event_info_id": 873,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 859,
                                "display_name": "周柏源",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "7"
                            }
                        },
                        {
                            "name": "温思欣",
                            "phone_num": "",
                            "member": {
                                "id": 909,
                                "member_event_info_id": 924,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 910,
                                "display_name": "温思欣",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "16"
                            }
                        },
                        {
                            "name": "王嘉瑩",
                            "phone_num": "",
                            "member": {
                                "id": 1087,
                                "member_event_info_id": 1102,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:04",
                                "updated_at": "2022-12-21 09:36:04",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1088,
                                "display_name": "王嘉瑩",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6C",
                                "group_num": "18"
                            }
                        },
                        {
                            "name": "劉芊彤",
                            "phone_num": "",
                            "member": {
                                "id": 1367,
                                "member_event_info_id": 271,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 257,
                                "display_name": "劉芊彤",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3B",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "鍾仲呈",
                            "phone_num": "",
                            "member": {
                                "id": 1411,
                                "member_event_info_id": 315,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 301,
                                "display_name": "鍾仲呈",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "林晉熙",
                            "phone_num": "",
                            "member": {
                                "id": 1433,
                                "member_event_info_id": 337,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 323,
                                "display_name": "林晉熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "10"
                            }
                        },
                        {
                            "name": "王靜怡",
                            "phone_num": "",
                            "member": {
                                "id": 1444,
                                "member_event_info_id": 348,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 334,
                                "display_name": "王靜怡",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4A",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "劉語心",
                            "phone_num": "",
                            "member": {
                                "id": 1474,
                                "member_event_info_id": 378,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 364,
                                "display_name": "劉語心",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "9"
                            }
                        },
                        {
                            "name": "周子仁",
                            "phone_num": "",
                            "member": {
                                "id": 1535,
                                "member_event_info_id": 439,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 425,
                                "display_name": "周子仁",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "李銳熙",
                            "phone_num": "",
                            "member": {
                                "id": 1545,
                                "member_event_info_id": 449,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 435,
                                "display_name": "李銳熙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "15"
                            }
                        },
                        {
                            "name": "陳心愉",
                            "phone_num": "",
                            "member": {
                                "id": 1663,
                                "member_event_info_id": 567,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 553,
                                "display_name": "陳心愉",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "吳景軒",
                            "phone_num": "",
                            "member": {
                                "id": 1746,
                                "member_event_info_id": 650,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 636,
                                "display_name": "吳景軒",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "1C",
                                "group_num": "17"
                            }
                        },
                        {
                            "name": "郭可昕",
                            "phone_num": "",
                            "member": {
                                "id": 1783,
                                "member_event_info_id": 687,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 673,
                                "display_name": "郭可昕",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "2A",
                                "group_num": "8"
                            }
                        },
                        {
                            "name": "鄭如意",
                            "phone_num": "",
                            "member": {
                                "id": 1880,
                                "member_event_info_id": 784,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:27",
                                "updated_at": "2022-12-21 09:36:27",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 770,
                                "display_name": "鄭如意",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3A",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "呂文曦",
                            "phone_num": "",
                            "member": {
                                "id": 1955,
                                "member_event_info_id": 859,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 845,
                                "display_name": "呂文曦",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "3D",
                                "group_num": "14"
                            }
                        },
                        {
                            "name": "鄭浩謙",
                            "phone_num": "",
                            "member": {
                                "id": 2005,
                                "member_event_info_id": 909,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 895,
                                "display_name": "鄭浩謙",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4C",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "黃梓恆",
                            "phone_num": "",
                            "member": {
                                "id": 2046,
                                "member_event_info_id": 950,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 936,
                                "display_name": "黃梓恆",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "4D",
                                "group_num": "21"
                            }
                        },
                        {
                            "name": "陳煦堯",
                            "phone_num": "",
                            "member": {
                                "id": 2070,
                                "member_event_info_id": 974,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 960,
                                "display_name": "陳煦堯",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5B",
                                "group_num": "1"
                            }
                        },
                        {
                            "name": "李彩瞳",
                            "phone_num": "",
                            "member": {
                                "id": 2105,
                                "member_event_info_id": 1009,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 995,
                                "display_name": "李彩瞳",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5C",
                                "group_num": "12"
                            }
                        },
                        {
                            "name": "郭柏翹",
                            "phone_num": "",
                            "member": {
                                "id": 2118,
                                "member_event_info_id": 1022,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1008,
                                "display_name": "郭柏翹",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "5D",
                                "group_num": "5"
                            }
                        },
                        {
                            "name": "甄悅",
                            "phone_num": "",
                            "member": {
                                "id": 2221,
                                "member_event_info_id": 1125,
                                "skill_code": "football",
                                "skill_score": 40,
                                "created_at": "2022-12-21 09:36:28",
                                "updated_at": "2022-12-21 09:36:28",
                                "deleted_at": "",
                                "event_id": 3,
                                "member_id": 1111,
                                "display_name": "甄悅",
                                "gender": "M",
                                "organization_id": "0",
                                "group": "6D",
                                "group_num": "20"
                            }
                        }
                    ]
                }
            ]
        },
        {
            "id": 8,
            "skill_name": "30s Hand Rolling",
            "skill_code": "hand_rolling",
            "color": "green",
            "unit": "分",
            "timeslot_id": 1,
            "next_timeslot_id": 2,
            "last_timeslot_id": -1,
            "timeslot_caption": "09:00 - 10:10",
            "start_at": "00:00",
            "end_at": "10:15",
            "result": []
        }
    ];
}
const getOneTimeslotLeaderboard = () => {
    return getTimeslotLeaderboard()[0];
}

const getFullEventStat = () => {
    //   return 

    return {
        "event_stat": {
            "total": 88,
            "member_count": 75,
            "org_count": 9
        },
        "skill_stat_list": [
            {
                "code": "vjump",
                "total": 37,
                "avg": 35.108108108108105,
                "best": 317,
                "member_count": 17,
                "top_records": [
                    {
                        "id": 9522,
                        "member_event_info_id": 2871,
                        "skill_code": "vjump",
                        "skill_score": 317,
                        "created_at": "2023-05-17 11:23:15",
                        "member_id": 5323,
                        "display_name": "GBA01",
                        "phone_num": "25952608",
                        "gender": "M",
                        "organization_id": "64",
                        "group": "1A",
                        "group_num": "01"
                    },
                    {
                        "id": 9497,
                        "member_event_info_id": 2872,
                        "skill_code": "vjump",
                        "skill_score": 125,
                        "created_at": "2023-05-16 14:23:12",
                        "member_id": 3129,
                        "display_name": "yctm001",
                        "phone_num": "98765567",
                        "gender": "M",
                        "organization_id": "55",
                        "group": "A",
                        "group_num": "5"
                    },
                    {
                        "id": 9524,
                        "member_event_info_id": 2871,
                        "skill_code": "vjump",
                        "skill_score": 120,
                        "created_at": "2023-05-17 11:24:05",
                        "member_id": 5323,
                        "display_name": "GBA01",
                        "phone_num": "25952608",
                        "gender": "M",
                        "organization_id": "64",
                        "group": "1A",
                        "group_num": "01"
                    }
                ],
                "info": {
                    "name_zh": "Vertical Jump",
                    "name_en": "Vertical Jump",
                    "unit": "cm",
                    "decimal_place": 0,
                    "name": "Vertical Jump"
                }
            },
            {
                "code": "moveit_ai_punch",
                "total": 29,
                "avg": 2116.4658620689656,
                "best": 12814.6,
                "member_count": 4,
                "top_records": [
                    {
                        "id": 9514,
                        "member_event_info_id": 2747,
                        "skill_code": "moveit_ai_punch",
                        "skill_score": 12814.6,
                        "created_at": "2023-05-16 22:40:07",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9246,
                        "member_event_info_id": 2747,
                        "skill_code": "moveit_ai_punch",
                        "skill_score": 9613.42,
                        "created_at": "2023-04-26 10:04:02",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9513,
                        "member_event_info_id": 2747,
                        "skill_code": "moveit_ai_punch",
                        "skill_score": 5011.76,
                        "created_at": "2023-05-16 22:38:05",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "AI 拳擊",
                    "name_en": "MoveIt AI Boxing",
                    "unit": "pt",
                    "decimal_place": 2,
                    "name": "AI 拳擊"
                }
            },
            {
                "code": "punch",
                "total": 3,
                "avg": 3351.3033333333333,
                "best": 4571.24,
                "member_count": 1,
                "top_records": [
                    {
                        "id": 9243,
                        "member_event_info_id": 2747,
                        "skill_code": "punch",
                        "skill_score": 4571.24,
                        "created_at": "2023-04-25 18:28:35",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9242,
                        "member_event_info_id": 2747,
                        "skill_code": "punch",
                        "skill_score": 2897.82,
                        "created_at": "2023-04-25 18:25:48",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9241,
                        "member_event_info_id": 2747,
                        "skill_code": "punch",
                        "skill_score": 2584.85,
                        "created_at": "2023-04-25 18:22:21",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "Power Punching",
                    "name_en": "Power Punching",
                    "unit": "n",
                    "decimal_place": 0,
                    "name": "Power Punching"
                }
            },
            {
                "code": "balance",
                "total": 4,
                "avg": 56.875,
                "best": 69,
                "member_count": 3,
                "top_records": [
                    {
                        "id": 9260,
                        "member_event_info_id": 2751,
                        "skill_code": "balance",
                        "skill_score": 69,
                        "created_at": "2023-04-28 09:11:37",
                        "member_id": 1,
                        "display_name": "Ken1233",
                        "phone_num": "91239123",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9261,
                        "member_event_info_id": 2738,
                        "skill_code": "balance",
                        "skill_score": 60.5,
                        "created_at": "2023-04-28 09:12:11",
                        "member_id": 4,
                        "display_name": "vtc-ce",
                        "phone_num": "af",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9259,
                        "member_event_info_id": 2751,
                        "skill_code": "balance",
                        "skill_score": 52,
                        "created_at": "2023-04-28 09:10:44",
                        "member_id": 1,
                        "display_name": "Ken1233",
                        "phone_num": "91239123",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "Single-leg Balance",
                    "name_en": "Single-leg Balance",
                    "unit": "pt",
                    "decimal_place": 0,
                    "name": "Single-leg Balance"
                }
            },
            {
                "code": "moveit_coach",
                "total": 4,
                "avg": 52.75,
                "best": 123,
                "member_count": 2,
                "top_records": [
                    {
                        "id": 9247,
                        "member_event_info_id": 2750,
                        "skill_code": "moveit_coach",
                        "skill_score": 123,
                        "created_at": "2023-04-26 10:12:35",
                        "member_id": 11,
                        "display_name": "ken.l@mosaicdigi.com",
                        "phone_num": "92128335",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9257,
                        "member_event_info_id": 2747,
                        "skill_code": "moveit_coach",
                        "skill_score": 36,
                        "created_at": "2023-04-27 10:30:56",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9253,
                        "member_event_info_id": 2747,
                        "skill_code": "moveit_coach",
                        "skill_score": 30,
                        "created_at": "2023-04-26 11:04:09",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "拳擊跟練",
                    "name_en": "MoveIt Boxing Coach",
                    "unit": "pt",
                    "decimal_place": 2,
                    "name": "拳擊跟練"
                }
            },
            {
                "code": "moveit_quiz",
                "total": 5,
                "avg": 96.2,
                "best": 166,
                "member_count": 2,
                "top_records": [
                    {
                        "id": 9249,
                        "member_event_info_id": 2750,
                        "skill_code": "moveit_quiz",
                        "skill_score": 166,
                        "created_at": "2023-04-26 10:29:48",
                        "member_id": 11,
                        "display_name": "ken.l@mosaicdigi.com",
                        "phone_num": "92128335",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9248,
                        "member_event_info_id": 2750,
                        "skill_code": "moveit_quiz",
                        "skill_score": 166,
                        "created_at": "2023-04-26 10:28:43",
                        "member_id": 11,
                        "display_name": "ken.l@mosaicdigi.com",
                        "phone_num": "92128335",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9254,
                        "member_event_info_id": 2747,
                        "skill_code": "moveit_quiz",
                        "skill_score": 100,
                        "created_at": "2023-04-26 15:51:10",
                        "member_id": 3952,
                        "display_name": "MoveItEclass",
                        "phone_num": "",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "拳擊答題挑戰",
                    "name_en": "MoveIt Boxing Quiz",
                    "unit": "pt",
                    "decimal_place": 2,
                    "name": "拳擊答題挑戰"
                }
            },
            {
                "code": "flextest",
                "total": 1,
                "avg": 11.5,
                "best": 11.5,
                "member_count": 1,
                "top_records": [
                    {
                        "id": 9266,
                        "member_event_info_id": 2737,
                        "skill_code": "flextest",
                        "skill_score": 11.5,
                        "created_at": "2023-04-28 16:08:47",
                        "member_id": 2934,
                        "display_name": "VTC",
                        "phone_num": "91239123",
                        "gender": "M",
                        "organization_id": "2",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "Shoulder Flexibility Test",
                    "name_en": "Shoulder Flexibility Test",
                    "unit": "cm",
                    "decimal_place": 0,
                    "name": "Shoulder Flexibility Test"
                }
            },
            {
                "code": "sitnreach",
                "total": 2,
                "avg": 22.25,
                "best": 26,
                "member_count": 2,
                "top_records": [
                    {
                        "id": 9301,
                        "member_event_info_id": 2738,
                        "skill_code": "sitnreach",
                        "skill_score": 26,
                        "created_at": "2023-05-09 12:00:05",
                        "member_id": 4,
                        "display_name": "vtc-ce",
                        "phone_num": "af",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9350,
                        "member_event_info_id": 2766,
                        "skill_code": "sitnreach",
                        "skill_score": 18.5,
                        "created_at": "2023-05-09 13:52:13",
                        "member_id": 2939,
                        "display_name": "IVE10",
                        "phone_num": "99999996",
                        "gender": "M",
                        "organization_id": "7",
                        "group": "",
                        "group_num": "0"
                    }
                ],
                "info": {
                    "name_zh": "Sit and Reach",
                    "name_en": "Sit and Reach",
                    "unit": "cm",
                    "decimal_place": 0,
                    "name": "Sit and Reach"
                }
            },
            {
                "code": "handgrip",
                "total": 2,
                "avg": 55.15,
                "best": 60.3,
                "member_count": 2,
                "top_records": [
                    {
                        "id": 9357,
                        "member_event_info_id": 2750,
                        "skill_code": "handgrip",
                        "skill_score": 60.3,
                        "created_at": "2023-05-09 15:57:16",
                        "member_id": 11,
                        "display_name": "ken.l@mosaicdigi.com",
                        "phone_num": "92128335",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    },
                    {
                        "id": 9529,
                        "member_event_info_id": 2887,
                        "skill_code": "handgrip",
                        "skill_score": 50,
                        "created_at": "2023-05-18 09:44:37",
                        "member_id": 3936,
                        "display_name": "Kelvin Lee",
                        "phone_num": "60147325",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "Hand Grip",
                    "name_en": "Hand Grip",
                    "unit": "kg",
                    "decimal_place": 0,
                    "name": "Hand Grip"
                }
            },
            {
                "code": "ttest",
                "total": 1,
                "avg": 14.64,
                "best": 14.64,
                "member_count": 1,
                "top_records": [
                    {
                        "id": 9358,
                        "member_event_info_id": 2750,
                        "skill_code": "ttest",
                        "skill_score": 14.64,
                        "created_at": "2023-05-09 15:57:38",
                        "member_id": 11,
                        "display_name": "ken.l@mosaicdigi.com",
                        "phone_num": "92128335",
                        "gender": "M",
                        "organization_id": "0",
                        "group": "",
                        "group_num": ""
                    }
                ],
                "info": {
                    "name_zh": "T-Test",
                    "name_en": "T-Test",
                    "unit": "sec",
                    "decimal_place": 0,
                    "name": "T-Test"
                }
            }
        ]
    };
}

const getMember = () => {
    return {
        "id": 5774,
        "join_time": "2023-08-08 18:09:13",
        "qrcode_id": "NQEGENNK",
        "member_id": "JC23G87",
        "organization_id": 114,
        "user_type": "school",
        "display_name": "CHUEK CHIN YAU",
        "email": "JC23G87",
        "group": "G",
        "group_num": "G87",
        "phone_num": "91119222",
        "gender": "M",
        "dob": "2000-01-01",
        "vendor_moveit_id": "",
        "vendor_afascan_id": "",
        "created_at": "2023-08-08 18:09:13",
        "updated_at": "2023-08-08 18:09:13",
        "deleted_at": "",
        "age": 23,
        "age_group": "adult"
    }
}

const getRedemptionInfo = () => {
    return {
        "id": 4,
        "member_id": 5774,
        "event_id": 16,
        "qrcode": "LAQBHMZ",
        "total_count": 6,
        "complete_count": 6,
        "total_score": 1234,
        // "status": "redeemed",
        "status": "ready",
        // "status": "not_ready",
        "created_at": "2023-10-10 01:27:47",
        "updated_at": "2023-10-10 01:46:15",
        "deleted_at": "",
        "reward_chance": 2
    }
}


const MockData = {
    getCoSportOrganizationScore,
    getOverallLeaderboardData,
    getOneOverallLeaderboardData,
    getOneSkillStatistics,
    getSkillPerformStatistics,
    getSkillUsageStatistics,
    getOverallSkillStatistics,
    getOneSkillTopOrganization,
    skillTopOrganization,
    getEventList,
    getMember,
    getRedemptionInfo,
    getFullEventStat,
    getLeaderboardEventList,
    getMemberEventInfo,
    getSkillInfoList,
    getMemberQueryResult,
    getHealthData,
    getTimeslotLeaderboardResponse,
    getOneTimeslotLeaderboardResponse,
    getTimeslotLeaderboard,
    getOneTimeslotLeaderboard,
    checkInputResult,
};

export default MockData;