import { Box, Grid, IconButton, Button } from "@mui/material";
// Component
import OverallSportDisplay from "../components/leaderBoard/OverallSportDisplay";
import LeaderboardDisplayForKid from "../components/leaderBoard/LeaderboardDisplayForKid/index.jsx";
import { getStoredAccountOrgType } from "../helper/AccountHelper.js";
import Footer from "../components/leaderBoard/Footer";
import { Carousel } from "react-responsive-carousel";


// import Mock from "../components/leaderBoard/SportDisplay/mock.json";
// Icon

import SvgToHome from "../components/icon/SvgToHome.jsx";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";

import { AdminApi } from "../api/AdminApi";
import { getCurrentDateStr } from "../helper/StringHelper";
const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];
// console.debug("TODAY: ", TODAY);
// const TODAY = new Date().toISOString().split("T")[0];
const CarouselProps = {
  showThumbs: false,
  autoPlay: true,
  infiniteLoop: true,
  showIndicators: false,
  showStatus: false,
  showArrows: false,
  // interval: 60000,
  interval: 4000,
  emulateTouch: true,
};

export default function Page({ }) {
  const navigate = useNavigate();

  const handleBackHome = (e) => {
    navigate("/");
  };

  const { eventId } = useParams();
  let [searchParams] = useSearchParams();
  // Get event id from url

  const orgType = getStoredAccountOrgType();

  const isKidTheme = orgType === "kid" || orgType === "primary";


  const [leaderData, setLeaderData] = useState([]);


  const updateLeaderbroad = () => {
    let date = searchParams.get("date") ? searchParams.get("date") : TODAY;
    console.log("updateLeaderbroad: ", date, " t=", new Date().toLocaleTimeString());

    AdminApi.loadEventOverallLeaderboardCallback(eventId, date, (data) => {
      console.log("loadEventOverallLeaderboardCallback: ", data);
      setLeaderData(data);
    });
    return;
  }

  useEffect(() => {
    updateLeaderbroad();
    // let date = searchParams.get("date") ? searchParams.get("date") : TODAY;

    // AdminApi.loadEventOverallLeaderboardCallback(eventId, date, (data) => {
    //   console.log("loadEventOverallLeaderboardCallback: ", data);
    //   setLeaderData(data);
    // });
    // return;
  }, []);



  // useEffect(() => {
  //   setLeaderData((pre) => leaderData);
  //   updateLeaderbroad();
  //   // setnIntervId(
  //   const interval = setInterval(() => {
  //   }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

  //   return () => clearInterval(interval);

  //   // );
  // }, [leaderData]);

  // forceUpdate(n => !n)


  // useEffect(() => {
  //   setLeaderData((pre) => leaderData);

  //   // setnIntervId(
  //   const interval = setInterval(() => {
  //     let date = searchParams.get("date") ? searchParams.get("date") : TODAY;

  //   AdminApi.loadEventOverallLeaderboardCallback(eventId, date, (data) => {
  //     console.log("loadEventOverallLeaderboardCallback: ", data);
  //     setLeaderData(data);
  //   });
  //   }, process.env.REACT_APP_UPDATE_INTERVAL || 10000);

  //   return () => clearInterval(interval);

  //   // );
  // }, [leaderData]);

  return (
    <Box position={"relative"} overflow="hidden">
      <Box position={"absolute"} left={10} top={10} zIndex={10}>
        <IconButton onClick={() => handleBackHome()}>
          <SvgToHome />
        </IconButton>
      </Box>

      <Footer />


      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={12}>
          <Box>
            <Carousel {...CarouselProps}>
              {leaderData.map((_sportLeader, index) => (
                isKidTheme ?
                  <LeaderboardDisplayForKid
                    leaderboardData={_sportLeader}
                    eventID={eventId}
                    date={TODAY}
                    overall
                    key={`carousel-${_sportLeader.skill_code}-${index}`}
                  /> :
                  <OverallSportDisplay
                    leaderboardData={_sportLeader}
                    eventID={eventId}
                    date={TODAY}
                    key={`carousel-${_sportLeader.skill_code}-${index}`}
                  />

              ))}
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
