import React from "react";
import { Typography, Box } from "@mui/material";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";


export default function MemberScoreRecordTable({ recordList }) {
    const hasData = recordList === undefined ? 0 : recordList.length > 0;

    return (<Box>
        <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
                my={4}
                align="center"
                color={"info"}
                fontWeight="bold"
                sx={{ display: hasData ? "none" : "block" }}
            >
                -- 沒有任何資料 --
            </Typography>
            <TableContainer
                sx={{ display: hasData ? "block" : "none" }}
            >
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                // size={dense ? "small" : "medium"}
                >   <TableHead>
                        <TableRow>
                            <TableCell align="center">排名</TableCell>
                            <TableCell align="center">姓名</TableCell>
                            <TableCell align="center">電話</TableCell>
                            <TableCell align="center">會員編號</TableCell>
                            <TableCell align="center">組別</TableCell>
                            <TableCell align="center">分數</TableCell>
                        </TableRow>
                    </TableHead>
                    {/* <MyTableHeader headerContent={dataFieldConfig} /> */}
                    <TableBody>
                        {recordList?.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={`table-row-${index}`}
                                >
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{row.display_name}</TableCell>
                                    <TableCell align="center">{row.phone_num}</TableCell>
                                    <TableCell align="center">{row.member_id}</TableCell>
                                    <TableCell align="center">{row.group} {row.group_num}</TableCell>
                                    <TableCell align="center">{row.skill_score}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>

        {/* <br />
        <pre>
            {JSON.stringify(recordList, null, 2)}
        </pre> */}
    </Box>);
}