import React, { useEffect, useState } from "react";
import { Divider, Grid, Card, Box, Button, Typography } from "@mui/material";
import { getLang, localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import EventCard from "./EventCard";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from "@emotion/styled";
import MemberQRCard from "./MemberQRCard";

export default function MemberQueryQRCardList({
    memberList,

}) {

    if (memberList == null || memberList.length === 0) {
        return (
            <Box></Box>
        );
    }



    return (
        <Box p="0" sx={{
            marginTop: '0mm',
            marginLeft: '0mm', 
            backgroundColor: 'white',
            pageBreakAfter: 'always',
        }}
            display="flex" flexWrap="wrap" gap={0}>
            {
                memberList.map((member) => {
                    return (

                        <MemberQRCard member={member} key={member.id} />

                    );
                })
            }
        </Box>
    );
}