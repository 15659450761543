import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import ChangeMemberPasswordForm from "../../components/tool/ChangeMemberPasswordForm";

export default function TestChangeMemberPasswordForm() {
    const { t } = useTranslation();

    let lang = getLang();

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestChangeMemberPasswordForm</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <ChangeMemberPasswordForm />
            </Stack>
        </Container>
    )
}
