import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import WhitePersonIcon from "../../components/common/Icons/WhitePersonIcon";
import WhiteReportIcon from "../../components/common/Icons/WhiteReportIcon";
import WhiteSchoolIcon from "../../components/common/Icons/WhiteSchoolIcon";
import ColorSchoolIcon from "../../components/common/Icons/ColorSchoolIcon";
import ColorReportIcon from "../../components/common/Icons/ColorReportIcon";
import ColorPersonIcon from "../../components/common/Icons/ColorPersonIcon";

export default function TestCustomIcon() {
    const { t } = useTranslation();

    let lang = getLang();

    let bg = "rgba(0, 0, 0, 0.5)";

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestCustomIcon</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <Box display="flex" gap={3} sx={{background: bg}}>
                    <WhitePersonIcon />
                    <WhitePersonIcon imageSize={111}/>
                    <WhiteReportIcon />
                    <WhiteSchoolIcon />
                    <WhiteSchoolIcon sx={{background:'#5f5'}}/>
                    <ColorSchoolIcon />
                    <ColorPersonIcon />
                    <ColorReportIcon />
                    
                </Box>
            </Stack>
        </Container>
    )
}
