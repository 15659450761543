import { Navigate, useRoutes } from "react-router-dom";
/** Layout */
import DashboardLayout from "../layouts/dashboard";
/** Organization */
import OrganizationIndexPage from "../views/organization";
import OrganizationCreatePage from "../views/organization/organizationCreate";
import OrganizationDetailPage from "../views/organization/organizationDetail";

import OrganizationMemberPage from "../views/organization/organizationMember";
import OrganizationMemberCreatePage from "../views/organization/organizationMemberCreate";
import OrganizationMemberDetailPage from "../views/organization/organizationMemberDetail";

import OrganizationEventPage from "../views/organization/organizationEvent";
import OrganizationEventCreatePage from "../views/organization/organizationEventCreate";
import OrganizationEventDetailPage from "../views/organization/organizationEventDetail";
import OrganizationMemberImportPage from "../views/organization/organizationMemberImport";

import OrganizationMissionSettingsPage from "../views/organization/organizationMissionSettingsPage";
import OrganizationMissionSettingDetail from "../views/organization/organizationMissionSettingDetail";

import { auth } from "./routerHelper";

const route = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    { path: "organizations", element: auth(<OrganizationIndexPage />) },
    {
      path: "organization",
      children: [
        { path: "detail/:orgId", element: auth(<OrganizationDetailPage />) },

        { path: ":orgId/members", element: auth(<OrganizationMemberPage />) },
        {
          path: ":orgId/member/create",
          element: auth(<OrganizationMemberCreatePage />),
        },
        {
          path: ":orgId/member/detail/:memberId",
          element: auth(<OrganizationMemberDetailPage />),
        },
        {
          path: ":orgId/member/import",
          element: auth(<OrganizationMemberImportPage />),
        },
        {
          path: ":orgId/events",
          element: auth(<OrganizationEventPage />),
        },
        {
          path: ":orgId/event/create",
          element: auth(<OrganizationEventCreatePage />),
        },
        {
          path: ":orgId/event/detail/:eventId",
          element: auth(<OrganizationEventDetailPage />),
        },
        {
          path: "mission_settings",
          // element: auth(<OrganizationEventDetailPage />),
          element: auth(<OrganizationMissionSettingsPage />),
        },
        {
          path: "missing_setting/:orgId/:missionSettingId",
          element: auth(<OrganizationMissionSettingDetail />),
        },
        { path: "create", element: auth(<OrganizationCreatePage />) },
        { path: "import", element: <></> },
      ],
    },
  ],
};

export default route;
