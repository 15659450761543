import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y={-0.0000610352} width={52} height={52} rx={8} fill="#055F8C" />
      <path
        d="M29.815 15.79L19.605 26l10.21 10.21"
        stroke="#fff"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
