import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import EventStatCard from "../../components/adminStatistic/EventStatCard";
import SkillStatCard from "../../components/adminStatistic/SkillStatCard";
import MemberScoreRecordTable from "../../components/adminStatistic/MemberScoreRecordTable";

import MockData from "../../helper/MockData";
export default function TestSimple() {
    const { t } = useTranslation();

    let lang = getLang();

    let fullStat = MockData.getFullEventStat();
    const eventStat = fullStat.event_stat;
    const skillStat = fullStat.skill_stat_list[0];

    console.log("eventStat: ", fullStat);

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Event Stat</Typography>

                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <EventStatCard stat={eventStat}></EventStatCard>
                <SkillStatCard stat={skillStat}></SkillStatCard>
                <MemberScoreRecordTable recordList={skillStat.top_records}></MemberScoreRecordTable>
                {/* <pre>
                    {JSON.stringify(eventStat, null, 2)}
                </pre> */}
            </Stack>
        </Container>
    )
}
