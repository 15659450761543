import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import AdminLeadboardSection from '../../sections/_dashboard/AdminLeadboardSection';
import {
  getlocalStorageUserObject,
} from "../../helper/LocalStorageHelper";



// ----------------------------------------------------------------------

export default function AdminLeaderboardPage() {
  const theme = useTheme();
  const user = getlocalStorageUserObject();

  return (
    <>
      <title> Dashboard | Minimal UI </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            User Leaderboard Checking
          </Typography>
          <AdminLeadboardSection userID={user.id} mode="user" />
        </Stack>

      </Container>
    </>
  );
}
