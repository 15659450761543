const handGrip = `Test Date,Strength Type,Name,Account ID,Gender,Dominant Hand,Date of Birth,Age,Series Name,Rung Position,Average Force,Avg Force % Change L- R,Peak Force,Average Of Peaks,"Avg Peak % Change L- R ",Maximum Peak,Max Peak % Change L- R,Discard Rep,Rep,Redo Rep,Parent Rep,Side,Time To Peak,Data Point,Date Time Stamp,Time Of Day,Duration,Value,HR Value
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Right - Rep 1,Position 2,23,-14.14,29,29,-6.86,29,-6.86,False,1,False,0,Right,1.95,74,2023-05-08 9:09:34 PM,9:09:34 PM,2.9,28.4,
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Right - Rep 1,Position 2,23,-14.14,29,29,-6.86,29,-6.86,False,1,False,0,Right,1.95,75,2023-05-08 9:09:34 PM,9:09:34 PM,2.94,28.4,
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Right - Rep 1,Position 2,23,-14.14,29,29,-6.86,29,-6.86,False,1,False,0,Right,1.95,76,2023-05-08 9:09:34 PM,9:09:34 PM,2.97,28.4,
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Left - Rep 1,Position 2,26.7,-14.14,31.2,31.2,-6.86,31.2,-6.86,False,1,False,0,Left,1.04,77,2023-05-08 9:09:55 PM,9:09:55 PM,0,1.4,
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Left - Rep 1,Position 2,26.7,-14.14,31.2,31.2,-6.86,31.2,-6.86,False,1,False,0,Left,1.04,78,2023-05-08 9:09:55 PM,9:09:55 PM,0.04,2.1,
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Left - Rep 1,Position 2,26.7,-14.14,31.2,31.2,-6.86,31.2,-6.86,False,1,False,0,Left,1.04,79,2023-05-08 9:09:55 PM,9:09:55 PM,0.07,2.8,
2023-05-08 9:09:24 PM,HandGrip,k Ken Lee,Kenlee,Male,Right,2000-04-02,23,Left - Rep 1,Position 2,26.7,-14.14,31.2,31.2,-6.86,31.2,-6.86,False,1,False,0,Left,1.04,80,2023-05-08 9:09:55 PM,9:09:55 PM,0.12,3.9,

`;



const data = {
    handGrip
};
  
export default data;
  