import { Box, Grid, Typography, Container, Stack } from "@mui/material";

import WhitePersonIcon from "../../common/Icons/WhitePersonIcon";
import WhiteReportIcon from "../../common/Icons/WhiteReportIcon";
import WhiteSchoolIcon from "../../common/Icons/WhiteSchoolIcon";
import ColorReportIcon from "../../common/Icons/ColorReportIcon";
import ColorPersonIcon from "../../common/Icons/ColorPersonIcon";
import ColorSchoolIcon from "../../common/Icons/ColorSchoolIcon";

import ImageDashCard from "../../common/ImageDashCard";
import { formatNumber } from "../../../helper/StringHelper";
import { th } from "date-fns/locale";

const cardStyle = {
  cardColor: "rgba(0, 0, 0, 0.15)",
  iconBgColor: "#ffc",
  iconColor: "#f0f",
  valueTextColor: "#D6FF33",
  labelTextColor: "#fff",
  maxWidth: 400,
  minWidth: 290,
}

const kidCardStyle = {
  cardColor: "#FFE0C1",
  iconBgColor: "#ffc",
  iconColor: "#f0f",
  valueTextColor: "#931A00",
  labelTextColor: "#552E00",
  flexGrow: 1,
  border: "8px solid #B96400",
  // maxWidth: '100%',
  // width: '100%',
  
  // minWidth: 290,
}

const DummyCard = () => {
  const cardStyle = {
    cardColor: "rgba(255, 255, 255, 0.3)",
    iconBgColor: "rgba(255, 255, 255, 0.5)",
    iconColor: "#f0f",
    maxWidth: 300,
    minWidth: 300,
  }

  return (
    <ImageDashCard
      label="    " value="   "
      icon={null}
      styleSetting={cardStyle} />
  )
}


const mockData =
{
  "skill_code": "balance",
  "sum_score": "141.5",
  "member_count": "2",
  "organization_count": "2"
}



const getCardStyle = (isKidTheme, index) => {
  if(isKidTheme === false){
    return cardStyle;  
  }

  let style = {...kidCardStyle}
  let radius1 = "20px"
  let radius2 = "5px"
  if(index === 0) {
    style.borderRadius = `${radius1} ${radius2} ${radius2} ${radius1}`;
  } else if(index === 1) {
    style.borderRadius = `${radius2} ${radius2} ${radius2} ${radius2}`;
  } else if(index === 2) {
    style.borderRadius = `${radius2} ${radius1} ${radius1} ${radius2}`;
  }


  return style;
  
}


export default function CoSportAnalyticsDashCards({ analyticData, unit, theme }) {


  // let unitStr = "cm"
  const isKidTheme = theme === "kid" || theme === "primary";


  let getDashCardData = (_analyticData) => {
    let result = [];
    if (!_analyticData) {
      for (let i = 0; i < 3; i++) {
        result.push({
          icon: null
        })
      }
      return result;
    }

    let totalScoreStr = formatNumber(Math.round(_analyticData.sum_score)) + " " + unit;
    let totalParticipantStr = _analyticData.member_count;
    let totalSchoolStr = _analyticData.organization_count;


    // style 
    let totalScoreStyle = { ...cardStyle }
    
    let totalParticipantStyle = { ...cardStyle }
    
    let totalSchoolStyle = { ...cardStyle }
    
    // 共創數據
    result.push({
      label: "共創數據",
      value: totalScoreStr,
      icon: isKidTheme ? ColorReportIcon : WhiteReportIcon,
      styleSetting: getCardStyle(isKidTheme, 0),
    })

    // 總參與人數
    result.push({
      label: "總參與人數",
      value: totalParticipantStr,
      // icon: WhitePersonIcon ,
      icon: isKidTheme ? ColorPersonIcon : WhitePersonIcon,
      styleSetting: getCardStyle(isKidTheme, 1),
    })

    // 總參與學校
    result.push({
      label: "總參與學校",
      value: totalSchoolStr,
      icon: isKidTheme ? ColorSchoolIcon : WhiteSchoolIcon,
      styleSetting: getCardStyle(isKidTheme, 2),
    })

    return result;
  }


  const dashCardData = getDashCardData(analyticData)
  console.debug("dashCardData=", dashCardData)

  return (
    <Box
      align="center"
      display={"flex"}
      pt={6}
      padding={0}
      width="100%"
      minWidth={200}
      sx={{
        justifyContent: 'space-between',
        // flexGrow: 1,
        gap: 1,
        // background: 'rgba(0, 0, 0, 0.5)',
      }}
      position="relative"
    >
      {
        dashCardData.map((data, index) => {
          return (
            // <Box>x</Box>
            data.icon == null ?
              <DummyCard key={`card-${index}`} /> :
              (<ImageDashCard key={`card-${index}`}
                label={data.label}
                value={data.value}
                icon={data.icon}
                styleSetting={data.styleSetting} />)

          )
        })
      }

    </Box >
  );
}
