import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import StandardInputForm from "../../components/app/skill/StandardInputForm";
import MultiInputForm from "../../components/app/skill/MultiInputForm";

export default function TestSimple() {
    const { t } = useTranslation();

    let lang = getLang();

    let unitName = "次";

    const onChangeStandard = (values) => {
        console.debug("onChangeStandard called: ", values);
    };

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Box>
                    <Typography variant="h3">MultiInput (LEFT / RIGHT) </Typography>
                    <MultiInputForm inputCount={2}
                        labelList={["左手", "右手"]}
                        unitName={unitName} formula="avg" onChange={onChangeStandard} />
                </Box>

                <Box>
                    <Typography variant="h3">MultiInput (AVG) </Typography>
                    <MultiInputForm unitName={unitName} formula="avg" onChange={onChangeStandard} />
                </Box>
                <Box>
                    <Typography variant="h3">MultiInput (MAX) </Typography>
                    <MultiInputForm unitName={unitName} formula="max" onChange={onChangeStandard} />
                </Box>

                <Box>
                    <Typography variant="h3">StandardInput</Typography>
                    <StandardInputForm unitName={unitName} onChange={onChangeStandard} />
                </Box>

                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
            </Stack>
        </Container>
    )
}
