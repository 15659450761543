import React, { useState, useEffect } from "react";
/** Helper */

/** Component */
import { Box, Grid, Typography, Container } from "@mui/material";
import CommonTable from "../../components/common/CommonTable";
import CommonFilter from "../../components/common/CommonFilter";
import CommonBreadcrumb from "../../components/common/CommonBreadcrumb";
/** Content */
import TableConfig from "../../components/test/tableConfig.jsx";
// import TableConfig from "../../components/organization/index/listTableContent";



export default function Component() {
  const [filterQuery, setFilterQuery] = useState(false);

  useEffect(() => {
    console.log("filterQuery", filterQuery);
    let filters = [];
    // filters.push({ 'field': 'display_name', 'value': "ken", 'op': 'like' });
    // setFilterQuery(filters);
  }, []);



  return (
    <Container maxWidth="md">
      <Grid container direction={"column"} gap={2}>
        <CommonBreadcrumb dbData={{ name_zh: "vvv" }} />
        <CommonFilter
          filterConfig={TableConfig.filterConfig}
        />
        <CommonTable
          filterQuery={filterQuery}
          tableConfig={TableConfig}
          debug={true}
        />
      </Grid>
    </Container>
  );
}
