import React, { useState, useEffect, useContext } from "react";
/** Helper */
import { localizedName } from "../../../helper/LangHelper";
import { useTranslation, Translation } from "react-i18next";
import { AdminApi } from "../../../api/AdminApi";
/** Component */
import {
  Box,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  Stack,
  Button,
} from "@mui/material";

import ConfirmInputDialog from "../ConfirmInputDialog";
import MqttInputDialog from "./MqttInputDialog";

/** Content */
import inputFormPropsConfig from "../skill/data/inputFormPropsConfig.json";

function getAvailableMicrobit({ import_setting }) {
  if (import_setting === null) return [];
  if (!("auto_import" in import_setting)) return [];
  if (!import_setting?.auto_import?.devices) return [];
  return import_setting.auto_import.devices;
}

export default function SkillMqttAutoInput({ skill, eventID, qrCode }) {
  const availableMicrobit = getAvailableMicrobit(skill);
  const [confirmResult, setConfirmResult] = useState(null);
  // const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { t } = useTranslation();
  // Custom value
  const unitName = t("unit." + skill.unit);
  // const skillName = localizedName(skill, "name");

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const callCheckInput = async (score = 0) => {
    const skillCode = skill.code;

    // ken: todo: remove useless eventID
    const result = await AdminApi.checkSkillInput(
      eventID,
      qrCode,
      skillCode,
      score
    );
    console.debug("result=", result);
    setConfirmResult(result);
    handleDialogOpen(true);
  };

  return (
    <Box>
      {confirmResult && (
        <ConfirmInputDialog
        eventID={eventID}
          confirmResult={confirmResult}
          open={isDialogOpen}
          onClose={handleDialogClose}
        />
      )}

      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Stack spacing={3}>
            <Typography variant="h6" sx={{ mt: 1 }}>
              自動輸入
            </Typography>
          </Stack>
          {!qrCode ? (
            <Typography
              variant="caption"
              fontWeight="bold"
              color="error"
              sx={{ mb: 1 }}
            >
              * 請先掃描 QR Code
            </Typography>
          ) : null}
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
            請選擇將會發送資料的 Micro:bit
          </Typography>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            my={3}
            mx={0.1}
            gap={2}
          >
            {availableMicrobit.map((microbit, index) => {
              return (
                <MqttInputDialog
                  microbit={microbit}
                  qrCode={qrCode}
                  key={`micro-bit-${index}`}
                  inputConfig={inputFormPropsConfig[skill.code]}
                  unitName={unitName}
                  callCheckInput={callCheckInput}
                />
              );
            })}
          </Grid>
          {/* <MultiInputForm
            key={"auto-input-form"}
            {...inputFormPropsConfig[skill.code]}
            unitName={unitName}
            onChange={(value) => {}}
          /> */}
        </CardContent>
      </Card>
    </Box>
  );
}
