import React, { } from "react";
import { Grid, Box, Button } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import Typography from "../../theme/overrides/Typography";


export default function SkillSelectionList({
    skillList, onSelect
}) {

    const handleClick = (skill) => {
        console.log("handleClick: ", skill);
        onSelect(skill);
    }


    return (
        <Grid container spacing={2}>


            {
                skillList.map((skill, index) => {
                    // let skillName = localizedName(skill, 'name');
                    // let fitnessName = localizedName(skill, 'fitness_type');
                    let skillNameEn = skill.name_en;
                    // localizedName(skill, 'nane');
                    let skillNameZh = skill.name_zh;
                    // localizedName(skill, 'Name_zh');
                    return (
                        <Grid item xs={6} md={3}>
                            <Button fullWidth key={index} variant="contained" color="primary"
                                onClick={
                                    () => handleClick(skill)
                                }>
                                {
                                    //JSON.stringify(skill)
                                }
                                {skillNameEn}
                                <br />
                                ({skillNameZh})
                            </Button>
                        </Grid>
                    );
                })
            }
        </Grid>

    );
}