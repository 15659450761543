import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import AdminEventStatSection from '../../sections/_dashboard/AdminEventStatSection';
import {
  getlocalStorageUserObject,
} from "../../helper/LocalStorageHelper";

// ----------------------------------------------------------------------

export default function UserStatisticPage() {
  const user = getlocalStorageUserObject();

  return (
    <>
      <title> Event Statistics </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            活動統計資料查詢
          </Typography>
          <AdminEventStatSection  userID={user.id} mode="user" />
        </Stack>

      </Container>
    </>
  );
}
