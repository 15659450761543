import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";

const EquipmentBaseInfoForm = React.memo(
  ({ existingData = null, onUpdateData }) => {
    const [data, setData] = useState({ name_en: "", name_zh: "", slug: "" });
    const [touched, setTouched] = useState({
      name_en: false,
      name_zh: false,
      slug: false,
    });

    const handleOnChange = useCallback((event) => {
      const { name, value } = event.target;
      setData((prevState) => ({ ...prevState, [name]: value }));
    }, []);

    const handleOnBlur = useCallback((event) => {
      const { name } = event.target;
      setTouched((prevState) => ({ ...prevState, [name]: true }));
    }, []);

    const handleOnSubmit = useCallback(() => {
      onUpdateData(data);
    }, [data, onUpdateData]);

    useEffect(() => {
      if (existingData === null) return;
      let { name_en, name_zh, slug } = existingData;
      setData((pre) => ({ ...pre, name_en, name_zh, slug }));
    }, [existingData]);

    useEffect(() => {
      handleOnSubmit();
    }, [data]);

    const isNameEnEmpty = touched.name_en && data.name_en === "";
    const isNameZhEmpty = touched.name_zh && data.name_zh === "";
    const isSlugEmpty = touched.slug && data.slug === "";

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="設備名稱"
            variant="outlined"
            fullWidth
            name="name_zh"
            value={data.name_zh}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isNameZhEmpty}
            helperText={isNameZhEmpty ? "必須輸入設備名稱" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Equipment Name"
            variant="outlined"
            fullWidth
            name="name_en"
            value={data.name_en}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isNameEnEmpty}
            helperText={isNameEnEmpty ? "Equipment Name is required" : ""}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Slug"
            variant="outlined"
            fullWidth
            name="slug"
            value={data.slug}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={isSlugEmpty}
            helperText={isSlugEmpty ? "Slug is required" : ""}
          />
        </Grid>
      </Grid>
    );
  }
);

export default EquipmentBaseInfoForm;
