import { useTheme } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Breadcrumbs } from '@mui/material';
import HealthDataImportCard from "../components/app/HealthDataImportCard";
import MockData from "../helper/MockData";
import {useState, useEffect} from "react";
import {InfoApi} from "../api/InfoApi";

// ----------------------------------------------------------------------

export default function TanitaImportPage() {
  const [eventList, setEventList] = useState(null);

  useEffect(() => {
    loadEventList();
  }, []);

  const loadEventList = async () => {
    // console.debug("currentMemberID: ", currentMemberID);
    const result = await InfoApi.getEventList();
    console.debug("result: ", result);
    setEventList(result);
  };
  // const theme = useTheme();
  // const eventList = MockData.getEventList();

  return (
    <>

      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">匯入Tanita</Typography>
        </Breadcrumbs>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HealthDataImportCard eventList={eventList} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
