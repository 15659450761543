import {
    Container,
    Typography,
    Box,
    Grid,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";
import CommonDashboardCard from "../../components/common/CommonDashboardCard/CommonDashboardCard";
import MainCard from "../../components/common/MainCard/MainCard";

export default function TestSimple() {
    const { t } = useTranslation();
    const [eventID, setEventID] = React.useState(0);

    let lang = getLang();

    // const CommonDataSelection = ({ key, label, value, dataList, captionFn, onChange }) => {
    const onEventChange = (event) => {
        setEventID(event.target.value);
    }

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Common Dashboard Card</Typography>

                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>

                <Grid container alignItems="center">
                    <Grid item xs={4} sx={{p: 2}}>
                        <CommonDashboardCard title="title" value="1234" />
                    </Grid>

                    <Grid item  xs={4} sx={{p: 2}}>
                        <CommonDashboardCard title="title" value="1234" />
                    </Grid>
                </Grid>


                <MainCard border={false} boxShadow={true} contentSX={{ p: 2 }}>
                    <Stack>
                        <Typography variant="h6" color="textSecondary">
                            title
                        </Typography>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography variant="h4" color="inherit">
                                    1234
                                </Typography>
                            </Grid>

                        </Grid>
                    </Stack>
                </MainCard>

            </Stack>
        </Container>
    )
}
