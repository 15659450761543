import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

import MockData from "../../helper/MockData";
import { getCurrentDateStr } from "../../helper/StringHelper";
import CoSportRankingBarItem from "../../components/leaderBoard/CoSportRankingBarItem";
import CoSportRankingBarItemForKid from "../../components/leaderBoard/CoSportRankingBarItemForKid";
import CoSportSchoolListCard from "../../components/leaderBoard/CoSportSchoolListCard";
import CoSportSchoolInfoCard from "../../components/leaderBoard/CoSportSchoolInfoCard";
// CoSportSchoolListCard

const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];



export default function Test() {
    const { t } = useTranslation();

    let lang = getLang();

    const oneTopSkillOrg = MockData.getOneSkillTopOrganization();
    const orgName = "Test";

    const eventID = 22;
    const skillCode = "vjump";
    
    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TestSportDisplay</Typography>

                <CoSportSchoolInfoCard eventID={eventID} skillCode={skillCode} />
            </Stack>

        </Container>
    )
}
