import React from "react";
import { Button } from "@mui/material";

const CommonButton = ({
    caption, onClick
}) => {

    const buttonCaption = caption ? caption : "Click Me";

    return (<Button variant="contained" color="primary">
        {buttonCaption}
    </Button>);

}

export default CommonButton;