import { Box, Grid, Typography, Container, Stack } from "@mui/material";

import { useTranslation, Translation } from "react-i18next";
import { useEffect, useState } from "react";
import MockData from "../../../helper/MockData.js";
import {
  getlocalStorageUserObject,

} from "../../../helper/LocalStorageHelper";
import { AdminApi } from "../../../api/AdminApi.js";

const MAIN_FONT = "Noto Sans TC";

const cardStyle = {
  flexGrow: 1,
  width: '100%',
  // height: '80px',
  color: 'white',
}

const kidCardStyle = {
  flexGrow: 1,
  width: '100%',
  // height: '80px',
  color: 'black',

  // minWidth: 290,
}


const getCardStyle = (isKidTheme) => {
  if (isKidTheme === false) {
    return kidCardStyle;
  } else {
    return cardStyle;
  }
}

const formatDistanceScore = (score, unit) => {
  if (unit === "cm") {
    if (score < 100) {
      return `${score} ${unit}`
    }
    score = score * 0.1; // cm to m 
    score = Math.round(score);
    unit = "m"
  }

  if (unit === "m") {
    if (score < 1000) {
      return `${score} ${unit}`
    }
    score = score * 0.001; // cm to m 
    unit = "km"
  }
  score = Math.round(score);

  return `${score.toLocaleString()} ${unit}`
  // return  + unit;
}

const formatScore = (score, unit, t) => {
  // score = 377.70000000000005;
  // unit = "m";
  score = Math.round(score);

  if (unit === "cm" || unit === "m") {
    return formatDistanceScore(score, unit)
  }

  const unitName = t("unit." + unit);

  return `${score.toLocaleString()} ${unitName}`;
}

export default function Component({ eventID, skillCode }) {
  const [scoreInfo, setScoreInfo] = useState(null);


  // const orgID = 1;
  // const orgName = "Testing";
  // const orgType = "primary";


  const { t } = useTranslation();

  const loadScoreInfo = async (eventID, skillCode) => {
    // loadCoSportSkillScore
    const response = await AdminApi.loadCoSportSkillScore(eventID, skillCode);
    if(!response) {
      console.error("loadCoSportSkillScore failed: no response. eventID=", eventID, " skillCode=", skillCode);
      return;
    }

    const {success, record, message} = response;
    if(!success) {
      console.error("loadCoSportSkillScore failed: ", message, " eventID=", eventID, " skillCode=", skillCode);
      return;
    }

    setScoreInfo(record);
  }


  useEffect(() => {
    // const fetchData = async () => {
    // const scoreInfo = MockData.getCoSportOrganizationScore(orgID, skillCode);
    // ken: for mock test
    // setScoreInfo(MockData.getCoSportOrganizationScore());
    // };
    loadScoreInfo(eventID, skillCode);
    // fetchData();
  }, []);

  //  const [scoreInfo, setScore] = React.useState(null);


  // const scoreinfo = MockData.getCoSportOrganizationScore();
  const isReady = scoreInfo !== null;
  const schoolName = scoreInfo?.organization?.name_zh;
  const score = scoreInfo?.skill_info?.total_score;
  const unit = scoreInfo?.skill_info?.unit;
  const orgType = scoreInfo?.organization?.type || "primary";

  const scoreStr = scoreInfo ? formatScore(score, unit, t) : ""; // `${score} ${unit}`;


  const isKidTheme = orgType === "kid" || orgType === "primary";
  const fontColor = isKidTheme ? "#552E00" : "white";
  let cardStyle = getCardStyle(isKidTheme);


  cardStyle = {
    ...cardStyle,
    justifyContent: 'center',
    alignItems: 'center',
  }
  const starImageSrc = "/assets/icon-images/star-200x200.png";
  const startStyle = { width: "20px", height: "20px", objectFit: "contain" };


  return (
    <Box
      display="flex"
      align="center"
      sx={
        cardStyle
      }

    >
      {/* <pre style={{color:"#999"}}>DEBUG: {eventID} / {skillCode} </pre> */}
      {
        isReady && (
          <>
            <Box>
              <img alt="" src={starImageSrc}
                style={startStyle} />
            </Box>
            <Typography
              variant={"h4"}
              sx={{
                fontFamily: MAIN_FONT,
                color: fontColor,
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              {schoolName} 共創了 {scoreStr}
            </Typography>
            {/* <pre style={{color:"#888"}}>
              {JSON.stringify(userObject, null, 2)}
            </pre> */}
            <Box>
              <img alt="" src={starImageSrc}
                style={startStyle} />
            </Box>
          </>
        )


      }

    </Box >

  );
}
