import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import ColorDashCard from "../../components/common/ColorDashCard";
import ImageDashCard from "../../components/common/ImageDashCard";
// import RoundIcon from "../../components/common/RoundIcon";
import RoundIcon from "../../components/common/RoundIcon/RoundIcon";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AdbIcon from '@mui/icons-material/Adb';

import WhitePersonIcon from "../../components/common/Icons/WhitePersonIcon";

export default function TestDashCard() {
    const { t } = useTranslation();

    let lang = getLang();

    const cardStyle = {
        cardColor: "rgba(255, 255, 255, 0.8)",
        iconBgColor: "#ffc",
        iconColor: "#f0f",
        maxWidth: 300, 
    }


    const imageCardStyle = {
        cardColor: "rgba(0, 0, 0, 0.15)",
        iconBgColor: "#ffc",
        iconColor: "#f0f",
        valueTextColor: "#D6FF33",
        labelTextColor: "#fff",
        maxWidth: 400, 
    }


    return (
        <Container maxWidth="lg" >
            <Stack direction="column" sx={{ background: '#595', padding: 3 }} >
                <Typography variant="h3">TestDashCard</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                { /* title, value, color, */}
                <Box >
                    {/* <RoundIcon icon="AccessibilityIcon" /> */}
                    <Stack direction="row" spacing={2}>
                        <RoundIcon
                            component={AccessibilityIcon}
                            bgColor={"#fcc"}
                            color={"#f22"}
                            padding={2}
                        />

                        <RoundIcon
                            component={AccessTimeIcon}
                            size={50}
                            bgColor={"#fcc"}
                            color={"#f22"}
                        />

                        <RoundIcon
                            component={AdbIcon}
                            size={40}
                            bgColor={"#fcc"}
                            color={"#f22"}
                        />
                    </Stack>




                    <hr />
                    <ColorDashCard 
                        label="共創數據" value="12345 cm" 
                       
                        icon={AccessibilityIcon}
                        styleSetting={cardStyle} />

                    <ImageDashCard 
                        label="共創數據" value="12345 cm" 
                        imageSize={80}
                        icon={WhitePersonIcon}
                        styleSetting={imageCardStyle} />
                </Box>
            </Stack>
        </Container>
    )
}
