import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

import MockData from "../../helper/MockData";
import { getCurrentDateStr } from "../../helper/StringHelper";

import CoSportAnalyticsDisplay from "../../components/leaderBoard/CoSportAnalyticsDisplay";
import CoSportAnalyticsDisplayForKid from "../../components/leaderBoard/CoSportAnalyticsDisplayForKid";
const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];



export default function TestCoSportRankingDisplay() {
    const { t } = useTranslation();

    let lang = getLang();

    const analyticData = MockData.getOneSkillStatistics();

    // let leaderBoardResult = MockData.getOneTimeslotLeaderboard();
    // MockData.getOneTimeslotLeaderboard();

    let eventId = 3;

    const barData = {
        rank: 1,
        schoolName: "Test School",
        score: 100,
    }

    const skillInfo = {

        "id": 8,
        "name_zh": "單腳平衡測試",
        "name_en": "Single-leg Balance",
        "code": "balance",
        "fitness_type": "balance",
        "fitness_type_en": "Single-leg Balance",
        "fitness_type_zh": "單腳平衡測試",
        "unit": "pt",
        "decimal_place": 0,
        "rank_direction": "desc",
    }

    // const themeData = {
    //     // bg: "blue",
    //     bg: "green",
    //     barColor: "#a00"
    // }

    // const themeData = {
    //     bg: "blue",
    //     barColor: "#f80"
    // }

    const themeData = {
        bg: "orange",
        barColor: "#afa"
    }

    const performData = MockData.getSkillPerformStatistics();
    const unitStr = "cm"

    // const themeData = {
    //     bg: "purple",
    //     barColor: "#afa"
    // }


    return (
        <Stack direction="column">
            <Typography variant="h3">Test TestSportDisplay</Typography>


            <Box>
                <p>Current Lang: {lang}</p>
                <pre>
                    {/* {JSON.stringify(leaderBoardResult, null, 2)} */}
                </pre>
            </Box>

            <Box width="100%" >
                <CoSportAnalyticsDisplayForKid display="flex"
                    sx={{ justifyContent: "center" }}
                    analyticData={analyticData}
                    themeData={themeData} skillInfo={skillInfo} />

                <CoSportAnalyticsDisplay display="flex"
                    sx={{ justifyContent: "center" }}
                    analyticData={analyticData}
                    themeData={themeData} skillInfo={skillInfo} />


            </Box>
        </Stack>

    )
}
