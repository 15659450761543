import {
  Box, Grid, Typography,
  Avatar,
  Container, Stack
} from "@mui/material";

import RoundIcon from "../../common/RoundIcon/RoundIcon";
import { useState } from "react";
import { formatNumber } from "../../../helper/StringHelper";
import headerImage from "../../../public/dashboard-kid-header.png";

const MAIN_FONT = "Noto Sans TC";

export default function Component({ heading, subHeading }) {
  heading = heading || "Unknown Heading";
  subHeading = subHeading || "N/A";

  const textColor = "#662A00";
  // const src = headerImage;
  heading = heading || "共創運動紀錄 (綜覽)";

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      sx={{
        flexShrink: 1, 
        width: '1000px',
        height: '150px',
        backgroundPosition: "center",
        backgroundImage: `url("${headerImage}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        // background: {headerImage},
      }}
    >
      <Box>
        <Typography
          color={textColor}
          sx={{
            width: 'fit-content',
            fontFamily: MAIN_FONT,
            fontSize: "48px",
            fontWeight: "900",
            lineHeight: "60px",
            letterSpacing: "0em",
            borderBottom: `2px solid ${textColor}`,
          }}
        >
          {heading}
        </Typography>

      </Box>


      <Typography
        variant={"h3"}
        color={textColor}
        sx={{
          fontFamily: MAIN_FONT,
          fontSize: "68px",
          fontWeight: "900",
          lineHeight: "60px",
          letterSpacing: "0em",
        }}
      >
        {subHeading}
      </Typography>

    </Box >

  );
}

