import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";
import RedemptionInfoCard from "../../components/redemption/RedemptionInfoCard";

export default function TestRedemptionInfoCard() {
    const { t } = useTranslation();

    let lang = getLang();
    let member = MockData.getMember();
    let redemptionInfo = MockData.getRedemptionInfo();

    const handleRedeemClicked = () => {
        console.debug("handleRedeemClicked called");
    }

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestRedemptionInfoCard</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <RedemptionInfoCard member={member} 
                    redemptionInfo={redemptionInfo} onRedeemClicked={handleRedeemClicked}/>
            </Stack>
        </Container>
    )
}
