import { Box, Grid, Typography, IconButton, Button, Container, Stack } from "@mui/material";

import LeaderDisplay from "../LeaderDisplay";
import SvgBackBtn from "../../../components/icon/SvgBackBtn.jsx";
import SvgForwardBtn from "../../../components/icon/SvgForwardBtn.jsx";
import SvgToHome from "../../../components/icon/SvgToHome.jsx";

import leaderBoardBg_1 from "../../../public/leaderBoardBg_1.png";
import leaderBoardBg_2 from "../../../public/leaderBoardBg_2.png";
import leaderBoardBg_3 from "../../../public/leaderBoardBg_3.png";
import leaderBoardBg_4 from "../../../public/leaderBoardBg_4.png";
import { useEffect } from "react";
import { AdminApi } from "../../../api/AdminApi";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Footer from "../Footer/Footer.jsx";

import CoSportRankingBarItem from "../CoSportRankingBarItem";
import MockData from "../../../helper/MockData.js";
import CoSportRankingDisplay from "../CoSportRankingDisplay/CoSportRankingDisplay.jsx";
import CoSportRankingDisplayKid from "../CoSportRankingDisplayForKid/CoSportRankingDisplayForKid.jsx";
import CoSportRankingDisplayForKid from "../CoSportRankingDisplayForKid/index.jsx";

const MAIN_FONT = "Noto Sans TC";

const CarouselProps = {
  showThumbs: false,
  autoPlay: true,
  infiniteLoop: true,
  showIndicators: false,
  showStatus: false,
  showArrows: false,
  // interval: 60000,
  interval: 4000,
  emulateTouch: true,
};
const themeSetting = [
  { bg: "green", barColor: "#9DF9FF" },
  { bg: "blue", barColor: "#9DF9FF" },
  { bg: "orange", barColor: "#9DF9FF" },
  { bg: "purple", barColor: "#9DF9FF" },
]

export default function CoSportRankingCarousel({ onBackClicked, orgType }) {
  const [rankingData, setRankingData] = useState([]);
  const [skillInfo, setSkillInfo] = useState([]);
  const isKidTheme = orgType === "kid" || orgType === "primary";

  console.debug("CoSportRankingCarousel: orgType: ", orgType, " isKidTheme: ", isKidTheme);

  const loadDataFromServer = async () => {
    let response = await AdminApi.loadCoSportTopRanking();
    // const response = MockData.skillTopOrganization();
    handleRankingData(response)
  }

  useEffect(() => {
    loadDataFromServer();
  }, []);

  const handleRankingData = (response) => {
    console.log("handleRankingData: ", response)
    let rankingDataMap = response.records;
    let skillInfo = response.skillInfo;

    setSkillInfo(skillInfo);

    let rankingDataList = [];

    for (let skillCode in rankingDataMap) {
      let rankingDataItem = {
        skillCode: skillCode,
        rankingData: rankingDataMap[skillCode]
      }
      rankingDataList.push(rankingDataItem);
    }
    setRankingData(rankingDataList);

  }


  const handleBackHome = () => {
    console.debug("handleBackHome: called")
    if (onBackClicked) {
      onBackClicked();
    }
  }

  const handleScrollLeft = () => {

  }

  const handleScrollRight = () => {

  }


  const getSkillInfo = (skillCode) => {
    for (let skillInfoItem of skillInfo) {
      if (skillInfoItem.code === skillCode) {
        return skillInfoItem;
      }
    }

    return ""
  }

  const getTheme = (index) => {
    let themeIndex = index % themeSetting.length;
    return themeSetting[themeIndex];
  }


  const showLeft = false
  const showRight = false

  return (
    <Box position={"relative"} overflow="hidden">
      <Box position={"absolute"} left={10} top={10} zIndex={10}>
        <IconButton onClick={() => handleBackHome()}>
          <SvgToHome />
        </IconButton>
      </Box>

      <Footer />
      <Box
        position={"absolute"}
        left={10}
        top={"50%"}
        zIndex={10}
        display={showLeft ? "block" : "none"}
      >
        <IconButton
          onClick={() => {
            handleScrollLeft();
          }}
        >
          <SvgBackBtn />
        </IconButton>
      </Box>
      <Box
        position={"absolute"}
        right={10}
        top={"50%"}
        zIndex={10}
        display={showRight ? "block" : "none"}
      >
        <IconButton
          onClick={() => {
            handleScrollRight();
          }}
        >
          <SvgForwardBtn />
        </IconButton>
      </Box>

      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={12}>
          <Box>
            {/* {leaderData.map((_sportLeader, index) => (
                <SportDisplay
                  SportLeader={_sportLeader}
                  eventID={eventId}
                  date={TODAY}
                  key={`carousel-${_sportLeader.skill_code}-${index}`}
                />
              ))} */}
            <Carousel {...CarouselProps}>
              {
                rankingData.map((rankingDataItem, index) => {
                  return (
                    isKidTheme ?
                      <CoSportRankingDisplayForKid
                        rankingData={rankingDataItem.rankingData}
                        skillInfo={getSkillInfo(rankingDataItem.skillCode)}
                        themeData={getTheme(index)}
                        key={`carousel-${rankingDataItem.skillCode}-${index}`}
                      /> :
                      <CoSportRankingDisplay
                        rankingData={rankingDataItem.rankingData}
                        skillInfo={getSkillInfo(rankingDataItem.skillCode)}
                        themeData={getTheme(index)}
                        key={`carousel-${rankingDataItem.skillCode}-${index}`}
                      />
                  )
                })
              }
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
