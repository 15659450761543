import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Link,
  Container,
  Typography,
  Breadcrumbs,
} from "@mui/material";

import { useParams, useLocation } from "react-router-dom";

import HealthInputSection from "../sections/_dashboard/HealthInputSection";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function SkillInputPage() {
  const { skill_code } = useParams();

  const { state } = useLocation();
  const { skill, event } = state;

  const navigate = useNavigate();

  const eventName = event ? event.name_zh : "Unknown Event";

  const skillListLink = `/skill-list/${event.id}`;

  const handleNavEvent = (e) => {
    // console.debug("onDataInput");
    const options = {
      state: { event },
    };
    navigate(`/skill-list/${event.id}`, options);
  };

  return (
    <>
      <title> Dashboard | Minimal UI </title>

      <Container maxWidth="xl">
        {/* BreadCrumbs */}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>

          <Link underline="hover" color="inherit" onClick={handleNavEvent}>
            {eventName}
          </Link>

          <Typography color="text.primary">Health Input</Typography>
        </Breadcrumbs>

        {/* Content  */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HealthInputSection event={event} />
          </Grid>
        </Grid>
        <Box>
          {/* {/* <pre>{skill_code}</pre> */}
          {/* <pre>{JSON.stringify(skill)}</pre>
          <pre>{JSON.stringify(event)}</pre> */}
        </Box>
      </Container>
    </>
  );
}
