import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Container, Typography } from '@mui/material';
import AdminLeadboardSection from '../sections/_dashboard/AdminLeadboardSection';
// ----------------------------------------------------------------------

export default function AdminLeaderboardPage() {
  const theme = useTheme();

  return (
    <>
      <title> Dashboard | Minimal UI </title>


      <Container maxWidth="xl">
        <Stack direction="column">
          <Typography variant="h4" sx={{ mb: 5 }}>
            排行榜資料查詢
          </Typography>
          <AdminLeadboardSection />
        </Stack>

      </Container>
    </>
  );
}
