import { useTheme } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Breadcrumbs } from '@mui/material';
// import EventListSection from '../sections/_dashboard/EventListSection';
import {
  getlocalStorageUserObject,
} from "../../helper/LocalStorageHelper";
import UserEventListSection from '../../sections/_dashboard/UserEventListSection';

// ----------------------------------------------------------------------

export default function UserLeaderboardPage() {
  // const [user]
  const theme = useTheme();
  const user = getlocalStorageUserObject();
  // const userObj = JSON.parse(user);


  // console.debug("user=", user);
  // console.debug("userObj=", userObj);
  const username = user.username;
  // console.debug("username=", username);

  return (
    <>
      <title> User Dashboard </title>


      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">User Dashboard</Typography>
        </Breadcrumbs>

        <Typography variant="h4" sx={{ mb: 5 }}>
          Welcome back, {username}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserEventListSection />
            {/* <EventListSection /> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
