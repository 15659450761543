import React from "react";
import {
    Box,
} from "@mui/material";
// import iconImage from "../../../public/iconImages/white-person-icon.png";
import BaseWhiteIcon from "./BaseWhiteIcon";

const WhitePersonIcon = ({
    size, imageSize, padding, ...otherProps
}) => {
    

    let imagesrc = "/assets/icon-images/icon-person.png";

    return (
        // <Box component="img" src={imagesrc}  {...style} />
        <BaseWhiteIcon imageSrc={imagesrc}
            size={size} imageSize={imageSize} padding={padding} {...otherProps} />
    );

}

export default WhitePersonIcon;


