import React, { } from "react";
import { useState, useEffect } from "react";
import {
    InputAdornment,
    OutlinedInput,
} from "@mui/material";

export default function StandardInputForm({
    unitName, onChange
}) {
    const [skillValue, setSkillValue] = useState(0);

    useEffect(() => {
        onChange({
            skillValue
        })
    }, [skillValue]);

    const handleOnChange = (e, type) => {
        // console.debug("handleOnChange called ", formik.values);
        if (type === 'skillValue') {
            setSkillValue(e.target.value);
        }
    }


    return <OutlinedInput
        id="skillValue"
        value={skillValue}
        inputProps={{ inputMode: 'numeric' }}
        // value={formik.values.skillValue}
        // onChange={formik.handleChange}

        onChange={(e) => {
            // formik.handleChange(e);
            handleOnChange(e, 'skillValue');
            // handleOnChange();
        }}
        endAdornment={<InputAdornment position="end">{unitName}</InputAdornment>}
        aria-describedby="outlined-weight-helper-text"
    />
}