import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import OrgMissionSettingListTable from "../../components/organization/organizationMissingSettings/OrgMissionSettingListTable";
import OrganizationDropdown from "../../components/organization/OrganizationDropdown";

export default function TestOrgMissionList() {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(1234);
    const [organization, setOrganization] = React.useState(null);

    React.useEffect(() => {
        setValue(1234);
    }, []);


    const onOrganizationSelected = (org) => {
        console.debug("onOrganizationSelected=", org);
        // setOrg(org);
        setOrganization(org);
    }

    let lang = getLang();

    // setValue(1234);

    const getOrgID = () => {    
        if (organization === null) {
            return 0;
        }

        return organization.id;
    }

    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test Organization Mission List</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                    <pre>Value: {value} </pre>
                </Box>
                <OrgMissionSettingListTable orgID={getOrgID()} />

                <OrganizationDropdown onOrganizationSelected={onOrganizationSelected} />

                <h2>Selected Org:</h2>
                <pre>
                    {JSON.stringify(organization, null, 2)}
                </pre>

            </Stack>
        </Container>
    )
}
