import React from "react";
import {
    Typography,
    Grid,
    Stack,
    Box,
    Avatar,
} from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import MainCard from "../MainCard/MainCard";
import RoundIcon from "../RoundIcon/RoundIcon";
import PendingIcon from '@mui/icons-material/Pending';

const ImageDashCard = ({
    label, value, icon,
    styleSetting, 
}) => {
    const cardColor = styleSetting?.cardColor || "rgba(0,0,0,0.2)";
    const iconColor = styleSetting?.iconColor || "#fff";
    const iconBgColor = styleSetting?.iconBgColor || "#fcc";
    const valueTextColor = styleSetting?.valueTextColor || "#fff";
    const labelTextColor = styleSetting?.labelTextColor || "#fff";

    const mainStyle = {
        background: cardColor,
    }


    let maxWidth = styleSetting?.maxWidth || 0;
    if (maxWidth > 0) {
        mainStyle.maxWidth = maxWidth;
    }

    let minWidth = styleSetting?.minWidth || 0;
    if (minWidth > 0) {
        mainStyle.minWidth = minWidth;
    }

    let iconComponent = icon

    const boxStyle = {
        justifyContent: "space-between",
        alignItems: 'center',
        ...mainStyle,
        padding: 1,
        borderRadius: 2,
        border: "2px solid white",
        ...styleSetting
    }

    return (
        <Box display="flex" sx={boxStyle}>
            <Stack sx={{ paddingLeft: 1, alignItems: "start" }} display={"flex"} >
                <Typography variant="h3" color={valueTextColor}>
                    {value}
                </Typography>
                <Typography variant="h6" color={labelTextColor}>
                    {label}
                </Typography>
            </Stack>
            <Box
                component={iconComponent}
                // size={70}
                imageSize={55}
                // bgColor={iconBgColor}
                color={iconColor}
                padding={1}
            />


        </Box>

    );

}

export default ImageDashCard;


