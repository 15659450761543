import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const mainField = "id";

const dataFieldConfig = [
  {
    id: "name_zh",
    field: "name_zh",
    align: "center",
    disablePadding: false,
    label: "活動名稱",
  },
  {
    id: "name_en",
    field: "name_en",
    align: "center",
    disablePadding: false,
    label: "Event Name",
  },
  {
    id: "type",
    field: "type",
    align: "center",
    disablePadding: false,
    label: "類型",
  },
  {
    id: "start_at",
    field: "start_at",
    align: "center",
    disablePadding: false,
    label: "開始日期",
  },
  {
    id: "end_at",
    field: "end_at",
    align: "center",
    disablePadding: false,
    label: "結束日期",
  },
];

const fetchDataConfig = {
  url: "/event/queryWithOrg",
  // fixedFilter: [{ field: "organization_id", value: null, op: "eq" }],
  extraQuery: [{ key: "org", field: "organization_id" }],
};

const tableActionConfig = [
  {
    type: "link",
    label: "Detail",
    icon: "detail",
    // icon: EmojiEventsIcon,
    url: "/organization/$organization_id/event/detail/$id",
  },
  {
    type: "confirmDialog",
    label: "Delete",
    icon: "delete",
    url: "/event/delete/$id",
    dialogConfig: {
      /** https://sweetalert2.github.io/#usage */
      title: "確定要刪除嗎？",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonAriaLabel: "確定",
      cancelButtonAriaLabel: "取消",
      cancelButtonColor: "#a7a7a7",
      successMsg: "刪除成功",
      failMsg: "刪除失敗",
    },
  },
];

const filterConfig = [
  {
    type: "text",
    label: "按 名稱 搜索",
    field: "name_en,name_zh",
  },
];

const TableConfig = {
  mainField,
  dataFieldConfig,
  fetchDataConfig,
  tableActionConfig,
  filterConfig,
};

export default TableConfig;
