import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import HealthDataConfirmCard from "../../components/app/HealthDataConfirmCard";
import HealthDataImportCard from "../../components/app/HealthDataImportCard";

import MockData from "../../helper/MockData";


export default function TestImportHealth() {
    const { t } = useTranslation();

    let lang = getLang();

    const healthData = MockData.getHealthData();
    const lastAccessTime = healthData.lastAcccessTime;
    const data = healthData.data;
    const eventList = MockData.getEventList();


    const handleConfirm = () => {
        console.debug("handleConfirm");
    };


    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">TestImportHealth</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <p>translateText: {t("testing.msg")}</p>
                </Box>
                <HealthDataImportCard eventList={eventList} />
                {/* <HealthDataConfirmCard healthData={data}
                         lastAccessTime={lastAccessTime} onConfirm={handleConfirm} /> */}
            </Stack>
        </Container>
    )
}
