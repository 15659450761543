const USER_STORE_KEY = 'vtc_admin_user';
const LANG_STORE_KEY = 'lang';

export const setLocalStorageLocale = (lang) => {
  localStorage.setItem(LANG_STORE_KEY, lang);
};

export const getLocalStorageLocale = () => {
  return localStorage.getItem(LANG_STORE_KEY);
};

export const setLocalStorageUser = (userInfo) => {
  localStorage.setItem(USER_STORE_KEY, JSON.stringify(userInfo));
};

export const getLocalStorageUser = () => {
  return localStorage.getItem(USER_STORE_KEY);
};

export const getlocalStorageUserObject = () => {
  const userJson = getLocalStorageUser();
  return JSON.parse(userJson);
};

export const removeLocalStorageUser = () => {
  localStorage.removeItem(USER_STORE_KEY);
};
