import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function DemoPage() {
  const theme = useTheme();

  return (
    <>
      <title> Dashboard | Minimal UI </title>


      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Simple Page
        </Typography>

        <Grid container spacing={3}>


          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="body1">
              This is a simple page.
            </Typography>

          </Grid>
        </Grid>
      </Container>
    </>
  );
}
