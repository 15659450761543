import {
    getLocalStorageUser,
    getlocalStorageUserObject,
} from "./LocalStorageHelper";

export const getStoredAccountOrgType = () => {
    var user = getlocalStorageUserObject();
    if (!user) {
        return "";
    }

    return user.orgType || "";
}

export const getStoredAccount = () => {
    var user = getLocalStorageUser();
    if (!user) {
        return null;
    }

    try {
        return JSON.parse(user);
    } catch (error) {
        console.log(error);
        return null;
    }
}