import { Box, Grid, Typography, Container, Stack } from "@mui/material";
import LeaderDisplay from "../LeaderDisplay";

import dashboardBackground1 from "../../../public/dashboard-kid-bg-1.png";
import { useEffect } from "react";
import { AdminApi } from "../../../api/AdminApi";
import { useState } from "react";

import CoSportRankingBarItemForKid from "../CoSportRankingBarItemForKid/index.jsx";
import CoSportDisplayHeaderForKid from "../CoSportDisplayHeaderForKid/index.jsx";
import CoSportSchoolListCard from "../CoSportSchoolListCard";

const MAIN_FONT = "Noto Sans TC";

const BackgroundMap = {
  blue: dashboardBackground1,
  orange: dashboardBackground1,
  purple: dashboardBackground1,
  green: dashboardBackground1,
};


export default function Component({ rankingData, skillInfo, themeData }) {
  const [localRankingData, setLocalRankingData] = useState(rankingData);


  const NothingDisplay = () => {
    return (
      <Box
        sx={{
          border: "3px solid #eee",
          borderRadius: "10px",
          boxShadow: " 0 3px 10px  #9ecaed;",
        }}
        my={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          py={4}
          px={4}
        >
          <Grid item xs={12}>
            <Typography variant="h2" color={"white"}>
              未有資料
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };


  const bgColor = themeData.bg || "blue"
  const barColor = themeData.barColor || "red"

  const getBarDataList = () => {
    return localRankingData.map((data, index) => {
      console.debug("data", data)
      return {
        rank: data.rank,
        schoolName: data.organization_name_zh,
        score: Math.round(data.sum_score),
      }
    })
  }

  const calculateBestScore = (dataList) => {
    let bestScore = 1
    for (let data of dataList) {
      let newScore = Math.round(data.sum_score)
      if (newScore > bestScore) {
        bestScore = newScore
      }
    }
    return bestScore
  }

  const bestScore = calculateBestScore(localRankingData)
  const skillName = skillInfo?.name_zh || "N/A"
  const unitStr = skillInfo?.unit || ""


  const dataBarList = getBarDataList().map((data, index) => {
    return (
      <>
        {
          index > 0 ? <Box sx={{ marginTop: 2, marginBottom: 2, width: '100%', background: '#ccc', height: '1px' }}></Box> : <></>
        }
        <CoSportRankingBarItemForKid key={`rand-${index}`}
          barData={data} bestScore={bestScore} unit={unitStr} />
      </>

    )
  }).slice(0, 5)

  // Ranking Bar Style  排行榜
  const barStyle = {
    background: "#FFE0C1",
    padding: "10px 50px",
    border: "8px solid #B96400",
    borderRadius: "20px",
    width: '90%',
    // marginTop: 3,
    // padding: 2,
    // background: 'rgba(0, 0, 0, 0.2)',
    // borderRadius: 2,
    // border: "2px solid white",
  }

  // console
  console.debug("dataBarList", dataBarList)

  const schoolList = rankingData.map((org, index) => {
    return {
      name: org.organization_name_zh
    }
  })

  return (
    <Box
      align="center"
      pt={2}
      minHeight={"100vh"}
      sx={{
        backgroundImage: `url("${BackgroundMap[bgColor]
          }")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      position="relative"
    >
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems={"center"}
        rowSpacing={2}
        pb={4}
        mb={4}
        sx={{
          // transform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg) ",
          // WebkitTransform: "rotateZ(-5deg) skewX(-5deg)" /* Safari & Chrome */,
          // MozTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Firefox */,
          // MsTransform:
          //   "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Internet Explorer */,
          // OTransform: "rotateY(12deg) rotateZ(-5deg) skewX(-5deg)" /* Opera */,
        }}
      >
        <Grid item xs={12} align={"center"}>
          <Grid item xs={12} align={"center"} sx={{ padding: 2 }}>
            <CoSportDisplayHeaderForKid heading="共創運動紀錄 (內部查閱)" subHeading={skillName || "N/A"} />
          </Grid>

        </Grid>
        { /* Ranking Bar */}
        <Box sx={barStyle}>
          <Stack>
            {dataBarList}
          </Stack>
        </Box>
        {/* <Typography>
  xxx
</Typography> */}
        <Box sx={{ width: '90%', paddingTop:1 }}>
          <CoSportSchoolListCard orgType={"primary"} schoolList={schoolList} />
        </Box>
      </Grid >
    </Box >
  );
}
