import React, { useEffect, useState } from "react";
import { Divider, Paper, Grid, Card, Stack, Box, Button, Typography } from "@mui/material";
import { getLang, localizedName } from "../../helper/LangHelper";
import { timeToTimeStr } from "../../helper/StringHelper";
import SkillRankTable from "./SkillRankTable";


export default function MemberEventSkillInfoCard({
    skillInfo, gender = 'M', unitName = ''
}) {
    // const lang = getLang();
    const skillName = localizedName(skillInfo, "name");
    const fitnessName = localizedName(skillInfo, "fitness_type");


    const skillRank = skillInfo.rank;
    const skillScoreStr = `${skillInfo.score} ${unitName}`;

    const rankTable = gender === 'M' ?
        skillInfo.skill_rank_male
        : skillInfo.skill_rank_female;

    return (
        <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Grid container>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{ color: 'white.main' }}>{fitnessName}</Typography>

                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h7" sx={{ color: 'white.main' }}>
                        測試項目：{skillName}
                    </Typography>

                </Grid>

                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ m: 2, p: 2 }}>
                        <Stack direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h6">等級</Typography>
                            <Typography variant="h4">{skillRank}</Typography>
                        </Stack>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ m: 2, p: 2 }}>
                        <Stack irection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h6">成績</Typography>
                            <Typography variant="h4">{skillScoreStr}</Typography>
                        </Stack>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <SkillRankTable rankTable={rankTable}
                        rank={skillRank}
                        unitName={unitName} />
                </Grid>

                <Grid item xs={12}>
                    {/* <Typography variant="h7" sx={{ color: 'white.main' }}>
                        測試時間：{timeToTimeStr(created_at)}
                    </Typography> */}
                </Grid>
            </Grid>
        </Card>
    );
}