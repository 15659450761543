import React, { useEffect } from "react";
import { Card, TextField, Typography, Box, Button, FormControl, Stack, MenuItem, InputLabel, Select } from "@mui/material";


import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


const CommonDatePicker = ({
    onChange,
    dateValue,
    minDate, 
    maxDate
}) => {
    const [selectedDate, setSelectedDate] = React.useState(dateValue);
    const [customDate, setCustomDate] = React.useState(dateValue === null ? false : true);


    // const customOrAll = (

    // );
    const onChangeCustomDate = (evt) => {
        setCustomDate(evt.target.value);
        if(evt.target.value === false) {    
            onChange(null);
        }
    };

    const onChangeDate = (newValue) => {
        setSelectedDate(newValue);
        onChange(newValue);
    };


    return (
        <Stack direction="row" spacing={1}>
            {/* <pre>customDate: {customDate}</pre> */}
            <Select
                labelId="custom_or_all"
                id="custom_or_all"
                value={customDate}
                label=""
                onChange={onChangeCustomDate}
            >
                <MenuItem value={true}>指定日期</MenuItem>
                <MenuItem value={false}>全部日期</MenuItem>
            </Select>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    inputFormat="YYYY-MM-DD"
                    value={selectedDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={!customDate}
                    onChange={(newValue) => {
                        onChangeDate(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            fullWidth

                            {...params}
                            label=""
                        />
                    )}
                />
            </LocalizationProvider>
        </Stack>
    );

}

export default CommonDatePicker;