import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";

import MockData from "../../helper/MockData";
import { getCurrentDateStr } from "../../helper/StringHelper";
import CoSportRankingBarItem from "../../components/leaderBoard/CoSportRankingBarItem";
import CoSportRankingBarItemForKid from "../../components/leaderBoard/CoSportRankingBarItemForKid";
import CoSportSchoolListCard from "../../components/leaderBoard/CoSportSchoolListCard";
// CoSportSchoolListCard

const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];



export default function Test() {
    const { t } = useTranslation();

    let lang = getLang();

    const oneTopSkillOrg = MockData.getOneSkillTopOrganization();

    // let leaderBoardResult = MockData.getOneTimeslotLeaderboard();
    // MockData.getOneTimeslotLeaderboard();

    let eventId = 3;

    const barData = {
        rank: 1,
        schoolName: "Test School",
        score: 100,
    }

    const barDataList = [
        {
            rank: 1,
            schoolName: "Test School",
            score: 1100,
        },
        {
            rank: 2,
            schoolName: "Test School",
            score: 500,
        },
        {
            rank: 3,
            schoolName: "Test School",
            score: 400,
        },
        {
            rank: 4,
            schoolName: "Test School",
            score: 100,
        },
    ]


    const skillInfo = {

        "id": 8,
        "name_zh": "單腳平衡測試",
        "name_en": "Single-leg Balance",
        "code": "balance",
        "fitness_type": "balance",
        "fitness_type_en": "Single-leg Balance",
        "fitness_type_zh": "單腳平衡測試",
        "unit": "pt",
        "decimal_place": 0,
        "rank_direction": "desc",
    }

    // const themeData = {
    //     // bg: "blue",
    //     bg: "green",
    //     barColor: "#a00"
    // }

    // const themeData = {
    //     bg: "blue",
    //     barColor: "#f80"
    // }

    const themeData = {
        bg: "orange",
        barColor: "#afa"
    }

    const schoolList= oneTopSkillOrg.map((org, index) => {
        return {
            name: org.organization_name_zh
        }
    })


    // const themeData = {
    //     bg: "purple",
    //     barColor: "#afa"
    // }


    return (
        <Container maxWidth="lg">
            <Stack direction="column">
                <Typography variant="h3">Test TestSportDisplay</Typography>


                <Box>
                    <p>Current Lang: {lang}</p>
                    <pre>
                        {/* {JSON.stringify(leaderBoardResult, null, 2)} */}
                    </pre>
                </Box>
                <pre>
                    {JSON.stringify(schoolList, null, 2)}
                </pre>
                <Box width="80%" sx={{ background: "#eee", padding: 3 }}>
                    <CoSportSchoolListCard orgType={"primary"} schoolList={schoolList}/>
                </Box>
                <Box width="80%" sx={{ background: "#eee", padding: 3 }}>
                    <CoSportSchoolListCard orgType={"secondary"}  schoolList={schoolList}/>
                </Box>

                <Box width="80%" sx={{ background: "#000", padding: 1 }}>
                    <CoSportRankingBarItem barData={barData} unit="cm" bestScore="1000" />
                </Box>

                {
                    barDataList.map((barData, index) => {
                        return (
                            <Box width="80%" sx={{ background: "#FFE0C1", padding: 1 }}>
                                <CoSportRankingBarItemForKid barData={barData} unit="cm" bestScore="1100" />
                            </Box>

                        )
                    })
                }
            </Stack>

        </Container>
    )
}
