import {
    Container,
    Typography,
    Box,
    Stack,
} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import MockData from "../../helper/MockData";
import CoSportAnalyticsCarousel from "../../components/leaderBoard/CoSportAnalyticsCarousel";

export default function TestCoSportAnalyticsCarousel() {
    const { t } = useTranslation();

    let lang = getLang();

    const component = CoSportAnalyticsCarousel;
    const response = MockData.getOverallSkillStatistics();
    return (
        <Container maxWidth="lg">
            <pre>
                {/* {JSON.stringify(response, null, 2)} */}
            </pre>
            <Stack direction="column">
                <CoSportAnalyticsCarousel orgType="primary" />
            </Stack>
        </Container>
    )
}
