import React, { } from "react";
import { Card, Stack, Box, Button, Typography } from "@mui/material";
import { localizedName } from "../../helper/LangHelper";
import { timeToDateStr } from "../../helper/StringHelper";
import { useNavigate } from "react-router-dom";
// import { MemberApi } from "../api/MemberApi";
// import { useSelector, useDispatch } from "react-redux";
// import { memberID, updateAuthState } from "../reducers/userSlice";


export default function HealthDataConfirmCard({
    healthData = null,
    lastAccessTime = null,
    group = null, 
    onConfirm = null,
}) {
    const groupStr = group ? group : '?';

    const info = <Box>
        <Typography variant="body1">
            數據數量：{healthData?.length}
        </Typography>
        <Typography variant="body1">
            機器ID: {groupStr}
        </Typography>
        {(lastAccessTime &&
            <Typography variant="body1">
                最後更新時間：{lastAccessTime}
            </Typography>
        )}
    </Box>

    const handleImport = () => {
        if (onConfirm) {
            onConfirm();
        }
    }

    return (
        <Card sx={{ p: 2 }}>
            <Stack direction="column" gap={2}>
                <Typography level="h2" sx={{ color: 'white.main', fontSize: '1.3rem', mb: 0.5 }}>
                    確認IMPORT數據資料
                </Typography>
                {info}
                <Box>
                    {healthData.length > 0 ? <Button variant="contained" color="primary" onClick={handleImport}>
                        確認 IMPORT TANITA 數據
                    </Button>
                        : <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            沒有新數據進行IMPORT
                        </Typography>
                    }
                </Box>
            </Stack>

        </Card >
    );

}