import {
    Container,
    Typography,
    Box,

} from "@mui/material";
import React from 'react';
import { useTranslation, Translation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import SkillInputForm from "../../components/app/SkillInputForm";
import MockData from "../../helper/MockData";
import SkillLabel from "../../components/app/SkillLabel";

export default function TestSkillInputForm() {
    const { t } = useTranslation();

    let lang = getLang();
    const skill = MockData.getSkillInfoList()[0];
    // skill.code = "vjump";
    // skill.name_zh = skill.code = "handgrip";
    skill.name_zh = skill.code = "ttest";

    return (
        <Container maxWidth="lg">
            <SkillLabel skill={skill}/>
            <Box mt={2} />

            <SkillInputForm skill={skill} />

        </Container>
    )
}
