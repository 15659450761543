import { Box, Grid, IconButton, Button } from "@mui/material";
// Component
import SportDisplay from "../components/leaderBoard/SportDisplay";
import Footer from "../components/leaderBoard/Footer";
import { Carousel } from "react-responsive-carousel";

// import Mock from "../components/leaderBoard/SportDisplay/mock.json";
// Icon
import SvgBackBtn from "../components/icon/SvgBackBtn.jsx";
import SvgForwardBtn from "../components/icon/SvgForwardBtn.jsx";
import SvgToHome from "../components/icon/SvgToHome.jsx";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";

import { AdminApi } from "../api/AdminApi";
import { getCurrentDateStr } from "../helper/StringHelper"; 
const TODAY = getCurrentDateStr(); // new Date().toISOString().split("T")[0];
// console.debug("TODAY: ", TODAY);

const CarouselProps = {
  showThumbs: false,
  autoPlay: true,
  infiniteLoop: true,
  showIndicators: false,
  showStatus: false,
  showArrows: false,
  // interval: 60000,
  interval: 4000,
  emulateTouch: true,
};

export default function Page() {
  const navigate = useNavigate();

  const handleBackTimeSlot = () => {
    let slot = leaderData.at(0).last_timeslot_id;
    if (slot === -1) return;
    AdminApi.loadEventLeaderboardCallback(eventId, TODAY, slot, setLeaderData);
  };
  const handleForwardTimeSlot = () => {
    let slot = leaderData.at(0).next_timeslot_id;

    if (slot === -1) return;
    AdminApi.loadEventLeaderboardCallback(eventId, TODAY, slot, setLeaderData);
  };
  const handleBackHome = (e) => {
    navigate("/");
  };

  const { eventId } = useParams();
  let [searchParams] = useSearchParams();
  // Get event id from url

  useEffect(() => {
    let timeslot = searchParams.get("timeslot") || 0;

    AdminApi.loadEventLeaderboardCallback(eventId, TODAY, timeslot, (data) => {
      setLeaderData(data);
    });
    return;
    // const result = await AdminApi.loadEvent(eventId, TODAY, timeslot);
  }, []);

  const [leaderData, setLeaderData] = useState([]);

  // forceUpdate(n => !n)

  return (
    <Box position={"relative"} overflow="hidden">
      <Box position={"absolute"} left={10} top={10} zIndex={10}>
        <IconButton onClick={() => handleBackHome()}>
          <SvgToHome />
        </IconButton>
      </Box>

      <Footer />
      <Box
        position={"absolute"}
        left={10}
        top={"50%"}
        zIndex={10}
        display={leaderData.at(0)?.last_timeslot_id === -1 ? "none" : "block"}
      >
        <IconButton
          onClick={() => {
            handleBackTimeSlot();
          }}
        >
          <SvgBackBtn />
        </IconButton>
      </Box>
      <Box
        position={"absolute"}
        right={10}
        top={"50%"}
        zIndex={10}
        display={leaderData.at(0)?.next_timeslot_id === -1 ? "none" : "block"}
      >
        <IconButton
          onClick={() => {
            handleForwardTimeSlot();
          }}
        >
          <SvgForwardBtn />
        </IconButton>
      </Box>
      <Grid container direction="row" alignItems={"center"}>
        <Grid item xs={12}>
          <Box>
            <Carousel {...CarouselProps}>
              {leaderData.map((_sportLeader, index) => (
                <SportDisplay
                  SportLeader={_sportLeader}
                  eventID={eventId}
                  date={TODAY}
                  key={`carousel-${_sportLeader.skill_code}-${index}`}
                />
              ))}
            </Carousel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
