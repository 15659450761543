import React from "react";
import { useState, useEffect } from "react";
import { InputAdornment, OutlinedInput, Stack, Box } from "@mui/material";
import { avg, max } from "../../../helper/MathHelper";
import Typography from "../../../theme/overrides/Typography";
// import { fShortenNumber } from "../../../utils/formatNumber";

function calculateResult(formula, valueArray) {
  if (formula === "avg") {
    return avg(valueArray);
  } else if (formula === "sum") {
    let total = 0.0;
    for (let v of valueArray) {
      total += parseFloat(v);
    }

    return total;
  } else if (formula === "yoyo") {
    if(valueArray === undefined) {
      return 0;
    }
    let v1 = valueArray.length <= 0 ? 0 : parseFloat(valueArray[0]);
    let v2 = valueArray.length <= 1 ? 0 : parseFloat(valueArray[1]);

    return v1 + v2 * 0.01;
  } else {
    return max(valueArray);
  }
}

// mode:
export default function MultiInputForm({
  inputCount = 3,
  formula = "max",
  unitName,
  labelList = [],
  onChange,
}) {
  const initialInputs = new Array(inputCount).fill("");
  const [skillValue, setSkillValue] = useState(0);
  const [valueArray, setValueArray] = useState(initialInputs);

  useEffect(() => {
    onChange({
      skillValue,
      valueArray,
    });
  }, [skillValue]);

  useEffect(() => {
    // setSkillValue(avg(valueArray));
    setSkillValue(calculateResult(formula, valueArray));
  }, [valueArray]);

  const getLabelName = (index) => {
    if (labelList == null) {
      // ken: no need
      return "";
    }

    if (labelList.length === 0) {
      return `第${index + 1}次`;
    }

    if (index < 0 || index >= labelList.length) {
      return "";
    }

    return labelList[index];
  };

  const handleOnChange = (e, index) => {
    // console.debug("handleOnChange called ", formik.values);
    // if (type === 'skillValue') {
    // setSkillValue(e.target.value);
    // }
    let newValueArray = [...valueArray]; // Copy array
    newValueArray[index] = e.target.value;

    setValueArray(newValueArray);
    console.debug("handleOnChange called ", e.target.value, " index=", index);
  };

  let aggregateLabel = "";
  if (formula === "avg") {
    aggregateLabel = "平均值";
  } else if (formula === "sum") {
    aggregateLabel = "總數";
  } else if (formula === "yoyo") {
    aggregateLabel = "YOYO值";
  } else {
    aggregateLabel = "最大值";
  }

  let inputForms = [];
  for (let i = 0; i < inputCount; i++) {
    inputForms.push(
      <Stack direction="row" gap={2} alignItems="center">
        <Box>{getLabelName(i)}</Box>
        <OutlinedInput
          id={`skillValue${i}`}
          key={`skil${i}`}
          value={valueArray[i]}
          inputProps={{ inputMode: "numeric" }}
          // value={formik.values.skillValue}
          // onChange={formik.handleChange}

          onChange={(e) => {
            // formik.handleChange(e);
            handleOnChange(e, i);
            // handleOnChange();
          }}
          endAdornment={
            <InputAdornment position="end">{unitName}</InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
        />
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <Stack direction="row" spacing={3}>
        {inputForms}
      </Stack>
      <Box sx={{ mt: 3 }}>
        {aggregateLabel}: {skillValue}
      </Box>

      {/* <Box component="pre">
            inputCount: {inputCount}

            formula: {formula}

            valueArray: {JSON.stringify(valueArray)}
            skillValue: {skillValue}
        </Box> */}
    </Stack>
  );
}
