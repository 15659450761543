import { Box, Stack, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { InfoApi } from "../../api/InfoApi";
import { useTranslation } from "react-i18next";
import { getLang } from "../../helper/LangHelper";
import AdminLeaderboardCard from "../../components/app/AdminLeaderboardCard";
import EventStatisticQueryForm from "../../components/adminStatistic/EventStatisticQueryForm";
import { StatApi } from "../../api/StatApi";
import EventStatCard from "../../components/adminStatistic/EventStatCard";
import SkillStatCard from "../../components/adminStatistic/SkillStatCard";
import { dateValueToYYYYMMDD } from "../../helper/TimeHelper";

export default function AdminEventStatSection({mode="admin", userID}) {
  const [eventList, setEventList] = useState([]);
  const [event, setEvent] = useState(null);
  const [eventStat, setEventStat] = useState(null);
  const [skillStatList, setSkillStatList] = useState([]);

  const isAdminUser = mode === "admin";
  userID = userID || 0;

  const { t } = useTranslation();

  let lang = getLang();

  useEffect(() => {
    if(isAdminUser) {
      loadAllEventList();
    } else {
      loadUserEventList();
    }
  }, []);


  const updateEventList = (newEventList) => {
    setEventList(newEventList);
    if (newEventList.length === 0) {
      setEvent(null);
    } else {
      setEvent(newEventList[0]);
    }
  }
  
  const loadUserEventList = async () => {
    // console.debug("currentMemberID: ", currentMemberID);
    const result = await InfoApi.getUserEventList(userID);
    console.debug("result: ", result);
    updateEventList(result);

  };

  const loadAllEventList = async () => {
    // console.debug("currentMemberID: ", currentMemberID);
    const result = await InfoApi.getEventList();
    console.debug("result: ", result);
    updateEventList(result);
    // setEventList(result);
  };

  const onQuery = async (inputData) => {
    console.debug("onQuery: ", inputData);
    const eventID = inputData.eventID || 0;
    // const timeslotID = inputData.timeslotID || 0;
    // const skillID = inputData.skillID || 0;
    const selectedDate = inputData.selectedDate || null;
    const selectedDateStr = selectedDate ? dateValueToYYYYMMDD(selectedDate) : "";

    console.debug("selectedDate: ", selectedDate);

    // InfoApi.getp
    const result = await StatApi.getEventStat(eventID, selectedDateStr);
    console.debug("result: ", result);
    if (!result) {
      alert("Failed to load statistics");
      return;
    }
    setEventStat(result.event_stat);
    setSkillStatList(result.skill_stat_list);
  }


  // const loadEventList = async () => {
  //   // console.debug("currentMemberID: ", currentMemberID);
  //   const result = await InfoApi.getEventList();
  //   console.debug("result: ", result);
  //   setEventList(result);
  //   if (result.length === 0) {
  //     setEvent(null);
  //   } else {
  //     setEvent(result[0]);
  //   }
  //   // setEventList(result);
  // };

  if (!event) {
    return <Box>Loading...</Box>;
  }

  const eventStatCard = eventStat ? <EventStatCard stat={eventStat} /> : <></>;
  const skillStatCards = skillStatList.map((skillStat) => {
    return <SkillStatCard stat={skillStat} />;
  });


  return (
    <Stack direction="column" spacing={2}>
      <EventStatisticQueryForm eventList={eventList} onQuery={onQuery}>

      </EventStatisticQueryForm>
      <Stack>
        {eventStatCard}
        {skillStatCards}
      </Stack>
      {/* {leaderboardTable} */}
      <Box>
        <pre>
          {/* {JSON.stringify(eventList, null, 2)} */}

          {/* {JSON.stringify(leaderboard, null, 2)} */}
          {/* {JSON.stringify(event, null, 2)} */}
        </pre>
      </Box>

    </Stack>
  );
}
